import {updateSupplierField} from './updateSupplierField';
import {create} from './create';
import * as set from './set';
import {merge} from './merge';
import * as validate from './validate';

export default {
    updateSupplierField,
    create,
    set,
    merge,
    validate
}