import { Modal } from "../modal";
import { screenSize } from "../../constants/screenSize";
import React from "react";
import styled from "styled-components";
import { Header } from "./parts/Header";
import { Sidebar } from "./parts/Sidebar";
import { fns, libTypes } from "@holta/lib";
import { ProductsTable } from "./parts/ProductsTable";
import { ProductBrowserContextProvider, useProductBrowserContext } from "./context";
import { Footer } from "./parts/Footer";
import { FilterInput } from "./parts/FilterInput";

interface Props {
    modalId: string;
    products: libTypes.Product[];
    categories: libTypes.Category[];
    handleConfirm: (product: libTypes.Product) => void;
}

export const ProductBrowser = ({ modalId, products, categories, handleConfirm } : Props) => {
    return (
        <ProductBrowserContextProvider categories={categories} products={products} handleConfirm={handleConfirm} modalId={modalId}>
            <ProductBrowserInner />
        </ProductBrowserContextProvider>
    )
}

export const ProductBrowserInner = () => {
    const { modalId, imgHover } = useProductBrowserContext();
    return (
        <S.Modal id={modalId} width="90vw">
            <>
                <Header />
                <S.ModalContentWrapper>
                    <Sidebar/>
                    <S.ModalBodyWrapper>
                        <FilterInput />
                        <ProductsTable />
                    </S.ModalBodyWrapper>
                </S.ModalContentWrapper>
                <Footer />
                {imgHover && <S.Image>
                    <img src={`/product-images/${imgHover}`} alt="" />
                </S.Image> }
            </>
        </S.Modal>
    );
};

/* --------------------------------- Styles --------------------------------- */

const S = {
    Modal: styled(Modal)`
        height: 90vh;

        @media (min-width: ${screenSize.sm}px) {
            height: 85vh;
        }
    `,

    ModalContentWrapper: styled.div`
        flex-grow: 1;
        display: flex;
        overflow: hidden;
    `,

    ModalBodyWrapper: styled.div`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,
    Image: styled.div`
        background-color: white;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
        border-radius: 5px;
        margin: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 100;
        transform: translate(-50%, -70%) ;
    `
};
