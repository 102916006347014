import {
    Container,
    FormDate,
    FormInput,
    FormLabel,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Menu,
    Popover,
    useDialog,
    Icon,
    IconButton,
} from "@holta/ui";
import React from "react";
import { useMenuButtonContext } from "@holta/ui";
import { InputCustomerSelect } from "../../../components/InputCustomerSelect";
import editOrderActions from "../../../actions/editOrder";
import { useStore } from "../../../config/store";
import { ModalConfirmUpdateOrderCustomer } from "./ModalConfirmCustomerChange";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { fns, libTypes } from "@holta/lib";
import { DeliveryDate } from "../../../components/InputDeliveryDate";
import { ProductionDate } from "../../../components/InputProductionDate";
import { hooks } from "@holta/ui";
import { useElementSize } from "usehooks-ts";
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import { staticData } from "@holta/lib";
import { InputCarcaseColour } from "../../../components/InputCarcaseColour";
import { InputGluedWallUnits } from "../../../components/InputGluedWallUnits";
import { InputDrawerbox } from "../../../components/InputDrawerbox";
import { InputConstructionType } from "../../../components/InputConstructionType";
import { InputVoid } from "../../../components/InputVoid";
import {InputSupplierSelect} from '../../../components/InputSupplierSelect';
import { InputSupplierRangeSelect } from "../../../components/InputRangeSelect";
import {getDay} from 'date-fns';
import { ModalDuplicateLinkedDoors } from "./ModalDuplicateLinkedDoors";

export const OrderDoors = () => {
    const { openModal } = useModal();
    const editOrder = useMergedEditOrder();
    const handleCustomerWarningCB = React.useRef(() => {});
    const [deliveryDateOpen, setDeliveryDateOpen] = hooks.useToggle(false);
    const [productionDateOpen, setProductionDateOpen] = hooks.useToggle(false);
    const [deliveryDateRef, deliveryDateSize] = useElementSize();
    const [productionDateRef, productionDateSize] = useElementSize();
    const { openGenericDialog } = useDialog();
    const {suppliers} = useStore(state => state);
    const [duplicateDoors, setDuplicateDoors] = React.useState<libTypes.LinkedDoorOrderItem[][][]>([]);
    const duplicateDoorsCb = React.useRef<(linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void>(() => () => {});

    const selectedSupplier = editOrder?.doorSupplier ? suppliers.find(s => s.id === editOrder.doorSupplier) : null;

    const handleDuplicates = (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][][], cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void) => {
        setDuplicateDoors(linkedDoorOrderItems);
        duplicateDoorsCb.current = (linkedDoorOrderItems) => {
            setDuplicateDoors((state) => [
                ...(state || []),
            ].slice(1));
            cb(linkedDoorOrderItems);
        };
        
    }

    if(duplicateDoors.length > 0) {
        openModal("MODAL_DUPLICATE_LINKED_DOORS");
    }

    if (!editOrder) return null;

    return (
        <div>
            {duplicateDoors.length > 0 && <ModalDuplicateLinkedDoors handleConfirm={duplicateDoorsCb.current} duplicateDoors={duplicateDoors[0]} /> }
            <hr />
            <Container xl="fixed">
                <Spacer xs={[3, 1]}>
                    <Spacer xs={[1]}>
                        <Text size="xl">Doors</Text>
                    </Spacer>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6 }}>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Has Doors
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <FormSelect
                                            value={editOrder.hasDoors ? { label: "Yes", value: true } : { label: "No", value: false }}
                                            options={[
                                                { label: "Yes", value: true },
                                                { label: "No", value: false },
                                            ]}
                                            style={{ width: "100%" }}
                                            onChange={(val: { value: boolean }) => {
                                                val?.value
                                                    ? editOrderActions.setHasDoors(val.value)
                                                    : editOrderActions.setHasDoors(false);
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                        </Grid.Col>
                        {editOrder.hasDoors && (
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[0.25, 1]}>
                                    <Grid.Row>
                                        <Grid.Col xs={{ cols: 4 }}>
                                            <FormLabel type="contained" position="left">
                                                Supplier
                                            </FormLabel>
                                        </Grid.Col>
                                        <Grid.Col xs={{ cols: 8 }}>
                                            <InputSupplierSelect onChange={editOrderActions.setDoorSupplier} value={editOrder.doorSupplier} />
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col xs={{ cols: 4 }}>
                                            <FormLabel type="contained" position="left">
                                                Range
                                            </FormLabel>
                                        </Grid.Col>
                                        <Grid.Col xs={{ cols: 8 }}>
                                            <InputSupplierRangeSelect supplierId={editOrder.doorSupplier} value={editOrder.doorRange} onChange={editOrderActions.setDoorRange}/>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col xs={{ cols: 4 }}>
                                            <FormLabel type="contained" position="left">
                                                Required Delivery Date
                                            </FormLabel>
                                        </Grid.Col>
                                        <Grid.Col xs={{ cols: 8 }}>
                                        <FormDate
                                                onChange={(d) => d && !Array.isArray(d) && editOrderActions.setSupplierDeliveryDate(d.getTime())}
                                                dateFormat="dd/MM/yyyy"
                                                selected={editOrder.supplierDeliveryDate ? new Date(editOrder.supplierDeliveryDate) : null}
                                                showWeekNumbers
                                            />
                                        </Grid.Col>
                                    </Grid.Row>

                                    {!editOrder.items.some(i => fns.order_item.isLinkedDoorItem(i) || fns.order_item.isMissingLinkedDoorItem(i)) && (
                                        <Grid.Row>
                                        <Grid.Col xs={{ cols: 4 }}>
                                            <button className="primary" onClick={() => editOrderActions.createLinkedDoors(handleDuplicates)}>Add Doors to order</button>
                                        </Grid.Col>
                                    </Grid.Row>
                                    )}
                                    {editOrder.items.some(i => fns.order_item.isLinkedDoorItem(i) || fns.order_item.isMissingLinkedDoorItem(i)) && (
                                        <Grid.Row>
                                        <Grid.Col xs={{ cols: 4 }}>
                                            <button className="primary" onClick={() => editOrderActions.replaceLinkedDoors(handleDuplicates)}>Replace Doors</button>
                                        </Grid.Col>
                                    </Grid.Row>
                                    )}
                                </Spacer>
                            </Grid.Col>
                        )}
                    </Grid.Row>
                </Spacer>
            </Container>
        </div>
    );
};
