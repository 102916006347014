import {Order} from '../../types';
import {orderStatuses} from '../../static_data/orderStatuses';
/**
 * 
 * Returns a readable version of the combined quote / order ref -- admin use only
 * 
 * @param  {Order} order
 * @returns string
 */
export function formatOrderRef(order: Order): string {
    const qRef = order.quoteRef ? order.quoteRef : "-";
    const oRef = order.orderRef ? order.orderRef : "-";

    if (order.orderStatus === orderStatuses.QUOTE.key) {
        return "Q." + qRef;
    } else if (order.orderStatus === orderStatuses.ORDER.key || order.orderStatus === orderStatuses.INVOICE.key) {
        return "Q." + qRef + " | #" + oRef;
    } else {
        return "";
    }
}
