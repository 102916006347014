import {OrderItem} from '../../types';
import Decimal from "decimal.js";
import { getDiscountPrice } from "../shared/getDiscountPrice";
import { getQuantityPrice } from "../shared/getQuantityPrice";
import { getM2Value } from "./getM2Value";
import { isMissingLinkedDoorItem } from './isMissingLinkedDoorItem';
import { isCarcaseItem } from './isCarcaseItem';

/**
 *
 * Gets the unit cost price of an order item, after discount 
 *
 * @param  {OrderItem} item
 * @returns string
 */
export function getOrderItemPrice(item: OrderItem): string {

    if(isMissingLinkedDoorItem(item)) throw new Error("Missing linked door item cannot be priced");
    
    const price = getDiscountPrice(item.price, item.discount);

    if (isCarcaseItem(item) && item.soldByM2) {
        const m2 = getM2Value(item);
        return new Decimal(price).mul(m2).toFixed(2);
    }

    return Number(price).toFixed(2);
}
