import { Product } from "../../types";
import { constructionTypes } from "../../static_data/constructionTypes";
import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";

export const categories = test((categories: Product["categories"]) => {
    verify(() => value(categories).isArray());
});

export const code = test((code: Product["code"], existingCodes: string[]) => {
    verify(() => value(code.toLowerCase()).isString().isTruthy().isNotWhitespace().isNotOneOf(existingCodes.map(c => c.toLowerCase()), "Product code already exists"));
});

export const component = test((component: Product["components"][number], allcomponents: Product["components"]) => {

    const idOccurences = allcomponents.filter(c => c.id === component.id).length;

    return {
        id: testInPlace(() => {
            verify(() => value(component.id).isUuid());
            verify(() => value(idOccurences).isMax(1));
        }),
        qty: testInPlace(() => {
            verify(() => value(component.qty).isNumber());
        }),
    };
});

export const components = test((components: Product["components"]) => {
    return {
        items: arrayOf(components, (c) => component(c, components)),
    };
});

export const createdBy = test((createdBy: Product["createdBy"]) => {
    verify(() => value(createdBy).isString().isTruthy().isNotWhitespace());
});

export const customerRestricted = test((customerRestricted: Product["customerRestricted"]) => {
    verify(() => value(customerRestricted).isBoolean());
});

export const customerWhitelistIds = test(
    (customerWhitelistIds: Product["customerWhitelistIds"]) => {
        verify(() => value(customerWhitelistIds).isArray());
    }
);

export const d = test((d: Product["d"]) => {
    verify(() => value(d).isNumber());
    passIf(() => d === null);
});

export const dateCreated = test((dateCreated: Product["dateCreated"]) => {
    verify(() => value(dateCreated).isNumber());
});

export const description = test((description: Product["description"]) => {
    verify(() => value(description).isString());
});

export const drawers = test((drawers: Product["drawers"]) => {
    verify(() => value(drawers).isNumber());
});

export const h = test((h: Product["h"]) => {
    verify(() => value(h).isNumber());
    passIf(() => h === null);
});

export const id = test((id: Product["id"]) => {
    verify(() => value(id).isUuid());
});

export const image = test((image: Product["image"]) => {
    verify(() => value(image).isString());
    passIf(() => image === null);
});

export const linkProduct = test((linkProduct: Product["linkProducts"][number]) => {
    return {
        constructionType: testInPlace(() => {
            verify(() =>
                value(linkProduct.constructionType).isOneOf([
                    "DEFAULT",
                    ...Object.keys(constructionTypes),
                ])
            );
        }),
        id: testInPlace(() => {
            verify(() => value(linkProduct.id).isUuid());
        }),
        qty: testInPlace(() => {
            verify(() => value(linkProduct.qty).isNumber().isMin(0));
        }),
    };
});

export const linkProducts = test((linkProducts: Product["linkProducts"]) => {
    return { items: arrayOf(linkProducts, linkProduct) };
});

export const price = test((price: Product["price"]) => {
    verify(() => value(price).isNumber());
});

export const priceType = test((priceType: Product["priceType"]) => {
    verify(() => value(priceType).isOneOf(["SIMPLE", "EXPRESSION"]));
});

export const priceExpression = test((priceExpression: Product["priceExpression"]) => {
    verify(() => value(priceExpression).isString());
    passIf(() => priceExpression === null);
});

export const soldByM2 = test((soldByM2: Product["soldByM2"]) => {
    verify(() => value(soldByM2).isBoolean());
});

export const specification = test((specification: Product["specification"]) => {
    verify(() => value(specification).isString());
});

export const w = test((w: Product["w"]) => {
    verify(() => value(w).isNumber());
    passIf(() => w === null);
});

export const product = test((product: Product, products: Product[]) => {
    return {
        categories: categories(product.categories),
        code: code(
            product.code,
            products.filter((p) => p.id !== product.id).map((p) => p.code)
        ),
        components: components(product.components),
        createdBy: createdBy(product.createdBy),
        customerRestricted: customerRestricted(product.customerRestricted),
        customerWhitelistIds: customerWhitelistIds(product.customerWhitelistIds),
        d: d(product.d),
        dateCreated: dateCreated(product.dateCreated),
        description: description(product.description),
        drawers: drawers(product.drawers),
        h: h(product.h),
        id: id(product.id),
        image: image(product.image),
        linkProducts: linkProducts(product.linkProducts),
        price: price(product.price),
        priceType: priceType(product.priceType),
        priceExpression: priceExpression(product.priceExpression),
        soldByM2: soldByM2(product.soldByM2),
        specification: specification(product.specification),
        w: w(product.w),
    };
});
