import { value } from "../validate";
import { verify } from "../validate";
import { SupplierBaseProduct } from "../../types";


export const code = (supplierBaseProduct: SupplierBaseProduct, code: SupplierBaseProduct["code"]): SupplierBaseProduct => {
    verify(() => value(code).isString());
    return {
        ...supplierBaseProduct,
        code,
    };
};


export const linkProductId = (
    supplierBaseProduct: SupplierBaseProduct,
    linkProductId: SupplierBaseProduct["linkProductId"]
): SupplierBaseProduct => {
    if(linkProductId !== null) {
        verify(() => value(linkProductId).isString().isNotEmpty().isNotWhitespace());
    }
    
    return {
        ...supplierBaseProduct,
        linkProductId,
    };
};


export const type = (supplierBaseProduct: SupplierBaseProduct, type: SupplierBaseProduct["type"]): SupplierBaseProduct => {
    verify(() => value(type).isOneOf(["UNIQUE", "RANGE"]));
    return {
        ...supplierBaseProduct,
        type,
    };
};

export const categories = (productComponent : SupplierBaseProduct, categories: SupplierBaseProduct["categories"]) => {
    if (!Array.isArray(categories)) throw new Error("categories must be an array");
    
    return {
        ...productComponent,
        categories: [...categories]
    };
}
