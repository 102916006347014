import Decimal from "decimal.js";
import { CutAndEdgeOrderGroupTypes } from "../../types";
import {constants} from '../../constants'

export const applyMFCThicknessSurcharge = (subTotal : number, boardType : keyof CutAndEdgeOrderGroupTypes) : number => {

    if (boardType === 'MFC_36') {
        const total = new Decimal(subTotal);

        if(total.isNaN()) throw new Error("Total is not a number");

        return total.mul(constants.MFC_36_SURCHARGE_MULTIPLE).toNumber();
    }

    return subTotal;

    
}

