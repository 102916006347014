import { feathersApp } from "../config/feathers";
import { libTypes } from "@holta/lib";
import { setProductionOrders } from "../actions/productionOrders";
import { useStore } from "../config/store";
import { add } from "date-fns";
import toast from "react-hot-toast";

let weekStart = 0;
let feathersSub: any;
export const useLoadProductionOrders = () => {
    const sub = useStore.subscribe((state) => {
        if (state.ui.production.weekStart !== weekStart) {
            feathersSub && toast.loading("Loading production orders...", { id: 'loading' });
            feathersSub && feathersSub.unsubscribe();
            weekStart = state.ui.production.weekStart;
            feathersSub = feathersApp
                .service("orders")
                .watch()
                .find({
                    query: {
                        $limit: 1000,
                        $sort: {
                            dateCreated: -1,
                        },

                        productionDateType: {
                            $ne: "NONE",
                        },
                        productionDate: {
                            $gte: weekStart,
                            $lt: add(new Date(weekStart), { days: 7 }).getTime(),
                        },
                    },
                })
                .subscribe(({ data }: { data: libTypes.Order[] }) => {
                    setTimeout(() => {
                        toast.dismiss('loading');
                    }, 500);
                    setProductionOrders(data);
                });
        }
    });
};
