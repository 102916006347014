import { ConstructionType } from "../../types/constructionType";
import { CarcaseOrderItem } from "../../types/orderItems";

export function getLinkProductIds(
    orderItem: CarcaseOrderItem,
    constructionType: ConstructionType
): { id: string; qty: number }[] {
    let constructionTypeLinkProductIds = orderItem.linkProducts.filter(
        (linkProduct) => linkProduct.constructionType === constructionType
    );
    constructionTypeLinkProductIds =
        constructionTypeLinkProductIds.length === 0
            ? orderItem.linkProducts.filter(
                  (linkProduct) => linkProduct.constructionType === "DEFAULT"
              )
            : constructionTypeLinkProductIds;

    return constructionTypeLinkProductIds.map((linkProduct) => ({
        id: linkProduct.id,
        qty: linkProduct.qty,
    }));
}
