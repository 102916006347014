import { taxGroups } from '../../static_data/taxGroups';
import { Address } from '../../types';
import { passIf, test, value, verify } from '../validate';




export const defaultContact = test((defaultContact : string | null) => {
    verify(() => value(defaultContact).isString());
    passIf(() => defaultContact === null);
})
export const defaultDeliveryAddress = test((defaultDeliveryAddress : string | null) => {
    verify(() => value(defaultDeliveryAddress).isString());
    passIf(() => defaultDeliveryAddress === null);
})
export const defaultInvoiceAddress = test((defaultInvoiceAddress : string | null) => {
    verify(() => value(defaultInvoiceAddress).isString());
    passIf(() => defaultInvoiceAddress === null);
})

export const discount = test((discount : number) => {
    verify(() => value(discount).isNumber());
});

export const name = test((name : string, existingNames: string[]) => {
    verify(() => value(name.toLowerCase()).isString().isTruthy().isNotWhitespace().isNotOneOf(existingNames.map(n => n.toLowerCase()), 'Customer already exists'));
});

export const notes = test((notes : string) => {
    verify(() => value(notes).isString());
});

//supplierDiscount
export const supplierDiscount = test((supplierDiscount : number) => {
    verify(() => value(supplierDiscount).isNumber());
});

//paymentTerms
export const paymentTerms = test((paymentTerms : string) => {
    verify(() => value(paymentTerms).isString());
});

//taxGroup 
export const taxGroup = test((taxGroup : string, taxGroups: string[]) => {
    verify(() => value(taxGroup).isString().isOneOf(taxGroups));
});

export const xeroContactId = test((xeroContactId : string | null) => {
    verify(() => value(xeroContactId).isUuid());
    passIf(() => xeroContactId === null);
});

export const customer = test((customer : any, existingNames: string[], taxGroups: string[]) => {
    return {
        defaultContact: defaultContact(customer.defaultContact),
        defaultDeliveryAddress: defaultDeliveryAddress(customer.defaultDeliveryAddress),
        defaultInvoiceAddress: defaultInvoiceAddress(customer.defaultInvoiceAddress),
        discount: discount(customer.discount),
        name: name(customer.name, existingNames),
        notes: notes(customer.notes),
        supplierDiscount: supplierDiscount(customer.supplierDiscount),
        paymentTerms: paymentTerms(customer.paymentTerms),
        taxGroup: taxGroup(customer.taxGroup, taxGroups),
        xeroContactId: xeroContactId(customer.xeroContactId)
    }
});