import { format, isValid } from "date-fns";

/**
 *
 * Format a datetime string to a standardised date format
 *
 * @param  {number} date
 * @returns string
 */

export function formatDate(date: number | Date): string {

    // first check its a valid date or number
    if (!isValid(date)) return "";
    
    // Convert to Date object if it's a number
    const dateObj = new Date(date);

    // Check if the date is valid
    if (isNaN(dateObj.getTime())) return "";

    // Create a formatter for UK timezone
    const formatter = new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });

    // Format the date
    return formatter.format(dateObj);
}
