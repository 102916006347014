import { findProductComponent } from "../productComponents/findProductComponent";
import { ProductComponent } from "../../types/productComponent";
import Decimal from "decimal.js";
import { Product } from "../../types";
import { getPrice as getComponentPrice } from "../productComponent/getPrice";
import { getComponentsTotal } from "./getComponentsTotal";

 export function getPrice(product : Product, components: ProductComponent[]) : number {


    const componentsPriceTotal = getComponentsTotal(product, components);

    const productAndComponentTotal = new Decimal(componentsPriceTotal).add(product.price)

    return parseFloat(productAndComponentTotal.toFixed(2));
}