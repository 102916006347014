import { findProductComponent } from "../productComponents/findProductComponent";
import { ProductComponent } from "../../types/productComponent";
import Decimal from "decimal.js";
import { Product } from "../../types";
import { getDiscountPrice } from "../shared/getDiscountPrice";
import {getMarginPrice} from "../shared/getMarginPrice";

export function getPrice(component: ProductComponent) : number {

    const discountPrice = getDiscountPrice(component.price, component.discount);
    const marginPrice = getMarginPrice(discountPrice, component.margin);

    return parseFloat(marginPrice);
}