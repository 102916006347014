import {SupplierOrderItem,CarcaseOrderItem, OrderItem} from '../../types';
import toArray from 'lodash/toArray';
import {isCarcaseItem} from '../order_item/isCarcaseItem';


/**
 * 
 * Filters just the carcase items from an order item list
 * 
 * @param  {OrderItems} items
 * @returns CarcaseOrderItem
 */
export function filterCarcaseItems(items : OrderItem[]) : CarcaseOrderItem [] {
    
    return items.filter(isCarcaseItem)
}


    
