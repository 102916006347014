import { MFCColour, MFCColours } from "../../types";

export const findMFCColour = (boardColours : MFCColour[]| MFCColours, code : MFCColour['code'], texture : MFCColour['texture']) : MFCColour  => {
    const match = boardColours.find(item => {
        return item.code === code && item.texture === texture;
    })

    if(!match) throw new Error("Board colour not found");

    return match;
    
}