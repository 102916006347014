// filter by string 
export function filterByString <Items extends Array<any>>(items: Items, key: keyof Items[number] | Array<keyof Items[number]>, value: string, fromStringStart?: boolean) : Items[number][] {
    if(!Array.isArray(items)) throw new Error("Items must be an array");
    if(items.length === 0) return items;

    const keys = Array.isArray(key) ? key : [key];
    
    return items.filter(item => {

        return keys.some(key => {
            if(!item[key] || typeof item[key] !== "string") return false;
            if(fromStringStart) return item[key].toLowerCase().startsWith(value.toLowerCase());
            return item[key].toLowerCase().includes(value.toLowerCase())
        });

    });
}