import { getLatestDate } from "../order/getLatestDate";
import { Order, OrderDateFilter } from "../../types";

export function filterByDateRange(
    orders: Order[],
    dateType: OrderDateFilter,
    dateFrom: number,
    dateTo: number | null
): Order[] {
    return orders.filter((order) => {
        let date = 0;
        switch (dateType) {
            case "CURRENT_STATUS":
                date = getLatestDate(order);
                break;
            case "CREATED":
                date = order.dateCreated;
                break;
            case "ORDERED":
                date = order.dateOrdered|| 0;
                break;
            case "INVOICED":
                date = order.dateInvoiced|| 0;
                break;
            case "DELIVERY":
                date = (order.deliveryDateType === 'WEEK' || order.deliveryDateType === 'DAY') && order.deliveryDate || 0;
                break;
            case "PRODUCTION":
                date = (order.productionDateType === 'WEEK' || order.productionDateType === 'DAY') && order.productionDate || 0;
                break;
            case "MODIFIED":
                date = order.editDate || order.dateCreated;
                break;
        }
        if(date === 0) return false;
        return date >= dateFrom && (dateTo ? date <= dateTo : true);
    });
}
