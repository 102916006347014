import {v4 as uuid} from 'uuid';
import { Address, AddressFields } from "../../types"

export const createFields = (address? : AddressFields | Address) : AddressFields => {

    return {

        addressLine1: address?.addressLine1 || "",
        addressLine2:  address?.addressLine2 ||"",
        addressLine3:  address?.addressLine3 || "",
        city:  address?.city || "",
        country: address?.country || "",
        county: address?.county || "",
        postcode: address?.postcode || ""
    }
    
}

