import styled from "styled-components";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../icon";

const Wrapper = styled.span`
    input {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        opacity: 0;

        &[disabled]+* {
            cursor: not-allowed;
        }
    }

    label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: ${(p) => p.theme.color.base_contrast};
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        width: ${({ theme }) => theme.spacing(2)};
        height: ${({ theme }) => theme.spacing(2)};
        border: 1px solid ${({ theme }) => theme.color.accent3};
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.base_contrast};
        transition: all 0.25s ease;
        position: relative;
        text-align: center;

        * {
            display: inline;
            opacity: 0;
            width: 80%;
        }
        
    }

    input:focus + label {
        outline: 2px solid ${({ theme }) => theme.color.highlight};
    }

    input:focus:checked + label {
        border: 1px solid ${({ theme }) => theme.color.base_contrast};
    }

    input:checked + label {
        border: 1px solid ${({ theme }) => theme.color.highlight};
        background-color: ${({ theme }) => theme.color.highlight};
        background-size: contain;
        background-position: center;

        * {
            opacity: 1;
        }
    }
`;

export const FormCheckbox = (props: React.AllHTMLAttributes<HTMLInputElement>) => {
    const id = React.useRef(uuidv4());

    return (
        <Wrapper>
            <input type="checkbox" id={props.id || id.current} {...props} />
            <label htmlFor={id.current}>
            <div><svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99999 10.172L16.192 0.979004L17.607 2.393L6.99999 13L0.635986 6.636L2.04999 5.222L6.99999 10.172Z" fill="currentColor"/>
</svg></div>

            </label>
        </Wrapper>
    );
};
