import styled, {css} from 'styled-components';
import { darken, lighten } from "polished"

export const Tag = styled.div<{type?: 1 | 2 | 3 | 4 | 5; size?: "s" | "l"}>`

    ${p => p.size === "l" && css`
        ${p => p.theme.type.m};
        padding: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(1)};
        border-radius: 4px;
    `};
    ${p => p.size !== "l" && css`
        ${p => p.theme.type.s};
        padding: ${p => p.theme.spacing(0.1)} ${p => p.theme.spacing(0.5)};
        border-radius: 1000px;
    `};
    border: 1px solid;
    display: inline-block;

    ${p => p.type === 1 && `
        background-color: ${p.theme.color.highlight2};
        border-color: ${darken(0.05, p.theme.color.highlight2)};
        color: ${p.theme.color.text};
    `}

    ${p => p.type === 2 && `
        background-color: ${p.theme.color.danger};
        border-color: ${darken(0.05, p.theme.color.danger)};
        color: ${p.theme.color.base_contrast};
    `}

    ${p => p.type === 3 && `
        background-color: ${p.theme.color.confirm};
        border-color: ${darken(0.05, p.theme.color.confirm)};
        color: ${p.theme.color.base_contrast};
    `}

    ${p => p.type === 4 && `
        background-color: ${p.theme.color.base_contrast};
        border-color: ${p.theme.color.base_contrast};
        color: ${p.theme.color.text};
    `}

    ${p => p.type === 5 && `
        background-color: ${p.theme.color.accent1};
        border-color: ${darken(0.05, p.theme.color.accent1)};
        color: ${p.theme.color.text};
    `}
    
`;