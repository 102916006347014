import React from 'react';
import {FormInput} from './formInput';

export const FormNumber = (props : React.ComponentProps<typeof FormInput> & Omit<React.ComponentProps<'input'>, 'value>'> & {isFloat? : boolean, allowNegative?: boolean}) => {

    const {onBlur, onChange, onFocus,value, placeholder, ...rest} = props;

    const [tempValue, setTempValue] = React.useState<string | number | readonly string[] | null>(value || null);


    const wrappedOnBlur = (e : React.FocusEvent<HTMLInputElement>) => {
        onBlur && onBlur(e);
        setTempValue(null);
    }

    const wrappedOnFocus = (e : React.FocusEvent<HTMLInputElement>) => {
        setTempValue(value ? value : placeholder || placeholder === '' ? placeholder : 0);
        onFocus && onFocus(e);
    }

    const wrappedOnChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        
        // if the value is a number or period
        if ((props.isFloat && e.target.value.match(props.allowNegative ? /^-?[0-9.]*$|^$/ : /^[0-9.]*$|^$/)) || (!props.isFloat && e.target.value.match(props.allowNegative ? /^-?[0-9]*$|^$/ : /^[0-9]*$|^$/))) {
            setTempValue(e.target.value);
            onChange && onChange(e);
        }
    }

    return (
        <FormInput
            onBlur={wrappedOnBlur}
            onFocus={wrappedOnFocus}
            onChange={wrappedOnChange}
            value={tempValue === null ? value : tempValue}
            placeholder={placeholder || placeholder === '' ? placeholder : 0}
            {...rest}
        ></FormInput>
    )
   
}