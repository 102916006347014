import {ProductComponent} from "../../types";


/*



    description: string;
    price: number;
    specification: string;
    supplierId: string | null;

    priceType: "SIMPLE" | "EXPRESSION";
    priceExpression: string;

    */


export const categories = (productComponent : ProductComponent, categories: ProductComponent["categories"]) => {
    if (!Array.isArray(categories)) throw new Error("categories must be an array");
    
    return {
        ...productComponent,
        categories: [...categories]
    };
}


export const code = (productComponent : ProductComponent, code: ProductComponent["code"]) => {
    if (typeof code !== 'string') throw new Error("code must be an string");
    
    return {
        ...productComponent,
        code
    };
}

export const d = (productComponent : ProductComponent, d: ProductComponent["d"]) => {
    let _d = d;
    if (typeof d !== 'number' || isNaN(d)) _d = null;
    
    return {
        ...productComponent,
        d: _d
    };
}

export const description = (productComponent : ProductComponent, description: ProductComponent["description"]) => {
    if (typeof description !== 'string') throw new Error("description must be an string");
    
    return {
        ...productComponent,
        description
    };
}


export const h = (productComponent : ProductComponent, h: ProductComponent["h"]) => {
    let _h = h;
    if (typeof h !== 'number' || isNaN(h)) _h = null;
    
    return {
        ...productComponent,
        h: _h
    };
}

export const price = (productComponent : ProductComponent, price: ProductComponent["price"]) => {
    let _price = price;
    if (typeof price !== 'number' || isNaN(price)) _price = 0;
    
    return {
        ...productComponent,
        price : _price
    };
}

export const discount = (productComponent : ProductComponent, discount: ProductComponent["discount"]) => {

    let _discount = discount;
    if (typeof discount !== 'number' || isNaN(discount)) _discount = 0;
    
    return {
        ...productComponent,
        discount: _discount
    };
}

export const margin = (productComponent : ProductComponent, margin: ProductComponent["margin"]) => {
    
    let _margin = margin;
    if (typeof margin !== 'number' || isNaN(margin)) _margin = 0;
    
    return {
        ...productComponent,
        margin: _margin
    };
}

export const specification = (productComponent : ProductComponent, specification: ProductComponent["specification"]) => {
    if (typeof specification !== 'string') throw new Error("specification must be an string");
    
    return {
        ...productComponent,
        specification
    }
}

export const supplierId = (productComponent : ProductComponent, supplierId: ProductComponent["supplierId"]) => {
    if (typeof supplierId !== 'string' && supplierId !== null ) throw new Error("supplierId must be an string or null");
    
    return {
        ...productComponent,
        supplierId
    }
}

export const priceExpression = (productComponent : ProductComponent, priceExpression: ProductComponent["priceExpression"]) => {
    if (typeof priceExpression !== "string" && priceExpression !== null ) throw new Error("priceExpression must be string");
    
    return {
        ...productComponent,
        priceExpression
    }
}

export const priceType = (productComponent : ProductComponent, priceType: ProductComponent["priceType"]) => {
    if (priceType !== "SIMPLE" && priceType !== "EXPRESSION" ) throw new Error("priceType must be 'SIMPLE' or 'EXPRESSION'");
    
    return {
        ...productComponent,
        priceType
    }
}

export const w = (productComponent : ProductComponent, w: ProductComponent["w"]) => {
    
    let _w = w;
    if (typeof w !== 'number' || isNaN(w)) _w = null;
    
    return {
        ...productComponent,
        w: _w
    };
}

