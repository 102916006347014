

// TODO: items types are urrently incompatible. Orders require an object but imports require an array. This needs to be unified 

import { CutAndEdgeOrder, Order } from "../../types";
import {v4 as uuid} from 'uuid';
import { create } from "../order/create";
import {merge} from '../order/merge';
import {mapCutAndEdgeToOrderItem} from './mapCutAndEdgeToOrderItem';
import { getMergedDeliveryAddress } from "./getMergedDeliveryAddress";

export const mapCutAndEdgeToOrder = (order: CutAndEdgeOrder) : Order => {

    const cutEdgeOrderItem = mapCutAndEdgeToOrderItem(order.items);

    const items = [
        cutEdgeOrderItem
    ] 


    let _order = create('0');
    
    _order = merge(_order, {
        customerRef : order.details.customerRef,
        deliveryAddress: {
            addressLine1 : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryAddressLine1 || '',
            addressLine2 : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryAddressLine2 || '',
            addressLine3 : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryAddressLine3 || '',
            city : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryAddressCity || '',
            county : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryAddressCounty || '',
            country: '',
            postcode : order.details.deliveryMethod === 'collection' ? 'Collection' : order.details.deliveryPostcode || '',
        },
        deliveryDateType: 'DAY',
        deliveryDate : order.details.deliveryDate,
        items
    })

    return _order;

}
