import {create} from './create';
import {merge} from './merge';
import * as validate from './validate';
import * as set from './set';

export default {
    create,
    merge,
    set,
    validate
}
