import { CutAndEdgeOrderGroup } from '../../types';
import {edgingTypes} from '../../static_data/edgingTypes';
import { isMFCGroup } from './isMFCGroup';
import { isSheetMaterialGroup } from './isSheetMaterialGroup';

export function getEdgingDisplayName (group : CutAndEdgeOrderGroup) : string {


    if(group.edgingType === 'NONE'){
        return edgingTypes.NONE.label;
    }
    
    if(isMFCGroup(group) && group.edgingColour) {
        return `${edgingTypes[group.edgingType].label} ${group.edgingColour?.code}-${group.edgingColour?.texture}`;
    }

    if(isSheetMaterialGroup(group) && group.edgingType === 'MATCHING') {

        return group.sheetMaterial.edgingDescription;
    }

    return '';

}


