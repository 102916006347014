import { isCarcaseItem } from "../order_item/isCarcaseItem";
import { CarcaseOrderItem, EditedOrderItem, OrderItem, SupplierOrderItem } from "../../types/orderItems";
import { isAnySupplierItem } from "../order_item/isAnySupplierItem";
import { isLinkedDoorItem } from "../order_item/isLinkedDoorItem";
import { isManuallyAddedSupplierItem } from "../order_item/isManuallyAddedSupplierItem";
import order_item from "../order_item";


export function discount(discount : number, items: OrderItem[], returnUpdatesOnly?: boolean) : typeof returnUpdatesOnly extends false ? OrderItem[] : EditedOrderItem[]  {

    let _items = items.filter(item => isCarcaseItem(item) && (item.discount !== discount || !returnUpdatesOnly)).map(item => {
        if(returnUpdatesOnly) {
            return {id : item.id, discount : order_item.set.discount(discount)};
        } else {
            return order_item.set.discount(discount, item as CarcaseOrderItem);
        }
    });

    return _items;

}


export function supplierDiscount(supplierDiscount : number, items: OrderItem[], returnUpdatesOnly?: boolean) : typeof returnUpdatesOnly extends false ? OrderItem[] : EditedOrderItem[]  {

    let _items = items.filter(item => (isLinkedDoorItem(item) || isManuallyAddedSupplierItem(item)) && (item.discount !== supplierDiscount || !returnUpdatesOnly)).map(item => {
        if(returnUpdatesOnly) {
            return {id : item.id, discount : order_item.set.discount(supplierDiscount)};
        } else {
            return order_item.set.discount(supplierDiscount, item as SupplierOrderItem);
        }
    });

    return _items;

}


