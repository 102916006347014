// Hooks
import {useSettings, SettingsProvider} from './hooks/useSettings';
import {useWindowSize} from './hooks/useWindowWidth';
import {useComponentWillMount} from './hooks/useComponentWillMount';
import { useWarnOnLeave } from './hooks/useWarnOnLeave';
import { useToggle } from './hooks/useToggle';


export const hooks = {
    useSettings, 
    SettingsProvider, 
    useWindowSize, 
    useComponentWillMount,
    useWarnOnLeave,
    useToggle
}

// Components
export {Accordion} from './components/accordion';
export {Arrow} from './components/arrow';
export {CircleButton} from './components/circleButton';
export {Container} from './components/container';
export {DialogProvider, useDialog, Dialog, GenericDialog} from './components/dialog';
export {DividerTitle} from './components/divider_title';
export {EdgingSelect} from './components/edging_select';
export {Ellipsis} from './components/ellipsis';
export {ErrorBoundary} from './components/errorBoundary';
export {FixedContainer} from './components/fixed_container';
export {FormCheckbox, FormDate, FormInput, FormItem, FormLabel, FormNotice, FormNumber, FormSelect, FormTextarea, FormToggle , FormValue, FormTags } from './components/form';
export {Grid} from './components/grid';
export {Header} from './components/header';
export {HighlightedText} from './components/highlightedText';
export {Icon} from './components/icon';
export {IconButton} from './components/icon_button';
export {IpadHeader} from './components/ipad_header';
export { IpadProductionGroup } from './components/ipad_production_group';
export {IpadProductionJob} from './components/ipad_production_job';
export {IpadProgress} from './components/ipad_progress';
export {Island, IslandAlt} from './components/island';
export {LoadingProvider, useLoading, Loading} from './components/loading';
export {Menu, MenuItem, useMenuButtonContext} from './components/menu';
export {ModalProvider, useModal, Modal , ModalHeader, ModalSubHeader, ModalBody, ModalFooter, ModalSidebar} from './components/modal';
export {NoResults} from './components/no_results';
export {Notice} from './components/notice';
export {OptionCard} from './components/option_card';
export {Overlay} from './components/overlay';
export {ProductionStausDot} from './components/productionStatusDot';
export {Spacer} from './components/spacer';
export {Step, StepStatus} from './components/step';
export {TableContext, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TdInput} from './components/table';
export {Tabs, Tab} from './components/tabs';
export {Tag} from './components/tag';
export {Text} from './components/text';
export {WeekSwitcher} from './components/week_switcher';
export {screenSize} from './constants/screenSize';
export {Tooltip} from './components/tooltip';
export {ProductBrowser} from './components/productBrowser';
export {Vr} from './components/vr';
export {VirtualisedTable} from './components/virtualisedTable';
export {PopoverComp as Popover} from './components/popover';
export {TreeNav} from './components/treeNav';

// Functions
export {registerImperativeGlobal} from './functions/registerImperativeGlobal';



// Theme
export {theme} from './theme';
export type {Colors} from './theme';
export {GlobalStyle} from './global_style';
