import {Category} from '../../types';


/**
 * 
 * Returns a list of category IDs that are hidden, either directly with 'public_hidden' or as a result of having a hidden parent
 * 
 * @param  {Category[]} categories
 * @returns string
 */

export function getHiddenCategoryList(categories : Category[]) : string[]{

    let hiddenIds: string[] = [];

    function getHiddenList(categories : Category[], parentId : string = '0', hasHiddenParent: boolean = false) {

        let children = categories.filter(child => {
            return child.parentId == parentId;
        });

        if (children.length > 0) {
            children.forEach(child => {
                if(hasHiddenParent || child.customerRestricted) {
                    hiddenIds.push(child.id);
                    getHiddenList(categories, child.id, true);
                } else {
                    getHiddenList(categories, child.id, false);
                }
            });
        }
    }

    getHiddenList(categories)

    return hiddenIds;

}