import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { LinkProduct } from "../../types";


export const id = test((id: LinkProduct["id"]) => {
    verify(() => value(id).isUuid());
});

export const name = test((name: LinkProduct["name"], existingNames: string[]) => {
    verify(() => value(name.toLowerCase()).isNotEmpty("Name is required").isNotWhitespace("Name is required").isNotOneOf(existingNames.map(ec => ec.toLowerCase()), "Name is already in use"));
});


export const h = test((h: LinkProduct["h"]) => {
    verify(() => value(h).isNumber());
    passIf(() => h === null);
});

export const w = test((w: LinkProduct["w"]) => {
    verify(() => value(w).isNumber());
    passIf(() => w === null);
});

export const d = test((d: LinkProduct["d"]) => {
    verify(() => value(d).isNumber());
    passIf(() => d === null);
});



export const linkProduct = test((linkProduct: LinkProduct, existingLinkProducts: LinkProduct[]) => {
    return {
        id : id(linkProduct.id),
        name : name(linkProduct.name, existingLinkProducts.filter(ec => ec.id !== linkProduct.id).map(c => c.name)),
        h : h(linkProduct.h),
        w : w(linkProduct.w),
        d : d(linkProduct.d)
    }
})