import {CarcaseOrderItem, OrderItem, SupplierOrderItem} from '../../types';

/**
 * 
 * Checks if an order item is a supplier item
 * 
 * @param  {CarcaseOrderItem|SupplierOrderItem} item
 * @returns itemisCarcaseOrderItem
 */
export function isManuallyAddedSupplierItem(item : OrderItem[][number]) : item is SupplierOrderItem {
    if(item.type === 'SUPPLIER' && item.supplierOrderItemType === 'MANUALLY_ADDED'){
        return true;
    }
    return false;
}


    
