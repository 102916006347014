import {CarcaseOrderItem, LinkedDoorOrderItem, OrderItem, SupplierOrderItem} from '../../types';

/**
 * 
 * Checks if an order item is a supplier item
 * 
 */
export function isAnySupplierItem(item : OrderItem[][number]) : item is (SupplierOrderItem | LinkedDoorOrderItem) {
    if(item.type === 'SUPPLIER'){
        return true;
    }
    return false;
}


    
