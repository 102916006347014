import Decimal from "decimal.js";
import { MFCPricingGroup, EdgingTypes } from "../../types";
import {constants} from '../../constants';

export const getMFCEdgingPrice = (boardPricingGroup : MFCPricingGroup, thickness :  Exclude<keyof EdgingTypes, "MATCHING" >) : number => {

    if(thickness === 'NONE') return 0;

    const edgingCost = boardPricingGroup[thickness];

    if(!edgingCost) throw new Error("Edging thickness not found");

    const price = new Decimal(edgingCost).mul(constants.MFC_BOARD_EDGING_MARGIN);

    if(price.isNaN()) throw new Error("Edging cost is not a number");
    
    return price.toNumber();
}
