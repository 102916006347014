import { SheetMaterials, SheetMaterial } from "../../types";

const sheetMaterialFilter = (sheetMaterial: SheetMaterial, filterVal: string) => {
    if (!filterVal) return true;
    const mergedString = sheetMaterial.name + sheetMaterial.description
    if (mergedString.toLowerCase().includes(filterVal.toLowerCase())) return true;
    return false;
};


export const filterSheetMaterialsByVal = (sheetMaterials : SheetMaterial[] | SheetMaterials, filterVal : string) => sheetMaterials.filter((sheetMaterial) =>
    sheetMaterialFilter(sheetMaterial, filterVal)
);


