

// Price charged for edging a single cut piece of board


import Decimal from "decimal.js";

export const getCutItemEdgingPrice = (price : number, width : number, height:number, h1: boolean, h2: boolean, w1: boolean, w2: boolean) : number => {

    let totalMm = 0;
    
    if(h1) totalMm += height;
    if(h2) totalMm += height;
    if(w1) totalMm += width;
    if(w2) totalMm += width;

    const total = new Decimal(totalMm).div(1000).mul(price);

    if(total.isNaN()) throw new Error("Item edging price total is not a number");
    
    return total.toNumber();
}


