import {Decimal} from 'decimal.js';

/**
 * 
 * returns a price with margin applied
 * 
 * @param  {string|number} price
 * @param  {string|number} margin
 * @returns string
 */
export function getMarginPrice(price : string | number , margin: string | number) : string {

    let _price = Number(price); // Convert price to a number or NaN
    _price = _price || 0 // If NaN, convert to 0

    let _margin = Number(margin); // Convert price to a number or NaN
    _margin = _margin || 0 // If NaN, convert to 0

    return new Decimal(_price).div(new Decimal(100).minus(_margin)).times(100).toFixed(2);

}