import { Order } from "../../types";
import { formatDate } from "../shared/formatDate";
import { deliveryDateTypes } from "../../static_data/deliveryDateTypes";

/**
 *
 * Takes an order and retruns a readable version of it's delivery date/date type
 *
 * @param  {Order} order
 * @returns string
 */

export function formatDeliveryDate(order: Order): string {
    // ASAP
    if (order.deliveryDateType === deliveryDateTypes.ASAP.key) {
        return "ASAP";
    }

    if ("deliveryDate" in order && order.deliveryDate) {
        // Week Commencing
        if (order.deliveryDateType === deliveryDateTypes.WEEK.key) {
            return "WC: " + formatDate(order.deliveryDate);
        }

        // Specific Date
        if (order.deliveryDateType === deliveryDateTypes.DAY.key) {
            /*if (order.originalDeliveryType === deliveryDateTypes.WC.key)
                return formatDate(order.deliveryDate) + " (Booked as WC)"; */
            return formatDate(order.deliveryDate);
        }
    }

    return "";
}
