export const constructionTypes = {
    LAYON : {
        label: "Lay On",
        key: "LAYON"
    },
    HANDLELESS_RAIL : {
        label: "Handless Rail",
        key: "HANDLELESS_RAIL"
    },
    IN_FRAME : {
        label: "In-Frame",
        key: "IN_FRAME"
    },
    HANDLELESS_CARCASE :{
        label: "Handleless Carcase",
        key: "HANDLELESS_CARCASE"
    }
} as const;