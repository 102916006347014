import styled from 'styled-components';

export const Island = styled.section<{p? : number, m? : number}>`

    border-radius: 4px;
    background-color: ${({theme}) => theme.color.base_contrast};
    border: 1px solid ${({theme}) => theme.color.accent2}; 
    margin:  ${({theme, m}) => m || m === 0 ? theme.spacing(m) : 0}; 
    padding:  ${({theme, p}) => p || p === 0 ? theme.spacing(p) : theme.spacing(2)}; 
`;

export const IslandAlt = styled(Island)`
    border: none;
    ${p => p.theme.dropShadow.s};
`;