import styled from "styled-components";
import "@reach/dialog/styles.css";
import {
    DialogOverlay,
    DialogContent,
} from "@reach/dialog";


export const StyledDialogOverlay : any = styled(DialogOverlay)`
    z-index: 1001;
    background-color: transparent;
    text-align: center;
    animation-name:intro;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    max-height: 100vh;
    overflow: hidden;

    &.isSidebar  {
        animation-name:introSidebar;
    }

    @keyframes intro {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes introSidebar {
        from {
            opacity: 0;
            transform: translateX(20px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &.isLeaving {
        animation-name: outro;
        animation-duration: 0.2s;

        &.isSidebar  {
        animation-name:outroSidebar;
        }

        @keyframes outro {
            from {
                opacity: 1;
                transform: translateY(0);
            }
            to {
                opacity: 0;
                transform: translateY(-10px);
            }
        }

        @keyframes outroSidebar {
            from {
                opacity: 1;
                transform: translateX(0);
            }
            to {
                opacity: 0;
                transform: translateX(20px);
            }
        }
    }


`;

export const StyledDialogContent : any = styled(DialogContent)<{width?: string }>`
    border-radius: 4px;
    width: ${props => props.width || 'auto'};
    max-width: 90vw;
    padding: ${({theme}) => theme.spacing(3)};
    position: relative;
    overflow: auto;
    max-height: 90vh;
    margin: 5vh auto 0 auto;
    padding: 0;
    display: inline-flex;
    flex-direction: column;

    .isSidebar & {
        border-radius: 4px 0 0 4px;
        margin:0;
        position: fixed;
        top: 0;
        right:0;
        bottom: 0;
        max-height: 100vh;
    }
`;


export const ClickBlocker = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

export const Overlay = styled.div`

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    position: relative;

    &.isOpen {
        position: absolute;
        animation-name: modalOverlayFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 0.25s;
        @keyframes modalOverlayFadeIn {
            from {background-color: rgba(0,0,0,0); }
            to { background-color: rgba(0,0,0,0.6); }
        }
    }

    &.isLeaving {
        position: absolute;
        animation-name: modalOverlayFadeOut;
        animation-fill-mode: forwards;
        animation-duration: 0.2s;
        @keyframes modalOverlayFadeOut {
            0% {background-color: rgba(0,0,0,0.6); opacity: 1;}
            99% { background-color: rgba(0,0,0,0); opacity: 1;}
            100% { position: relative; background-color: rgba(0,0,0,0); opacity: 0;}
        }
    }


`;


export const ModalHeader = styled.header<{secondary?: boolean}>`
    ${({theme}) => theme.type.xl};
    background-color:  ${({theme, secondary}) => secondary ? theme.color.base_contrast : theme.color.highlight};
    color:  ${({theme, secondary}) => secondary ? theme.color.text : theme.color.base_contrast};
    padding: ${({theme}) => theme.spacing(2)};
    display: flex; 
    justify-content: space-between;
    align-items: center;
`;

export const ModalSubHeader = styled.div`
    ${({theme}) => theme.type.l};
    color:  ${({theme}) => theme.color.accent5};
    border-bottom: 1px solid  ${({theme}) => theme.color.accent2};
    padding: ${({theme}) => theme.spacing(2)};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const ModalFooter = styled.footer`
    border-top: 1px solid  ${({theme}) => theme.color.accent2};
    padding: ${({theme}) => theme.spacing(2)};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.color.base_contrast};
`;

export const ModalBody = styled.article`
    text-align: left;
    background-color:  ${({theme}) => theme.color.accent1};
    min-height: 1px;
    overflow: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
`;

export const ModalSidebar = styled.aside`
    text-align: left;
    background-color:  ${({theme}) => theme.color.base_contrast};
    border-right: 1px solid ${({theme}) => theme.color.accent2};
    min-height: 1px;
    overflow: auto;
    flex-basis: 25%;
    -webkit-overflow-scrolling: touch;
`;

