import React from "react";
import styled from "styled-components";
import BarLoader from "react-spinners/BarLoader";
import { useLoading } from "./context";

const Container = styled.div`
    position: fixed;
    top: 40%;
    left: 50%;
    
    color: white;
    text-align: center;
    z-index: 1001;
    

    animation-name: intro;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;

    @keyframes intro {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.isLeaving {
        animation-name: outro;
        animation-duration: 0.2s;

        @keyframes outro {
            from {
                opacity: 1;
                transform: translateY(0);
            }
            to {
                opacity: 0;
                transform: translateY(-10px);
            }
        }
    }
`;

const Label = styled.h3`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${({ theme }) => theme.type.m};
    ${({ theme }) => theme.type.bold};
`;

const Inner  = styled.div`
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;


export const Loading = () => {
    const { isLoadingOpen, isLoadingClosing } = useLoading();

    const label = typeof isLoadingOpen() == "string" ? isLoadingOpen() : false;
    return (
        <>
            {(isLoadingOpen() || isLoadingClosing()) && (
                <Container className={(isLoadingClosing() && "isLeaving") || ""}>
                    <Inner>
                        {label && <Label>{label}</Label>}
                        <BarLoader color={"white"} loading={true} />
                    </Inner>
                </Container>
            )}
        </>
    );
};
