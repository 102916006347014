import { naturalSort } from './naturalSort';
// sort by string
export function sortByString <Items extends Array<any>>(items: Items, key: keyof Items[number], direction?: 'ASC' | 'DESC') : Items {
    if(!Array.isArray(items)) throw new Error("Items must be an array");
    if(items.length === 0) return items;


    let _items = [...items] as Items;
    
    if(!direction || direction === 'ASC') {
        return _items.sort((a, b) => naturalSort(a[key], b[key]));
    } else {
        return _items.sort((a, b) => naturalSort(b[key], a[key]));
    }
}