import React from "react";
import styled from "styled-components";
import { Icon } from "../icon";

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
`;

const HWrapper = styled.div``;



const VLine = styled.div<{checked : boolean}>`
    width: 4px;
    background-color: ${(props) => props.checked ? props.theme.color.text : props.theme.color.accent2};
    border-radius: 100px;
    height: ${(props) => props.theme.spacing(3.25)};
    transition: background 0.2s;
    margin-left: calc(50% - 1px);
`;

const Check = styled.div<{checked : boolean, top? : boolean, bottom?: boolean}>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${(p) => p.top ? 'calc(0% + 1px)' : p.bottom ? 'calc(100% - 1px)' : '50%'};
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1000px;
    background-color: ${(props) => props.checked ? props.theme.color.highlight : props.theme.color.accent2};
    color: white;
    font-size: ${(props) => props.theme.spacing(1.5)};
    width: ${(props) => props.theme.spacing(1.75)};
    transition: background 0.2s;
`;

const HLine = styled.div<{bottom : boolean, checked : boolean}>`
    height: 4px;
    background-color: ${(props) => props.checked ? props.theme.color.text : props.theme.color.accent2};
    border-radius: 100px;
    width: ${(props) => props.theme.spacing(7)};
    ${(props) => props.bottom && `margin-top: auto;`};
    transition: background 0.2s;
`;

const HContainer = styled.div`
    position: relative;
    height: ${(props) => props.theme.spacing(1.5)};
    display: flex;
    flex-direction: column;
    

    &:hover {
        cursor: pointer;

        ${HLine} {
            background-color: ${(p) => p.theme.color.highlight2}
        }
        ${Check} {
            background-color: ${(p) => p.theme.color.highlight2}
        }
    }
`;

const VContainer = styled.div`
    position: relative;
    width: ${(props) => props.theme.spacing(1.75)};
    &:hover {
        cursor: pointer;

        ${VLine} {
            background-color: ${(p) => p.theme.color.highlight2}
        }
        ${Check} {
            background-color: ${(p) => p.theme.color.highlight2}
        }
    }
`;



const VEdge = ({checked = false, onClick} : {checked? : boolean, onClick : () => void}) => {
    return (
        <VContainer onClick={onClick}>
            <VLine checked={checked}></VLine>
            <Check checked={checked}>
                <Icon name="check"/>
            </Check>
        </VContainer>
    );
};

const HEdge = ({bottom = false, top = false, checked = false, onClick}: {top?: boolean, bottom?: boolean, checked?: boolean, onClick : () => void}) => {
    return (
        <HContainer onClick={onClick}>
            <HLine bottom={bottom} checked={checked}></HLine>
            <Check checked={checked} bottom={bottom}  top={top} >
                <Icon name="check"/>
            </Check>
        </HContainer>
    );
};

interface EdgingSelectProps {
    l: boolean;
    r: boolean;
    t: boolean;
    b: boolean;
    lClick: () => void;
    rClick: () => void;
    tClick: () => void;
    bClick: () => void;
}
export const EdgingSelect = ({ l, r, t, b, lClick, rClick, tClick, bClick }: EdgingSelectProps) => {
    return (
        <Wrapper>
            <VEdge checked={l} onClick={lClick}></VEdge>
            <HWrapper>
                <HEdge top checked={t} onClick={tClick}></HEdge>
                <HEdge bottom checked={b} onClick={bClick}></HEdge>
            </HWrapper>
            <VEdge checked={r} onClick={rClick}></VEdge>
        </Wrapper>
    );
};
