import {v4 as uuid} from 'uuid';
import {Supplier, SupplierBaseProduct, SupplierProduct, SupplierRange, SupplierComputedProduct} from '../../types';
import { getSupplierProductPrice } from './getSupplierProductPrice';
import { findBaseProduct } from '../supplierBaseProduct/findBaseProduct';
import { findRange } from '../supplierRange/findRange';

export function createSupplierComputedProduct (product: SupplierProduct, baseProduct: SupplierBaseProduct[], supplier: Supplier, range?: SupplierRange[]) : SupplierComputedProduct
export function createSupplierComputedProduct (product: SupplierProduct, baseProduct: SupplierBaseProduct, supplier: Supplier, range?: SupplierRange) : SupplierComputedProduct 
export function createSupplierComputedProduct (product: SupplierProduct, baseProduct: SupplierBaseProduct | SupplierBaseProduct[], supplier: Supplier, range?: SupplierRange | SupplierRange[]) : SupplierComputedProduct   {

    if(!product.baseProductId) throw new Error('Product haas no base product');
    let _baseProduct;
    if(Array.isArray(baseProduct)) _baseProduct = findBaseProduct(product.baseProductId, baseProduct);
    else _baseProduct = baseProduct;
    if(!_baseProduct) throw new Error('Base product not found');

    let _range;
    if(Array.isArray(range) && product.supplierRangeId) _range = findRange(product.supplierRangeId, range);
    else if(range && !Array.isArray(range)) _range = range;

    return {
        id: product.id,
        baseProductId: product.baseProductId,
        categories: [],
        code: product.code,
        d: product.d,
        description: product.description,
        h: product.h,
        linkProductId: _baseProduct.linkProductId,
        price: getSupplierProductPrice(product, _baseProduct, supplier, _range),
        supplierId: product.supplierId,
        supplierRangeId: product.supplierRangeId,
        supplierRangeName: _range?.name || '',
        w: product.w,
        type: _baseProduct.type,
    }
}




    