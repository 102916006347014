import { TestResults, ValidationResults } from "../types";
import { results } from "./results";
import {cloneDeep} from 'lodash';


export function test<CB extends (...args: any[]) => TestResults | void>(
    cb: CB
): (...args: Parameters<CB> | []) => ValidationResults<ReturnType<CB>> {
    return function (...args) {
        results.current.push({
            _isValid: true,
            _errors: [],
        });
        results.draft = cloneDeep(results.current);
        const nestedTestResults = cb(...args);
        let result = results.draft[results.draft.length - 1];
        let returnResult;
        if (nestedTestResults && Array.isArray(nestedTestResults)) returnResult = nestedTestResults;
        else if (nestedTestResults) returnResult = { ...nestedTestResults, ...result};
        else returnResult = result;
        results.draft.pop();
        results.current = [...results.draft];
        return returnResult;
    };
}

export function testInPlace<CB extends () => TestResults | void>(cb: CB): ValidationResults<ReturnType<CB>>{
    return test(cb)();
}