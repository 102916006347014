import {MFCPricingGroups, MFCPricingGroup} from '../../types';


export const findMFCPricingGroup = (groups:MFCPricingGroups, group : MFCPricingGroup['group']) : MFCPricingGroup => {
    const match = groups.find(item => {
        return item.group === group;
    })

    if(!match) throw new Error("Board pricing group not found");

    return match;
}