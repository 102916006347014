import React from "react";
import { feathersApp } from "../config/feathers";
import { setActiveOrders } from "../actions/orders";
import { libTypes } from "@holta/lib";
import { AdminStore, useStore } from "../config/store";

let dateFrom = 0;

export const useLoadOrders = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);

    if (!hasRun.current) {
        const sub = useStore.subscribe((state) => {
            if (state.ui.orders.dateFrom !== dateFrom) {
                dateFrom = state.ui.orders.dateFrom;
                feathersApp
                    .service("orders")
                    .watch()
                    .find({
                        query: {
                            $limit: 1000,
                            $sort: {
                                dateCreated: -1,
                            },
                        },
                    })
                    .subscribe(({ data }: { data: libTypes.Order[] }) => {
                        //console.log("data", data);
                        setActiveOrders(data);
                        setIsLoaded(true);
                    });
            }
        });
        hasRun.current = true;
        
    }

    return isLoaded;
};
