import {Category} from '../../types';
/**
 * 
 * Returns an ordered array of a catagorie's parents
 * 
 * @param  {Category} category
 * @param  {Category[]} categories
 * @returns Category
 */
export function getParents(category: Category, categories: Category[]): Category[] {

    let parents: Category[] = []

    const _getParents = function(category: Category) {

        if(!category.parentId || category.parentId == '0') return false;

        const parent = categories.find(_category => {
            return _category.id == category.parentId;
        })
        
        if (parent) {
            parents.unshift(parent);
            _getParents(parent)
        }

    };

    _getParents(category);

    return parents;
}