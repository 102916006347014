import styled, { css } from "styled-components";

const hasClick = css`
    &:focus {
        border-color: ${({ theme }) => theme.color.highlight};
        box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.25);
    }

    &:hover:not(:focus) {
        border-color: ${({ theme }) => theme.color.accent3};
    }
`;

export const FormValue = styled.div`
    border: 1px solid ${({ theme }) => theme.color.accent2};
    padding: ${({ theme }) => theme.spacing(1.5)};
    border-radius: 4px;
    transition: border 0.2s, box-shadow 0.3s;
    color: ${({ theme }) => theme.color.accent4};
    flex: auto;
    background-color: ${({ theme }) => theme.color.base_contrast};
    height: 100%;
    ${(props) => props.onClick && hasClick}

`;
