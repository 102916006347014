
import { LinkedDoorOrderItem, MissingLinkedOrderItem, OrderItem, SupplierOrderItem } from '../../types';
import { isMissingLinkedDoorItem } from '../order_item/isMissingLinkedDoorItem';

export function filterMissingLinkedDoorItems(items : OrderItem[]) : MissingLinkedOrderItem[] {
    
    return items.filter(isMissingLinkedDoorItem)

}


    
