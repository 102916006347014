import { Order } from '../../types';
import { getAllOrderItemsExcludingDoorsTotal } from '../order_items/getAllOrderItemsExcludingDoorsTotal';
import { getAllOrderItemsTotal } from '../order_items/getAllOrderItemsTotal';


/**
 * 
 * Get the total cost of all items & sub items, with discount, without tax
 * 
 * @param  {Order} order
 * @returns string
 */
export function getOrderItemsPrice(order : Order) : string{

    if(order.hasDoors) return getAllOrderItemsTotal(order.items);
    return getAllOrderItemsExcludingDoorsTotal(order.items);
}