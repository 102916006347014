import { SupplierBaseProduct } from "../../types/supplierBaseProduct";
import { SupplierRange } from "../../types/supplierRange";
import { SupplierImportProduct } from '../../types/supplierImportProduct';
import { findRangeFromProductCode } from '../supplierRanges/findRangeFromProductCode';
import { getBaseProductCodeUsingRangePrefix } from '../supplierImportProduct/getBaseProductCodeUsingRangePrefix';

export function getUnmatchedSupplierImportProducts(
    baseProducts: SupplierBaseProduct[],
    ranges: SupplierRange[],
    supplierImportProducts: SupplierImportProduct[]
): {noMatch: SupplierImportProduct[], hasRangeOnly: SupplierImportProduct[]} {

    const uniqueBaseProducts = baseProducts.filter(bp => bp.type === 'UNIQUE');
    
    const uniqueProductCodes = uniqueBaseProducts.map((p) => p.code.toLowerCase());
    const productsWithoutMatchedUnique = supplierImportProducts.filter(product => !uniqueProductCodes.includes(product.code.toLowerCase()));

    const noMatch = productsWithoutMatchedUnique.filter(product => !findRangeFromProductCode(ranges, product.code));

    const hasRangeOnly = productsWithoutMatchedUnique.filter(product => {
        const range = findRangeFromProductCode(ranges, product.code);
        const baseProductCode = range && getBaseProductCodeUsingRangePrefix(product, range.prefix);

        return !baseProducts.find(bp => bp.code.toLowerCase() === baseProductCode?.toLowerCase());
      
    }).map(product => {
        const range = findRangeFromProductCode(ranges, product.code);
        return {
            ...product,
            matchedRangePrefix: range?.prefix
        };
    });

    return {
        noMatch,
        hasRangeOnly
    };

}
