import { taxGroups } from '../../static_data/taxGroups';
import { Address } from '../../types';
import { passIf, test, value, verify } from '../validate';




export const defaultContact = test((defaultContact : string | null) => {
    verify(() => value(defaultContact).isString());
    passIf(() => defaultContact === null);
})

export const discount = test((discount : number) => {
    verify(() => value(discount).isNumber());
});

export const margin = test((margin : number) => {
    verify(() => value(margin).isNumber());
});

export const name = test((name : string, existingNames: string[]) => {
    verify(() => value(name.toLowerCase()).isString().isTruthy().isNotWhitespace().isNotOneOf(existingNames.map(n => n.toLowerCase()), 'Supplier already exists'));
});

export const notes = test((notes : string) => {
    verify(() => value(notes).isString());
});



export const supplier = test((supplier : any, existingNames: string[], taxGroups: string[]) => {
    return {
        defaultContact: defaultContact(supplier.defaultContact),
        discount: discount(supplier.discount),
        margin: margin(supplier.margin),
        name: name(supplier.name, existingNames),
        notes: notes(supplier.notes),
    }
});