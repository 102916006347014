import { feathersApp } from "../config/feathers";
import { libTypes } from "@holta/lib";
import { setDeliveryOrders } from "../actions/deliveryOrders";
import { useStore } from "../config/store";
import { add, sub } from "date-fns";
import toast from "react-hot-toast";

let weekStart = 0;
let feathersSub: any;
export const useLoadDeliveryOrders = () => {
    
    useStore.subscribe((state) => {
        if (state.ui.delivery.weekStart !== weekStart) {
            feathersSub && toast.loading("Loading delivery orders...", { id: 'loading' });
            weekStart = state.ui.delivery.weekStart;
            feathersSub && feathersSub.unsubscribe();
            feathersSub = feathersApp
                .service("orders")
                .watch()
                .find({
                    query: {
                        $limit: 1000,
                        $sort: {
                            dateCreated: -1,
                        },
                        $or: [
                            {
                                deliveryDateType: {
                                    $eq: "ASAP",
                                },
                                productionStatus: {
                                    $ne: "DELIVERED",
                                },
                            },
                            {
                                deliveryDate: {
                                    //$gte: sub(new Date(weekStart), { days: 28 }).getTime(),
                                    //$lt: add(new Date(weekStart), { days: 28 }).getTime(),
                                    $gte: weekStart,
                                    $lt: add(new Date(weekStart), { days: 7 }).getTime(),
                                },
                                deliveryDateType: {
                                    $ne: "NONE",
                                },
                            },
                        ],
                    },
                })
                .subscribe(({ data }: { data: libTypes.Order[] }) => {
                    setTimeout(() => {
                        toast.dismiss('loading');
                    }, 500);
                    setDeliveryOrders(data);
                });
        }
    });
};
