import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import {Contact} from "../../types";

export const firstName = test((firstName: Contact["firstName"]) => {
    verify(() => value(firstName).isString());
})
    
export const lastName = test((lastName: Contact["lastName"]) => {
    verify(() => value(lastName).isString());
})
    
export const phone1 = test((phone1: Contact["phone1"]) => {
    verify(() => value(phone1).isString());
})
    
export const phone2 = test((phone2: Contact["phone2"]) => {
    verify(() => value(phone2).isString());
})
    
export const email = test((email: Contact["email"]) => {
    verify(() => value(email).isString());
})
    
export const fax = test((fax: Contact["fax"]) => {
    verify(() => value(fax).isString());
})
    

export const contact = test((contact : Contact) => {
    return {
        firstName : firstName(contact.firstName),
        lastName : lastName(contact.lastName),
        phone1 : phone1(contact.phone1),
        phone2 : phone2(contact.phone2),
        email : email(contact.email),
        fax : fax(contact.fax),
    }
})