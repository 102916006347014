import React from "react";

export const useDragOrderItems = () => {
    const [hoverId, setHoverId] = React.useState<string | number | null>(null);
    const [dragId, setDragId] = React.useState<string | null>(null);

    return {
        hoverId,
        setHoverId,
        dragId,
        setDragId,
    };
};
