import {Order} from '../../types';
import { getISOWeek } from 'date-fns';
import {productionDateTypes} from '../../static_data/productionDateTypes';

/**
 * 
 * Gets the production week of an order
 * 
 * @param  {Order} order
 * @returns string
 */

export function getProductionWeek(order: Order) : string {
    if (!order.productionDate || order.productionDateType === productionDateTypes.NONE.key || !order.productionDateType) {
        return "";
    } else {
        return getISOWeek(order.productionDate).toString();
    }
}