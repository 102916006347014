import {Decimal} from 'decimal.js';


/**
 * 
 * Multiplies the price of an item by its quantity
 * 
 * @param  {string|number} price
 * @param  {string|number} quantity
 * @returns string
 */
export function getQuantityPrice(price : string | number , quantity: string | number) : string {

    let _price = Number(price); // Convert price to a number or NaN
    _price = _price || 0 // If NaN, convert to 0

    const _quantity =  Number(quantity);

    if(!_quantity) return _price.toFixed(2); 
   
    const decimalPrice = new Decimal(_price).mul(_quantity)

    return decimalPrice.toFixed(2);
    
}