import React from "react";
import { WindowedMenuList } from "react-windowed-select";
import Select, {
    Props as RSProps,
    StylesConfig,
    components,
    MenuProps,
    GroupBase,
} from "react-select";
import styled, { createGlobalStyle } from "styled-components";

declare module "react-select/dist/declarations/src/Select" {
    export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
        onAddNew?: () => void;
    }
}

const GlobalSelectStyle = createGlobalStyle`

   

    .rs__control {
        // padding: calc(${({ theme }) => theme.spacing(2)} - 2px) !important;
        padding: ${({ theme }) => theme.spacing(1.5)} !important;
        border-width: 1px !important;
        border-color: ${({ theme }) => theme.color.accent2} !important;
        background-color: ${({ theme }) => theme.color.base_contrast} !important;
        &:hover:not(.rs__control--is-focused):not(.rs__control--menu-is-open) {
            border-color: ${({ theme }) => theme.color.accent3} !important;
        }

        &.rs__control--is-focused {
            border-color: ${({ theme }) => theme.color.highlight} !important;
            box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.25) !important;
        }

    }


    .rs__value-container {
        padding: 0 !important;

        * {
            padding: 0 !important;
            margin : 0 !important;
        }
        input {
            box-shadow : none!important;
        }
    }

    .rs__indicator-separator {
        margin : 0 !important;
    }

    .rs__indicator {
        padding: 0 ${({ theme }) => theme.spacing(1)} !important;
    }

    .rs__menu {
        overflow: hidden !important;
        background-color: ${({ theme }) => theme.color.base} !important; 
    }

    .rs__single-value {
        color: ${({ theme }) => theme.color.text} !important;

        &.rs__single-value--is-disabled{
            color: ${(p) => p.theme.color.accent3} !important;
        }

    }

    .rs__indicators-container {
        color: ${({ theme }) => theme.color.accent2};
    }

    .rs__option.rs__option--is-selected {
        background-color: ${({ theme }) => theme.color.highlight};
    }
    .rs__option.rs__option--is-focused {
        background-color: ${({ theme }) => theme.color.highlight2};
    }
    .rs__option.rs__option--is-focused.rs__option--is-selected {
        color: ${({ theme }) => theme.color.text};
    }

    .rs__multi-value {
        background-color: ${({ theme }) => theme.color.accent1} !important;
        padding: ${({ theme }) => theme.spacing(1)} !important;
        margin-right: ${({ theme }) => theme.spacing(0.5)} !important;
    }

    .rs__value-container.rs__value-container--is-multi.rs__value-container--has-value {
        margin: ${({ theme }) => theme.spacing(-0.5)} !important;
    }

    .rs__multi-value__remove {
        margin-left: ${({ theme }) => theme.spacing(0.5)} !important;
    }
`;

export const FormSelect = React.forwardRef(
    (
        props: Omit<RSProps<any>, "theme"> & {
            style?: React.CSSProperties;
            onAddNew?: () => void;
            isWindowed?: boolean;
        },
        ref
    ) => {
        const customStyles: StylesConfig<any, any> = {
            menuPortal: (base) => ({ ...base, zIndex: 1001 }),
            menu: (base) => ({
                ...base,
                whiteSpace: "nowrap",
                width: "auto",
                minWidth: "100%",
                right: 0,
            }),
            container: (base) => ({ ...base, flex: "auto", ...props.style }),
            placeholder: (base) => ({ ...base, position: "relative", top: 0, transform: "none" }),
        };

        return (
            <>
                <GlobalSelectStyle />
                <Select
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    ref={ref as any}
                    menuPlacement="auto"
                    classNamePrefix="rs"
                    closeMenuOnScroll={true}
                    components={{
                        ...(props.onAddNew ? { Menu: SelectMenuButton } : {}),
                        ...(props.isWindowed ? { MenuList: WindowedMenuList } : {}),
                    }}
                    {...props}
                />
            </>
        );
    }
);

const AddNewButton = styled.div`
    display: block;
    text-align: center;
    padding: ${({ theme }) => theme.spacing(0.5)};
    width: 100%;
    bottom: 0;
    border-top: 1px solid ${({ theme }) => theme.color.accent2};
    button {
        width: 100%;
        padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)} !important;
        color: ${({ theme }) => theme.color.accent3} !important;
        background-color: transparent !important;
        border-color: transparent !important;
        &:hover {
            color: ${({ theme }) => theme.color.base_contrast} !important;
            background-color: ${({ theme }) => theme.color.highlight} !important;
        }
    }
`;

const SelectMenuButton = (props: MenuProps) => {
    return (
        <components.Menu {...props}>
            <>
                {props.children}
                <AddNewButton>
                    {" "}
                    <button onClick={props.selectProps.onAddNew}>Add new...</button>
                </AddNewButton>
            </>
        </components.Menu>
    );
};
