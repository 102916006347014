import { MFCColour, MFCColours } from "../../types";

const boardColourFilter = (boardColour: MFCColour, filterVal: string) => {
    if (!filterVal) return true;
    const mergedString = boardColour.code + boardColour.texture + boardColour.decor_name;
    if (mergedString.toLowerCase().includes(filterVal.toLowerCase())) return true;
    return false;
};


export const filterMFCColoursByVal = (boardColours : MFCColour[] | MFCColours, filterVal : string) => boardColours.filter((boardColour) =>
    boardColourFilter(boardColour, filterVal)
);


