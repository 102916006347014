import {
    Island,
    Spacer,
    useLoading,
    useModal, 
} from "@holta/ui";
import { Header } from "./parts/Header";
import { OrderDetails } from "./parts/OrderDetails";
import { OrderItems } from "./parts/OrderItems";
import { OrderTabs } from "./parts/OrderTabs";
import { OrderTotals } from "./parts/OrderTotals";
import { OrderContextProvider, useOrderContext } from "./context";
import { OrderConstruction } from "./parts/OrderConstruction";
import {SupplierProductBrowser} from '../../components/SupplierProductBrowser';

import { useMatch, usePathParams, useHash } from "raviger";
import React from "react";
import editOrderActions from "../../actions/editOrder";
import {createNewOrder} from "../../actions/editOrder/createNewOrder";
import {createNewOrderFromCustomerOrder} from "../../actions/editOrder/createNewOrderFromCustomerOrder";
import { Settings } from "./parts/Settings";
import { ModalAddPayment } from "./parts/ModalPayment";
import { useStore } from "../../config/store";
import { OrderDoors } from "./parts/OrderDoors";
import { ModalChooseSupplierPurchaseOrder } from "./parts/ModalChooseSupplierPurchaseOrder";
import { ModalChooseSupplierPurchaseOrderSend } from "./parts/ModalChooseSupplierPurchaseOrderSend";
import { useDialogRequestCreateXeroInvoice } from "./parts/Xero";

export const OrderInner = () => {
    const { closeAll } = useModal();
    const isNew = useMatch("/orders/new");
    const params = usePathParams("/orders/:id");
    const { openLoading, closeLoading } = useLoading();
    const { tabs } = useOrderContext();
    const editOrder = useStore(state => state.editOrder);
    const suppliers = useStore(state => state.suppliers);
    const hash = useHash() 
    useDialogRequestCreateXeroInvoice();

    React.useEffect(() => {
        async function init() {
            // This covers creating a new order just via URL
            if (isNew && !editOrder?.currentValues.order.id && !editOrder?.newValues?.order.id) {
                if(hash) createNewOrderFromCustomerOrder(hash);
                else createNewOrder();
            } 
            
            else if (!isNew && params?.id) {
                
                const to = setTimeout(() => {
                    openLoading();
                }, 1000);
                await editOrderActions.loadOrder(params.id);
                clearTimeout(to);
                closeLoading(200);
            } 
        }

        init();

        return () => {
            editOrderActions.clearAll();
        };
    }, [isNew, params?.id]);

    React.useEffect(() => {
        return () => {
            closeAll();
            editOrderActions.clearAll();
        };
    }, []);

    if(!editOrder) return null;
    return (
        <div style={{ width: "100%" }}>
            <Header />
            <Spacer xs={[0, 2, 6, 2]}>
                <Island p={0}>
                    <OrderTabs />
                    {tabs.current === 'details' && (
                        <>
                            <OrderDetails />
                            <OrderConstruction />
                            <OrderDoors />
                            <OrderItems />
                            <OrderTotals />
                        </>
                    )}
                    {tabs.current === 'settings' && (
                        <>
                            <Settings />
                        </>
                    )}
                </Island>
            </Spacer>
            <ModalAddPayment /> 
            <ModalChooseSupplierPurchaseOrder />
            <ModalChooseSupplierPurchaseOrderSend />
            <SupplierProductBrowser modalId="SUPPLIER_PRODUCT_BROWSER" products={[]} categories={[]} handleConfirm={(item)=>{editOrderActions.createSupplierItem(item); closeAll();}} suppliers={suppliers}/>
        </div>
    );
};

export const Order = () => (
    <OrderContextProvider>
        <OrderInner></OrderInner>
    </OrderContextProvider>
);
