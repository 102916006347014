import {Order} from '../../types';
import {isSameDay} from 'date-fns';
import {deliveryDateTypes} from '../../static_data/deliveryDateTypes';

export function filterByDeliveryDay(orders : Order[], date : number ) : Order[]{

    return orders.filter(order=> {
        return order.deliveryDateType === deliveryDateTypes.DAY.key && order.deliveryDate && isSameDay(order.deliveryDate, date);
    })

}       