export const sheetMaterials = [
    {
        "name": "18MM Standard MDF",
        "code": "STDMDF18",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
    {
        "name": "18MM Moisture Resistant MDF",
        "code": "MRMDF18",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
    {
        "name": "25MM Standard MDF",
        "code": "STDMDF25",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
    {
        "name": "25MM Moisture Resistant MDF",
        "code": "MRMDF25",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
    {
        "name": "18MM Hardwood Ply",
        "code": "HWPLY18",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
    {
        "name": "25MM Hardwood Ply",
        "code": "HWPLY25",
        "price": 0,
        "isPoa": true,
        "edgingPrice": null,
        "description": '',
        "edgingDescription": ''
    },
] as const;