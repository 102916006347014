import {Category} from '../../types';
import {getHeirachicalName} from '../category/getHeirachicalName';
import {naturalSort} from '../shared/naturalSort';

// sort categories by heirachical name
export const sortByHeirachicalName = (categories: Category[], dir: "ASC" | "DESC") => {
    return categories.sort((a, b) => {

        let aName = getHeirachicalName(b, categories);
        let bName = getHeirachicalName(a, categories);
        
        if(dir === "DESC"){
            aName = getHeirachicalName(a, categories);
            bName = getHeirachicalName(b, categories);
        }

        return naturalSort(aName, bName);
    });
}




