

import * as Popover from '@radix-ui/react-popover';
import styled from "styled-components";


export const Root = styled(Popover.Root)``;

export const Trigger = styled(Popover.Trigger)``;

export const Content = styled(Popover.Content)`
    border-radius: 4px;
    padding: 20px;
    width: fit-content;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    
    &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
    }
    &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
    }
    &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
    }
    &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
    }
`;

export const Arrow = styled(Popover.Arrow)``;

export const Portal = styled(Popover.Portal)``;

export const Close = styled(Popover.Close)``;

export const Anchor = styled(Popover.Anchor)``;


export const PopoverComp = {
    Root,
    Trigger,
    Content,
    Arrow,
    Portal,
    Close,
    Anchor
}