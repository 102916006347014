export function anyOf(...tests: (() => boolean)[]): boolean {
    for (const test of tests) {
        try {
            const result = test();
            if (result) return true;
        } catch (error) {
            
        }
    }
    return false;
}