import React from "react";
import {
    Accordion as ReachAccordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from "@reach/accordion";
import { Icon } from "../icon";
import "@reach/accordion/styles.css";
import styled from "styled-components";

const Container = styled(ReachAccordion)`
    .AccordionButton {
        width: 100%;
        box-shadow: none;
        padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
        cursor: pointer;
        background-color: ${(p) => p.theme.color.accent2};
        ${(p) => p.theme.type.m};
        ${(p) => p.theme.type.bold};
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            color: ${(p) => p.theme.color.accent4};
        }
    }

    [data-reach-accordion-item] {
        transition: all 0.2s;
        border: 1px solid ${(p) => p.theme.color.accent2};
        border-radius: 8px;
    }
    [data-reach-accordion-item][data-state="open"] {
        border: 1px solid ${(p) => p.theme.color.accent5};

        .AccordionButton {
            color: ${(p) => p.theme.color.accent2};
            background-color: ${(p) => p.theme.color.accent5};

            i {
                color: ${(p) => p.theme.color.accent2};
                transform: rotate(45deg);
            }
        }
    }
    [data-reach-accordion-item][data-state="collapsed"] {
        /* styles for all collapsed accordion items */
    }
    [data-reach-accordion-item][data-disabled] {
        /* styles for all disabled accordion items */
    }
    [data-reach-accordion-item][data-read-only] {
        /* styles for all read-only accordion items */
    }
`;

const Button = ({ children }: { children: React.ReactNode }) => {
    return (
        <AccordionButton as="div" className="AccordionButton">
            {children}
            <Icon name="add-circle" type="fill"></Icon>
        </AccordionButton>
    );
};

export const Accordion: {
    Container: (props: {
        children: React.ReactNode;
        collapsible?: boolean;
        defaultIndex?: number;
        index?: number;
        onChange?(index?: number): void;
        readOnly?: boolean;
        multiple?: boolean;
    }) => React.ReactElement;
    Item: (props: { children: React.ReactNode }) => any;
    Button: (props: { children: React.ReactNode }) => any;
    Panel: (props: { children: React.ReactNode }) => any;
} = {
    Container,
    Item: AccordionItem,
    Button,
    Panel: AccordionPanel,
};
