import { Order, EditedOrderValues } from "../../types";
import { merge as mergeOrderItems } from "../order_items/merge";
import type { PartialDeep } from "type-fest";

/**
 * Merges an order with another order/partial order.
 * Merged values are cloned where neccessary but the resulting order is NOT a deep clone.
 * Use lodash cloneDeep if mutation is required on the resulting order.
 * @param order
 * @param changes
 */

export function merge(order: Order, changes: EditedOrderValues): Order {
    const mergedOrder = { ...order };
    if (changes && Object.keys(changes).length !== 0 && changes.constructor === Object) {
        (Object.keys(changes) as Array<keyof Order>).forEach(<T extends keyof Order>(key: T) => {
            if (key === "items") {
                if( changes.items && changes.items.length) mergedOrder.items = mergeOrderItems(order.items, changes.items);
            } else if (key === "payments" && changes.payments && changes.payments.length > 0) {
                mergedOrder.payments = changes.payments
                    .filter((payment) => !payment._deleted)
                    .concat(
                        order.payments.filter(
                            (payment) =>
                                !changes.payments!.find((change) => change.id === payment.id)
                        )
                    );
            } else if (key === "carcaseColour" && changes.carcaseColour !== undefined) {
                mergedOrder.carcaseColour = changes.carcaseColour;
            } else if (changes[key] || changes[key] === 0 || changes[key] === "" || changes[key] === false) {
                mergedOrder[key] = changes[key] as Order[T];
            }
        });
    }
    return mergedOrder;
}
