
import {FormSelect} from '@holta/ui';
import {staticData, fns, libTypes} from '@holta/lib';


const options = fns.shared.formSelect.createOptions('key', 'label', Object.values(staticData.constructionTypes));

export const InputConstructionType = ({value, onChange } : {value : libTypes.EditedOrderValues['constructionType'], onChange: (value: libTypes.ConstructionType) => void}) => {

    const selected = value ? fns.shared.formSelect.createValueOption(value, 'key', 'label', Object.values(staticData.constructionTypes)) : null;

    return (
        <FormSelect options={options} style={{ width: "100%" }} onChange={(val : {value : libTypes.ConstructionType}) => {onChange(val.value)}} value={selected}/>
    )
}