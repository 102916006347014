import { SheetMaterial, SheetMaterials } from "../../types";


export const findSheetMaterial = (sheetMaterials : SheetMaterial[]| SheetMaterials, code : SheetMaterial['code']) : SheetMaterial  => {
    const match = sheetMaterials.find(item => {
        return item.code === code;
    })

    if(!match) throw new Error("Sheet material not found");

    return match;
    
}