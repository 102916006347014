import React, { ReactElement } from 'react';

export interface ModalContextType {
    openModal : (id : string) => void;
    closeModal : (id : string, callback? : () => void) => void;
    closeAll : (callback? : () => void) => void;
    isModalOpen : (id? : string) => boolean;
    isModalClosing : (id? : string) => boolean
    isTopModal : (id : string) => boolean;
}

const defaultFunctions = {
    openModal : () => {},
    closeModal : () => {},
    closeAll : () => {},
    isModalOpen : () => false,
    isModalClosing : () => false,
    isTopModal: () => false
}


export const ModalContext = React.createContext<ModalContextType>(defaultFunctions as ModalContextType);

export const useModal = () : ModalContextType => React.useContext(ModalContext);



const ModalProviderRenderFunction:React.ForwardRefRenderFunction<ModalContextType, {children : React.ReactNode}>= ({children}, ref) : ReactElement => {

    const [openModals, setOpenModal] = React.useState(new Set());
    const [closingModals, setClosingModal] = React.useState(new Set());

    const openModal : ModalContextType['openModal'] = id  => {
        if(!openModals.has(id)) {
            const nextState = new Set(openModals);
            nextState.add(id);
            setOpenModal(nextState);
        }
    }
    const closeModal : ModalContextType['closeModal'] = (id, callback) => {
        if(openModals.has(id)) {
            const nextState = new Set(openModals);
            nextState.delete(id);
            setOpenModal(nextState);
            
            if(!closingModals.has(id)) {
                const nextState = new Set(closingModals);
                nextState.add(id);
                setClosingModal(nextState);
                cleanUpClosingModals(callback)
            }
        }
    }

    const closeAll : ModalContextType['closeAll'] = (callback) => {

            setOpenModal(new Set());
                const nextState = new Set(closingModals);
                openModals.forEach(id => {
                    nextState.add(id);
                });
                setClosingModal(nextState);
                cleanUpClosingModals(callback)
            
        
    }

    const isTopModal : ModalContextType['isTopModal'] = id => {
        const openModalsArray = Array.from(openModals);
        return openModalsArray[openModalsArray.length - 1] === id;
    }

    const isModalOpen : ModalContextType['isModalOpen'] = id => {
        if(!id && openModals.size > 0) return true;
        if(openModals.has(id)) return true;
        return false;
    }

    const isModalClosing : ModalContextType['isModalClosing'] = id => {
        if(!id && closingModals.size > 0) return true;
        if(closingModals.has(id)) return true;
        return false;
    }


    function cleanUpClosingModals(callback? : () => void){
        setTimeout(() => {
            setClosingModal(new Set())
            if(callback) callback();
        }, 200)
    }

    React.useImperativeHandle(ref, () => ({
        openModal : openModal,
        closeModal : closeModal,
        isModalOpen : isModalOpen,
        isModalClosing : isModalClosing,
        isTopModal: isTopModal,
        closeAll : closeAll
    }));

    return (
        <ModalContext.Provider value={{openModal, closeModal, closeAll, isModalOpen, isModalClosing, isTopModal}} >
            {children}
        </ModalContext.Provider>
    )

};

// NOTE: This can be writter more succinctly by using an anonymous function in the forward ref function which automatically infers the ForwardRefRenderFunction type
export const ModalProvider = React.forwardRef<ModalContextType, {children : React.ReactNode}>(ModalProviderRenderFunction);