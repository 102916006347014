import {LinkProduct} from "../../types";


export const categories = (linkProduct : LinkProduct, categories: LinkProduct["categories"]) => {
    if (!Array.isArray(categories)) throw new Error("categories must be an array");
    
    return {
        ...linkProduct,
        categories: [...categories]
    };
}


export const name = (linkProduct : LinkProduct, name: LinkProduct["name"]) => {
    if (typeof name !== 'string') throw new Error("name must be an string");
    
    return {
        ...linkProduct,
        name
    };
}

export const d = (linkProduct : LinkProduct, d: LinkProduct["d"]) => {
    if (typeof d !== 'number' && typeof d !== null) throw new Error("d must be an number or null");
    
    return {
        ...linkProduct,
        d
    };
}

export const description = (linkProduct : LinkProduct, description: LinkProduct["description"]) => {
    if (typeof description !== 'string') throw new Error("description must be an string");
    
    return {
        ...linkProduct,
        description
    };
}


export const h = (linkProduct : LinkProduct, h: LinkProduct["h"]) => {
    if (typeof h !== 'number' && typeof h !== null) throw new Error("h must be an number or null");
    
    return {
        ...linkProduct,
        h
    };
}

export const w = (linkProduct : LinkProduct, w: LinkProduct["w"]) => {
    if (typeof w !== 'number' && typeof w !== null) throw new Error("w must be an number or null");
    
    return {
        ...linkProduct,
        w
    };
}

