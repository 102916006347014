export const productionStatuses = {
    PROVISIONAL: {
        label: "Provisional",
        key: "PROVISIONAL"
    },
    IN_PRODUCTION: {
        label: "In Production",
        key: "IN_PRODUCTION"
    },
    CUT: {
        label: "Cut",
        key: "CUT"
    },
    EDGED: {
        label: "Edged",
        key: "EDGED"
    },
    MACHINED: {
        label: "Machined",
        key: "MACHINED"
    },
    BUILT: {
        label: "Built",
        key: "BUILT"
    },
    COMPLETE: {
        label: "Complete",
        key: "COMPLETE"
    },
    DELIVERED: {
        label: "Delivered",
        key: "DELIVERED"
    },
} as const;
