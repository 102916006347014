import {Supplier} from '../../types';
/**
 * 
 * Returns a list of suppliers, filtered by a list of IDs
 * 
 * @param  {Array<string>} ids
 * @param  {Supplier[]} suppliers
 * @returns Supplier
 */
export function filterById(ids : Array<string>, suppliers : Supplier[]) : Supplier[] {
    return suppliers.filter(supplier => ids.includes(supplier.id))
}