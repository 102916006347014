
import {  LinkProduct } from "../../types";
import {v4 as uuid} from 'uuid';

export const create = (fields? : Partial<LinkProduct>) : LinkProduct => {     
    return  {
        dateCreated: new Date().getTime(),
        id: uuid(),
        name: "",
        categories: [],
        description: "",
        h: null,
        d: null,
        w: null,
        ...fields
}};