import { OrderItem } from "../../types";
import { filterLinkedDoorItems } from "./filterLinkedDoorItems";
import Decimal from "decimal.js";
import {getOrderItemTotal} from "../order_item/getOrderItemTotal";

export function getLinkedDoorItemsTotal(orderItems : OrderItem[]) : string {

    const filtererd = filterLinkedDoorItems(orderItems);
    let total = new Decimal(0);

    filtererd.forEach(item => {
        total = total.add(getOrderItemTotal(item));
    });

    return total.toFixed(2);

}