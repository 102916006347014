import { filterBranch } from '../categories/filterBranch';
import {Category, Product} from '../../types';

export const filterByCategories = (products: Product[], categoryIds: string[], categories: Category[]) : Product[] => {
    let categoryBranches : string[][] = [];
    categoryIds.forEach(id => {
        const branch = filterBranch(categories, id, true, true).map(cat => cat.id);
        branch && categoryBranches.push(branch);
    });


    return products.filter(product => {

        return categoryBranches.every(branch => {
            return branch.some(category => product.categories.includes(category));
        });

    })
} 