export const accountingStatuses = {
    NONE: {
        label : 'None',
        key: 'NONE'
    },
    ORDERED: {
        label : 'Ordered',
        key: 'ORDERED'
    },
    INVOICED: {
        label : 'Invoiced',
        key: 'INVOICED'
    },
    PAID: {
        label : 'Paid',
        key: 'PAID'
    }
} as const;
