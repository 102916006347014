import { CutAndEdgeOrderDetails } from "../../types";


export function getMergedDeliveryAddress(orderDetails : CutAndEdgeOrderDetails) {
    let address = orderDetails.deliveryAddressLine1 ? orderDetails.deliveryAddressLine1 + '\n' : '';
    address += orderDetails.deliveryAddressLine2 ? orderDetails.deliveryAddressLine2 + '\n' : "";
    address += orderDetails.deliveryAddressLine3 ? orderDetails.deliveryAddressLine3 + '\n' : "";
    address += orderDetails.deliveryAddressCity ? orderDetails.deliveryAddressCity + '\n' : "";
    address += orderDetails.deliveryAddressCounty ? orderDetails.deliveryAddressCounty + '\n' : "";
    address += orderDetails.deliveryPostcode ? orderDetails.deliveryPostcode : "";
    return address;
}