import { OrderItem } from '../../types';
import { getQuantityPrice } from '../shared/getQuantityPrice';
import { getOrderItemPrice } from './getOrderItemPrice';
import {isCarcaseItem} from './isCarcaseItem';
import { isMissingLinkedDoorItem } from './isMissingLinkedDoorItem';

/**
 *
 * Gets the price of an order item, after discount and quantity is applied 
 *
 * @param  {CarcaseOrderItem} item
 * @returns string
 */
export function getOrderItemTotal(item: OrderItem): string {

    if(isMissingLinkedDoorItem(item)) throw new Error("Missing linked door item cannot be priced");

    return getQuantityPrice(getOrderItemPrice(item), item.quantity)
}
