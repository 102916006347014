import {SupplierOrderItem,CarcaseOrderItem, OrderItem, EditedOrderItem} from '../../types';
import {orderItem as validateOrderItem} from '../order_item/validate';

import {combineEdits as combineOrderItemEdits} from '../order_item/combineEdits';

/**
 * Merges an object of order items with another object of full or partial order items.
 * Merged values are cloned where neccessary but the result is NOT a deep clone.
 * @param oldItems 
 * @param newItems 
 */
// prettier-ignore

export function combineEdits(oldItems : EditedOrderItem[], newItems: EditedOrderItem[]) : EditedOrderItem[]{

    let merged : EditedOrderItem[] = [];

    // 1. Update any old items with new values or remove if deleted.
    oldItems.forEach(oldItem => {
        const updatedValues = newItems.find(newItem => newItem.id === oldItem.id);
        if(updatedValues){
            merged.push(combineOrderItemEdits(oldItem, updatedValues));
        } else {
            merged.push(oldItem);
        }
    });

    // 2. Add new items.
    newItems.forEach(newItem => {

        if(!oldItems.find(oldItem => oldItem.id === newItem.id)){

            if( !validateOrderItem(newItem)._isValid) {
                console.error("Invalid order item", validateOrderItem(newItem));
                //throw new Error("Invalid order item");
            }
            merged.push(newItem as OrderItem);
        }
    });

    return merged;
    
}

