import toast from "react-hot-toast";
import { feathersApp } from "../../config/feathers";
import { getEditOrder } from "./getEditOrder";
import { getMergedOrder } from "./getMergedOrder";
import { fns } from "@holta/lib";
import { user } from "../../fns/user";
import { loadOrder } from "./loadOrder";
import { navigate } from "raviger";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { requestCreateXeroInvoice } from "./requestCreateXeroInvoice";
export const saveOrder = async () => {
    const editOrder = getEditOrder();
    const type = getEditOrder().type;
    const order = getMergedOrder();
    const edits = getEditOrder().newValues.order;
    // TODO: Validate order
    // Validation should be context specific (e.g. when saving, when sending and printing)
    if (type === "NEW") {
        if (true || fns.order.validate.order(order)._isValid) {
            const returnedOrder = await feathersApp.service("orders").create(order);
            window.history.pushState({}, "", `/orders/${returnedOrder.id}`);
            await loadOrder(returnedOrder.id);
            if(returnedOrder.orderRef) {
                requestCreateXeroInvoice();
            }
        } else {
            toast.error("Order is not valid");
        }
    } else if (type === "EDIT") {
        if (true || fns.order.validate.order(order)._isValid) {
            try {
                const returnedOrder = await feathersApp.service("orders").patch(order.id, order);
                await feathersApp
                    .service("history")
                    .create({ parentId: order.id, userId: user.id, data: edits });
                await loadOrder(returnedOrder.id);
                toast.success("Order saved");
                if(!order.orderRef && returnedOrder.orderRef) {
                    requestCreateXeroInvoice();
                } 
                return;
            } catch (error) {
                toast.error("" + error);
            }
        } else {
            toast.error("Order is not valid");
        }
    }

};