import React from "react";
import styled from "styled-components";
import { Icon } from "../icon";
import { Text } from "../text";
import { Spacer, SpaceProp } from "../spacer";
import {Colors} from '../../theme';
import {screenSize} from '../../constants/screenSize';

export enum StepStatus {
    Default,
    Current,
    Complete,
    Error
}

const Container = styled(Spacer)<{inline : boolean, isCurrent? : boolean}>`
    display: inline-flex;
    flex-direction: ${({inline}) => inline ? 'row' : 'column'};
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
    ${(props) => {
        if ((props.onClick || props.onDoubleClick) && !props.isCurrent)
            return `
        &:hover {
            cursor: pointer;
            opacity: 0.6
        }`;
    }}
`;

const Circle = styled.div<{ status: StepStatus, small : boolean, inline : boolean, currentColor? : keyof Colors }>`
    border-radius: 1000px;
    border-width: 2px;
    width: ${({ theme, small }) => theme.spacing(2)};
    height: ${({ theme, small }) => theme.spacing(2)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${({ theme, inline }) => inline ? theme.spacing(1) : 0};;
    border-style: solid;
    ${p => p.theme.type.s};

    @media (min-width: ${screenSize.xs}px) {
        width: ${({ theme, small }) => small ? theme.spacing(2) : theme.spacing(3)};
        height: ${({ theme, small }) => small ? theme.spacing(2) : theme.spacing(3)};
        ${p => p.theme.type.m};
    }
    @media (min-width: ${screenSize.md}px) {
        width: ${({ theme, small }) => small ? theme.spacing(3) : theme.spacing(4)};
        height: ${({ theme, small }) => small ? theme.spacing(3) : theme.spacing(4)};
    }
   

    ${p => p.status === StepStatus.Default && `
        border-color: ${p.theme.color.accent3};
        background-color: transparent;
        color: ${p.theme.color.accent4};
    `}

    ${p => p.status === StepStatus.Current && `
        border-color: ${p.theme.color.highlight};
        background-color: ${p.theme.color.highlight};
        color: ${p.theme.color.base_contrast};
    `}

    ${p => p.status === StepStatus.Complete && `
        border-color: ${p.theme.color.highlight};
        background-color: transparent;
        color: ${p.theme.color.highlight};
    `}

    ${p => p.status === StepStatus.Error && `
        border-color: ${p.theme.color.danger};
        background-color: ${p.theme.color.danger};
        color: ${p.theme.color.base_contrast};
    `}

`;

interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
    stepNumber: string | number;
    status: StepStatus;
    space?: SpaceProp;
    small?: boolean;
    inline?: boolean
}

export const Step = ({
    text,
    stepNumber,
    status = StepStatus.Default,
    space = [0],
    small = false,
    inline = false,
    ...rest
}: StepProps
) => {
    return (
        <Container xs={space} inline={inline} {...rest} isCurrent={status === StepStatus.Current}>
            <Circle status={status} small={small} inline={inline}>
                {status !== StepStatus.Complete ? stepNumber : <Icon name="check" />}
            </Circle>
            <Text size="m" bold color={status === StepStatus.Default ? "accent4" : status === StepStatus.Complete ? "highlight" : "text"}>
                {text}
            </Text>
        </Container>
    );
};


