import { create } from "mutative";
import { useStore } from "../../config/store";

export const requestCreateXeroInvoice = () => {
    const editOrder = useStore.getState().editOrder;
    if (editOrder) {
        useStore.setState({
            editOrder: create(editOrder, (draft) => {
                draft.requestedActions.push({
                    type: "REQUEST_CREATE_XERO_INVOICE",
                });
            }),
        });
    }
};
