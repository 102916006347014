import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedEditOrder = () : libTypes.Order | null => {
    const editOrder = useStore(state => state.editOrder?.newValues.order);
    const existingOrder = useStore(state => state.editOrder?.currentValues.order);

    
    if(!existingOrder) return null;
    if(!editOrder) return existingOrder;
    return fns.order.merge(existingOrder, editOrder);

}