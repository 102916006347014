import styled from "styled-components";
import {lighten} from 'polished';


export const FormNotice = styled.div<{type? : 'warning' | 'notice'}>`
    ${({ theme }) => theme.type.s};
    padding: ${({ theme }) => theme.spacing(1)};
    border-radius: 4px;
    transition: border 0.2s, box-shadow 0.3s;
    margin-top: 2px;
    color: ${({ theme }) => theme.color.danger};
    flex: auto;
    background-color: ${({ theme }) => lighten(0.3, theme.color.danger)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};

    ${p => p.type === 'notice' && ` 
    
        background-color: ${p.theme.color.accent2};
        color: ${p.theme.color.accent4}
    
    `}
`;

FormNotice.defaultProps = {
    type : 'warning'
}