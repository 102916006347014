
import {  Category } from "../../types";
import {v4 as uuid} from 'uuid';

export const create = (createdBy : string, fields? : Partial<Category>) : Category => {     
    return  {
        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        id: uuid(),
        name: "",
        parentId: null,
        customerRestricted: false,
        customerWhitelistIds: [],
        ...fields
}};