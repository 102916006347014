import { Spacer, Grid, OptionCard } from "@holta/ui";
import { useModalSupplierProductImport } from "../context";

export const FinishImport = () => {
    const { chosenImportType, setChosenImportType, doImport } = useModalSupplierProductImport();

    return (
        <Spacer xs={[1]}>
            <Grid.Row xs={{ wrap: "wrap", align: "center", justify: "center" }}>
                <Grid.Col xs={{ cols: 3 }}>
                    <Spacer xs={[1]}>
                        <OptionCard
                            onClick={() => setChosenImportType("UPDATE")}
                            isChosen={chosenImportType === "UPDATE"}
                            heading="Update"
                            subheading="Update existing products with this import (no products are deleted)"
                            icon="checkbox-blank"
                        />
                    </Spacer>
                </Grid.Col>
                <Grid.Col xs={{ cols: 3 }}>
                    <Spacer xs={[1]}>
                        <OptionCard
                            onClick={() => setChosenImportType("REPLACE_RANGE")}
                            isChosen={chosenImportType === "REPLACE_RANGE"}
                            heading="Replace Range"
                            subheading="Delete all products in relevant ranges and replace with this import"
                            icon="checkbox-multiple-blank"
                        />
                    </Spacer>
                </Grid.Col>
                <Grid.Col xs={{ cols: 3 }}>
                    <Spacer xs={[1]}>
                        <OptionCard
                            onClick={() => setChosenImportType("REPLACE_ALL")}
                            isChosen={chosenImportType === "REPLACE_ALL"}
                            heading="Replace All"
                            subheading="Delete all existing products for this supplier and replacewith this import"
                            icon="checkbox-multiple"
                        />
                    </Spacer>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row xs={{ wrap: "wrap", align: "center", justify: "center" }}>
                <button className="primary" disabled={!chosenImportType} onClick={doImport}>
                    Import
                </button>
            </Grid.Row>
        </Spacer>
    );
};
