
// sort by string
export function sortByNumerical <Items extends Array<any>>(items: Items, key: keyof Items[number], direction?: 'ASC' | 'DESC') : Items {
    if(!Array.isArray(items)) throw new Error("Items must be an array");
    if(items.length === 0) return items;
   
    
    if(!direction || direction === 'ASC') {
        return items.sort((a, b) => a[key] - b[key]);
    } else {
        return items.sort((a, b) => b[key] - a[key]);
    }
}