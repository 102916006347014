import { create } from './create';
import { formatDeliveryDate } from './formatDeliveryDate';
import { formatOrderRef } from './formatOrderRef';
import { formatProductionDate } from './formatProductionDate';
import { getCustomerName } from './getCustomerName';
import { getLatestDate } from './getLatestDate';
import { getOrderItemsPrice } from './getOrderItemsPrice';
import { getOrderSize } from './getOrderSize';
import { getPaymentsTotal } from './getPaymentsTotal';
import { getProductionWeek } from './getProductionWeek';
import { getRemainingBalance } from './getRemainingBalance';
import { getSupplierIds } from './getSupplierIds';
import { getTaxPrice } from './getTaxPrice';
import { getTotal } from './getTotal';
import { hasDeliveryDate } from './hasDeliveryDate';
import { isAsapDelivery } from './isAsapDelivery';
import { isCarcaseColour } from './isCarcaseColour';
import { isDayDelivery } from './isDayDelivery';
import { isDeleted } from './isDeleted';
import { isDeliveredOnDate } from './isDeliveredOnDate';
import { isProductionStatus } from './isProductionStatus';
import { isWeekDelivery } from './isWeekDelivery';
import {getOrderTotalOrEstimate} from './getOrderTotalOrEstimate';
import { merge } from './merge';
import * as set from './set';
import * as validate from './validate';
import { combineEdits } from './combineEdits';

const defaultExport = {
    create,
    combineEdits,
    formatDeliveryDate,
    formatOrderRef,
    formatProductionDate,
    getCustomerName,
    getLatestDate,
    getOrderItemsPrice,
    getOrderSize,
    getPaymentsTotal,
    getProductionWeek,
    getRemainingBalance,
    getSupplierIds,
    getTaxPrice,
    getTotal,
    hasDeliveryDate,
    isAsapDelivery,
    isCarcaseColour,
    isDayDelivery,
    isDeleted,
    isDeliveredOnDate,
    isProductionStatus,
    isWeekDelivery,
    getOrderTotalOrEstimate,
    merge,
    set,
    validate,
}

export default defaultExport;