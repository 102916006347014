import { TestResults } from "../validate/types";
import { CarcaseOrderItem, LinkedDoorOrderItem, MissingLinkedOrderItem, OrderItem, SupplierOrderItem,  } from "../../types";
import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { isCarcaseItem } from "./isCarcaseItem";
import { isAnySupplierItem } from "./isAnySupplierItem";
import { orderItemTypes } from "../../static_data/orderItemTypes";
import { isMissingLinkedDoorItem } from "./isMissingLinkedDoorItem";


export const type = test((type: Exclude<OrderItem, MissingLinkedOrderItem>["type"] | undefined) => {
    verify(() => value(type).isOneOf([...Object.keys(orderItemTypes)]));
});

export const code = test((code: Exclude<OrderItem, MissingLinkedOrderItem>["code"] | undefined) => {
    verify(() => value(code).isString().isTruthy().isNotWhitespace());
});

export const d = test((d: CarcaseOrderItem["d"] | undefined) => {
    verify(() => value(d).isNumber());
    passIf(() => (d as any) === null);
});

export const dEdited = test((dEdited: CarcaseOrderItem["dEdited"] | undefined) => {
    verify(() => value(dEdited).isNumber());
    passIf(() => (dEdited as any) === null);
});

export const dateCreated = test((dateCreated: CarcaseOrderItem["dateCreated"] | undefined) => {
    verify(() => value(dateCreated).isNumber());
});


export const discount = test(
    (discount: Exclude<OrderItem, MissingLinkedOrderItem>["discount"] | undefined) => {
        verify(() => value(discount).isNumber());
        passIf(() => (discount as any) === null);
    }
);

export const description = test(
    (description: (SupplierOrderItem | LinkedDoorOrderItem)["description"] | undefined) => {
        verify(() => value(description).isString());
    }
);

export const linkProductId = test((linkProductId: (SupplierOrderItem | LinkedDoorOrderItem)["linkProductId"] | undefined) => {
    verify(() => value(linkProductId).isString());
    passIf(() => (linkProductId as any) === null);
})

export const supplierId = test((supplierId: (SupplierOrderItem | LinkedDoorOrderItem)["supplierId"] | undefined) => {
    verify(() => value(supplierId).isString());
})

export const supplierProductId = test((supplierProductId: (SupplierOrderItem | LinkedDoorOrderItem)["supplierProductId"] | undefined) => {
    verify(() => value(supplierProductId).isString());
})

export const supplierRangeId = test((supplierRangeId: (SupplierOrderItem | LinkedDoorOrderItem)["supplierRangeId"] | undefined) => {
    verify(() => value(supplierRangeId).isString());
    passIf(() => (supplierRangeId as any) === null);
})

export const supplierOrderItemType = test((supplierOrderItemType: (SupplierOrderItem | LinkedDoorOrderItem)["supplierOrderItemType"] | undefined) => {
    verify(() => value(supplierOrderItemType).isOneOf(['LINKED_DOOR', 'MANUALLY_ADDED']));
})

export const parentId = test((parentId: (SupplierOrderItem | LinkedDoorOrderItem)["parentId"] | undefined) => {
    verify(() => value(parentId).isString());
    passIf(() => (parentId as any) === null);
})

export const linkProducts = test((linkProducts: CarcaseOrderItem["linkProducts"] | undefined) => {
    verify(() => value(linkProducts).isArray());
})

export const draftHEdited = test((hEdited: CarcaseOrderItem["hEdited"]  | undefined) => {
    verify(() => value(hEdited).isNumber());
    passIf(() => hEdited === null);
});

export const h = test((h: CarcaseOrderItem["h"] | undefined) => {
    verify(() => value(h).isNumber());
    passIf(() => (h as any) === null);
});

export const hEdited = test((hEdited: CarcaseOrderItem["hEdited"] | undefined, soldByM2: CarcaseOrderItem['soldByM2']) => {
    if (soldByM2) {
        verify(() => value(hEdited).isNumber().isMin(1));
    }
    verify(() => value(hEdited).isNumber());
    passIf(() => hEdited === null);
});

export const handing = test((handing: CarcaseOrderItem["handing"] | undefined) => {
    verify(() => value(handing).isOneOf(["L", "R"]));
    passIf(() => handing === null);
});

export const id = test((id: CarcaseOrderItem["id"] | undefined) => {
    verify(() => value(id).isUuid());
});

export const notes = test(
    (notes: Exclude<OrderItem, MissingLinkedOrderItem>["notes"] | undefined) => {
        verify(() => value(notes).isString());
    }
);

export const position = test((position: CarcaseOrderItem["position"] | undefined) => {
    verify(() => value(position).isString());
});

export const price = test((price: CarcaseOrderItem["price"] | undefined) => {
    verify(() => value(price).isNumber());
});

export const quantity = test(
    (quantity: Exclude<OrderItem, MissingLinkedOrderItem>["quantity"] | undefined) => {
        verify(() => value(quantity).isNumber());
    }
);

export const w = test((w: CarcaseOrderItem["w"] | undefined) => {
    verify(() => value(w).isNumber());
    passIf(() => (w as any) === null);
});

export const wEdited = test((wEdited: CarcaseOrderItem["hEdited"] | undefined, soldByM2: CarcaseOrderItem['soldByM2']) => {
    if (soldByM2) {
        verify(() => value(wEdited).isNumber().isMin(1));
    }
    verify(() => value(wEdited).isNumber());
    passIf(() => wEdited === null);
});

export const draftWEdited = test((wEdited: CarcaseOrderItem["wEdited"]  | undefined) => {
    verify(() => value(wEdited).isNumber());
    passIf(() => wEdited === null);
});

// No need to validate this really
export const productId = test((productId: any) => {
    passIf(() => true === true);
});

export const soldByM2 = test((sortOrder: CarcaseOrderItem["soldByM2"] | undefined) => {
    verify(() => value(sortOrder).isBoolean());
    passIf(() => sortOrder === null);
});

export const sortOrder = test((sortOrder: OrderItem["sortOrder"] | undefined) => {
    verify(() => value(sortOrder).isNumber());
    passIf(() => sortOrder === null);
});

export const carcaseOrderItem = test((orderItem: CarcaseOrderItem) => {
    return {
        type: type(orderItem.type),
        code: code(orderItem.code),
        d: d(orderItem.d),
        dateCreated: dateCreated(orderItem.dateCreated),
        dEdited: dEdited(orderItem.dEdited),
        discount: discount(orderItem.discount),
        description: description(orderItem.description),
        specification: description(orderItem.description),
        linkProducts: linkProducts(orderItem.linkProducts),
        h: h(orderItem.h),
        hEdited: hEdited(orderItem.hEdited, orderItem.soldByM2),
        handing: handing(orderItem.handing),
        id: id(orderItem.id),
        notes: notes(orderItem.notes),
        position: position(orderItem.position),
        price: price(orderItem.price),
        quantity: quantity(orderItem.quantity),
        w: w(orderItem.w),
        wEdited: wEdited(orderItem.wEdited, orderItem.soldByM2),
        productId: productId(orderItem.productId),
        soldByM2: soldByM2(orderItem.soldByM2),
        sortOrder: sortOrder(orderItem.sortOrder),
    };
});

export const supplierOrderItem = test((orderItem: SupplierOrderItem | LinkedDoorOrderItem) => {
    return {
        code: code(orderItem.code),
        d: d(orderItem.d),
        dateCreated: dateCreated(orderItem.dateCreated),
        description: description(orderItem.description),
        discount: discount(orderItem.discount),
        h: h(orderItem.h),
        id: id(orderItem.id),
        linkProductId: linkProductId(orderItem.linkProductId),
        notes: notes(orderItem.notes),
        parentId: parentId(orderItem.parentId),
        price: price(orderItem.price),
        quantity: quantity(orderItem.quantity),
        sortOrder: sortOrder(orderItem.sortOrder),
        supplierId: supplierId(orderItem.supplierId),
        supplierProductId: supplierProductId(orderItem.supplierProductId),
        supplierRangeId: supplierRangeId(orderItem.supplierRangeId),
        supplierOrderItemType: supplierOrderItemType(orderItem.supplierOrderItemType),
        type: type(orderItem.type),
        w: w(orderItem.w),
    };
});

export const missingLinkedDoorItem = test((orderItem: MissingLinkedOrderItem) => {
    return {
        type: testInPlace(() => verify(() => orderItem.type === orderItemTypes.MISSING_LINKED_DOOR.key)),
        linkProductId: testInPlace(() => verify(() => value(orderItem.linkProductId).isString())),
        parentId: testInPlace(() => verify(() => value(orderItem.parentId).isString())),
        dateCreated: testInPlace(() => verify(() => value(orderItem.dateCreated).isNumber())),
        id: testInPlace(() => verify(() => value(orderItem.id).isUuid())),
        sortOrder: testInPlace(() => {verify(() => value(orderItem.sortOrder).isNumber()); passIf(() => orderItem.sortOrder === null);}),
    }
});


export const orderItem = test((orderItem: any) => {
    if(isCarcaseItem(orderItem)){
        return carcaseOrderItem(orderItem);
    }
    if(isAnySupplierItem(orderItem)){
        return supplierOrderItem(orderItem);
    }
    if(isMissingLinkedDoorItem(orderItem)){
        return missingLinkedDoorItem(orderItem);
    }

    else {
        verify(() => false);
    }
})

export const partialCarcaseOrderItem = test((values: Partial<CarcaseOrderItem>) => {
    const retrurnedValues: { [key: string]: TestResults } = {};

    Object.keys(values).forEach((key) => {
        if (key === "dEdited") retrurnedValues.dEdited = dEdited(values.dEdited);
        else if (key === "discount") retrurnedValues.discount = discount(values.discount);
        else if (key === "hEdited") retrurnedValues.hEdited = draftHEdited(values.hEdited);
        else if (key === "handing") retrurnedValues.handing = handing(values.handing);
        else if (key === "id") retrurnedValues.id = id(values.id);
        else if (key === "notes") retrurnedValues.notes = notes(values.notes);
        else if (key === "position") retrurnedValues.position = position(values.position);
        else if (key === "quantity") retrurnedValues.quantity = quantity(values.quantity);
        else if (key === "wEdited") retrurnedValues.wEdited = draftWEdited(values.wEdited);
        else if (key === "sortOrder") retrurnedValues.sortOrder = sortOrder(values.sortOrder);
        else
            retrurnedValues[key] = testInPlace(() => {
                verify(() => false, "Key does not exist on order");
            });
    });

    return retrurnedValues;
});

export const partialSupplierOrderItem = test(
    (values: Partial<Exclude<OrderItem, MissingLinkedOrderItem>>) => {
        const retrurnedValues: { [key: string]: TestResults } = {};

        Object.keys(values).forEach((key) => {
            if (key === "discount") retrurnedValues.discount = discount(values.discount);
            else if (key === "notes") retrurnedValues.notes = notes(values.notes);
            else if (key === "quantity") retrurnedValues.quantity = quantity(values.quantity);
            else if (key === "sortOrder") retrurnedValues.sortOrder = sortOrder(values.sortOrder);
            else
                retrurnedValues[key] = testInPlace(() => {
                    verify(() => false, "Key does not exist on order");
                });
        });

        return retrurnedValues;
    }
);