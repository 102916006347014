import {results} from './results';

export function verify(cb: () => any, message? : string): void {

    let cbResult: any;
    try {
        cbResult = cb();
    } catch (e) {
        results.draft.forEach((result) => {
            if(typeof result === 'object') {
                result._isValid = false;
            }
        });
        if(e instanceof Error) {
            results.draft[results.draft.length - 1]._errors.push(e.message);
        }
        else if (message) {
            results.draft[results.draft.length - 1]._errors.push(message);
        }
        
    }

    if (cbResult === false) {
        results.draft.forEach((result) => {
            if(typeof result === 'object') {
                result._isValid = false;
            }
        });
        message && results.draft[results.draft.length - 1]._errors.push(message);
    }
}