import {CutAndEdgeOrderMFCGroup, CutAndEdgeOrderSheetMaterialGroup} from '../../types';
import { isSheetMaterialGroup } from './isSheetMaterialGroup';
import {isSheetMaterialPoa} from './isSheetMaterialPoa';

export const isOrderGroupPoa = (group : (CutAndEdgeOrderMFCGroup | CutAndEdgeOrderSheetMaterialGroup)) : boolean => {

    if(isSheetMaterialGroup(group)) {
        return isSheetMaterialPoa(group);
    }

    return false;
}
