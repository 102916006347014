import { getLatestDate } from "../order/getLatestDate";
import { Order } from "../../types";
import { compareDate } from "../shared/compareDate";

export function sortByDate(
    orders: Order[],
    dateType:
        | "dateCreated"
        | "deliveryDate"
        | "productionDate"
        | "editDate"
        | "currentStatusDate"
        | "dateOrdered"
        | "dateInvoiced",
    direction: "ASC" | "DESC" = "ASC"
): Order[] {
    return orders.sort((a, b) => {
        let aDate = 0;
        let bDate = 0;

        if (dateType === "currentStatusDate") {
            aDate = getLatestDate(a);
            bDate = getLatestDate(b);
        } else {
            aDate = a[dateType] || 0;
            bDate = b[dateType] || 0;
        }

        if (direction === "DESC") return compareDate(bDate, aDate);
        return compareDate(aDate, bDate);
    });
}
