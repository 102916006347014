import React from 'react';
import styled from 'styled-components';


const Label = styled.label<{hasFocus? : boolean, isDisabled?: boolean, type : 'external' | 'contained', position: 'left' | 'top'}>`
    display: block;
    ${({theme}) => theme.type.m};   
    ${({theme}) => theme.type.bold};
    margin-bottom: ${({theme}) => theme.spacing(0.5)};
    color: ${(props) => props.hasFocus ? props.theme.color.highlight :props.theme.color.text};
    
    ${p => p.isDisabled &&  `opacity:0.25;`}


    ${p => p.type === 'contained' && `
        background-color: ${p.theme.color.accent1};
        margin-bottom:0px;
        padding: ${p.theme.spacing(1.5)};
        border: 1px solid ${p.theme.color.accent2};
        border-radius:4px;

        ${p.position === 'top' && `
            border-radius:4px 4px 0 0 ;
            border-bottom:none;
            margin-bottom: -3px;
        `}

        ${p.position === 'left' && `
            border-radius:4px 0 0 4px;
            border-right:none;
            margin-right: -5px;
            height: ${p.theme.spacing(5)};
        `}
    `}

    
`;


interface FormLabelProps extends React.HTMLAttributes<HTMLLabelElement> {
    hasFocus? : boolean;
    children? : React.ReactNode | React.ReactNode[];
    type : 'external' | 'contained';
    position: 'left' | 'top';
    htmlFor? : string;
    isDisabled? : boolean
}

const FormLabel = ({hasFocus = false, isDisabled = false, children, type, position, ...rest} : FormLabelProps) : React.ReactElement => {
    return (
        <Label hasFocus={hasFocus} isDisabled={isDisabled} {...rest} type={type} position={position}>{children}</Label>
    )
}

FormLabel.defaultProps = {
    type:"external",
    position: 'top'
}

export { FormLabel };


