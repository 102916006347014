import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { SupplierBaseProduct } from "../../types";

export const code = test((code: SupplierBaseProduct["code"], existingNames: string[]) => {
    verify(() => value(code).isString().isNotWhitespace().isNotEmpty().isNotOneOf(existingNames));
});

export const linkProductId = test((linkProductId: SupplierBaseProduct["linkProductId"]) => {
    verify(() => value(linkProductId).when(linkProductId !== '').isUuid());
    passIf(() => linkProductId === null);
});

export const supplierId = test((supplierId: SupplierBaseProduct["supplierId"]) => {
    verify(() => value(supplierId).isUuid());
});

export const type = test((type: SupplierBaseProduct["type"]) => {
    verify(() => value(type).isOneOf(["UNIQUE", "RANGE"]));
});

export const categories = test((categories: SupplierBaseProduct["categories"]) => {
    verify(() => value(categories).isArray());
});


export const supplierBaseProduct = test((supplierBaseProduct: SupplierBaseProduct, otherBaseProducts: SupplierBaseProduct[]) => {
    return {
        code: code(
            supplierBaseProduct.code,
            otherBaseProducts.map((bp) => bp.code)
        ),
        linkProductId: linkProductId(supplierBaseProduct.linkProductId),
        supplierId: supplierId(supplierBaseProduct.supplierId),
        type: type(supplierBaseProduct.type),
        categories: categories(supplierBaseProduct.categories)
    };
});
