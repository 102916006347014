import { CarcaseOrderItem, CutAndEdgeOrderGroup, CutAndEdgeOrder } from "../../types";
import { getCutAndEdgeOrderItemSubtotal } from "./getCutAndEdgeOrderItemSubtotal";
import { v4 as uuid } from "uuid";

export const mapCutAndEdgeToOrderItem = (
    order: CutAndEdgeOrder | CutAndEdgeOrderGroup[]
): CarcaseOrderItem => {
    let items: CutAndEdgeOrderGroup[];

    if (!Array.isArray(order) && order.items) {
        items = order.items;
    } else {
        items = order as CutAndEdgeOrderGroup[];
    }

    const orderTotal = getCutAndEdgeOrderItemSubtotal(items);

    return {
        type: "CARCASE",
        h: null,
        d: null,
        w: null,
        description: "",
        sortOrder: null,
        dEdited: null,
        linkProducts: [],
        hEdited: null,
        handing: null,
        position: "",
        wEdited: null,
        specification: "",
        code: "CUT EDGE",
        dateCreated: new Date().getTime(),
        discount: 0,
        price: orderTotal,
        quantity: 1,
        notes: "Refer to order PDF",
        id: uuid(),
        soldByM2: false,
        productId: "481759ad-5163-4b9c-8864-3f1bb24c1ab0", // This references an actual item in the production app
    };
};
