import React, { ReactNode } from 'react';
import styled, { StyledComponentProps } from 'styled-components';
import { lighten, darken, desaturate } from 'polished';
import { libTypes } from '@holta/lib';
import {
    RiFileLine,
    RiTruckLine,
    RiFocus2Line,
    RiStickyNoteLine
} from "react-icons/ri";
import { DialogContentProps } from '@reach/dialog';
import { Spacer } from '../spacer';
/**
 * 
 * Container
 * 
 */
export const Container = styled.article<{productionStatus : libTypes.ProductionStatuses[keyof libTypes.ProductionStatuses]['key'] | null}>`

    width:100%;
    background: ${({productionStatus, theme}) => (theme.color as {[index : string] : string})['production_' + productionStatus] };
    border-radius: 4px;
    margin-top: ${({theme}) => theme.spacing(1)};
    color: ${({theme}) => theme.color.production_text};
`;

/**
 * 
 * Header
 * 
 */
const HeaderStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({theme}) => theme.type.m};
    ${({theme}) => theme.type.bold};
    padding: ${({theme}) => theme.spacing(1)};
`;


export function Header({customer, reference} : { customer: string, reference : string}) : React.ReactElement{
    return (
        <HeaderStyle>
            <div>{customer}</div>
            <div>{reference}</div>
        </HeaderStyle>
    )
}   

/**
 * 
 * Details
 * 
 */
const DetailsContainer = styled.div`
    margin-left: ${({theme}) => theme.spacing(0.5)};
    margin-right: ${({theme}) => theme.spacing(0.5)};
    padding-bottom: 0.1px;
    display: flex;
    flex-wrap: wrap;


`;

const DetailsItem = styled.div<{productionStatus : libTypes.ProductionStatuses[keyof libTypes.ProductionStatuses]['key']}>`
    margin-left: ${({theme}) => theme.spacing(0.5)};
    margin-right: ${({theme}) => theme.spacing(0.5)};
    background: ${({productionStatus, theme}) => desaturate(0, lighten(0.07, (theme.color as {[index : string] : string})['production_' + productionStatus]))};
    margin-bottom: ${({theme}) => theme.spacing(1)};
    padding: ${({theme}) => theme.spacing(0.5)}  ${({theme}) => theme.spacing(1)} ${({theme}) => theme.spacing(0.5)} ${({theme}) => theme.spacing(0.5)};
    border-radius: 4px;
    display: flex;
    align-items: center;
    ${({theme}) => theme.type.m};
    span {
        opacity: 0.5
    }
    i {
        color: ${({productionStatus, theme}) => desaturate(0.4, darken(0.3, (theme.color as {[index : string] : string})['production_' + productionStatus]))};
        display: flex;
        margin-right: ${({theme}) => theme.spacing(0.5)};
    }
`;


export function Details({orderRef="", quoteRef="", jobSize="", deliveryDate="", deliveryNotes="", productionNotes="", productionStatus} : {orderRef : string, quoteRef : string, jobSize : string, deliveryDate : string, deliveryNotes? : string, productionNotes? : string, productionStatus : libTypes.ProductionStatuses[keyof libTypes.ProductionStatuses]['key']}) : React.ReactElement {
    return (
        <DetailsContainer>
            <div style={{display: 'flex', marginRight: 'auto'}}>
            <DetailsItem productionStatus={productionStatus}><i><RiFileLine/></i><span>Q.</span>&nbsp;{quoteRef}&nbsp;<span>&nbsp;|&nbsp;</span><span>&nbsp;#&nbsp;</span>{orderRef}</DetailsItem> 
            <DetailsItem productionStatus={productionStatus}><i><RiFocus2Line/></i>{jobSize}</DetailsItem> 
            <DetailsItem productionStatus={productionStatus}><i><RiTruckLine/></i>{deliveryDate}</DetailsItem> 
            </div>
            <div style={{ display: 'flex'}}>
                {deliveryNotes && <DetailsItem productionStatus={productionStatus}><i><RiStickyNoteLine/></i>{deliveryNotes}</DetailsItem>}
                {productionNotes && <DetailsItem productionStatus={productionStatus}><i><RiStickyNoteLine/></i>{productionNotes}</DetailsItem> }
            </div>
        </DetailsContainer>
    )
}

export const IpadProductionJob = {
    Container,
    Header,
    Details
}