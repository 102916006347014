import {SupplierOrderItem,CarcaseOrderItem, OrderItem, EditedOrderItem} from '../../types';


/**
 * 
 * Checks if an order item is a carcase
 * 
 * @param  {CarcaseOrderItem|SupplierOrderItem} item
 * @returns itemisCarcaseOrderItem
 */
export function isCarcaseItem(item : OrderItem | EditedOrderItem) : item is CarcaseOrderItem {
    if(item.type === 'CARCASE'){
        return true;
    }
    return false;
}


    