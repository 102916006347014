import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import {Address} from "../../types";

export const addressLine1 = test((addressLine1: Address["addressLine1"]) => {
    verify(() => value(addressLine1).isString());
})
    
export const addressLine2 = test((addressLine2: Address["addressLine2"]) => {
    verify(() => value(addressLine2).isString());
})
    
export const addressLine3 = test((addressLine3: Address["addressLine3"]) => {
    verify(() => value(addressLine3).isString());
})
    
export const city = test((city: Address["city"]) => {
    verify(() => value(city).isString());
})
    
export const country = test((country: Address["country"]) => {
    verify(() => value(country).isString());
})
    
export const county = test((county: Address["county"]) => {
    verify(() => value(county).isString());
})

export const name = test((name: Address["name"]) => {
    verify(() => value(name).isString());
})
export const note = test((note: Address["note"]) => {
    verify(() => value(note).isString());
})
export const postcode = test((postcode: Address["postcode"]) => {
    verify(() => value(postcode).isString());
})
    

export const address = test((address : Address) => {
    return {
        addressLine1 : addressLine1(address.addressLine1),
        addressLine2 : addressLine2(address.addressLine2),
        addressLine3 : addressLine3(address.addressLine3),
        city : city(address.city),
        country : country(address.country),
        county : county(address.county),
        name : name(address.name),
        note : note(address.note),
        postcode : postcode(address.postcode),
    }
})

export const addressFields = test((address : Address) => {
    return {
        addressLine1 : addressLine1(address.addressLine1),
        addressLine2 : addressLine2(address.addressLine2),
        addressLine3 : addressLine3(address.addressLine3),
        city : city(address.city),
        country : country(address.country),
        county : county(address.county),
        postcode : postcode(address.postcode),
    }
})