
/**
 * 
 * Returns a formatted category name that shows parent structure
 * 
 * @param  {string} categoryName
 * @param  {string[]} parentNames
 * @returns string
 */
export function formatHeirachicalName(categoryName : string, parentNames : string[]) : string {
    
    if(parentNames.length == 0) return categoryName;

    return parentNames.reduce((accumulatedName, parentName) => {
        let prefix = "";
        if (accumulatedName) prefix = " → ";
        return accumulatedName + prefix + parentName;
    }, '') + " → " + categoryName

}