import React from "react";
import styled from "styled-components";
import "@reach/dialog/styles.css";
import {
    AlertDialogLabel,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogContent,
} from "@reach/alert-dialog";
import { useDialog } from "./context";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const StyledAlertDialogOverlay = styled(AlertDialogOverlay)`
    z-index: 1001;
    background-color: transparent;
    text-align: center;
    animation-name: dialogFadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;

    @keyframes dialogFadeIn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.isLeaving {
        animation-name: dialogFadeOut;
        animation-duration: 0.2s;

        @keyframes dialogFadeOut {
            from {
                opacity: 1;
                transform: translateY(0);
            }
            to {
                opacity: 0;
                transform: translateY(-10px);
            }
        }
    }
`;

const StyledAlertDialogContent = styled(AlertDialogContent)`
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    padding: ${({theme}) => theme.spacing(3)};
    position: relative;
    ${({theme}) => theme.dropShadow.m};
`;

const Label = styled.div<{success? : boolean}>`
    ${({theme}) => theme.type.l};
    color: ${(p) => p.success ? p.theme.color.confirm : p.theme.color.danger};
    margin-bottom: ${({theme}) => theme.spacing(2)};
`;

const StyledAlertDialogDescription = styled(AlertDialogDescription)`
    white-space: pre-line;
    margin-bottom: ${({theme}) => theme.spacing(3)};
    ${({theme}) => theme.type.m};
`;

const Buttons = styled.div`
    display: flex;
    gap: ${({theme}) => theme.spacing(1)};
    align-items: center;
    justify-content: center;
    `;

const ClickBlocker = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

export interface DialogProps {
    label?: string;
    success? : boolean;
    description?: string;
    id: string;
    children?: React.ReactNode
    buttons: (
        defaultButtonRef: React.MutableRefObject<null>
    ) => React.ReactElement;
}

export function Dialog({
    label,
    description,
    buttons,
    id,
    success,
    children
}: DialogProps): React.ReactElement | null {

    const { isDialogOpen, isDialogClosing } = useDialog();
    const isOpen = isDialogOpen(id);
    const isLeaving = isDialogClosing(id);
    const defaultButtonRef = React.useRef(null);

    React.useEffect(() => {
        const el = document.querySelector("#app");
        if(isOpen){
            //disableBodyScroll(el as HTMLElement)
        } else {
            //enableBodyScroll(el as HTMLElement)
        }
        //return () => enableBodyScroll(el as HTMLElement);
    }, [isOpen, id]);

    return (
        <>
            {(isOpen || isLeaving) &&  (
                <StyledAlertDialogOverlay
                    leastDestructiveRef={defaultButtonRef}
                    id={"dialog-" + id}
                    className={(isLeaving && "isLeaving") || ""}
                    key={id}
                >
                    <StyledAlertDialogContent>
                        {isLeaving && <ClickBlocker onClick={e => {e.preventDefault(); e.stopPropagation();}}></ClickBlocker> }
                        {label && <AlertDialogLabel><Label success={success}>{label}</Label></AlertDialogLabel>}

                            <StyledAlertDialogDescription>
                                {description || children }
                            </StyledAlertDialogDescription>
                        
                        <Buttons>{buttons(defaultButtonRef)}</Buttons>
                    </StyledAlertDialogContent>
                </StyledAlertDialogOverlay>
            )}
        </>
    );
}
