import {Order} from "../../types/order";
import {startOfISOWeek} from "date-fns";

export function getDeliveredSameWeekPercentage(orders: Order[]): number {
    
    if(orders.length === 0) return 0;

    const deliveredSameWeek = orders.filter((order) => {
        if (!order.deliveryDate || !order.productionDate) return false;
        const weekStartProduction = startOfISOWeek(new Date(order.productionDate)).getTime();
        const weekStartDelievery = startOfISOWeek(new Date(order.deliveryDate)).getTime();
        return weekStartProduction === weekStartDelievery;
    });

    return (deliveredSameWeek.length / orders.length) * 100;
}