import {useStore} from "../../config/store";
import { libTypes, fns } from "@holta/lib";
import { user } from "../../fns/user";

// Initialise the state with a new order
export const createNewOrder = ( initialValues?: Partial<libTypes.Order>) => {
    if (user.id === null) {
        throw new Error("User is not logged in");
    }
    const order = fns.order.create(user.id, initialValues);

    useStore.setState({
        editOrder: {
            type: "NEW",
            newValues: {
                order: {},
                orderItem: null,
            },
            currentValues: {
                order: order,
                supplierDoorRange: [],
            },
            requestedActions: [],
        },
    });

}

