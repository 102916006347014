import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { TypeSizes, Colors } from '../../theme';

const StyledText = styled.div<{size : keyof TypeSizes, color: keyof Colors, bold? : boolean, italic? : boolean}>`
    ${(props) => props.theme.type[props.size]}
    ${(props) => props.bold && props.theme.type['bold']}
    ${(props) => props.color && ('color:' + props.theme.color[props.color]) + ';'}
    ${(props) => props.italic && ('font-style: italic;')}
`


export const Text = ({el = 'span', size = 'm', color = 'text', bold = false, italic = false, children}: {el? : React.ElementType, size?: keyof TypeSizes, color?: keyof Colors, bold? : boolean, italic?: boolean, children : ReactNode | string } ) : React.ReactElement => {

    return <StyledText as={el} size={size} bold={bold} italic={italic} color={color}>{children}</StyledText>

}