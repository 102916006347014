import address from './address';
import board from './board';
import category from './category';
import categories from './categories';
import contact from './contact';
import customer from './customer';
import customers from './customers';
import cut_edge_order from './cut_edge_order';
import linkProduct from './linkProduct';
import order from './order';
import orders from './orders';
import order_item from './order_item';
import order_items from './order_items';
import product from './product';
import products from './products';
import productComponent from './productComponent';
import productComponents from './productComponents';
import shared from './shared';
import supplier from './supplier';
import supplierRange from './supplierRange';
import supplierRanges from './supplierRanges';
import supplierBaseProduct from './supplierBaseProduct';
import supplierProduct from './supplierProduct';
import supplierProducts from './supplierProducts';
import supplierImportProduct from './supplierImportProduct';
import supplierImportProducts from './supplierImportProducts';
import suppliers from './suppliers';
import * as validate from './validate';

export const fns = {
    address,
    board,
    category,
    categories,
    contact,
    customer,
    customers,
    cut_edge_order,
    linkProduct,
    order,
    orders,
    order_item,
    order_items,
    product,
    products,
    productComponent,
    productComponents,
    shared,
    supplier,
    suppliers,
    supplierRange,
    supplierRanges,
    supplierBaseProduct,
    supplierProduct,
    supplierProducts,
    supplierImportProduct,
    supplierImportProducts,
    validate
}