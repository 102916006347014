import { CarcaseOrderItem, Order } from "../../types";
import { constructionTypes } from "../../static_data/constructionTypes";
/**
 *
 * Gets the correct list of generic codes to use, depending on order construction type
 *
 * @param  {Order} order
 * @param  {CarcaseOrderItem} orderItem
 * @returns string
 */
export function getCarcaseItemLinkProductIds(
    order: Order,
    orderItem: CarcaseOrderItem
): string[] | false {

    return false;
}
