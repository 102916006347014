import React from "react";
import styled from "styled-components";
import { Icon } from '../icon';
import {Menu as RMenu,  MenuList as RMenuList,  MenuButton as RMenuButton,  MenuItem as RMenuItem, useMenuButtonContext as useRMenuButtonContext} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { Spacer } from "../spacer";

export const useMenuButtonContext = useRMenuButtonContext;

const MenuButton = styled(RMenuButton)`
    width: ${p => p.theme.spacing(1.5)};
    height: ${p => p.theme.spacing(2.5)};
    padding: ${p => p.theme.spacing(0.5)} 0 !important; 
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    &:before, &:after, & i {
        border-radius:100px;
        background-color: ${p => p.theme.color.accent4};
        width: 3px;
        height: 3px;
        content : '';
        display: block;
    }
`;

const UnstyledMenuButton = styled(RMenuButton)`
    background-color: transparent !important;
    padding: 0  !important;
    border: none  !important;
    outline : none  !important;
    width: inherit  !important;
    height: inherit  !important;
    border-radius: inherit  !important;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
`;

const MenuList = styled(RMenuList)<{portal : boolean}>`
    border: none;
    border-radius: 4px;
    overflow: hidden;
    padding: 0.1px;
    z-index: 1;
    ${p => p.theme.dropShadow.m};
`;

const StyledRMenuItem = styled(RMenuItem)`
    min-width: 150px;
    padding:${p => p.theme.spacing(1)};
    border-bottom: 1px solid ${p => p.theme.color.accent1};

    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background-color: ${p => p.theme.color.highlight};
        color:  ${p => p.theme.color.base_contrast};
    }
`;


export const MenuItem = ({children, onClick, icon} :  {children : React.ReactNode, onClick : () => void, icon? : string}) => {
    return (
        <StyledRMenuItem onSelect={onClick}>
            {icon && <><Icon name="icon"/> <Spacer xs={[0,1,0,0]} /></>}
            {children}
        </StyledRMenuItem>
    )
}


export const Menu = ({children, button, portal = true, style} : {children : React.ReactNode, button? : React.ReactNode, portal?:boolean, style?: React.CSSProperties}) => {
    return (
        <RMenu>
            {button && (<UnstyledMenuButton >{button}</UnstyledMenuButton>)}
            {!button && <MenuButton><i></i></MenuButton>}
            <MenuList portal={portal} style={style}> 
                {children}
            </MenuList>
        </RMenu>
    );
}


