import {Category} from '../../types';
import {filterBranch} from './filterBranch';
import {getHeirachicalName} from '../category/getHeirachicalName';

/**
 * 
 * Returns a category array formated for use in advanced select. FilterId arg is used to remove a branch from returned options.
 * 
 * @param  {Category[]} categories
 * @param  {} filterId=''
 */


export function formatForAdvancedSelect(categories: Category[], filterId = '') {
    
    let _categories = [...categories];

    // If an id is supplied, filter this item and it's children from the list
    _categories = filterId ? filterBranch(_categories, filterId) : _categories;

    return _categories.map(item => ({
        value: item.id,
        label: getHeirachicalName(item, _categories)
    }));
}
