import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { deleteOrderItems } from "./deleteOrderItems";


function getLinkedSupplierProducts(
	items: libTypes.OrderItem[],
	linkProducts: libTypes.LinkProduct[],
	doorRange: libTypes.SupplierComputedProduct[],
	constructionType: libTypes.ConstructionType,
	supplierDiscount: number
): ReturnType<typeof fns.order_items.getLinkedSupplierProducts> {
	return fns.order_items.getLinkedSupplierProducts(
		items,
		doorRange,
		linkProducts,
		constructionType,
		supplierDiscount
	);
}

function getLinkedDoorsToSave(
	doorMatches: ReturnType<typeof getLinkedSupplierProducts>
): libTypes.LinkedDoorOrderItem[] {
	return doorMatches.flatMap((item) => item.matched);
}

function getMissingLinkedDoorOrderItems(
	doorMatches: ReturnType<typeof getLinkedSupplierProducts>
): libTypes.MissingLinkedOrderItem[] {
	return doorMatches.flatMap((item) => item.unmatched);
}

function getDuplicateDoorOrderItems(
	doorMatches: ReturnType<typeof getLinkedSupplierProducts>
): libTypes.LinkedDoorOrderItem[][][] {
	return doorMatches.filter((item) => item.duplicates.length > 0).map((item) => item.duplicates);
}

export const createLinkedDoors = (
	handleDuplicates: (
		linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][][],
		cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void
	) => void
) => {
	const mergedOrder = getMergedOrder();
	const editOrder = getEditOrder();
	const linkProducts = useStore.getState().linkProducts;


	const doorMatches = getLinkedSupplierProducts(
		mergedOrder.items,
		linkProducts,
		editOrder.currentValues.supplierDoorRange,
		mergedOrder.constructionType || "LAYON",
		mergedOrder.supplierDiscount
	);

	const itemsToSave = getLinkedDoorsToSave(doorMatches);
	itemsToSave.forEach((item) => saveLinkedDoorOrderItem(item));

	const missingItems = getMissingLinkedDoorOrderItems(doorMatches);
	missingItems.forEach((item) => saveMissingLinkedDoorOrderItem(item));

	if (doorMatches.some((item) => item.duplicates.length > 0)) {
		handleDuplicates(getDuplicateDoorOrderItems(doorMatches), (linkedDoorOrderItems) => {
			linkedDoorOrderItems.forEach((linkedDoorOrderItem) => {
				saveLinkedDoorOrderItem(linkedDoorOrderItem);
			});
		});
	}
};
