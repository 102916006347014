import styled from "styled-components";
import { screenSize } from "../../constants/screenSize";
import { containerWidths } from "../../constants/containerWidths";


type ContainerSizeValue = 'fixed' | 'fluid';

type IntersectingKeys = Extract<keyof typeof screenSize, keyof typeof containerWidths>

type BreakPoint = {
    [key in IntersectingKeys ]?: ContainerSizeValue;
};



export const Container = styled.div<BreakPoint>`
    margin: 0 auto;
    ${({theme, xs}) => {
        if (xs) {
            return `
                    max-width: ${xs === 'fixed' ? containerWidths['xs'] : '100%'};
                `;
        } else {
            return "";
        }
    }};

    ${(props) => {
        let css = "";
        for (const [key, value] of Object.entries(screenSize)) {
            const _key = key as keyof BreakPoint
            const val : ContainerSizeValue | undefined = props[_key];
            if (val !== undefined) {
                css += `
                    @media(min-width: ${value}px) {
                        max-width: ${val === 'fixed' ? containerWidths[_key] : '100%'};
                    }
                `;
            }
        }
        return css;
    }}
`;
