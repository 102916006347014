import { CarcaseOrderItem, OrderItem, Product, Supplier, SupplierBaseProduct, SupplierComputedProduct, SupplierOrderItem, SupplierProduct, SupplierRange } from "../../types";
import {getPrice as getProductPrice} from "../product/getPrice";
import {v4 as uuid} from 'uuid';
import { getSupplierProductPrice } from "../supplierProduct/getSupplierProductPrice";

export function createSupplierOrderItem(product : SupplierComputedProduct, discount: number) : SupplierOrderItem{
    
    return {
        code: product.code,
        d: product.d,
        dateCreated: new Date().getTime(),
        description: product.description,
        discount: discount,
        h: product.h,
        id:  uuid(),
        notes: '',
        price: Number(product.price),
        quantity: 1,
        sortOrder: null,
        w: product.w,
        type: 'SUPPLIER',
        supplierOrderItemType: 'MANUALLY_ADDED',
        linkProductId: product.linkProductId,
        supplierId: product.supplierId,
        supplierProductId: product.id,
        supplierRangeId: product.supplierRangeId,
        parentId: null,
    }
}


