import {
    SupplierOrderItem,
    CarcaseOrderItem,
    LinkedDoorOrderItem,
    EditedOrderItem,
    OrderItem,
} from "../../types";
/**
 * Merges an order item with another item/partial item.
 * Merged values are cloned where neccessary but the resulting item is NOT a deep clone.
 * @param baseItem
 * @param valuesToMerge
 */


export function combineEdits<T extends Partial<EditedOrderItem>>( baseItem: T, valuesToMerge: T ): T {

    if (valuesToMerge) {
        return {
            ...baseItem,
            ...valuesToMerge,
        };
    }

    return baseItem;
}
