import {CarcaseOrderItem, MissingLinkedOrderItem, OrderItem, SupplierOrderItem} from '../../types';

/**
 * 
 * Checks if an order item is a missing door item
 * 
 */
export function isMissingLinkedDoorItem(item : any) : item is MissingLinkedOrderItem {
    if(item.type === 'MISSING_LINKED_DOOR'){
        return true;
    }
    return false;
}


    
