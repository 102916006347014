import Decimal from "decimal.js";
import { Product, ProductComponent } from "../../types";
import { getPrice } from "../productComponent/getPrice";
import { findProductComponent } from "../productComponents/findProductComponent";

export function getComponentTotal(component: Product['components'][number], components: ProductComponent[]) : number{
    const match = findProductComponent(components, component.id)
    if(!match){
        throw new Error(`Could not find component with id ${component.id}`)
    }

    const componentPrice = getPrice(match);
    const componentTotal = new Decimal(componentPrice).mul(component.qty).toNumber();

    return componentTotal;
}