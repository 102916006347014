import {v4 as uuid} from 'uuid';
import {SupplierBaseProduct} from '../../types';

export const create = (supplierId:string, fields? : Partial<SupplierBaseProduct>) : SupplierBaseProduct  => {

    return {
        id: uuid(),
        code: '',
        supplierId,
        linkProductId: null,
        type: 'RANGE',
        categories: [],
        ...fields
    }

}

