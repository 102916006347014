export const orderValueTypes = {
    ESTIMATE: {
        label: "Estimate",
        key: "ESTIMATE"
    },
    ORDERITEMS: {
        label: "Order Items",
        key: "ORDERITEMS"
    },
};
