import React from "react";
import { Text } from "../text";
import { Icon } from "../icon";
import styled from "styled-components";

const Container = styled.div<{ isChosen?: boolean }>`
    background: ${(p) => p.theme.color.base_contrast};
    border: 1px solid ${(p) => p.theme.color.base_contrast};
    padding: ${(p) => p.theme.spacing(4)} ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(6)} ${(p) => p.theme.spacing(2)};
    ${(p) => p.theme.dropShadow.s};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: border 0.2s;
    cursor: pointer;
    border: ${({ theme, isChosen }) =>
        isChosen ? `1px solid ${theme.color.highlight}` : `1px solid ${theme.color.base_contrast}`};

    &:hover {
        border: 1px solid ${(p) => (p.isChosen ? p.theme.color.highlight : p.theme.color.accent3)};
    }

    i {
        font-size: 3em;
    }
`;

const Image = styled.figure`
    border-radius: 1000px;
    background-color: ${({ theme }) => theme.color.accent1};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(p) => p.theme.spacing(12)};
    height: ${(p) => p.theme.spacing(12)};
    margin-bottom: ${(p) => p.theme.spacing(4)};
    img {
        height: ${(p) => p.theme.spacing(6)};
        width: auto;
    }
`;

const Heading = styled.div``;
const SubHeading = styled.div`
    text-align: center;
    padding-top: ${(p) => p.theme.spacing(1)};
`;

export const OptionCard = ({
    icon,
    img,
    heading,
    subheading,
    onClick,
    isChosen = false,
    className,
    type
}: {
    icon?: string;
    img?: string;
    heading?: string;
    subheading?: string;
    onClick?: () => void;
    isChosen?: boolean;
    className? : string;
    type? : 'line' | 'fill'
}) => {
    return (
        <Container onClick={onClick} isChosen={isChosen} className={className}>
            {(icon || img) && (
                <Image>{icon ? <Icon name={icon} type={type} /> : <img src={img} alt="" />}</Image>
            )}
            {heading && (
                <Heading>
                    <Text bold>{heading}</Text>{" "}
                </Heading>
            )}
            {subheading && (
                <SubHeading>
                    <Text>{subheading}</Text>
                </SubHeading>
            )}
        </Container>
    );
};
