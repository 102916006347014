import { CutAndEdgeOrderGroup } from '../../types';
import { isMFCGroup } from './isMFCGroup';
import { isSheetMaterialGroup } from './isSheetMaterialGroup';


export function getBoardDisplayDescription (group : CutAndEdgeOrderGroup) : string {

    if(isMFCGroup(group) && group.mfcColour.decor_name) {
        return group.mfcColour.decor_name || '';
    }

    if(isSheetMaterialGroup(group) && group.sheetMaterial.description) {
        return group.sheetMaterial.description;
    }

    return '';

};