import { CutAndEdgeOrderGroup } from "../../types";
import { isMFCGroup } from "./isMFCGroup";
import { isSheetMaterialGroup } from "./isSheetMaterialGroup";
import { cutAndEdgeOrderGroupTypes } from "../../static_data/cutAndEdgeOrderGroupTypes";



export const getBoardDisplayName = (group : CutAndEdgeOrderGroup) : string => {

    if(isMFCGroup(group)) {
        return "(" + cutAndEdgeOrderGroupTypes[group.type].label + ")" + group.mfcColour.code + "-" + group.mfcColour.texture ;
    }
    if(isSheetMaterialGroup(group)) {
        return group.sheetMaterial.name;
    }

    return '';

}

