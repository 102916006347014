import { SupplierOrderItem, CarcaseOrderItem, OrderItem, EditedOrderItem } from "../../types";
import { orderItem as validateOrderItem } from "../order_item/validate";

import { merge as mergeOrderItem } from "../order_item/merge";
import { hasProperty } from "../shared/hasProperty";

/**
 * Merges an object of order items with another object of full or partial order items.
 * Merged values are cloned where neccessary but the result is NOT a deep clone.
 * @param oldItems
 * @param newItems
 */
// prettier-ignore

export function merge<T extends OrderItem | EditedOrderItem>(oldItems : T[], newItems: Partial<T>[]) : T[]{

    let merged : T[] = [];

    // 1. Update any old items with new values or remove if deleted.
    oldItems.forEach(oldItem => {
        const updatedValues = newItems.find(newItem => newItem.id === oldItem.id);
        if(updatedValues && hasProperty(updatedValues, '_deleted') && updatedValues._deleted) return;
        if(updatedValues){
            merged.push(mergeOrderItem(oldItem, updatedValues));
        } else {
            merged.push(oldItem);
        }
    });

    // 2. Add new items.
    newItems.forEach(newItem => {

        if(!oldItems.find(oldItem => oldItem.id === newItem.id) && (!hasProperty(newItem, '_deleted') || !newItem._deleted)){

            if( !validateOrderItem(newItem)._isValid) {
                console.error("Invalid order item", validateOrderItem(newItem));
                //throw new Error("Invalid order item");
            }
            merged.push(newItem as T);
        }
        
    });

    return merged;
    
}

