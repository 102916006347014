import { sheetMaterials } from '../../static_data/sheetMaterials';
import { CutAndEdgeOrderSheetMaterialGroup } from '../../types';
import { findSheetMaterial } from './findSheetMaterial';


export const isSheetMaterialPoa = (boardGroup : CutAndEdgeOrderSheetMaterialGroup) : boolean => {

    if(boardGroup.sheetMaterial.isPoa) return true;

    return false;

}
