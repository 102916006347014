export function createOptions<Options extends Array<any>, VK extends keyof Options[number], LK extends keyof Options[number]>(valueKey: VK, labelKey : LK, options : Options) {
    return options.map((option) => ({
        value: option[valueKey],
        label: option[labelKey]
    }));
}

export function createValueOption<Options extends Array<any>, VK extends keyof Options[number], LK extends keyof Options[number]>(value: Options[number][VK], valueKey: VK, labelKey : LK, options : Options) {
    
    const match = options.find((option) => option[valueKey] === value);
    if(match) {
        return {
            value: match[valueKey],
            label: match[labelKey]
        }
    }
    return null;
}