import { getTotal } from "../order/getTotal";
import { Order } from "../../types";

export const getTotalValue = (
    orders: Order[],
    options? : { excludeTax? : boolean, includeEstimates?: boolean }
): string => {
    return orders
        .reduce((acc, order) => {
            return acc + Number(getTotal(order, options));
        }, 0)
        .toFixed(2);
};
