import {v4 as uuid} from 'uuid';
import {SupplierRange} from '../../types';

export const create = (supplierId:string, fields? : Partial<SupplierRange>) : SupplierRange  => {

    return {
        id: uuid(),
        name: '',
        supplierId,
        prefix: '',
        discount: null,
        margin: null,
        notes: '',
        subDiscounts: [],
        ...fields
    }

}

