import {Order, Customer} from '../../types';

export function getCustomerName(order: Order, customers: Customer[]) : string {
    if(customers.find(customer => customer.id === order.customerId)) {
        return customers.find(customer => customer.id === order.customerId)?.name!;
    }
    if(order.deletedCustomerName) {
        return 'Deleted: ' + order.deletedCustomerName;
    }
    return 'No customer';
}