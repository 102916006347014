export const mfcPricingGroups = [
    {
        "group": 2,
        "price": 48.82,
        "_08": 0.30,
        "_20": 0.60,
    },
    {
        "group": 3,
        "price": 50.06,
        "_08": 0.30,
        "_20": 0.60,
    },
    {
        "group": 4,
        "price": 54.26,
        "_08": 0.30,
        "_20": 0.60,
    },
    {
        "group": 5,
        "price": 59.69,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 6,
        "price": 62.09,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 7,
        "price": 66.59,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 8,
        "price": 69.65,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 9,
        "price": 75.50,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 10,
        "price": 80.50,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 11,
        "price": 87.50,
        "_08": 0.37,
        "_20": 0.74,
    },
    {
        "group": 12,
        "price": 186,
        "_08": 0.74,
        "_20": 0.74,
    },
    {
        "group": 13,
        "price": 173,
        "_08": 0.74,
        "_20": 0.74,
    },
] as const;
