import { libTypes } from "@holta/lib";
import React from "react";
import styled from "styled-components";
import { Icon } from "../icon";
import { Text } from "../text";

interface Item {
    id: string;
    name: string;
    parentId?: string | null | undefined;
}

interface Props {
    items: Item[];
    expandedItems: string[];
    selectedBranch: string[];
    options?: {
        hasAll?: boolean;
        allLabel?: string;
        hasUncategorised?: boolean;
        uncategorisedLabel?: string;
    };
    toggleItem: (id: string | null) => void;
}

export const TreeNav = ({ items, expandedItems, selectedBranch, toggleItem, options }: Props) => {
    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => a.name.localeCompare(b.name));
    }, [items]);

    // merge options
    const mergedOptions = {
        hasAll: options?.hasAll ?? true,
        allLabel: options?.allLabel ?? "All",
        hasUncategorised: options?.hasUncategorised ?? true,
        uncategorisedLabel: options?.uncategorisedLabel ?? "Uncategorised",
    };

    return (
        <S.List>
            {mergedOptions?.hasAll && (
                <>
                    <S.ListItem
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleItem(null);
                        }}
                    >
                        <S.ListItemHeader
                            isCurrent={selectedBranch.length === 0}
                            isInCurrentBranch={false}
                        >
                            {selectedBranch.length === 0 && (
                                <Icon name="folder-open" color={"highlight"} />
                            )}
                            {selectedBranch.length !== 0 && (
                                <Icon name="folder" color={"highlight"} />
                            )}
                            <S.ThisItemName>
                                <Text size="m" bold={selectedBranch.length === 0}>
                                    {mergedOptions?.allLabel || "All"}
                                </Text>
                            </S.ThisItemName>
                        </S.ListItemHeader>
                    </S.ListItem>
                    <hr />
                </>
            )}

{mergedOptions?.hasUncategorised && (
                <>
                    
                    <S.ListItem
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleItem('uncategorised');
                        }}
                    >
                        <S.ListItemHeader
                            isInCurrentBranch={selectedBranch[selectedBranch.length - 1] === 'uncategorised'}
                            isCurrent={selectedBranch[selectedBranch.length - 1] === 'uncategorised'}
                        >
                            {selectedBranch[selectedBranch.length - 1] === 'uncategorised' && (
                                <Icon name="folder-open" color={"highlight"} />
                            )}
                            {selectedBranch[selectedBranch.length - 1] !== 'uncategorised' && (
                                <Icon name="folder" color={"highlight"} />
                            )}
                            <S.ThisItemName>
                                <Text size="m" bold={selectedBranch[selectedBranch.length - 1] === 'uncategorised'}>
                                    {mergedOptions?.uncategorisedLabel || "Uncategorised"}
                                </Text>
                            </S.ThisItemName>
                        </S.ListItemHeader>
                    </S.ListItem>
                    <hr />
                </>
            )}

            {sortedItems
                .filter((c) => !c.parentId)
                .map((thisItem) => {
                    return (
                        <ThisItemTreeItem
                            toggleItem={toggleItem}
                            thisItem={thisItem}
                            items={items}
                            expandedItems={expandedItems}
                            key={thisItem.id}
                            selectedBranch={selectedBranch}
                        />
                    );
                })}

            
        </S.List>
    );
};

interface ItemProps {
    thisItem: Item;
    items: Item[];
    expandedItems: string[];
    toggleItem: (id: string) => void;
    selectedBranch: string[];
}

export const ThisItemTreeItem = ({
    thisItem,
    items,
    expandedItems,
    toggleItem,
    selectedBranch,
}: ItemProps) => {
    const children = items.filter((c) => c.parentId === thisItem.id);
    const isExpanded = expandedItems.some((id) => id === thisItem.id);
    return (
        <S.ListItem
            onClick={(e) => {
                e.stopPropagation();
                toggleItem(thisItem.id);
            }}
        >
            <S.ListItemHeader
                isInCurrentBranch={selectedBranch.includes(thisItem.id)}
                isCurrent={selectedBranch[selectedBranch.length - 1] === thisItem.id}
            >
                {isExpanded && <Icon name="folder-open" color={"highlight"} />}
                {!isExpanded && <Icon name="folder" color={"highlight"} />}
                <S.ThisItemName>
                    <Text size="m" bold={selectedBranch[selectedBranch.length - 1] === thisItem.id}>
                        {thisItem.name}
                    </Text>
                </S.ThisItemName>
            </S.ListItemHeader>
            {isExpanded && children.length > 0 && (
                <S.List>
                    {children.map((childCat) => (
                        <ThisItemTreeItem
                            expandedItems={expandedItems}
                            items={items}
                            thisItem={childCat}
                            toggleItem={toggleItem}
                            key={childCat.id}
                            selectedBranch={selectedBranch}
                        />
                    ))}
                </S.List>
            )}
        </S.ListItem>
    );
};

const S = {
    List: styled.ul`
        margin-left: ${({ theme }) => theme.spacing(1)};
    `,
    ListItem: styled.li`
        cursor: pointer;
    `,
    ListItemHeader: styled.div<{ isCurrent: boolean; isInCurrentBranch: boolean }>`
        display: flex;
        align-items: center;
        ${({ theme, isInCurrentBranch }) =>
            isInCurrentBranch && `background-color: ${theme.color.accent1};`}
        ${({ theme, isCurrent }) => isCurrent && `background-color: ${theme.color.highlight2};`}
        padding: ${({ theme }) => theme.spacing(0.5)};
        border-radius: 4px 0 0 4px;
        margin-bottom: 1px;
    `,
    ThisItemName: styled.span`
        margin-left: ${({ theme }) => theme.spacing(0.5)};
    `,
};
