import styled, { Interpolation } from "styled-components";
import { ThemeSpacingFn } from "../../styled";
import { screenSize } from "../../constants/screenSize";

export type SpaceProp = [number] | [number, number] | [number, number, number, number];

function calcPad(
    s: number[],
    fn: ThemeSpacingFn,
    direction: "top" | "bottom" | "left" | "right"
): string {
    if (direction === "top") return fn(s[0]);
    if (direction === "right") {
        if (s.length > 1) return fn(s[1]);
        return fn(s[0]);
    }
    if (direction === "bottom") {
        if (s.length > 2) return fn(s[2]);
        return fn(s[0]);
    }
    if (direction === "left") {
        if (s.length > 3) return fn(s[3]);
        if (s.length > 1) return fn(s[1]);
        return fn(s[0]);
    }
    return "0px";
}

type BreakPoint = {
    [key in keyof typeof screenSize]?: SpaceProp;
};

export const Spacer = styled.div<BreakPoint & { grow?: boolean; xs?: SpaceProp, styles?: Interpolation<React.CSSProperties>; }>`

    ${({ styles }) => styles};
    
    ${({theme, xs}) => {
        if (xs) {
            return `
                    padding-top: ${calcPad(xs, theme.spacing, "top")};
                    padding-right: ${calcPad(xs, theme.spacing, "right")};
                    padding-bottom: ${calcPad(xs, theme.spacing, "bottom")};
                    padding-left: ${calcPad(xs, theme.spacing, "left")};
                `;
        } else {
            return "";
        }
    }};

    ${(props) => props.grow && "flex-grow: 1"};

    ${(props) => {
        let css = "";
        for (const [key, value] of Object.entries(screenSize)) {
            const _key = key as keyof typeof screenSize
            const vals : SpaceProp | undefined = props[_key];
            if (vals !== undefined) {
                css += `
                    @media(min-width: ${value}px) {
                        padding-top: ${calcPad(
                            vals,
                            props.theme.spacing,
                            "top"
                        )};
                        padding-right: ${calcPad(
                            vals,
                            props.theme.spacing,
                            "right"
                        )};
                        padding-bottom: ${calcPad(
                            vals,
                            props.theme.spacing,
                            "bottom"
                        )};
                        padding-left: ${calcPad(
                            vals,
                            props.theme.spacing,
                            "left"
                        )};
                    }
                `;
            }
        }
        return css;
    }}
`;
