import * as set from './set';
import * as validate from './validate';
import {create} from './create';
import {findRange} from './findRange';

export default {
    set,
    validate,
    create,
    findRange
}