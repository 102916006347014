import { MissingLinkedOrderItem } from '../../types/orderItems';
import {v4 as uuid} from 'uuid';

export function createMissingDoorItem(linkProductId: string, linkProductName: string, quantity: number, parentId: string, sortOrder: number | null = null) : MissingLinkedOrderItem{
    const id = uuid();
    const dateCreated = Date.now();
    return {
        type: 'MISSING_LINKED_DOOR',
        linkProductName,
        linkProductId,
        parentId,
        dateCreated,
        id,
        quantity,
        sortOrder
    };
}