import { CarcaseOrderItem, LinkedDoorOrderItem, OrderItem, Product, Supplier, SupplierBaseProduct, SupplierComputedProduct, SupplierOrderItem, SupplierProduct, SupplierRange } from "../../types";
import {getPrice as getProductPrice} from "../product/getPrice";
import {v4 as uuid} from 'uuid';
import { getSupplierProductPrice } from "../supplierProduct/getSupplierProductPrice";

export function createLinkedDoorOrderItem(product : SupplierComputedProduct, discount: number, parentId : string, quantity: number) : LinkedDoorOrderItem{
    
    return {
        code: product.code,
        d: product.d,
        dateCreated: new Date().getTime(),
        description: product.description,
        discount: discount,
        h: product.h,
        id:  uuid(),
        notes: '',
        price: Number(product.price),
        quantity: quantity,
        sortOrder: null,
        w: product.w,
        type: 'SUPPLIER',
        supplierOrderItemType: 'LINKED_DOOR',
        linkProductId: product.linkProductId,
        supplierId: product.supplierId,
        supplierProductId: product.id,
        supplierRangeId: product.supplierRangeId,
        parentId,
    }
}


