import { Order } from "../../types";
import { accountingStatuses as accountingStatusData } from "../../static_data/accountingStatuses";
import { mfcColours as mfcColoursData } from "../../static_data/mfcColours";
import { constructionTypes as constructionTypeData } from "../../static_data/constructionTypes";
import { deliveryDateTypes as deliveryDateTypeData } from "../../static_data/deliveryDateTypes";
import { orderStatuses as orderStatusesData } from "../../static_data/orderStatuses";
import { drawerboxTypes as drawerboxData } from "../../static_data/drawerboxTypes";
import { orderValueTypes as orderValueTypeData } from "../../static_data/orderValueTypes";
import { productionDateTypes as productionDateTypeData } from "../../static_data/productionDateTypes";
import { productionStatuses as productionStatusData } from "../../static_data/productionStatuses";
import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { TestResults } from "../validate/types";
import { orderItems as validateOrderItems } from "../order_items/validate";
import { voidSizes } from "../../static_data/voidSizes";

export const accountingStatus = test((accountingStatus: Order["accountingStatus"] | undefined) => {
    verify(() => value(accountingStatus).isNotEmpty());
    verify(() => value(accountingStatus).isOneOf(Object.keys(accountingStatusData)));
});

// Typescript errors unlesss explicitly typed
export const carcaseColour = test((carcaseColour: Order["carcaseColour"] | undefined) => {
    verify(() => value(carcaseColour).isOneOf(mfcColoursData));
    verify(() => value(carcaseColour).isNotEmpty());
    passIf(() =>
        carcaseColour && Object.values(carcaseColour).every((colour) => colour === null)
            ? true
            : false
    );
});

export const constructionType = test((constructionType: Order["constructionType"] | undefined) => {
    verify(() => value(constructionType).isNotEmpty());
    verify(() => value(constructionType).isOneOf(Object.keys(constructionTypeData)));
});

export const createdBy = test((createdBy: any) => {
    verify(() => value(createdBy).isNotEmpty());
    verify(() => value(createdBy).isString());
});

export const customerId = test((customerId: Order["customerId"] | undefined) => {
    verify(() => value(customerId).isNotEmpty());
    verify(() => value(customerId).isString());
});

export const customerRef = test((customerRef: Order["customerRef"] | undefined) => {
    verify(() => value(customerRef).isString());
});

export const dateCreated = test((dateCreated: any) => {
    verify(() => value(dateCreated).isNumber());
});

export const dateInvoiced = test((dateInvoiced: Order["dateInvoiced"] | undefined) => {
    verify(() => value(dateInvoiced).isNotEmpty());
    verify(() => value(dateInvoiced).isNumber());
    passIf(() => dateInvoiced === null);
});

export const dateOrdered = test((dateOrdered: Order["dateOrdered"] | undefined) => {
    verify(() => value(dateOrdered).isNotEmpty());
    verify(() => value(dateOrdered).isNumber());
    passIf(() => dateOrdered === null);
});

export const deletedCustomerName = test((deletedCustomerName: Order["deletedCustomerName"]) => {
    verify(() => value(deletedCustomerName).isString());
    passIf(() => deletedCustomerName === null);
});

export const deliveryAddress = test((deliveryAddress: Order["deliveryAddress"]) => {
    return {
        addressLine1: testInPlace(() => {
            verify(() => value(deliveryAddress.addressLine1).isString());
        }),
        addressLine2: testInPlace(() => {
            verify(() => value(deliveryAddress.addressLine2).isString());
        }),
        addressLine3: testInPlace(() => {
            verify(() => value(deliveryAddress.addressLine3).isString());
        }),
        city: testInPlace(() => {
            verify(() => value(deliveryAddress.city).isString());
        }),
        county: testInPlace(() => {
            verify(() => value(deliveryAddress.county).isString());
        }),
        country: testInPlace(() => {
            verify(() => value(deliveryAddress.country).isString());
        }),
        postcode: testInPlace(() => {
            verify(() => value(deliveryAddress.postcode).isString());
        }),
    };
});

export const deliveryDate = test((deliveryDate: Order["deliveryDate"] | undefined) => {
    verify(() => value(deliveryDate).isNotEmpty());
    verify(() => value(deliveryDate).isNumber());
    passIf(() => deliveryDate === null);
});

export const deliveryDateType = test((deliveryDateType: Order["deliveryDateType"] | undefined) => {
    verify(() => value(deliveryDateType).isNotEmpty());
    verify(() => value(deliveryDateType).isOneOf(Object.keys(deliveryDateTypeData)));
});

export const deliveryNotes = test((deliveryNotes: Order["deliveryNotes"] | undefined) => {
    verify(() => value(deliveryNotes).isString());
});


export const discount = test((discount: Order["discount"] | undefined) => {
    verify(() => value(discount).isNotEmpty());
    verify(() => value(discount).isNumber());
});

export const doorRange = test((doorRange: Order["doorRange"] | undefined) => {
    verify(() => value(doorRange).isNotEmpty());
    passIf(() => doorRange === null);
});

export const doorSupplier = test((doorSupplier: Order["doorSupplier"] | undefined) => {
    verify(() => value(doorSupplier).isNotEmpty());
    verify(() => value(doorSupplier).isString());
    passIf(() => doorSupplier === null);
});

export const drawerbox = test((drawerbox: Order["drawerbox"] | undefined) => {
    verify(() => value(drawerbox).isNotEmpty());
    verify(() => value(drawerbox).isOneOf(drawerboxData));
});

export const editDate = test((editDate: Order["editDate"] | undefined) => {
    verify(() => value(editDate).isNotEmpty());
    verify(() => value(editDate).isNumber());
    passIf(() => editDate === null);
});

export const editVersion = test((editVersion: Order["editVersion"] | undefined) => {
    verify(() => value(editVersion).isNotEmpty());
    verify(() => value(editVersion).isNumber());
});

export const gluedWallUnits = test((gluedWallUnits: Order["gluedWallUnits"] | undefined) => {
    verify(() => value(gluedWallUnits).isNotEmpty());
    verify(() => value(gluedWallUnits).isBoolean());
});

export const hasDoors = test((hasDoors: Order["hasDoors"] | undefined) => {
    verify(() => value(hasDoors).isNotEmpty());
    verify(() => value(hasDoors).isBoolean());
});

export const id = test((id: any) => {
    verify(() => value(id).isUuid());
});

export const invoiceAddress = test((invoiceAddress: Order["invoiceAddress"]) => {
    return {
        addressLine1: testInPlace(() => {
            verify(() => value(invoiceAddress.addressLine1).isString());
        }),
        addressLine2: testInPlace(() => {
            verify(() => value(invoiceAddress.addressLine2).isString());
        }),
        addressLine3: testInPlace(() => {
            verify(() => value(invoiceAddress.addressLine3).isString());
        }),
        city: testInPlace(() => {
            verify(() => value(invoiceAddress.city).isString());
        }),
        county: testInPlace(() => {
            verify(() => value(invoiceAddress.county).isString());
        }),
        country: testInPlace(() => {
            verify(() => value(invoiceAddress.country).isString());
        }),
        postcode: testInPlace(() => {
            verify(() => value(invoiceAddress.postcode).isString());
        }),
    };
});

export const invoiceSent = test((invoiceSent: Order["invoiceSent"] | undefined) => {
    verify(() => value(invoiceSent).isNotEmpty());
    verify(() => value(invoiceSent).isBoolean());
});

export const isPinned = test((isPinned: Order["isPinned"] | undefined) => {
    verify(() => value(isPinned).isNotEmpty());
    verify(() => value(isPinned).isBoolean());
});

export const orderConfirmationSent = test(
    (orderConfirmationSent: Order["orderConfirmationSent"] | undefined) => {
        verify(() => value(orderConfirmationSent).isNotEmpty());
        verify(() => value(orderConfirmationSent).isBoolean());
    }
);

export const orderRef = test((orderRef: Order["orderRef"] | undefined) => {
    verify(() => value(orderRef).isNotEmpty());
    verify(() => value(orderRef).isString());
    passIf(() => orderRef === null);
});

export const orderStatus = test((orderStatus: Order["orderStatus"] | undefined) => {
    verify(() => value(orderStatus).isNotEmpty());
    verify(() => value(orderStatus).isOneOf(Object.keys(orderStatusesData)));
});

export const orderValueEstimate = test(
    (orderValueEstimate: Order["orderValueEstimate"] | undefined) => {
        verify(() => value(orderValueEstimate).isNotEmpty());
        verify(() => value(orderValueEstimate).isString());
        passIf(() => orderValueEstimate === null);
    }
);

export const orderValueType = test((orderValueType: Order["orderValueType"] | undefined) => {
    verify(() => value(orderValueType).isNotEmpty());
    verify(() => value(orderValueType).isOneOf(Object.keys(orderValueTypeData)));
});

export const productionDate = test((productionDate: Order["productionDate"] | undefined) => {
    verify(() => value(productionDate).isNotEmpty());
    verify(() => value(productionDate).isNumber());
    passIf(() => productionDate === null);
});

export const productionDateType = test(
    (productionDateType: Order["productionDateType"] | undefined) => {
        verify(() => value(productionDateType).isNotEmpty());
        verify(() => value(productionDateType).isOneOf(Object.keys(productionDateTypeData)));
    }
);

export const productionNotes = test((productionNotes: Order["productionNotes"] | undefined) => {
    verify(() => value(carcaseColour).isNotEmpty());
    verify(() => value(productionNotes).isString());
});

export const productionStatus = test((productionStatus: Order["productionStatus"] | undefined) => {
    verify(() => value(productionStatus).isNotEmpty());
    verify(() => value(productionStatus).isOneOf(Object.keys(productionStatusData)));
});

export const quoteRef = test((quoteRef: Order["quoteRef"] | undefined) => {
    verify(() => value(quoteRef).isNotEmpty());
    verify(() => value(quoteRef).isString());
});

export const supplierDeliveryDate = test(
    (supplierDeliveryDate: Order["supplierDeliveryDate"] | undefined) => {
        verify(() => value(supplierDeliveryDate).isNotEmpty());
        verify(() => value(supplierDeliveryDate).isNumber());
        passIf(() => supplierDeliveryDate === null);
    }
);

export const supplierDiscount = test((supplierDiscount: Order["supplierDiscount"] | undefined) => {
    verify(() => value(supplierDiscount).isNotEmpty());
    verify(() => value(supplierDiscount).isNumber());
});

export const taxValue = test((taxValue: Order["taxValue"] | undefined) => {
    verify(() => value(taxValue).isNotEmpty());
    verify(() => value(taxValue).isNumber());
});

export const voidSize = test((voidSize: Order["voidSize"] | undefined) => {
    verify(() => value(voidSize).isOneOf(Object.values(voidSizes).map((voidSize) => voidSize.key)));
});

export const payment = test((payment: Order["payments"][number]) => {
    verify(() => value(payment.amount).isNumber());
    verify(() => value(payment.date).isNumber());
});

export const payments = test((payments: Order["payments"]) => {
    return {
        items: arrayOf(payments, payment),
    };
});

export const order = test((order: Order) => {
    return {
        accountingStatus: accountingStatus(order.accountingStatus),
        carcaseColour: carcaseColour(order.carcaseColour),
        constructionType: constructionType(order.constructionType),
        createdBy: createdBy(order.createdBy),
        customerId: customerId(order.customerId),
        customerRef: customerRef(order.customerRef),
        dateCreated: dateCreated(order.dateCreated),
        dateInvoiced: dateInvoiced(order.dateInvoiced),
        dateOrdered: dateOrdered(order.dateOrdered),
        deliveryAddress: deliveryAddress(order.deliveryAddress),
        deliveryDate: deliveryDate(order.deliveryDate),
        deliveryDateType: deliveryDateType(order.deliveryDateType),
        deliveryNotes: deliveryNotes(order.deliveryNotes),
        discount: discount(order.discount),
        doorRange: doorRange(order.doorRange),
        doorSupplier: doorSupplier(order.doorSupplier),
        drawerbox: drawerbox(order.drawerbox),
        editDate: editDate(order.editDate),
        editVersion: editVersion(order.editVersion),
        gluedWallUnits: gluedWallUnits(order.gluedWallUnits),
        hasDoors: hasDoors(order.hasDoors),
        id: id(order.id),
        invoiceAddress: invoiceAddress(order.invoiceAddress),
        invoiceSent: invoiceSent(order.invoiceSent),
        isPinned: isPinned(order.isPinned),
        items: Array.isArray(order.items) && validateOrderItems(order.items),
        orderConfirmationSent: orderConfirmationSent(order.orderConfirmationSent),
        orderRef: orderRef(order.orderRef),
        orderStatus: orderStatus(order.orderStatus),
        orderValueEstimate: orderValueEstimate(order.orderValueEstimate),
        orderValueType: orderValueType(order.orderValueType),
        productionDate: productionDate(order.productionDate),
        productionDateType: productionDateType(order.productionDateType),
        productionNotes: productionNotes(order.productionNotes),
        productionStatus: productionStatus(order.productionStatus),
        quoteRef: quoteRef(order.quoteRef),
        supplierDeliveryDate: supplierDeliveryDate(order.supplierDeliveryDate),
        supplierDiscount: supplierDiscount(order.supplierDiscount),
        taxValue: taxValue(order.taxValue),
        voidSize: voidSize(order.voidSize),
        payments: payments(order.payments),
    };
});
