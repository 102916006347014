import { constants } from "../../constants";
import Decimal from "decimal.js";

export const getMFCM2Price = (cost : number) : number => {
    const useableBoardM2 = constants.MFC_BOARD_USEABLE_M2;
    const margin = constants.MFC_BOARD_MARGIN;

    const price = new Decimal(cost).div(useableBoardM2).mul(margin);

    if(price.isNaN()) throw new Error("Board cost is not a number");
    
    return price.toNumber();
}

