import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { Category } from "../../types";
import { getHeirachicalName } from "./getHeirachicalName";

export const id = test((id: Category["id"]) => {
    verify(() => value(id).isUuid());
});

export const name = test((category: Category, categories: Category[]) => {
    verify(() =>
        value(category.name.toLowerCase())
            .isTruthy("Name is required")
            .isNotWhitespace("Name is required")
    );
    verify(() =>
        value(getHeirachicalName(category, categories).toLowerCase()).isNotOneOf(
            categories
                .filter((cat) => cat.id !== category.id)
                .map((c) => getHeirachicalName(c, categories).toLowerCase())
        )
    );
});

export const category = test((category: Category, categories: Category[]) => {
    return {
        id: id(category.id),
        name: name(category, categories),
    };
});
