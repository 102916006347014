export const edgingTypes = {
    _08: {
        label: "0.8mm",
        key: "_08",
    },
    _20: {
        label: "2.0mm",
        key: "_20",
    },
    NONE: {
        label: "None",
        key: "NONE",
    },
    MATCHING: {
        label: "Matching",
        key: "MATCHING",
    },
} as const;
