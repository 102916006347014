import {Category} from '../../types';
import {naturalSort} from "../shared/naturalSort";

/**
 * 
 * Sorts an array of categories by name
 * 
 * @param  {Category[]} categories
 * @returns Category
 */

export function sortByName(categories : Category[]) : Category[] {
    return categories.sort((a, b) => naturalSort(a.name, b.name))
}