import styled from 'styled-components';
import React from 'react';
import { Tooltip, useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";

const EllipsisOuter = styled.div`
    overflow: hidden;
`;
const EllipsisInner = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100%);
`;


export const Ellipsis: React.FC<{tooltipText? : string}> = ({children, tooltipText, ...rest}) => {

    if(tooltipText) {
        return (
            <Tooltip label={tooltipText}>
                <EllipsisOuter>
                    <EllipsisInner {...rest} >{children}</EllipsisInner>
                </EllipsisOuter>   
            </Tooltip>
        )   
    }

    return (
        <EllipsisOuter>
            <EllipsisInner {...rest} >{children}</EllipsisInner>
        </EllipsisOuter>   
    )  
}