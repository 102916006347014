// Import required UI components and utilities
import {
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spacer,
    Grid,
    Text,
    FormSelect,
} from "@holta/ui";
import React from "react";
import { feathersApp } from "../../config/feathers";
import { proxy, useSnapshot } from "valtio";
import toast from "react-hot-toast";

interface Props {
    children?: React.ReactNode;
    handleSave: (contact_id: string) => void;
}

// Initial state for the modal
const stateObject = {
    selectedContact: null,
    contacts: [],
    callback: () => {},
};

// Global state using Valtio proxy
const state =
    proxy<{
        selectedContact: null | { value: string; label: string };
        contacts: Array<{ name: string; contactID: string }>;
        callback: () => void;
    }>(stateObject);

// Hook to open the modal from anywhere in the app
export function useOpenModalXeroContacts() {
    const { openModal } = useModal();
    return (callback?: () => void) => {
        state.callback = callback || (() => {});
        openModal("MODAL_XERO_CONTACTS");
    };
}

// Main modal component for selecting Xero contacts
export const ModalXeroContacts = ({ handleSave }: Props) => {
    const { closeModal } = useModal();
    const { callback, selectedContact, contacts } = useSnapshot(state);

    // Fetch Xero contacts when modal opens
    React.useEffect(() => {
        let isMounted = true;
        try {
            const fetchContacts = async () => {
                const fetchedContacts = await feathersApp
                    .service("xero")
                    .create({ type: "GET_XERO_CONTACTS" });
                if (isMounted) state.contacts = fetchedContacts;
            };
            fetchContacts();
        } catch (error) {
            toast.error("Error fetching Xero contacts");
        }
        return () => {
            state.selectedContact = null;
            isMounted = false;
        };
    }, []);

    // Handle save button click
    const confirm = () => {
        if (!selectedContact) return;
        handleSave(selectedContact.value);
        closeModal("MODAL_XERO_CONTACTS");
        callback();
    };

    // Handle cancel button click
    const cancel = () => {
        state.selectedContact = null;
        closeModal("MODAL_XERO_CONTACTS");
    };

    return (
        <Modal id="MODAL_XERO_CONTACTS" style={{ width: "800px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Xero Contact</Grid.Row>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2, 2, 1, 2]}>
                        <Text size="l" bold>
                            Choose Xero Contact
                        </Text>
                        <br />
                        <br />
                        <Text>This will be assigned to the customer for future use.</Text>
                    </Spacer>
                    <Spacer xs={[1, 2, 2, 2]}>
                        <FormSelect
                            options={contacts.map((c) => ({ value: c.contactID, label: c.name }))}
                            value={selectedContact}
                            onChange={(contact: { value: string; label: string } | null) =>
                                (state.selectedContact = contact)
                            }
                        />
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={cancel}>Cancel</button>
                    {selectedContact && (
                        <>
                            <Spacer xs={[0.5]} />
                            <button className="primary" onClick={confirm}>
                                Save
                            </button>
                        </>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
