import {Order} from '../../types';
import Decimal from 'decimal.js';
import { orderValueTypes } from '../../static_data/orderValueTypes';
import { getCarcaseItemsTotal } from '../order_items/getCarcaseItemsTotal';
import { getOrderTotalOrEstimate } from './getOrderTotalOrEstimate';

export function getOrderSize(order : Order) : string {

    const total = getOrderTotalOrEstimate(order, {excludeTax: true});

    if((typeof total == 'string' && parseFloat(total)) || typeof total == 'number') {
        return new Decimal(total).div(1000).toFixed(2);
    }
    return '0';

}
