import React from 'react';
import {enGB} from 'date-fns/locale';
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale('en-GB', enGB);

export const FormDate = (props : ReactDatePickerProps) => {

    /*
    const picker = React.useRef<any>()

    const handleClickOutside = () => {
        if(picker.current) {
            picker.current.cancelFocusInput();
            picker.current.setOpen(false);
        }
    }
    

    return (
        <DatePicker {...props} ref={picker.current} onBlur={handleClickOutside} onClickOutside={handleClickOutside}/>
    )

    */

    return (
        <DatePicker {...props} locale="en-GB"/> 
    )
}


