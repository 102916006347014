import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { deleteOrderItems } from "./deleteOrderItems";
import { createLinkedDoors } from "./createLinkedDoors";

function getItemsToDelete(items: libTypes.OrderItem[]) : string[] {
	const filteredLinkedDoors = fns.order_items.filterLinkedDoorItems(items);
	const filteredMissingLinkedDoors = fns.order_items.filterMissingLinkedDoorItems(items);
	return [...filteredLinkedDoors, ...filteredMissingLinkedDoors].map((item) => item.id);
}

export const replaceLinkedDoors = (
	handleDuplicates: (
		linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][][],
		cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void
	) => void
) => {
	const mergedOrder = getMergedOrder();

	deleteOrderItems(getItemsToDelete(mergedOrder.items));

	createLinkedDoors(handleDuplicates);
};
