import {filterActiveOrders} from './filterActiveOrders';
import {filterAsapDeliveries} from './filterAsapDeliveries';
import {filterByCarcaseColour} from './filterByCarcaseColour';
import {filterByDeliveryDay} from './filterByDeliveryDay';
import {filterByProductionWeek } from './filterByProductionWeek';
import {filterByProductionStatus} from './filterByProductionStatus';
import {filterDayDeliveries} from './filterDayDeliveries';
import {filterNoDelivery} from './filterNoDelivery';
import {filterUndelivered} from './filterUndelivered';
import {filterWCDeliveries} from './filterWCDeliveries';
import {getCarcaseColours} from './getCarcaseColours';
import {getProductionStatusPercentage} from './getProductionStatusPercentage';
import {sortByDate} from './sortByDate';
import {sortByDeliveryDateAndType} from './sortByDeliveryDateAndType';
import {getWeekProductionCapacity} from './getWeekProductionCapacity';
import {getDeliveredSameWeekPercentage} from './getDeliveredSameWeekPercentage';
import {filterByDateRange} from './filterByDateRange';
import { getTotalValue } from './getTotalValue';
export default {
    filterActiveOrders,
    filterAsapDeliveries,
    filterByCarcaseColour,
    filterByDeliveryDay,
    filterByProductionWeek,
    filterByProductionStatus,
    filterDayDeliveries,
    filterNoDelivery,
    filterUndelivered,
    filterWCDeliveries,
    getCarcaseColours,
    getProductionStatusPercentage,
    sortByDate,
    sortByDeliveryDateAndType,
    getWeekProductionCapacity,
    getDeliveredSameWeekPercentage,
    filterByDateRange,
    getTotalValue
}

