import { fns } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";



export const createCarcaseItem = () => {
    const mergedOrder = getMergedOrder();
    const editOrder = getEditOrder();
    const newOrderItem = fns.order_item.createCarcaseItem({discount: mergedOrder.discount});
    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.orderItem = newOrderItem;
        }),
    });
}