
import { Product } from "../../types";
import {v4 as uuid} from 'uuid';

export const create = (createdBy : string, fields? : Partial<Product>) : Product => {     
    const prunedFields = {
        ...fields,
    }

    delete prunedFields.id;
    delete prunedFields.dateCreated;
    delete prunedFields.createdBy;

    prunedFields.code = prunedFields.code ? prunedFields.code + " (copy)" : "";


    return  {
        categories: [],
        code: "",
        components: [],
        createdBy,
        customerRestricted: false,
        customerWhitelistIds: [],
        d: null,
        dateCreated: new Date().getTime(),
        description: "",
        drawers: 0,
        h: null,
        id: uuid(),
        image: '',
        linkProducts: [],
        activeLinkProductGroups: [],
        price: 0,
        priceType: "SIMPLE",
        priceExpression: null,
        soldByM2: false,
        specification: "",
        w: null,
        ...prunedFields
}};