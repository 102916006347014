import styled from 'styled-components';
import React from 'react';
import {Icon} from '../icon';
import { Spacer } from '../spacer';

const Button = styled.button`
    display: flex;
    align-items: center;
    border-radius: 1000px !important;
    padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(1.25)}!important;
`;

const IconWrap = styled.div`
    //margin-right: ${p => p.theme.spacing(0.5)};
`;


interface IconButtonProps extends React.HTMLAttributes<HTMLOrSVGElement> {
    icon : string
    children? : React.ReactNode
    disabled? : boolean
    as?: keyof JSX.IntrinsicElements;
}

export const IconButton = ({icon, children, ...rest} : IconButtonProps) => {
    return (
        <Button {...rest} className="btn">
            <IconWrap> <Icon name={icon} /> </IconWrap>
            {children ? <><Spacer xs={[0.25]}/>{children}</> : null}
        </Button>
    )
}