export function value(val: any) {
    return {
        isEmail: (message?: string) => isEmail(val, message),
        isString: (message?: string) => isString(val, message),
        isNotEmpty: (message?: string) => isNotEmpty(val, message),
        isNotWhitespace: (message?: string) => isNotWhitespace(val, message),
        isNumber: (message?: string) => isNumber(val, message),
        isNumerical: (message?: string) => isNumerical(val, message),
        isEqualTo: (compare: any, message?: string) => isEqualTo(val, compare, message),
        isNotEqualTo: (compare: any, message?: string) => isNotEqualTo(val, compare, message),
        isOneOf: (compare: any, message?: string) => isOneOf(val, compare, message),
        isNotOneOf: (compare: any, message?: string) => isNotOneOf(val, compare, message),
        isMax: (max: number, message?: string) => isMax(val, max, message),
        isMin: (min: number, message?: string) => isMin(val, min, message),
        isBoolean: (message?: string) => isBoolean(val, message),
        isArray: (message?: string) => isArray(val, message),
        isObject: (message?: string) => isObject(val, message),
        isLength: (length: number, message?: string) => isLength(val, length, message),
        isUuid: (message?: string) => isUuid(val, message),
        isEmptyString: (message?: string) => isEmptyString(val, message),
        isNotUndefined: (message?: string) => isNotUndefined(val, message),
        isNotNull: (message?: string) => isNotNull(val, message),
        isTruthy: (message?: string) => isTruthy(val, message),
        isFalsy: (message?: string) => isFalsy(val, message),
        when: (condition: boolean) => when(val, condition),
    };
}

function isEmail(val: any, message?: string) {
    if (!/^[^@]+@[^@]+\.[^@]+$/.test(val)) {
        throw new Error(message || "Invalid email");
    }
    return value(val);
}

function isString(val: any, message?: string) {
    if (typeof val !== "string") {
        throw new Error(message || "Is not string");
    }
    return value(val);
}

function isNumber(val: any, message?: string) {
    if (typeof val !== "number" || isNaN(val)) {
        throw new Error(message || "Is not number");
    }
    return value(val);
}

function isNumerical(val: any, message?: string) {
    if (!/^-?\d+\.?\d*$/.test(val)) {
        throw new Error(message || "Is not numerical");
    }
    return value(val);
}

function isEqualTo(val: any, compare: any, message?: string) {
    if (val !== compare) {
        throw new Error(message || `Is not equal to ${compare}`);
    }
    return value(val);
}

function isNotEqualTo(val: any, compare: any, message?: string) {
    if (val === compare) {
        throw new Error(message || `Is equal to ${compare}`);
    }
    return value(val);
}

function isNotEmpty(val: any, message?: string) {
    if (!val && val !== 0 && val !== false)  {
        throw new Error(message || "Is empty");
    }
    return value(val);
}

function isNotWhitespace(val: any, message?: string) {
    if (!/([^\s])/.test(val)) {
        throw new Error(message || "Is whitespace");
    }
    return value(val);
}

function isBoolean(val: any, message?: string) {
    if (typeof val !== "boolean") {
        throw new Error(message || "Is not boolean");
    }
    return value(val);
}

function isOneOf(val: any, compare: any, message?: string) {

    if (typeof val === "object") {
        if (!compare.some((item: any) => object_equals(val, item))) {
            throw new Error(message || `Does not match compare values`);
        }
    } else {
        if (!compare.includes(val)) {
            throw new Error(message || `Does not match compare values`);
        }
    }
    return value(val);
}

function isNotOneOf(val: any, compare: any, message?: string) {

    if (typeof val === "object") {
        if (compare.some((item: any) => object_equals(val, item))) {
            throw new Error(message || `Matches compare values`);
        }
    } else {
        if (compare.includes(val)) {
            throw new Error(message || `Matches compare values`);
        }
    }
    return value(val);
}

function isMax(val: any, max: number, message?: string) {
    if (val > max) {
        throw new Error(message || `Is greater than ${max}`);
    }
    return value(val);
}

function isMin(val: any, min: number, message?: string) {
    if (val < min) {
        throw new Error(message || `Is less than ${min}`);
    }
    return value(val);
}

function isArray(val: any, message?: string) {
    if (!Array.isArray(val)) {
        throw new Error(message || "Is not array");
    }
    return value(val);
}

function isObject(val: any, message?: string) {
    if (typeof val !== "object") {
        throw new Error(message || "Is not object");
    }
    return value(val);
}

function isLength(val: any, length: number, message?: string) {
    if (val.length !== length) {
        throw new Error(message || `Length is not ${length}`);
    }
    return value(val);
}

function isUuid(val: any, message?: string) {
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(val)) {
        throw new Error(message || "Invalid uuid");
    }
    return value(val);
}

function isEmptyString(val: any, message?: string) {
    if (val !== "") {
        throw new Error(message || "Has no value");
    }
    return value(val);
}

function when(val: any, condition: boolean) {
    if (condition) {
        return value(val);
    }
    return _break();
}

function isNotUndefined(val: any, message?: string) {
    if (typeof val === "undefined") {
        throw new Error(message || "Is undefined");
    }
    return value(val);
}

function isNotNull(val: any, message?: string) {
    if (val === null) {
        throw new Error(message || "Is null");
    }
    return value(val);
}

function isTruthy(val: any, message?: string) {
    if (!val) {
        throw new Error(message || "Is not truthy");
    }
    return value(val);
}

function isFalsy(val: any, message?: string) {
    if (val) {
        throw new Error(message || "Is not falsy");
    }
    return value(val);
}

export function _break() {
    const valueFns = value(null);
    const rtnValueFns: any = {};

    for (const key in valueFns) {
        rtnValueFns[key] = (...args: any) => _break();
    }
    return rtnValueFns;
}

// https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
function object_equals(x: any, y: any) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof x[p] !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!object_equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined

    return true;
}
