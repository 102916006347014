export const taxGroups = {
    VAT : {
        key: 'VAT',
        label: 'VAT',
        value: 20
    },
    NONE : {
        key: 'NONE',
        label: 'None',
        value: 0
    },
    ZERO : {
        key: 'ZERO',
        label: 'Zero',
        value: 0
    }
} as const;