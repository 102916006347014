import { Order } from "../../types";
import { sortByDate } from "./sortByDate";
import { filterAsapDeliveries } from "./filterAsapDeliveries";
import { filterPinned } from "./filterPinned";
import { filterWCDeliveries } from "./filterWCDeliveries";
import { filterDayDeliveries } from "./filterDayDeliveries";
import { filterNoDelivery } from "./filterNoDelivery";

export function sortByDeliveryDateAndType(orders: Order[]): Order[] {
    const ordersByDate = sortByDate(sortByDate(orders, "dateCreated"), "deliveryDate");
    const pinnedOrders = filterPinned(ordersByDate);

    const withoutPinned = ordersByDate.filter(order => !order.isPinned);
    const wcOrders = filterWCDeliveries(withoutPinned);
    const asapOrders = filterAsapDeliveries(withoutPinned);
    const dateDeliveredOrders = filterDayDeliveries(withoutPinned);
    const noDelivery = filterNoDelivery(withoutPinned);


    return [...pinnedOrders, ...dateDeliveredOrders, ...wcOrders, ...asapOrders, ...noDelivery];
}
