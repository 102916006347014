import {Order, MFCColour} from '../../types';

export function isCarcaseColour(
    order: Order,
    carcaseColour: MFCColour,
    exact = false
): boolean {

    if(!order.carcaseColour) return false;
    if (exact)
        return (
            (order.carcaseColour.texture == carcaseColour.texture &&
                order.carcaseColour.code == carcaseColour.code &&
                order.carcaseColour.decor_name == carcaseColour.decor_name &&
                order.carcaseColour.group == carcaseColour.group) ||
            false
        );
    return (
        (order.carcaseColour.texture == carcaseColour.texture &&
            order.carcaseColour.code == carcaseColour.code) ||
        false
    );

}
