import Decimal from "decimal.js";
import {constants} from '../../constants';

export const getSheetMaterialM2Price = (cost : number) : number => {
    const useableBoardM2 = constants.SHEET_MATERIAL_USEABLE_M2;
    const margin = constants.SHEET_MATERIAL_MARGIN;

    const price = new Decimal(cost).div(useableBoardM2).mul(margin);

    if(price.isNaN()) throw new Error("Sheet material cost is not a number");
    
    return price.toNumber();
}

