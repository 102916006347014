
import {Order} from '../../types';
import Decimal from 'decimal.js';
import { getOrderItemsPrice } from './getOrderItemsPrice';


/**
 * 
 * Gets the tax value of all products and supplier products in an order
 * 
 * @param  {Order} order
 * @returns string
 */
export function getTaxPrice(order : Order) : string{

    const subTotal = getOrderItemsPrice(order);

    return new Decimal(subTotal).div(100).mul(order.taxValue).toFixed(2);
    
}