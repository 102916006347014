import React from "react";
import { fns, libTypes } from "@holta/lib";
import { feathersApp } from "../../config/feathers";

export const ProductBrowserContext =
    React.createContext<ReturnType<typeof useSupplierProductBrowserContextValue> | undefined>(undefined);

export const useSupplierProductBrowserContext = () => {
    const context = React.useContext(ProductBrowserContext);
    if (context === undefined) {
        throw new Error("useSupplierProductBrowserContextmust be within the correct provider");
    }
    return context;
};

const useSupplierProductBrowserContextValue = (
    suppliers: libTypes.Supplier[],
    categories: libTypes.Category[],
    handleConfirm: (product: libTypes.SupplierComputedProduct) => void,
    modalId: string,
) => {

    // state for holding the expanded supplier
    const [expandedSupplier, setExpandedSupplier] = React.useState<string|null>(null);

    // state for holding the selected product
    const [selectedProduct, setSelectedProduct] = React.useState<libTypes.SupplierComputedProduct | null>(null);

    // state for holding the filter term
    const [filter, setFilter] = React.useState("");

    // Wrapped handle confirm function
    const wrappedHandleConfirm = React.useCallback(() => {
        if (selectedProduct) {
            handleConfirm(selectedProduct);
        }
    }, [selectedProduct, handleConfirm]);

    const [products, setProducts] = React.useState<libTypes.SupplierComputedProduct[]>([]);

    React.useEffect(() => {
        if(!expandedSupplier) setProducts([]);
        feathersApp.service('supplier-composite-products').find({
            query: {
                $limit: 1000000,
                supplierId: expandedSupplier,
            }
        }).then((res : any) => {
            //console.log(res)
            setProducts(res.data)
        })
    }, [expandedSupplier])



    const sortedProducts = React.useMemo(() => {
        if(Array.isArray(products)){
            return fns.shared.sortByString(products, 'code');
        }
        return [];
    }, [products]);

    // Filtered products
    const filteredProducts = React.useMemo(() => {
        if (!expandedSupplier && !filter) return sortedProducts;
        let ps = sortedProducts;
        if (expandedSupplier) ps = fns.shared.filterByString(ps, 'supplierId', expandedSupplier, true);
        if (filter) ps = fns.shared.filterByString(ps, 'code', filter, true);
        return ps;
    }, [sortedProducts, expandedSupplier, suppliers, filter]);

    return {
        expandedSupplier,
        setExpandedSupplier,
        selectedProduct,
        setSelectedProduct,
        handleConfirm : wrappedHandleConfirm,
        filteredProducts,
        modalId,
        filter,
        setFilter,
        suppliers
    };
};

export const SupplierProductBrowserContextProvider = ({
    children,
    suppliers,
    products,
    handleConfirm,
    modalId,
    categories,
}: {
    children: React.ReactNode;
    suppliers: libTypes.Supplier[];
    categories: libTypes.Category[];
    products: libTypes.SupplierComputedProduct[];
    handleConfirm: (product: libTypes.SupplierComputedProduct) => void;
    modalId: string;
}) => {
    const contextValue = useSupplierProductBrowserContextValue(suppliers, categories, handleConfirm, modalId);

    return (
        <ProductBrowserContext.Provider value={contextValue}>
            {children}
        </ProductBrowserContext.Provider>
    );
};
