export const cutAndEdgeOrderStatuses = {
    QUOTE: { label: "Quote", key: "QUOTE" },
    ORDER: { label: "Order", key: "ORDER" },
    AWAITING_ADMIN_APPROVAL: { label: "Processing", key: "AWAITING_ADMIN_APPROVAL" },
    AWAITING_CUSTOMER_APPROVAL: { label: "Please confirm", key: "AWAITING_CUSTOMER_APPROVAL" },
    AWAITING_CUSTOMER_APPROVAL_ADMIN_CHANGED: {
        label: "Please approve changes",
        key: "AWAITING_CUSTOMER_APPROVAL_ADMIN_CHANGED",
    },
    COMPLETED: { label: "Completed", key: "COMPLETED" },
    DELETED: { label: "Deleted", key: "DELETED" },
} as const;
