import styled from "styled-components";
import React from 'react';
import {Text} from '../text';
import {Spacer} from '../spacer';
import {screenSize} from '../../constants/screenSize';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${({theme}) => theme.spacing(4)};
    background-color: ${({theme}) => theme.color.accent1}; 
    border-radius: 16px;

    @media (min-width: ${screenSize.xs}px) {
        padding: ${({theme}) => theme.spacing(8)};
    }
`;

export const NoResults = ({text, buttonText, onClick} : {text? : string, buttonText?: string, onClick?: () => void}) => {
    return (
        <Wrapper>
            {text && <><Text size="l" color="text">{text || 'No Results'}</Text> <Spacer xs={[2]}></Spacer></>}
            {onClick && buttonText && (<button className="primary" onClick={onClick}>{buttonText}</button>)}
        </Wrapper>
    )
}