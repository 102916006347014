import React from "react";
import styled from "styled-components";
import {
    RiArrowRightLine,
    RiArrowLeftLine,
    RiArrowUpLine,
    RiArrowDownLine,
} from "react-icons/ri";

const Container = styled.div<{size : number}>`
    border-radius: 1000px;
    background-color: ${(props) => props.theme.color.accent1};
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.color.highlight};
`;

interface ArrowProps {
    left?: boolean;
    right?: boolean;
    up?: boolean;
    down?: boolean;
    size?: number;
    className? : string;
    onClick?: () => void
}

export const Arrow = ({
    left,
    right,
    up,
    down,
    onClick,
    size = 40,
    className
}: ArrowProps): React.ReactElement => {

    const arrowSize = size / 2 + 'px';

    return (
        <Container onClick={onClick} size={size} className={className}>
            {left && <RiArrowLeftLine size={arrowSize} />}
            {right && <RiArrowRightLine size={arrowSize} />}
            {up && <RiArrowUpLine size={arrowSize} />}
            {down && <RiArrowDownLine size={arrowSize} />}
        </Container>
    );
};
