import React from 'react';
import { Dialog } from './dialog';
import { useDialog, Dialog as DialogInterface} from './context';



export const GenericDialog = () => {

    const {openDialogs, closeDialog} = useDialog();
    const genericOpenDialogs : DialogInterface[] = Object.keys(openDialogs).filter(id => typeof openDialogs[id] === 'object').map(id => openDialogs[id] as DialogInterface);


    return (
        <>
            { genericOpenDialogs.map(item => 
            
                <Dialog key={item.id} id={item.id} label={item.label} success={item.success} description={item.description} buttons={defaultButton => 
                    <>
                        {item.buttons.map((button ,i) => {
                            if (button.primary) return <button key={i} ref={defaultButton} className="primary" onClick={() => {button.fn(); closeDialog(item.id)}} >{button.label}</button>;
                            else return <button key={i} onClick={() => {button.fn(); closeDialog(item.id)}} >{button.label}</button>;
                        })}
                    </>
                }></Dialog>
            )}
        </>
        
    )
};