import Decimal from 'decimal.js';

import { CutAndEdgeOrderSheetMaterialGroup } from '../../types';
import { getCutItemPrice } from './getCutItemPrice';
import { getSheetMaterialEdgingPrice } from './getSheetMaterialEdgingPrice';
import { getSheetMaterialM2Price } from './getSheetMaterialM2Price';


export const getSheetMaterialOrderGroupPrice = (boardGroup : CutAndEdgeOrderSheetMaterialGroup) : number => {


    // Get the board m2 price
    const m2Price = getSheetMaterialM2Price(boardGroup.sheetMaterial.price);

    // Get the edging cost or return 0
    const edgingPrice = getSheetMaterialEdgingPrice(boardGroup.sheetMaterial.edgingPrice, boardGroup.edgingType);

    let total = new Decimal(0);

    boardGroup.cuts.forEach(cut => {
        const cutTotal = getCutItemPrice(m2Price, edgingPrice, cut);
        total = total.add(cutTotal);
    });

    return total.toNumber()
    
}


