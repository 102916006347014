import {Supplier} from '../../types';
/**
 * 
 * Returns a single supplier from an ID
 * 
 * @param  {string} id
 * @param  {Supplier[]} suppliers
 * @returns Supplier
 */
export function findById(id : string, suppliers : Supplier[]) : Supplier | undefined {
    return suppliers.find(supplier => id == supplier.id)
}