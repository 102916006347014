import {v4 as uuid} from 'uuid';
import { Supplier } from "../../types"

export const create = (createdBy: string) : Supplier => {

    return {
        defaultContact: null,
        defaultAddress: null,
        discount: 0,
        id: uuid(),
        name: '',
        notes: '',
        margin: 0,
        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        lastImport: null,
        matchedImportHeadings: {}
    }
    
}
