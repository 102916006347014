import { feathersApp } from "../config/feathers";
import { useStore } from "../config/store";
import * as ordersSearchActions from "../actions/ordersSearch";
import isEqual from 'lodash/isEqual';
import React from "react";
import { libTypes } from "@holta/lib";
import { throttle } from "lodash";
import toast from "react-hot-toast";

let timer: ReturnType<typeof setTimeout> | null = null;
let searchFilter = "";
let loadingToastId: string | null = null;
let requestCount = 0;
let lastRequestCount = 0;

export const useLoadOrdersSearch = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    async function getOrders() {
        toast.loading("Searching orders...", { id: 'loading' });
        requestCount++;
        const currentRequestCount = requestCount;
        const orders = await feathersApp.service("orders").find({
            query: {
                $limit: 1000,
                $joinRelation: 'customer', 
                $sort: {
                    dateCreated: -1
                },
                $or: [
                    {
                        quoteRef: { $ilike : `${searchFilter}%`},
                    },
                    {
                        orderRef: { $ilike : `${searchFilter}%`},
                    },
                    {
                        customerRef: { $ilike : `${searchFilter}%`},
                    },
                    {
                        'customer.name': { $ilike : `${searchFilter}%`},
                    }
                ]
            },
        });
        toast.dismiss('loading');
        if(lastRequestCount < currentRequestCount) {
            lastRequestCount = currentRequestCount;
            ordersSearchActions.setOrdersSearch(orders.data);
            startPollTimer();
        }
    }

    // For ease, we'll just poll every 15 seconds rather than listening to events and diffing.
    // This is a workaround due to feathers reactive not working with '$like' operator.
    function startPollTimer() {
        if(timer) {
            // if there is a newer timer set, don't do anything.
            return;
        }
        timer = setTimeout(() => {
            getOrders();
        }, 15000);
    }

    function clearTimer(){
        timer && clearTimeout(timer);
        timer = null;
    }

    function setNewSearchFilter(term : string) {
        searchFilter = term;
    }

    const throttledGetOrders = throttle(getOrders, 250);

    useStore.subscribe((state) => {

        if(state.ui.orders.searchFilter.length === 0) {
            clearTimer();
            return;
        }
        else if(state.ui.orders.searchFilter === searchFilter) {
            return;
        }
        clearTimer();

        setNewSearchFilter(state.ui.orders.searchFilter);
        
        throttledGetOrders();

    });
};
