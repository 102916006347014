import { Customer } from "../../types";

export function sortByDateCreated(customers: Customer[], direction: 'ASC' | 'DESC' | 'NONE' = 'ASC'): Customer[] {
    
    const _customers = [...customers];

    return _customers.sort((a, b) => {
        if(a.dateCreated < b.dateCreated){
            if(direction === 'ASC' || direction === 'NONE') return -1;
            return 1
        }
        if(a.dateCreated > b.dateCreated){
            if(direction === 'ASC' || direction === 'NONE') return 1;
            return -1
        }
        return 0;
    });
}