import { Order } from "../../types";
import Decimal from "decimal.js";
import { getTaxPrice } from "./getTaxPrice";
import { getOrderItemsPrice } from "./getOrderItemsPrice";
import { getOrderTotalOrEstimate } from "./getOrderTotalOrEstimate";

/**
 *
 * Gets the total cost includeing tax for the whole order
 *
 * @param  {Order} order
 * @returns string
 */

export function getTotal(
    order: Order,
    options? : { excludeTax? : boolean, includeEstimates?: boolean }
): string {
    let total = options?.includeEstimates ? new Decimal(getOrderTotalOrEstimate(order)) : new Decimal(getOrderItemsPrice(order));
    if (!options?.excludeTax) total = total.add(getTaxPrice(order));
    return total.toFixed(2);
}
