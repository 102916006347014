import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { v4 as uuid } from "uuid";

export const duplicateOrderItem = (orderItemId: string) => {
    const mergedOrder = getMergedOrder();
    const editOrder = getEditOrder();

    const exisitingItem = mergedOrder.items?.find((item) => item.id === orderItemId);

    if(!exisitingItem) return;

    const existingChildItems = mergedOrder.items?.filter(
        (item) => fns.shared.hasProperty(item, "parentId") && item.parentId === orderItemId
    );

    const newId = uuid();

    const newChildItems = existingChildItems.map((item) => {
        return {
            ...item,
            parentId: newId,
            id: uuid(),
        };
    });

    const newItem = {
        ...exisitingItem,
        id: newId,
    };

    useStore.setState({
        editOrder: create(editOrder, (draft) => {
            draft.newValues.order.items = [
                ...(editOrder.newValues.order.items || []),
                newItem,
                ...newChildItems,
            ];
        }),
    });
    
};
