import { CarcaseOrderItem, LinkedDoorOrderItem, MissingLinkedOrderItem } from "../../types/orderItems";
import { SupplierComputedProduct } from "../../types/supplierComputedProduct";
import { getLinkProductIds } from "./getLinkProductIds";
import { ConstructionType } from "../../types/constructionType";
import { findSupplierComputedProductByLinkProduct } from "../supplierProducts/findSupplierComputedProductByLinkProduct";
import { createMissingDoorItem } from "./createMissingDoorItem";
import { createLinkedDoorOrderItem } from "./createLinkedDoorOrderItem";
import { LinkProduct } from "../../types/linkProduct";

export function getLinkedSupplierProducts(
    orderItem: CarcaseOrderItem,
    supplierProducts: SupplierComputedProduct[],
    linkProducts: LinkProduct[],
    constructionType: ConstructionType,
    supplierDiscount: number
): {
    matched: LinkedDoorOrderItem[];
    unmatched: MissingLinkedOrderItem[];
    duplicates: LinkedDoorOrderItem[][];
} {
    const linkProductIds = getLinkProductIds(orderItem, constructionType);

    const results = {
        matched: [] as LinkedDoorOrderItem[],
        unmatched: [] as MissingLinkedOrderItem[],
        duplicates: [] as LinkedDoorOrderItem[][],
    };
    linkProductIds.forEach((linkProductId) => {
        const result = supplierProducts.filter(
            (product) => product.linkProductId === linkProductId.id
        );
        if (result.length === 0) {
            const linkProduct =  linkProducts.find((linkProduct) => linkProduct.id === linkProductId.id);
            linkProduct &&
            results.unmatched.push(
                createMissingDoorItem(linkProductId.id, linkProduct.name, orderItem.quantity * linkProductId.qty, orderItem.id)
            );
        } else if (result.length === 1) {
            results.matched.push(
                createLinkedDoorOrderItem(result[0], supplierDiscount, orderItem.id, orderItem.quantity * linkProductId.qty)
            );
        } else if (result.length > 1) {
            results.duplicates.push(
                result.map((product) =>
                    createLinkedDoorOrderItem(product, supplierDiscount, orderItem.id, orderItem.quantity * linkProductId.qty)
                )
            );
        }
    });

    return results;
}
