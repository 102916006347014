import {Order} from '../../types';
import { getDayOfYear } from 'date-fns';
import {isDeleted} from './isDeleted';
import { deliveryDateTypes } from '../../static_data/deliveryDateTypes';

export function isDeliveredOnDate(order:Order, deliveryDate : number) : boolean {

    if(isDeleted(order)) return false;
    if(!order.deliveryDate) return false;
    if(order.deliveryDateType === deliveryDateTypes.DAY.key && getDayOfYear(order.deliveryDate) === getDayOfYear(deliveryDate)) return true;
    return false;
}