import { CarcaseOrderItem, OrderItem, Product } from "../../types";
import {getPrice as getProductPrice} from "../product/getPrice";
import {v4 as uuid} from 'uuid';
import { ProductComponent } from "../../types/productComponent";

export function createCarcaseItem(values?: Partial<CarcaseOrderItem>): CarcaseOrderItem {
    return {
        code: "",
        d: null,
        dateCreated: new Date().getTime(),
        dEdited: null,
        description: "",
        discount: 0,
        h: null,
        handing:  null,
        hEdited: null,
        id:  uuid(),
        linkProducts: [],
        notes: '',
        position: "",
        price: 0,
        productId: "",
        quantity: 1,
        soldByM2: false,
        sortOrder: null,
        specification: "",
        w: null,
        wEdited: null,
        type: 'CARCASE',
        ...values
    }
}
