export function allOf(...tests: (() => boolean)[]): boolean {
    let results = [];
    for (const test of tests) {
        try {
            const result = test();
            result && results.push(true);
        }
        catch(e) {
            return false;
        }
    }
    return results.length === tests.length;
    
}