import {Order, MFCColour} from '../../types';
import { isCarcaseColour } from "../order/isCarcaseColour";

/**
 * Creates an array of all unique caracase colours found in an array of orders.
 * 
 * @param  {Order[]} orders
 * @returns CarcaseColour
 */
export function getCarcaseColours(orders:Order[]):MFCColour[] {

    return orders.reduce<MFCColour[]>((acc, order) => {
        if(!acc.some(item => isCarcaseColour(order, item))) {
            if(order.carcaseColour && order.carcaseColour.texture && order.carcaseColour.code)
            return [...acc, order.carcaseColour]
        };
        return acc;
    }, [] )

}