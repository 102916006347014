export const cutAndEdgeOrderGroupTypes = {
    MFC_18: {
        label: "Egger 18mm",
        key: "MFC_18"
    },
    MFC_36: {
        label: "Egger 36mm",
        key: "MFC_36"
    },
    SHEET_MATERIAL: {
        label: "8x4 Sheet",
        key: "SHEET_MATERIAL"
    },
} as const;
