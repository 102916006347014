import {Category} from '../../types';
/**
 * 
 * Returns an array of categories either removing or isolating children of specific category(s). Optionally including or excluding the supplied parent categories.
 * 
 * @param  {Category[]} categories
 * @param  {string} parentId
 * @param  {returnChildren} returnChildren=false
 * @returns Category
 */

export function filterBranch(categories : Category[], parentIds : string | string[], filterSelf: boolean = false, returnChildren : boolean = false) : Category[] {

    parentIds = Array.isArray(parentIds) ? parentIds : [parentIds];

    let childIdList : string[] = [];

    function getChildren(categories : Category[], parentIds : string[]) {

        let children = categories.filter(child => {
            return  child.parentId && parentIds.includes(child.parentId);
        });

        if (children.length > 0) {
            children.forEach(child => {
                
                childIdList.push(child.id);
                getChildren(categories, [child.id]);
            });
        }
    }

    getChildren(categories, parentIds);

    if(filterSelf) childIdList = childIdList.concat(parentIds);

    if(returnChildren) return categories.filter(category => childIdList.includes(category.id));

    return categories.filter(category => !childIdList.includes(category.id));
    
}
