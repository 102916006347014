import styled from "styled-components";
import React from "react";

export const TableContext = React.createContext({
    cols: 1,
});

export const Table = ({ cols = 1, children }: { cols: number; children: React.ReactNode }) => {
    const contextVal = {
        cols,
    };
    return <TableContext.Provider value={contextVal}>{children}</TableContext.Provider>;
};

export const Thead = styled.header`

    border-left: 1px solid ${({ theme }) => theme.color.accent3};
    border-right: 1px solid ${({ theme }) => theme.color.accent3};
    border-bottom: 1px solid ${({ theme }) => theme.color.accent3};
    border-top: 1px solid ${({ theme }) => theme.color.accent3};
    border-radius: 4px 4px 0 0;
    background-color: ${({ theme }) => theme.color.accent1};
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
`;

export const Tbody = styled.div`

    border-left: 1px solid ${({ theme }) => theme.color.accent3};
    border-right: 1px solid ${({ theme }) => theme.color.accent3};
    border-bottom: 1px solid ${({ theme }) => theme.color.accent3};
    //overflow: hidden;
    background-color: ${({ theme }) => theme.color.base_contrast};
    width: 100%;

    ${Thead} + & {
        border-radius: 0px 0px 4px 4px;
    }
`;

export const Tr = styled.div<{hasHover?: boolean, isSelected?: boolean, isStriped?: boolean}>`
    display: flex;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.color.accent3};
    
    ${({ theme, hasHover }) => hasHover && `
        transition: all 0.1s;
        &:hover {
            background-color: ${theme.color.highlight2};
        }
    `}

    ${({ theme, isStriped }) => isStriped && `
        background-color: ${theme.color.accent1};
    `}

    ${({ theme, isSelected }) => isSelected && `
        border-bottom: 1px solid ${theme.color.highlight};
        background-color: ${theme.color.highlight2};
    `}
    
    
`;

const ThStyle = styled.div<{ colPercent: number; align?: "sb" | "c", minWidth?: string | number}>`
    display: flex;
    align-items: center;
    flex-basis: ${(props) => props.colPercent}%;
    padding: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.type.s};
    ${(props) => props.theme.type.bold};
    text-align: center;
    justify-content: ${(props) => (props.align === "c" ? "center" : "space-between")};
    background-color: ${({ theme }) => theme.color.accent1};

    ${(props) => {
        if(props.minWidth) {
            if(typeof props.minWidth === 'string' && props.minWidth.match(/[a-zA-Z]$/)) {
                return 'min-width: ' + props.minWidth + ';'
            } else if(typeof props.minWidth === 'number'){
                return 'min-width: ' + props.theme.spacing(props.minWidth) + ';';
            }
        } 
        else {
            return 'min-width: 0;'
        }
    }}
`;

export const Th = ({
    children,
    colspan,
    minWidth,
    align,
    className,
    onClick,
}: {
    children?: React.ReactNode;
    colspan: number;
    minWidth?: string | number
    align?: "sb" | "c";
    className? : string
    onClick? : () => void
}) => {
    const TC = React.useContext(TableContext);
    const colPercent = (100 / TC.cols) * colspan;
    return (
        <ThStyle colPercent={colPercent} align={align} className={className} minWidth={minWidth} onClick={onClick}>
            {children}
        </ThStyle>
    );
};

const TdStyle = styled.div<{ colPercent: number, align? :'l'|'r'|'c', minWidth?: string | number, spacing? : number}>`
    display: flex;
    align-items: center;
    justify-content: ${p => p.align === 'c' ? 'center' : p.align === 'r' ? 'flex-end' : 'flex-start' };
    flex-basis: ${(props) => props.colPercent}%;
    padding: ${(props) => props.theme.spacing(props.spacing || 1)};
    ${(props) => {
        if(props.minWidth) {
            if(typeof props.minWidth === 'string' && props.minWidth.match(/[a-zA-Z]$/)) {
                return 'min-width: ' + props.minWidth + ';'
            } else if(typeof props.minWidth === 'number'){
                return 'min-width: ' + props.theme.spacing(props.minWidth) + ';';
            }
        } 
        else {
            return 'min-width: 0;'
        }
    }}

    #{Tr} & {
        border-right: 1px solid ${({ theme }) => theme.color.accent3};

        &:last-child {
            border: none;
        }
    }
`;

export const Td = ({ children, colspan, align, minWidth, className, spacing }: { children?: React.ReactNode; align? : 'l'|'r'|'c'; colspan: number, minWidth? : string | number, className? : string, spacing? : number }) => {
    const TC = React.useContext(TableContext);
    const colPercent = (100 / TC.cols) * colspan;
    return <TdStyle colPercent={colPercent} align={align} className={className} minWidth={minWidth} spacing={spacing}>{children}</TdStyle>;
};

const TdInputStyle = styled.div<{ colPercent: number, error: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: ${(props) => props.colPercent}%;
    min-width: 0;
    #{Tr} & {
        border-right: 1px solid ${({ theme }) => theme.color.accent3};

        &:last-child {
            border: none;
        }
    }

    input {
        border: none;
        padding: ${({ theme }) => theme.spacing(1.5)};
        flex-grow: 1;
        min-width: 0;
        ${p => p.error && `
            color: ${p.theme.color.danger}
        `}
    }
    input:focus {
        box-shadow: none;
    }

    input + i {
        border: 1px solid transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
        transition: all 0.2s;
        border-color: ${(props) => props.error ? props.theme.color.danger : 'transparent'};
    }

    input:focus + i {
        border: 1px solid ${({ theme }) => theme.color.highlight};
    }
`;

const TdInputPrefix = styled.div`
    padding: ${({ theme }) => theme.spacing(1.5)};
    padding-right: 0;
    color: ${({theme}) => theme.color.accent4};
    ${({theme}) => theme.type.s};
    ${({theme}) => theme.type.bold};
`;

const TdInputSuffix = styled.div`
    padding: ${({ theme }) => theme.spacing(1.5)};
    padding-left: 0;
    color: ${({theme}) => theme.color.accent4};
    ${({theme}) => theme.type.s};
    ${({theme}) => theme.type.bold};
`;

const Error = styled.div`
    position: absolute;
    top: 75%;
    background-color: ${p => p.theme.color.danger};
    border-radius: 4px 4px;
    padding: 2px 4px;
    ${p => p.theme.type.s};
    ${p => p.theme.type.bold};
    color: ${p => p.theme.color.base_contrast};
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 1;
`;


interface TdInputProps<P> extends React.AllHTMLAttributes<HTMLInputElement> {
    colspan: number;
    prefix?: string;
    suffix?: string;
    error?: string;
    component?: React.ComponentType<P> | React.ElementType;
}

// Redecalare forwardRef
declare module "react" {
    function forwardRef<T, P = {}>(
      render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
  }

export const TdInputInner = <P extends {}>({ colspan, prefix, suffix, error = '', component, ...rest } : TdInputProps<P> & P, ref: React.ForwardedRef<React.ComponentType<P> | React.ElementType>) => {
    const TC = React.useContext(TableContext);
    const colPercent = (100 / TC.cols) * colspan;
    const Component = component || 'input';
    return (
        <TdInputStyle colPercent={colPercent} error={error ? true : false}>
            {prefix && <TdInputPrefix>{prefix}</TdInputPrefix>}
            {<Component {...rest} ref={ref} />}
            <i></i>
            {suffix && <TdInputSuffix>{suffix}</TdInputSuffix>}
            {error && error.length > 0 && <Error>{error}</Error>} 
        </TdInputStyle>
    );
};

export const TdInput = React.forwardRef(TdInputInner);


export const Tfoot = styled.footer``;