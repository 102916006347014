import { SupplierProduct } from "../../types";
import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";

export const code = test((code: SupplierProduct["code"], existingCodes: string[]) => {
    verify(() => value(code).isString().isTruthy().isNotWhitespace().isNotOneOf(existingCodes));
});


export const description = test((description: SupplierProduct["description"]) => {
    verify(() => value(description).isString());
});


export const d = test((d: SupplierProduct["d"]) => {
    verify(() => value(d).isNumber());
    passIf(() => d === null);
});


export const h = test((h: SupplierProduct["h"]) => {
    verify(() => value(h).isNumber());
    passIf(() => h === null);
});

export const w = test((w: SupplierProduct["w"]) => {
    verify(() => value(w).isNumber());
    passIf(() => w === null);
});


export const price = test((price: SupplierProduct["price"]) => {
    verify(() => value(price).isNumber());
});

export const id = test((id: SupplierProduct["id"]) => {
    verify(() => value(id).isUuid());
});

export const supplierId = test((supplierId: SupplierProduct["supplierId"]) => {
    verify(() => value(supplierId).isUuid());
});


export const dateCreated = test((dateCreated: SupplierProduct["dateCreated"]) => {
    verify(() => value(dateCreated).isNumber());
});


export const dateUpdated = test((dateUpdated: SupplierProduct["dateUpdated"]) => {
    verify(() => value(dateUpdated).isNumber());
    passIf(() => dateUpdated === null);
});


export const supplierRangeId = test((supplierRangeId: SupplierProduct["supplierRangeId"]) => {
    verify(() => value(supplierRangeId).isUuid());
    passIf(() => supplierRangeId === null);
});

export const baseProductId = test((baseProductId: SupplierProduct["baseProductId"]) => {
    verify(() => value(baseProductId).isUuid());
    passIf(() => baseProductId === null);
});

export const supplierProduct = test((supplierProduct: SupplierProduct, existingCodes: string[]) => {
    return {
        code: code(supplierProduct.code, existingCodes),
        description: description(supplierProduct.description),
        h: h(supplierProduct.h),
        w: w(supplierProduct.w),
        d: d(supplierProduct.d),
        price: price(supplierProduct.price),
        supplierId: supplierId(supplierProduct.supplierId),
        id: id(supplierProduct.id),
        dateCreated: {},
        dateUpdated: {},
        supplierRangeId: supplierRangeId(supplierProduct.supplierRangeId),
        baseProductId: baseProductId(supplierProduct.baseProductId)
    }

})