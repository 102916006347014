import { Category } from "../../types";

export const name = (category: Category, name: Category["name"]) => {
    if (typeof name !== "string") throw new Error("Category name must be a string");

    return {
        ...category,
        name,
    };
};

export const parentId = (category: Category, parentId: Category["parentId"]) => {
    if (typeof parentId !== "string") throw new Error("parentId must be a string");

    return {
        ...category,
        parentId,
    };
};

export const customerRestricted = (category: Category, customerRestricted: Category["customerRestricted"]) => {
    if (typeof customerRestricted !== "boolean") throw new Error("customerRestricted must be a string");

    return {
        ...category,
        customerRestricted,
    };
};


export const customerWhitelist = (category: Category, customers: string[]) => {
    if (!Array.isArray(customers)) throw new Error("Customers must be an array");
    
    return {
        ...category,
        customerWhitelistIds: [...customers
        ]
    };
};

export const addToCustomerWhitelist = (category: Category, id: string) => {
    if (typeof id !== "string") throw new Error("Id name must be a string");
    
    //if the id is not in the whitelist, add it
    if (!category.customerWhitelistIds.includes(id)) {
        return {
            ...category,
            customerWhitelistIds: [
                ...category.customerWhitelistIds,
                id
            ]
        };
    }
    return category;
};


export const removeFromCustomerWhitelist = (category: Category, id: string) => {
    if (typeof id !== "string") throw new Error("Id name must be a string");
    
    //if the id is in the whitelist, remove it
    if (category.customerWhitelistIds.includes(id)) {
        return {
            ...category,
            customerWhitelistIds: category.customerWhitelistIds.filter(item => item !== id)
        };
    }
    return category;
}