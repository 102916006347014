import {Category, Product} from '../../types';
/**
 * 
 * Filters only categories that either contain products of other categories
 * 
 * @param  {Category[]} categories
 * @param  {Product[]} products
 * @returns Category
 */
export function filterPopulatedCategories(categories : Category[], products: Product[]) : Category[] {
    let _categories = categories.filter(cat => {
        if(categories.some(_cat =>  _cat.parentId == cat.id)) {
            return cat;
        } else if (products.some(product => product?.categories?.includes(cat.id))) {
            return cat;
        }
    })

    return _categories;
}
