import {v4 as uuid} from 'uuid';
import { Address } from "../../types"
import { createFields } from './createFields';

export const create = (createdBy: string, parentId: string) : Address => {

    return {

        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        id: uuid(),
        name: "",
        note: "",
        parentId: parentId,
        deleted_at: null,
        ...createFields()

    }
    
}

