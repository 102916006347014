import {Customer} from '../../types';
import {merge as mergeOrderItems} from '../order_items/merge';
import type {PartialDeep} from 'type-fest';

/** 
 * Merges an order with another order/partial order.
 * Merged values are cloned where neccessary but the resulting order is NOT a deep clone.
 * Use lodash cloneDeep if mutation is required on the resulting order.
 * @param order 
 * @param changes 
 */


export function merge(customer : Customer, changes : Partial<Customer>) : Customer {
    const mergedCustomer = {...customer};

    if(changes && Object.keys(changes).length !== 0 && changes.constructor === Object){
        (Object.keys(changes) as Array<keyof Customer>).forEach(<T extends keyof Customer>(key : T) => {
            if(changes[key] || changes[key] === 0 || changes[key] === '' ){
                mergedCustomer[key] = changes[key] as Customer[T];
            }
        });
    }
    return mergedCustomer;
} 