import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Line } from 'rc-progress';

const Container = styled.section`
    padding: ${({theme}) => theme.spacing(2)} ${({theme}) => theme.spacing(3)};
    background-color: ${({theme}) => theme.color.accent1};
    border-top: 1px solid ${({theme}) => theme.color.accent2};
    border-bottom: 1px solid ${({theme}) => theme.color.accent2};
    
`;

const Title = styled.h2`
    margin-bottom: ${({theme}) => theme.spacing(1)};
    ${({theme}) => theme.type.m};
    ${({theme}) => theme.type.bold};
`

interface IpadProgressProps {
    title : string; 
    percent : number
}

export const IpadProgress = ({title, percent} : IpadProgressProps) : React.ReactElement => {
    const themeContext = React.useContext(ThemeContext);
    return (
        <Container>
            <Title>{title}</Title>
            <Line percent={percent} strokeWidth={themeContext.spacing(1, true) as number / 10} strokeColor={themeContext.color.highlight} trailColor={themeContext.color.base_contrast} />
        </Container>
    );
}
