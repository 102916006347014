import { Order, EditedOrderValues } from "../../types";
import { combineEdits as combineOrderItemsEdits } from "../order_items/combineEdits";
import type { PartialDeep } from "type-fest";

/**
 * Merges an order with another order/partial order.
 * Merged values are cloned where neccessary but the resulting order is NOT a deep clone.
 * Use lodash cloneDeep if mutation is required on the resulting order.
 * @param order
 * @param changes
 */

export function combineEdits(currentChanges: EditedOrderValues, newChanges: EditedOrderValues): EditedOrderValues {
    const mergedOrder = { ...currentChanges };
    if (newChanges && Object.keys(newChanges).length !== 0 && newChanges.constructor === Object) {
        (Object.keys(newChanges) as Array<keyof Order>).forEach(<T extends keyof Order>(key: T) => {
            if (key === "items") {
                if( newChanges.items?.length) mergedOrder.items = currentChanges.items ? combineOrderItemsEdits(currentChanges.items, newChanges.items) : newChanges.items;
            } else if (key === "payments" && newChanges.payments && newChanges.payments.length > 0) {
                mergedOrder.payments = newChanges.payments
                    .concat(
                        (currentChanges.payments ?? []).filter(
                            (payment) =>
                                !newChanges.payments!.find((change) => change.id === payment.id)
                        )
                    );
            } else if (key === "carcaseColour" && newChanges.carcaseColour !== undefined) {
                mergedOrder.carcaseColour = newChanges.carcaseColour;
            } else if (newChanges[key] || newChanges[key] === 0 || newChanges[key] === "" || newChanges[key] === false) {
                mergedOrder[key] = newChanges[key] as EditedOrderValues[T];
            }
        });
    }
    return mergedOrder;
}
