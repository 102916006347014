import React, { ErrorInfo } from 'react';

interface Props {
    children : React.ReactNode;
    handleError : (error : Error, errorInfo : ErrorInfo) => void;
}
export class ErrorBoundary extends React.Component<Props> {
    constructor(props : Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error : Error, errorInfo : ErrorInfo) {    
        this.props.handleError(error, errorInfo);  
    }

    static getDerivedStateFromError() {    // Update state so the next render will show the fallback UI.    
        return { hasError: true };  
    }

    render() {
        return <>{this.props.children}</> 
    }
}