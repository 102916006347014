import React from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import { Island } from '../island';
import { Arrow } from '../arrow';
import { darken } from 'polished';
import {libTypes} from '@holta/lib';
const Header = styled.header`
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
`;

const StyledArrow = styled(Arrow)`
    transition: transform 200ms;
    .Collapsible__trigger.is-open & {
        transform: rotate(180deg);
        
    }
`;

const Title = styled.h1<{status : libTypes.ProductionStatuses[keyof libTypes.ProductionStatuses]['key'] | null}>`
    ${({theme}) => theme.type.l};
    color : ${({status, theme}) => status || status === 0 ? darken(0.05, (theme.color as {[index : string] : string})['production_' + status]) : 'inherit'};
    display: flex;
    align-items: center;
`;

const JobCount = styled.span`
    ${({theme}) => theme.type.s};
    ${({theme}) => theme.type.bold};
    color: ${({theme}) => theme.color.text};
    margin-left:${({theme}) => theme.spacing(1)};
    margin-top: 3px;
    background-color: ${({theme}) => theme.color.accent1};
    width: ${({theme}) => theme.spacing(2)};
    height: ${({theme}) => theme.spacing(2)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;
const StyledIsland = styled(Island)`
    padding : ${({theme}) => theme.spacing(1)};
    margin: ${({theme}) => theme.spacing(1)} ${({theme}) => theme.spacing(2)};
`;

export function IpadProductionGroup({productionStatus = null, productionStatusLabel, job_count, children, className} : {productionStatus? : libTypes.ProductionStatuses[keyof libTypes.ProductionStatuses]['key'] | null, productionStatusLabel : string, job_count : number, children : React.ReactNode, className?: string}) : React.ReactElement {
    
    return (
        <StyledIsland className={className}>
            {job_count > 0 && (
                <Collapsible  open trigger={
                    <Header>
                        <Title status={productionStatus}><div>{productionStatusLabel}</div><JobCount>{job_count}</JobCount></Title> <StyledArrow up/>
                    </Header>
                } transitionTime={100}>
            
                {children}
                </Collapsible>
            )}

            {job_count <= 0 && (
                <Header style={{opacity : 0.5}}>
                    <Title status={productionStatus}><div>{productionStatusLabel}</div><JobCount>{job_count}</JobCount></Title> 
                    <div style={{opacity: 0}}><StyledArrow up/></div>
                </Header>
            )}
        </StyledIsland>
    )
}