import {condenseSupplierItems} from './condenseSupplierItems';
import {filterAnySupplierItems} from './filterAnySupplierItems';
import {filterCarcaseItems} from './filterCarcaseItems';
import {filterLinkedDoorItems} from './filterLinkedDoorItems';
import {filterMissingLinkedDoorItems} from './filterMissingLinkedDoorItems';
import {filterSupplierItemsBySupplier} from './filterSupplierItemsBySupplier';
import {getAllOrderItemsTotal} from './getAllOrderItemsTotal';
import {getAllSupplierItemsTotal} from './getAllSupplierItemsTotal';
import {getCarcaseItemsTotal} from './getCarcaseItemsTotal';
import {getLinkedDoorItemsTotal} from './getLinkedDoorItemsTotal';
import {getLinkedSupplierProducts} from './getLinkedSupplierProducts';
import {filterManuallyAddedSupplierItems} from './filterManuallyAddedSupplierItems';
import {merge} from './merge';
import {removeLinkedDoorItems} from './removeLinkedDoorItems';
import {removeMissingLinkedDoorItems} from './removeMissingLinkedDoorItems';
import {getSupplierRanges} from './getSupplierRanges';
import {sortItems} from './sortItems';
import * as validate from './validate';
import * as set from './set';
import { combineEdits } from './combineEdits';
import { getManuallyAddedSupplierItemsTotal } from './getManuallyAddedSupplierItemsTotal';

const defaultExport = {
    condenseSupplierItems,
    filterAnySupplierItems,
    filterCarcaseItems,
    filterLinkedDoorItems,
    filterMissingLinkedDoorItems,
    filterSupplierItemsBySupplier,
    getAllOrderItemsTotal,
    getAllSupplierItemsTotal,
    getCarcaseItemsTotal,
    getManuallyAddedSupplierItemsTotal,
    getLinkedDoorItemsTotal,
    filterManuallyAddedSupplierItems,
    getLinkedSupplierProducts,
    merge,
    removeLinkedDoorItems,
    removeMissingLinkedDoorItems,
    sortItems,
    getSupplierRanges,
    validate,
    combineEdits,
    set
}

export default defaultExport;