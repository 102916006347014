import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import {
    BoardCut,
    DraftCutAndEdgeOrder,
    DraftCutAndEdgeOrderDetails,
    CutAndEdgeOrderGroup,
} from "../../types";
import { constants } from "../../constants";
import { isValidPostcode } from "../shared/isValidPostcode";
import { sheetMaterials } from "../../static_data/sheetMaterials";
import { isMFCGroup } from "../board/isMFCGroup";
import { isSheetMaterialGroup } from "../board/isSheetMaterialGroup";
import { cutAndEdgeOrderGroupTypes } from "../../static_data/cutAndEdgeOrderGroupTypes";
import { edgingTypes } from "../../static_data/edgingTypes";

/* ------------------------------ Order Details ----------------------------- */

// Email
export const email = test((email: DraftCutAndEdgeOrderDetails["email"]) => {
    verify(() => value(email).isEmail().isString());
});

// Customer Ref
export const customerRef = test((customerRef: DraftCutAndEdgeOrderDetails["customerRef"]) => {
    verify(() => value(customerRef).isString().isNotEmpty("A reference is required"));
});

// Company Name
export const companyName = test((companyName: DraftCutAndEdgeOrderDetails["companyName"]) => {
    verify(() => value(companyName).isString().isNotEmpty("Company Name is required"));
});

// Delivery Method
export const deliveryMethod = test(
    (deliveryMethod: DraftCutAndEdgeOrderDetails["deliveryMethod"]) => {
        verify(() => value(deliveryMethod).isOneOf(["collection", "delivery"]));
    }
);

// Delivery Postcode
export const deliveryPostcode = test(
    (
        deliveryPostcode: DraftCutAndEdgeOrderDetails["deliveryPostcode"],
        deliveryMethod: DraftCutAndEdgeOrderDetails["deliveryMethod"]
    ) => {
        verify(() => value(deliveryPostcode).isString());
        verify(() => isValidPostcode(deliveryPostcode || ""), "Must be a valid UK postcode");
        passIf(() => deliveryMethod !== "delivery");
    }
);

//Delivery Address Line 1
export const deliveryAddressLine1 = test(
    (
        deliveryAddressLine1: DraftCutAndEdgeOrderDetails["deliveryAddressLine1"],
        deliveryMethod: DraftCutAndEdgeOrderDetails["deliveryMethod"]
    ) => {
        verify(() =>
            value(deliveryAddressLine1).isNotEmpty("Delivery address line 1 is required").isString()
        );
        passIf(() => deliveryMethod !== "delivery");
    }
);

// Delivery Address County
export const deliveryAddressCounty = test(
    (
        deliveryAddressCounty: DraftCutAndEdgeOrderDetails["deliveryAddressCounty"],
        deliveryMethod: DraftCutAndEdgeOrderDetails["deliveryMethod"]
    ) => {
        verify(() =>
            value(deliveryAddressCounty).isNotEmpty("Delivery address county is required").isString()
        );
        passIf(() => deliveryMethod !== "delivery");
    }
);

// Delivery Date
export const deliveryDate = test((deliveryDate: DraftCutAndEdgeOrderDetails["deliveryDate"]) => {
    verify(() => value(deliveryDate).isNotEmpty("Date is required").isNumber());
});

// Order Details
export const orderDetails = test((orderDetails: DraftCutAndEdgeOrderDetails) => {
    return {
        email: email(orderDetails.email),
        customerRef: customerRef(orderDetails.customerRef),
        companyName: companyName(orderDetails.companyName),
        deliveryMethod: deliveryMethod(orderDetails.deliveryMethod),
        deliveryPostcode: deliveryPostcode(
            orderDetails.deliveryPostcode,
            orderDetails.deliveryMethod
        ),
        deliveryAddressLine1: deliveryAddressLine1(
            orderDetails.deliveryAddressLine1,
            orderDetails.deliveryMethod
        ),
        deliveryAddressCounty: deliveryAddressCounty(
            orderDetails.deliveryAddressCounty,
            orderDetails.deliveryMethod
        ),
        deliveryDate: deliveryDate(orderDetails.deliveryDate),
    };
});

// Order Details
export const orderDeliveryDetails = test((orderDetails: DraftCutAndEdgeOrderDetails) => {
    deliveryMethod(orderDetails.deliveryMethod);
    deliveryPostcode(orderDetails.deliveryPostcode, orderDetails.deliveryMethod);
    deliveryAddressLine1(orderDetails.deliveryAddressLine1, orderDetails.deliveryMethod);
    deliveryAddressCounty(orderDetails.deliveryAddressCounty, orderDetails.deliveryMethod);
    deliveryDate(orderDetails.deliveryDate);
});
export const orderCompanyDetails = test((orderDetails: DraftCutAndEdgeOrderDetails) => {
    email(orderDetails.email);
    customerRef(orderDetails.customerRef);
    companyName(orderDetails.companyName);
});

/* ------------------------------ Order Items ----------------------------- */

// Board Cut Height
export const boardCutHeight = test(
    (
        height: BoardCut["height"],
        type: CutAndEdgeOrderGroup["type"],
        width?: BoardCut["width"],
        qty?: BoardCut["qty"],
        edging?: BoardCut["edging"]
    ) => {
        verify(() =>
            value(height)
                .when(height !== "")
                .isNumerical()
        );

        if (type === cutAndEdgeOrderGroupTypes.SHEET_MATERIAL.key) {
            verify(() =>
                value(height)
                    .isMin(1, "Value is required")
                    .isMax(
                        constants.SHEET_MATERIAL_MAX_HEIGHT,
                        `Max width: ${constants.SHEET_MATERIAL_MAX_HEIGHT}mm`
                    )
            );
        } else {
            verify(() =>
                value(height)
                    .isMin(1, "Value is required")
                    .isMax(constants.MFC_MAX_HEIGHT, `Max width: ${constants.MFC_MAX_HEIGHT}mm`)
            );
        }
        passIf(() => {
            return !height && !width && !qty && (!edging || !edging.includes(true));
        });
    }
);

// Board Cut Width
export const boardCutWidth = test(
    (
        width: BoardCut["width"],
        type: CutAndEdgeOrderGroup["type"],
        height?: BoardCut["height"],
        qty?: BoardCut["qty"],
        edging?: BoardCut["edging"]
    ) => {
        verify(() =>
            value(width)
                .when(width !== "")
                .isNumerical()
        );

        if (type === cutAndEdgeOrderGroupTypes.SHEET_MATERIAL.key) {
            verify(() =>
                value(width)
                    .isMin(1, "Value is required")
                    .isMax(
                        constants.SHEET_MATERIAL_MAX_WIDTH,
                        `Max width: ${constants.SHEET_MATERIAL_MAX_WIDTH}mm`
                    )
            );
        } else {
            verify(() =>
                value(width)
                    .isMin(1, "Value is required")
                    .isMax(constants.MFC_MAX_WIDTH, `Max width: ${constants.MFC_MAX_WIDTH}mm`)
            );
        }
        passIf(() => {
            return !width && !height && !qty && (!edging || !edging.includes(true));
        });
    }
);

// Board Cut Quantity
export const boardCutQuantity = test(
    (
        qty: BoardCut["qty"],
        width?: BoardCut["width"],
        height?: BoardCut["height"],
        edging?: BoardCut["edging"]
    ) => {
        verify(() =>
            value(qty)
                .when(qty !== "")
                .isNumerical()
        );

        verify(() =>
            value(qty).isMin(
                constants.MINIMUM_CUT_ITEM_QTY,
                `Qty must be above ${constants.MINIMUM_CUT_ITEM_QTY}`
            )
        );
        passIf(() => {
            return !qty && !width && !height && (!edging || !edging.includes(true));
        });
    }
);

// Edging
export const edging = test((edging: BoardCut["edging"]) => {
    verify(() => value(edging).isArray().isLength(4));
    return arrayOf(
        edging,
        test((edge: boolean) => {
            verify(() => value(edge).isBoolean());
        })
    );
});

// Board Cut
export const boardCut = test((boardCut: BoardCut, type: CutAndEdgeOrderGroup["type"]) => {
    return {
        id: boardCut.id,
        height: boardCutHeight(
            boardCut.height,
            type,
            boardCut.width,
            boardCut.qty,
            boardCut.edging
        ),
        width: boardCutWidth(boardCut.width, type, boardCut.height, boardCut.qty, boardCut.edging),
        qty: boardCutQuantity(boardCut.qty, boardCut.width, boardCut.height, boardCut.edging),
        edging: edging(boardCut.edging),
    };
});

// MFC Colour
export const mfcColour = test((cutAndEdgeOrderGroup: CutAndEdgeOrderGroup) => {
    if (isMFCGroup(cutAndEdgeOrderGroup)) {
        return {
            code: testInPlace(() =>
                verify(() => value(cutAndEdgeOrderGroup.mfcColour.code).isString().isNotEmpty())
            ),
            texture: testInPlace(() =>
                verify(() => value(cutAndEdgeOrderGroup.mfcColour.texture).isString().isNotEmpty())
            ),
        };
    }
});

// Edging Colour
export const edgingMfcColour = test((cutAndEdgeOrderGroup: CutAndEdgeOrderGroup) => {
    if (
        isMFCGroup(cutAndEdgeOrderGroup) &&
        (cutAndEdgeOrderGroup.edgingType === edgingTypes._08.key ||
            cutAndEdgeOrderGroup.edgingType === edgingTypes._20.key)
    ) {
        return {
            code: testInPlace(() =>
                verify(() => value(cutAndEdgeOrderGroup.mfcColour.code).isString().isNotEmpty())
            ),
            texture: testInPlace(() =>
                verify(() => value(cutAndEdgeOrderGroup.mfcColour.texture).isString().isNotEmpty())
            ),
        };
    }
});

// Edging Type
export const edgingType = test((cutAndEdgeOrderGroup: CutAndEdgeOrderGroup) => {
    if (isMFCGroup(cutAndEdgeOrderGroup)) {
        verify(() =>
            value(cutAndEdgeOrderGroup.edgingType).isOneOf([
                edgingTypes.NONE.key,
                edgingTypes._08.key,
                edgingTypes._20.key,
            ])
        );
    } else {
        verify(() =>
            value(cutAndEdgeOrderGroup.edgingType).isOneOf([
                edgingTypes.NONE.key,
                edgingTypes.MATCHING.key,
            ])
        );
    }
});

// Sheet Material
export const sheetMaterial = test((cutAndEdgeOrderGroup: CutAndEdgeOrderGroup) => {
    if (isSheetMaterialGroup(cutAndEdgeOrderGroup)) {
        verify(() => value(cutAndEdgeOrderGroup.sheetMaterial).isOneOf(sheetMaterials));
    }
});

// Cut and Edge Order Group
export const cutAndEdgeOrderGroup = test((cutAndEdgeOrderGroup: CutAndEdgeOrderGroup) => {
    return {
        id: cutAndEdgeOrderGroup.id,
        cuts: arrayOf(cutAndEdgeOrderGroup.cuts, (cut) => {
            return boardCut(cut, cutAndEdgeOrderGroup.type);
        }),
        type: testInPlace(() =>
            verify(() =>
                value(cutAndEdgeOrderGroup.type)
                    .isOneOf(Object.keys(cutAndEdgeOrderGroupTypes))
            )
        ),
        mfcColour: mfcColour(cutAndEdgeOrderGroup),
        edgingType: edgingType(cutAndEdgeOrderGroup),
        sheetMaterial: sheetMaterial(cutAndEdgeOrderGroup),
    };
});

// Cut and Edge Order Groups

export const cutAndEdgeOrderItems = test((cutAndEdgeOrderGroups: CutAndEdgeOrderGroup[]) => {
    return {
        items: arrayOf(cutAndEdgeOrderGroups, cutAndEdgeOrderGroup),
    };
});

export const cutAndEdgeOrder = test((cutAndEdgeOrder: Omit<DraftCutAndEdgeOrder, "status">) => {
    return {
        orderDetails: orderDetails(cutAndEdgeOrder.details),
        items: cutAndEdgeOrderItems(cutAndEdgeOrder.items),
    };
});
