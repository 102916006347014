import { Order, MFCColour } from "../../types";
import { isCarcaseColour } from "../order/isCarcaseColour";

export function filterByCarcaseColour(
    orders: Order[],
    carcaseColour?: MFCColour
): Order[] {
    if (carcaseColour) return orders.filter((order) => isCarcaseColour(order, carcaseColour));
    else
        return orders.filter(
            (order) =>
                !order.carcaseColour ||
                (order.carcaseColour.code === null)
        );
}
