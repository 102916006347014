import {constants} from '../../constants';
import Decimal from 'decimal.js';
import { CutAndEdgeOrderGroup } from '../../types';
import { getCutAndEdgeOrderItemSubtotal } from './getCutAndEdgeOrderItemSubtotal';

export const getCutAndEdgeDeliveryPrice = (distance : number, orderItems: CutAndEdgeOrderGroup[]) : number => {


    const orderTotal = getCutAndEdgeOrderItemSubtotal(orderItems)

    if(distance >= constants.CUT_EDGE_ORDER_DELIVERY_MAX_DISTANCE) throw new Error("Delivery distance too far");

    if(distance <= constants.CUT_EDGE_ORDER_FREE_DELIVERY_DISTANCE_THRESHOLD && orderTotal >= constants.CUT_EDGE_ORDER_FREE_DELIVERY_VALUE_THRESHOLD) return 0;

    const deliveryPrice = new Decimal(distance).mul(constants.CUT_EDGE_ORDER_DELIVERY_DISTANCE_UNIT_MULTIPLE).toNearest(0.01).toNumber();

    if(deliveryPrice <= constants.CUT_EDGE_ORDER_DELIVERY_MIN_VALUE) return constants.CUT_EDGE_ORDER_DELIVERY_MIN_VALUE;

    return deliveryPrice;

}