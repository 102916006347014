import React from "react";
import 'remixicon/fonts/remixicon.css'
import styled from 'styled-components';
import {Colors} from '../../theme';
import { useTheme } from 'styled-components'

interface IconProps extends React.HTMLAttributes<HTMLElement>{
    name : string
    size?: 'lg'|'xl'|'xxs'|'xs'|'sm'|'fw'|'1x'|'2x'|'3x'|'4x'|'5x'|'6x'|'7x'|'8x'|'9x'|'0x'
    component?: React.ElementType
    role?: string
    ariaHidden? : boolean
    userClass?: string
    children? : React.ReactChildren
    type?: 'line'|'fill'
    style? : React.CSSProperties,
    onClick? : () => void,
    color? : keyof Colors;
}




const IconInner : React.ForwardRefRenderFunction<HTMLElement, IconProps> = (props, ref) => {
    const {
        name,
        size,
        component,
        role,
        ariaHidden,
        userClass,
        children,
        type = 'line',
        style,
        color,
        ...remainder
    } = props;

    const theme = useTheme();

    const CustomTag = component || "i";

    const _style = {
        ...style,
        color: color ? theme.color[color] : 'inherit'
    }


    return (
        <CustomTag
            aria-hidden={ariaHidden || "true"}
            role={role || "presentation"}
            className={[
                `ri-${name}${type !== undefined ? `-${type}` : ""} ${size ? `ri-${size}` : ""}`,
                userClass,
            ]
                .filter((e) => e)
                .join(" ")}
            {...remainder}
            ref={ref}
            style={_style}
        >
            {children}
        </CustomTag>
    );
};

export const Icon = React.memo(React.forwardRef(IconInner));
