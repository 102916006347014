import { FormSelect } from "@holta/ui";

const options = [
    {
        label: "Yes",
        value: true,
    },
    {
        label: "No",
        value: false,
    },
];

export const InputGluedWallUnits = ({
    value,
    onChange,
}: {
    value: boolean;
    onChange: (value: boolean) => void;
}) => {
    const selected =
        value === undefined || value === null
            ? null
            : options.find((option) => option.value === value);

    return (
        <FormSelect
            options={options}
            style={{ width: "100%" }}
            isSearchable={false}
            onChange={(val: { value: boolean }) => {
                onChange(val.value);
            }}
            value={selected}
        />
    );
};
