import { OrderItem, SupplierOrderItem } from '../../types';
import { isManuallyAddedSupplierItem } from '../order_item/isManuallyAddedSupplierItem';

export function filterManuallyAddedSupplierItems(items : OrderItem[]) : SupplierOrderItem[] {
    
    return items.filter(isManuallyAddedSupplierItem)

}


    
