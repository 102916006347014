import {SupplierImportProduct} from '../../types/supplierImportProduct';
import {SupplierRange} from '../../types/supplierRange';

export function getBaseProductCodeUsingRangePrefix(product: SupplierImportProduct, prefix: string): string | undefined {

    if(!product.code.toLowerCase().startsWith(prefix.toLowerCase())) {
        throw new Error("Product code does not start with range prefix");
    }

    const code = product.code;
    const baseCode = code.substring(prefix.length, code.length);
    
    if (baseCode.length === 0) {
       throw new Error("Base product code is empty");
    }
        
    return baseCode;
}