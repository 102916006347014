import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { ProductComponent } from "../../types";


export const id = test((id: ProductComponent["id"]) => {
    verify(() => value(id).isUuid());
});

export const code = test((code: ProductComponent["code"], existingCodes: string[]) => {
    verify(() => value(code.toLowerCase()).isNotEmpty("Code is required").isNotWhitespace("Code is required").isNotOneOf(existingCodes.map(ec => ec.toLowerCase()), "Code is already in use"));
});

export const h = test((h: ProductComponent["h"]) => {
    verify(() => value(h).isNumber());
    passIf(() => h === null);
});

export const w = test((w: ProductComponent["w"]) => {
    verify(() => value(w).isNumber());
    passIf(() => w === null);
});

export const d = test((d: ProductComponent["d"]) => {
    verify(() => value(d).isNumber());
    passIf(() => d === null);
});

export const productComponent = test((productComponent: ProductComponent, existingComponents: ProductComponent[]) => {
    return {
        id : id(productComponent.id),
        code : code(productComponent.code, existingComponents.filter(ec => ec.id !== productComponent.id).map(c => c.code)),
        h : h(productComponent.h),
        w : w(productComponent.w),
        d : d(productComponent.d)
    } 
})

