import styled from "styled-components";
import { screenSize } from "../../constants/screenSize";
import { containerWidths } from "../../constants/containerWidths";

type RowValues = {
    align?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    direction?: "row" | "row-reverse" | "column" | "column-reverse";
    gap?: string;
    justify?: "start" | "center" | "end" | "space-around" | "space-between";
    wrap?: "nowrap" | "wrap" | "wrap-reverse";
};

type RowBreakPoint = {
    [key in keyof typeof screenSize]?: RowValues;
};

export const Row = styled.div<RowBreakPoint>`
    display: flex;
    width: 100%;

    ${({ theme, xs }) => {
        if (xs) {
            return `
            align-items: ${xs.align || "initial"};
            flex-direction: ${xs.direction || "initial"};
            gap: ${xs.gap || "initial"};
            justify-content: ${xs.justify || "initial"};
            flex-wrap: ${xs.wrap || "initial"};
            
                `;
        } else {
            return "";
        }
    }};

    ${(props) => {
        let css = "";
        for (const [key, value] of Object.entries(screenSize)) {
            const _key = key as keyof RowBreakPoint;
            const val: RowValues | undefined = props[_key];
            if (val !== undefined) {
                css += `
                    @media(min-width: ${value}px) {
                        ${val.align && "align-items:" + val.align + ";"}
                        ${val.direction && "flex-direction:" + val.direction + ";"}
                        ${val.gap && "gap:" + val.gap + ";"}
                        ${val.justify && "justify-content:" + val.justify + ";"}
                        ${val.wrap && "flex-wrap:" + val.wrap + ";"}
                        
                    }
                `;
            }
        }
        return css;
    }}
`;

type ColValues = {
    cols?: number;
    offset?: number;
    order?: number;
    width?: string;
};

type ColBreakPoint = {
    [key in keyof typeof screenSize]?: ColValues;
};

export const Col = styled.div<ColBreakPoint>`
    ${({ theme, xs }) => {
        if (xs) {
            return `
            flex-basis: ${xs.cols ? 100 / 12 * xs.cols + "%" : "initial"};
            margin-left: ${xs.offset ? xs.offset + "%" : "initial"};
            order: ${xs.order ? xs.order : "initial"};
            width: ${xs.width ? xs.width : "initial"};
            
                `;
        } else {
            return "";
        }
    }};

    ${(props) => {
        let css = "";
        for (const [key, value] of Object.entries(screenSize)) {
            const _key = key as keyof ColBreakPoint;
            const val: ColValues | undefined = props[_key];
            if (val !== undefined) {
                css += `
                    @media(min-width: ${value}px) {
                        ${val.cols && "flex-basis:" + 100 / 12 * val.cols + "%;"}
                        ${val.offset && "margin-left:" + 100 / 12 * val.offset + "%;"}
                        ${val.order && "order:" + val.order + ";"}
                        ${val.width && "width:" + val.width + ";"}
                        
                    }
                `;
            }
        }
        return css;
    }}
`;

export const Grid = {
    Row,
    Col
};
