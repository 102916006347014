import { OrderItem } from "../../types";
import { filterManuallyAddedSupplierItems } from "./filterManuallyAddedSupplierItems";
import Decimal from "decimal.js";
import {getOrderItemTotal} from "../order_item/getOrderItemTotal";

export function getManuallyAddedSupplierItemsTotal(orderItems : OrderItem[]) : string {

    const filtererd = filterManuallyAddedSupplierItems(orderItems);
    let total = new Decimal(0);

    filtererd.forEach(item => {
        total = total.add(getOrderItemTotal(item));
    });

    return total.toFixed(2);

}