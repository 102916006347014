import { Product } from "../../types";
import * as validate from "./validate";

export const categories = (product: Product, categories: Product["categories"])  : Product => {
    if (!Array.isArray(categories)) throw new Error("Categories code must be an array");
    return {
        ...product,
        categories
    };
};


export const code = (product: Product, code: Product["code"])  : Product => {
    if (typeof code !== "string") throw new Error("Product code must be a string");
    return {
        ...product,
        code
    };
};

export const components = (product: Product, components: Product["components"])  : Product => {
    if (!Array.isArray(components)) throw new Error("Components must be an array");
    return {
        ...product,
        components
    };
};

export const addComponent = (product: Product, component: Product["components"][number])  : Product => {
    if (!validate.component(component, product.components)._isValid) throw new Error("Product components must valid");
    if (product.components.find(c => c.id === component.id))throw new Error("Product component already set");
    return {
        ...product,
        components : [
            ...product.components,
            component
        ]
    };
};

// remove component from product
export const removeComponent = (product: Product, index: number)  : Product => {
    return {
        ...product,
        components : product.components.filter((c, i) => i !== index)
    };
};

// update component in product
export const updateComponent = (product: Product, index: number, component: Product["components"][number])  : Product => {
    if (!validate.component(component, product.components)._isValid) throw new Error("Product components must be valid");
    return {
        ...product,
        components : product.components.map((c, i) => i === index ? component : c)
    }
}

// customer restricted
export const customerRestricted = (product: Product, customerRestricted: Product["customerRestricted"])  : Product => {
    if (typeof customerRestricted !== "boolean") throw new Error("Customer restricted must be a boolean");
    return {
        ...product,
        customerRestricted
    };
};

// customer whitelistIds

export const customerWhitelist = (product: Product, customerWhitelistIds: Product["customerWhitelistIds"])  : Product => {
    if (!Array.isArray(customerWhitelistIds)) throw new Error("Customer whitelistIds must be an array");
    return {
        ...product,
        customerWhitelistIds
    };
}

export const addToCustomerWhitelist = (product: Product, id: string)  : Product => {
    if (typeof id !== "string") throw new Error("Id name must be a string");
    //if the id is not in the whitelist, add it
    if (!product.customerWhitelistIds.includes(id)) {
        return {
            ...product,
            customerWhitelistIds: [
                ...product.customerWhitelistIds,
                id
            ]
        };
    }
    return product;
}

export const removeFromCustomerWhitelist = (product: Product, id: string)  : Product => {
    if (typeof id !== "string") throw new Error("Id name must be a string");
    //if the id is in the whitelist, remove it
    if (product.customerWhitelistIds.includes(id)) {
        return {
            ...product,
            customerWhitelistIds: product.customerWhitelistIds.filter(item => item !== id)
        };
    }
    return product;
}

// d
export const d = (product: Product, d: Product["d"])  : Product => {
    if (typeof d !== "number" && d !== null) throw new Error("Depth must be a number or null");
    return {
        ...product,
        d
    };
}

export const description = (product: Product, description: Product["description"])  : Product => {
    if (typeof description !== "string") throw new Error("Description must be a string");
    return {
        ...product,
        description
    };
}

// drawers
export const drawers = (product: Product, drawers: Product["drawers"])  : Product => {
    if (typeof drawers !== "number") throw new Error("Drawers must be a number");
    return {
        ...product,
        drawers
    };
}

// height
export const h = (product: Product, h: Product["h"])  : Product => {
    if (typeof h !== "number" && h !== null) throw new Error("Height must be a number or null");
    return {
        ...product,
        h
    };
};

// image
export const image = (product: Product, image: Product["image"])  : Product => {
    if (typeof image !== "string") throw new Error("Image must be a string");
    return {
        ...product,
        image
    };
}

// linkProducts
export const linkProducts = (product: Product, linkProducts: Product["linkProducts"])  : Product => {
    if (!validate.linkProducts(linkProducts)._isValid) throw new Error("Link products must be valid");
    return {
        ...product,
        linkProducts
    };
}

// price
export const price = (product: Product, price: Product["price"])  : Product => {
    if (typeof price !== "number") throw new Error("Price must be a number");
    return {
        ...product,
        price
    };
}

// priceType
export const priceType = (product: Product, priceType: Product["priceType"])  : Product => {
    if (!validate.priceType(priceType)._isValid) throw new Error("Price type not valid");
    return {
        ...product,
        priceType
    };
}

// price Expression
export const priceExpression = (product: Product, priceExpression: Product["priceExpression"])  : Product => {
    if (typeof priceExpression !== "string") throw new Error("Price expression must be a string");
    return {
        ...product,
        priceExpression
    };
}

// sold by m2
export const soldByM2 = (product: Product, soldByM2: Product["soldByM2"])  : Product => {
    if (typeof soldByM2 !== "boolean") throw new Error("Sold by m2 must be a boolean");
    return {
        ...product,
        soldByM2
    };
}

// specification
export const specification = (product: Product, specification: Product["specification"])  : Product => {
    if (typeof specification !== "string") throw new Error("Specification must be a string");
    return {
        ...product,
        specification
    };
}

// w
export const w = (product: Product, w: Product["w"])  : Product => {
    if (typeof w !== "number" && w !== null) throw new Error("Width must be a number or null");
    return {
        ...product,
        w
    };
}

// addLinkProductGroup
export const addActiveLinkProductGroup = (product: Product, constructionType: Product["linkProducts"][number]['constructionType']) : Product => {
    if (product.activeLinkProductGroups.includes(constructionType)) throw new Error("Link product group already set");

    return {
        ...product,
        activeLinkProductGroups: [
            ...product.activeLinkProductGroups,
            constructionType
        ]
    };
}

export const removeActiveLinkProductGroup = (product: Product, constructionType: Product["linkProducts"][number]['constructionType'])  : Product => {
    if (!product.activeLinkProductGroups.includes(constructionType)) throw new Error("Link product group not set");
    return {
        ...product,
        activeLinkProductGroups: product.activeLinkProductGroups.filter(group => group !== constructionType)
    };
}

export const toggleActiveLinkProductGroup = (product: Product, constructionType: Product["linkProducts"][number]['constructionType'])  : Product => {
    if (!product.activeLinkProductGroups.includes(constructionType)) return addActiveLinkProductGroup(product, constructionType);
    return removeActiveLinkProductGroup(product, constructionType);
}

export const addLinkProduct = (product: Product, constructionType: Product["linkProducts"][number]['constructionType'], linkProductId: string, qty: number) : Product => {
    if (product.linkProducts.find(lp => lp.constructionType === constructionType && lp.id === linkProductId)) throw new Error("Link product already set");
    
    return {
        ...product,
        linkProducts: [
            ...product.linkProducts,
            {
                constructionType,
                id: linkProductId,
                qty
            }
        ]
    };
}


export const removeLinkProduct = (product: Product, constructionType: Product["linkProducts"][number]['constructionType'], linkProductId: string) : Product  => {
    if (!product.linkProducts.find(lp => lp.constructionType === constructionType && lp.id === linkProductId)) throw new Error("Link product not found");
   
    return {
        ...product,
        linkProducts: product.linkProducts.filter(lp => lp.id !== linkProductId || lp.constructionType !== constructionType)
    };
}


export const updateLinkProductQty = (product: Product, constructionType: Product["linkProducts"][number]['constructionType'], linkProductId: string, qty: number) : Product  => {
    if (!product.linkProducts.find(lp => lp.constructionType === constructionType && lp.id === linkProductId)) throw new Error("Link product not found");
    return {
        ...product,
        linkProducts: product.linkProducts.map(lp  => {
            if (lp.constructionType === constructionType && lp.id === linkProductId) {
                return {
                    ...lp,
                    qty
                }
            }
            return lp;
        })
    };
}