import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RiHammerFill, RiTruckFill, RiScissorsFill  } from "react-icons/ri";
import { WeekSwitcher } from '../week_switcher';


const Container = styled.div`
    padding: ${({theme}) => theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    background-color: ${({theme}) => theme.color.base_contrast};;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
`;

const Icon = styled.div`
    border-radius: 1000px;
    border: 1px solid ${({theme}) => theme.color.accent2};
    width: ${({theme}) => theme.spacing(5)};
    height: ${({theme}) => theme.spacing(5)};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.color.highlight};
`;

const Text = styled.h1`
    ${({theme}) => theme.type.xl};
    margin-left: ${({theme}) => theme.spacing(1)};
    text-transform: capitalize;
`;


interface Props {
    type : 'production' | 'delivery' | 'cutting',
    onClickNext : () => void,
    onClickPrev : () => void,
    onClickNow : () => void,
    week : number
}

export const IpadHeader = ({type, onClickNext, onClickPrev, onClickNow, week} : Props): React.ReactElement => {

    const themeContext = React.useContext(ThemeContext);

    return (
        
        <Container>
            <Title>
                <Icon>
                    {type === 'cutting' && <RiScissorsFill size={themeContext.spacing(2)}/> }
                    {type === 'production' && <RiHammerFill size={themeContext.spacing(2)}/> }
                    {type === 'delivery' && <RiTruckFill size={themeContext.spacing(2)}/> }
                </Icon>
                <Text>
                    {type}
                </Text>
            </Title>
            <WeekSwitcher onClickNext={onClickNext} onClickPrev={onClickPrev} onClickNow={onClickNow} week={week}/>
        </Container>
    )
}
