import {Supplier} from '../../types';




export function merge(supplier : Supplier, changes : Partial<Supplier>) : Supplier {
    const mergedSupplier = {...supplier};

    if(changes && Object.keys(changes).length !== 0 && changes.constructor === Object){
        (Object.keys(changes) as Array<keyof Supplier>).forEach(<T extends keyof Supplier>(key : T) => {
            if(changes[key] || changes[key] === 0 || changes[key] === '' || changes[key] === null ){
                mergedSupplier[key] = changes[key] as Supplier[T];
            }
        });
    }
    return mergedSupplier;
} 