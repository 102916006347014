import Decimal from "decimal.js";

export const getItemQtyPrice = (price : number, qty : number) : number => {

    const total = new Decimal(price).mul(qty);

    if(total.isNaN()) throw new Error("Item price total is not a number");

    return total.toNumber();
}


