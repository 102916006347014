import { value } from "../validate";
import { CarcaseColour, Order, OrderItem, SupplierOrderItem, EditedOrderItem, NotMissingOrderItem, MFCColour } from "../../types";
import { AccountingStatus } from "../../types/accountingStatus";
import { ConstructionType } from "../../types";
import { Customer } from "../../types";
import order_items from "../order_items";
import { taxGroups } from "../../static_data/taxGroups";
import * as validate from './validate';


export function accountingStatus (accountingStatus: AccountingStatus) : AccountingStatus
export function accountingStatus (accountingStatus: AccountingStatus, order: Order) : Order
export function accountingStatus (accountingStatus: AccountingStatus, order?: Order) : Order | AccountingStatus {
    
    if(!validate.accountingStatus(accountingStatus)) throw new Error("Accounting status is not valid");
    if(order) {
        return {
            ...order,
            accountingStatus
        }
    }
    return accountingStatus;
    
}

export function carcaseColour (carcaseColour: Order['carcaseColour']): Order['carcaseColour']
export function carcaseColour (carcaseColour: Order['carcaseColour'], order: Order): Order
export function carcaseColour (carcaseColour: Order['carcaseColour'], order?: Order): Order | Order['carcaseColour'] {
    if (!validate.carcaseColour(carcaseColour)._isValid && carcaseColour !== null) throw new Error("Carcase colour is not valid");
    if (order) {
        return {
            ...order,
            carcaseColour
        }
    }
    return carcaseColour;
}

export function constructionType (constructionType: ConstructionType): ConstructionType
export function constructionType (constructionType: ConstructionType, order: Order): Order
export function constructionType (constructionType: ConstructionType, order?: Order): Order | ConstructionType {
    if (!validate.constructionType(constructionType)._isValid) throw new Error("Construction type is not valid");
    if (order) {
        return {
            ...order,
            constructionType
        };
    }
    return constructionType;
}

type CustomerIdPartialOrderRtn = {
    customerId : Order['customerId'], 
    discount? : number, 
    taxValue? : number,
    supplierDiscount?: number, 
    items? : EditedOrderItem[]}

export function customerId (customer: Customer, order: Order, options: {updateDiscount? : boolean, updateTaxValue?: boolean, updateItemDiscounts? : boolean, returnUpdatesOnly?: true | undefined}) : CustomerIdPartialOrderRtn
export function customerId (customer: Customer, order: Order, options: {updateDiscount? : boolean, updateTaxValue?: boolean, updateItemDiscounts? : boolean, returnUpdatesOnly: false}) : Order
export function customerId (customer: Customer, order: Order, options: {updateDiscount? : boolean, updateTaxValue?: boolean, updateItemDiscounts? : boolean, returnUpdatesOnly?: boolean}) : Order | CustomerIdPartialOrderRtn {
    const {updateDiscount = true, updateTaxValue = true, updateItemDiscounts = true, returnUpdatesOnly = true} = options || {};
    let _order : CustomerIdPartialOrderRtn | Order = returnUpdatesOnly ? {customerId : customer.id} : {...order, customerId : customer.id};

    if(updateDiscount) {
        _order.discount = customer.discount;
        _order.supplierDiscount = customer.supplierDiscount;
    }

    if(updateTaxValue) {
        _order.taxValue = taxGroups[customer.taxGroup]?.value || 0;
    }

    if(updateItemDiscounts) {
        const updatedItems = [
            ...order_items.set.discount(customer.discount, order.items, true),
            ...order_items.set.supplierDiscount(customer.supplierDiscount, order.items, true)
        ];
        if(returnUpdatesOnly && updatedItems.length > 0) _order.items = updatedItems;
        else _order.items = order_items.merge(order.items, updatedItems);
    }

    return _order;

}

export function customerRef (customerRef: Order['customerRef']) : Order['customerRef']
export function customerRef (customerRef: Order['customerRef'], order: Order) : Order
export function customerRef (customerRef: Order['customerRef'], order?: Order) : Order | Order['customerRef'] {
    if(!validate.customerRef(customerRef)._isValid) throw new Error("Customer ref is not valid");
    if(order) {
        return {
            ...order,
            customerRef
        };
    }
    return customerRef;
}

/*
export function dateInvoiced (order: Order, dateInvoiced: Order['dateInvoiced']) : Order {
    if(!validate.dateInvoiced(dateInvoiced)._isValid) throw new Error("Date invoiced is not valid");
    return {
        ...order,
        dateInvoiced
    };
}

export function dateOrdered (order: Order, dateOrdered: Order['dateOrdered']) : Order {
    if(!validate.dateOrdered(dateOrdered)._isValid) throw new Error("Date ordered is not valid");
    return {
        ...order,
        dateOrdered
    };
}

export function deletedCustomerName (order: Order, deletedCustomerName: Order['deletedCustomerName']) : Order {
    if(!validate.deletedCustomerName(deletedCustomerName)._isValid) throw new Error("Deleted customer name is not valid");
    return {
        ...order,
        deletedCustomerName
    };
}

*/
export function deliveryAddress(deliveryAddress: Order['deliveryAddress']) : Order['deliveryAddress']
export function deliveryAddress (deliveryAddress: Order['deliveryAddress'], order: Order) : Order
export function deliveryAddress (deliveryAddress: Order['deliveryAddress'], order?: Order) : Order | Order['deliveryAddress'] {
    if(!validate.deliveryAddress(deliveryAddress)._isValid) throw new Error("Delivery address is not valid");
    if(order) {
        return {
            ...order,
            deliveryAddress
        };
    }
    return deliveryAddress;
}


export function deliveryDate (deliveryDate: Order['deliveryDate']) :  Order['deliveryDate']
export function deliveryDate (deliveryDate: Order['deliveryDate'], order: Order) : Order
export function deliveryDate (deliveryDate: Order['deliveryDate'], order?: Order) : Order | Order['deliveryDate'] {
    if(!validate.deliveryDate(deliveryDate)._isValid) throw new Error("Delivery date is not valid");
    if(order) {
        return {
            ...order,
            deliveryDate
        };
    }
    return deliveryDate;
}

export function deliveryDateType (deliveryDateType: Order['deliveryDateType']) : Order['deliveryDateType']
export function deliveryDateType (deliveryDateType: Order['deliveryDateType'], order: Order) : Order
export function deliveryDateType (deliveryDateType: Order['deliveryDateType'], order?: Order) : Order | Order['deliveryDateType'] {
    if(!validate.deliveryDateType(deliveryDateType)._isValid) throw new Error("Delivery date type is not valid");
    if(order) {
        return {
            ...order,
            deliveryDateType
        };
    } 
    return deliveryDateType;
}

export function dateOrdered (dateOrdered: Order['dateOrdered']) :  Order['dateOrdered']
export function dateOrdered (dateOrdered: Order['dateOrdered'], order: Order) : Order
export function dateOrdered (dateOrdered: Order['dateOrdered'], order?: Order) : Order | Order['dateOrdered'] {
    if(!validate.dateOrdered(dateOrdered)._isValid) throw new Error("Ordered date is not valid");
    if(order) {
        return {
            ...order,
            dateOrdered
        };
    }
    return dateOrdered;
}

export function dateInvoiced (dateInvoiced: Order['dateInvoiced']) :  Order['dateInvoiced']
export function dateInvoiced (dateInvoiced: Order['dateInvoiced'], order: Order) : Order
export function dateInvoiced (dateInvoiced: Order['dateInvoiced'], order?: Order) : Order | Order['dateInvoiced'] {
    if(!validate.dateInvoiced(dateInvoiced)._isValid) throw new Error("Invoiced date is not valid");
    if(order) {
        return {
            ...order,
            dateInvoiced
        };
    }
    return dateInvoiced;
}

export function deliveryNotes (deliveryNotes: Order['deliveryNotes']) : Order['deliveryNotes'] 
export function deliveryNotes (deliveryNotes: Order['deliveryNotes'], order: Order) : Order 
export function deliveryNotes (deliveryNotes: Order['deliveryNotes'], order? : Order) : Order | Order['deliveryNotes'] {
    if(!validate.deliveryNotes(deliveryNotes)._isValid) throw new Error("Delivery notes are not valid");
    if(order) {
        return {
            ...order,
            deliveryNotes
        };
    } 
    return deliveryNotes;
}

type discountPartialOrderRtn = {discount : number, items? : EditedOrderItem[]}

export function discount (discount: Order['discount'], order: Order, options: { updateItemDiscounts? : boolean, returnUpdatesOnly: true | undefined}) : discountPartialOrderRtn 
export function discount (discount: Order['discount'], order: Order, options: { updateItemDiscounts? : boolean, returnUpdatesOnly: false}) : Order 
export function discount (discount: Order['discount'], order: Order, options: { updateItemDiscounts? : boolean, returnUpdatesOnly?: boolean}) : Order | discountPartialOrderRtn {

    const { updateItemDiscounts = true, returnUpdatesOnly = true } = options;

    if(!validate.discount(discount)._isValid) throw new Error("Discount is not valid");

    let _order : Order | discountPartialOrderRtn = returnUpdatesOnly ? {discount} : {...order, discount};

    if(updateItemDiscounts) {
        let items = order_items.set.discount(discount, order.items, returnUpdatesOnly);
        if(items.length > 0) _order.items = items;
    }

    return _order;
}


export function doorRange ( doorRange: Order['doorRange']) : Order['doorRange']
export function doorRange ( doorRange: Order['doorRange'], order: Order) : Order
export function doorRange ( doorRange: Order['doorRange'], order?: Order) : Order | Order['doorRange']{
    if(!validate.doorRange(doorRange)._isValid) throw new Error("Door range is not valid");
    if(order) {
        return {
            ...order,
            doorRange
        };
    } 
    return doorRange;
}

export function doorSupplier (doorSupplier: Order['doorSupplier']) : Order['doorSupplier'] 
export function doorSupplier (doorSupplier: Order['doorSupplier'], order: Order) : Order 
export function doorSupplier (doorSupplier: Order['doorSupplier'], order?: Order) : Order  | Order['doorSupplier'] {
    if(!validate.doorSupplier(doorSupplier)._isValid) throw new Error("Door supplier is not valid");
    if(order) {
        return {
            ...order,
            doorSupplier
        };
    }
    return doorSupplier
}

export function drawerbox (drawerbox: Order['drawerbox']) : Order['drawerbox']
export function drawerbox (drawerbox: Order['drawerbox'], order: Order) : Order
export function drawerbox (drawerbox: Order['drawerbox'], order?: Order) : Order | Order['drawerbox'] {
    if(!validate.drawerbox(drawerbox)._isValid) throw new Error("Drawerbox not valid");
    if(order) {
        return {
            ...order,
            drawerbox
        };
    }
    return drawerbox;
}


export function gluedWallUnits (gluedWallUnits: Order['gluedWallUnits']) : Order['gluedWallUnits']
export function gluedWallUnits (gluedWallUnits: Order['gluedWallUnits'], order: Order) : Order
export function gluedWallUnits (gluedWallUnits: Order['gluedWallUnits'], order?: Order) : Order | Order['gluedWallUnits'] {
    if(!validate.gluedWallUnits(gluedWallUnits)._isValid) throw new Error("Glued wall units is not valid");
    if(order) {
        return {
            ...order,
            gluedWallUnits
        };
    }
    return gluedWallUnits;
}

export function hasDoors (hasDoors: Order['hasDoors']) : Order['hasDoors']
export function hasDoors (hasDoors: Order['hasDoors'], order: Order) : Order
export function hasDoors (hasDoors: Order['hasDoors'], order?: Order) : Order | Order['hasDoors'] {
    if(!validate.hasDoors(hasDoors)._isValid) throw new Error("Has doors is not valid");
    if(order) {
        return {
            ...order,
            hasDoors
        };
    }
    return hasDoors;
}

export function invoiceAddress(invoiceAddress: Order['invoiceAddress']) : Order['invoiceAddress']
export function invoiceAddress (invoiceAddress: Order['invoiceAddress'], order: Order) : Order
export function invoiceAddress (invoiceAddress: Order['invoiceAddress'], order?: Order) : Order | Order['invoiceAddress'] {
    if(!validate.invoiceAddress(invoiceAddress)._isValid) throw new Error("Invoice address is not valid");
    if(order) {
        return {
            ...order,
            invoiceAddress
        };
    }
    return invoiceAddress;
}






export function invoiceSent (invoiceSent: Order['invoiceSent']) : Order['invoiceSent']
export function invoiceSent (invoiceSent: Order['invoiceSent'], order: Order) : Order
export function invoiceSent (invoiceSent: Order['invoiceSent'], order?: Order) : Order | Order['invoiceSent'] {
    if(!validate.invoiceSent(invoiceSent)._isValid) throw new Error("Invoice sent is not valid");
    if(order) {
        return {
            ...order,
            invoiceSent
        };
    }   
    return invoiceSent;
}

export function isPinned (isPinned: Order['isPinned']) : Order['isPinned'] 
export function isPinned (isPinned: Order['isPinned'], order: Order) : Order 
export function isPinned (isPinned: Order['isPinned'], order?: Order) : Order | Order['isPinned'] {
    if(!validate.isPinned(isPinned)._isValid) throw new Error("Is pinned is not valid");
    if(order) {
        return {
            ...order,
            isPinned
        };
    }
    return isPinned;
}


/*
export function orderConfirmationSent (order: Order, orderConfirmationSent: Order['orderConfirmationSent']) : Order {
    if(!validate.orderConfirmationSent(orderConfirmationSent)._isValid) throw new Error("Order confirmation sent is not valid");
    return {
        ...order,
        orderConfirmationSent
    };
}
*/

export function orderRef (orderRef: Order['orderRef']) :  Order['orderRef']
export function orderRef (orderRef: Order['orderRef'], order: Order) : Order
export function orderRef (orderRef: Order['orderRef'], order?: Order) : Order |  Order['orderRef'] {
    if(!validate.orderRef(orderRef)._isValid) throw new Error("Order ref is not valid");
    if(order) {
        return {
            ...order,
            orderRef
        };
    }
    return orderRef;
}

export function orderStatus (orderStatus: Order['orderStatus']) : Order['orderStatus'] 
export function orderStatus (orderStatus: Order['orderStatus'], order: Order) : Order 
export function orderStatus (orderStatus: Order['orderStatus'], order?: Order) : Order | Order['orderStatus'] {
    if(!validate.orderStatus(orderStatus)._isValid) throw new Error("Order status is not valid");
    if (order) {
        return {
            ...order,
            orderStatus
        };
    }
    return orderStatus;
}

export function orderValueEstimate (orderValueEstimate: Order['orderValueEstimate']) :  Order['orderValueEstimate']
export function orderValueEstimate (orderValueEstimate: Order['orderValueEstimate'], order: Order) : Order
export function orderValueEstimate (orderValueEstimate: Order['orderValueEstimate'], order?: Order) : Order |  Order['orderValueEstimate']{
    if(!validate.orderValueEstimate(orderValueEstimate)._isValid) throw new Error("Order value estimate is not valid");
    if(order) {
        return {
            ...order,
            orderValueEstimate
        };
    }
    return orderValueEstimate;
}

export function orderValueType (orderValueType: Order['orderValueType']) : Order['orderValueType']
export function orderValueType (orderValueType: Order['orderValueType'], order: Order) : Order
export function orderValueType (orderValueType: Order['orderValueType'], order?: Order) : Order |  Order['orderValueType'] {
    if(!validate.orderValueType(orderValueType)._isValid) throw new Error("Order value type is not valid");
    if(order) {
        return {
            ...order,
            orderValueType
        };
    }
    return orderValueType;
}

export function productionDateType (productionDateType: Order['productionDateType']) : Order['productionDateType'] 
export function productionDateType (productionDateType: Order['productionDateType'], order: Order) : Order 
export function productionDateType (productionDateType: Order['productionDateType'], order?: Order) : Order | Order['productionDateType'] {
    if(!validate.productionDateType(productionDateType)._isValid) throw new Error("Production date type is not valid");
    if(order) {
        return {
            ...order,
            productionDateType
        };
    }
    return productionDateType;
}

export function productionDate (productionDate: Order['productionDate']) : Order['productionDate'] 
export function productionDate (productionDate: Order['productionDate'], order: Order) : Order 
export function productionDate (productionDate: Order['productionDate'], order?: Order) : Order | Order['productionDate'] {
    if(!validate.productionDate(productionDate)._isValid) throw new Error("Production date is not valid");
    if(order) {
        return {
            ...order,
            productionDate
        };
    }
    return productionDate;
}

export function productionStatus (productionStatus: Order['productionStatus']) : Order['productionStatus']
export function productionStatus (productionStatus: Order['productionStatus'], order: Order) : Order
export function productionStatus (productionStatus: Order['productionStatus'], order?: Order) : Order | Order['productionStatus'] {
    if(!validate.productionStatus(productionStatus)._isValid) throw new Error("Production status is not valid");
    if(order) {
        return {
            ...order,
            productionStatus
        };
    }
    return productionStatus;
}

export function productionNotes (productionNotes: Order['productionNotes']) : Order['productionNotes']
export function productionNotes (productionNotes: Order['productionNotes'], order: Order) : Order
export function productionNotes (productionNotes: Order['productionNotes'], order?: Order) : Order | Order['productionNotes'] {
    if(!validate.productionNotes(productionNotes)._isValid) throw new Error("Production notes are not valid");
    if(order) {
        return {
            ...order,
            productionNotes
        };
    }
    return productionNotes;
}

export function quoteRef (quoteRef: Order['quoteRef']) : Order['quoteRef']
export function quoteRef (quoteRef: Order['quoteRef'], order: Order) : Order
export function quoteRef (quoteRef: Order['quoteRef'], order?: Order) : Order | Order['quoteRef'] {
    if(!validate.quoteRef(quoteRef)._isValid) throw new Error("Quote ref not valid");
    if(order) {
        return {
            ...order,
            quoteRef
        };
    }
    return quoteRef;
}

export function supplierDeliveryDate (supplierDeliveryDate: Order['supplierDeliveryDate']) : Order['supplierDeliveryDate']
export function supplierDeliveryDate (supplierDeliveryDate: Order['supplierDeliveryDate'], order: Order) : Order
export function supplierDeliveryDate (supplierDeliveryDate: Order['supplierDeliveryDate'], order?: Order) : Order | Order['supplierDeliveryDate'] {
    if(!validate.supplierDeliveryDate(supplierDeliveryDate)._isValid) throw new Error("Supplier delivery date is not valid");
    if(order) {
        return {
            ...order,
            supplierDeliveryDate
        };
    }
    return supplierDeliveryDate;
}

type supplierDiscountPartialOrderRtn = {supplierDiscount : number, items? : EditedOrderItem[]}

export function supplierDiscount (supplierDiscount: Order['supplierDiscount'], order: Order, options: { updateItemDiscounts? : boolean, returnUpdatesOnly?: true}) : Order | supplierDiscountPartialOrderRtn {

    const { updateItemDiscounts = true, returnUpdatesOnly = true } = options;

    if(!validate.supplierDiscount(supplierDiscount)._isValid) throw new Error("Supplier Discount is not valid");

    let _order : Order | supplierDiscountPartialOrderRtn = returnUpdatesOnly ? {supplierDiscount} : {...order, supplierDiscount};

    if(updateItemDiscounts) {
        let items = order_items.set.supplierDiscount(supplierDiscount, order.items, returnUpdatesOnly);
        if(items.length > 0) _order.items = items;
    }

    return _order;
}



export function taxValue (taxValue: Order['taxValue']) : Order['taxValue']
export function taxValue (taxValue: Order['taxValue'], order: Order) : Order
export function taxValue (taxValue: Order['taxValue'], order?: Order) : Order | Order['taxValue'] {
    if(!validate.taxValue(taxValue)._isValid) throw new Error("Tax group is not valid");
    if(order) {
        return {
            ...order,
            taxValue
        };
    }
    return taxValue;
}

export function voidSize (voidSize: Order['voidSize']) : Order['voidSize'] 
export function voidSize (voidSize: Order['voidSize'], order: Order) : Order 
export function voidSize (voidSize: Order['voidSize'], order?: Order) : Order | Order['voidSize'] {
    if(!validate.voidSize(voidSize)._isValid) throw new Error("Void size is not valid");
    if(order) {
        return {
            ...order,
            voidSize
        };
    }
    return voidSize;
}

export function payments (payments: Order['payments']) : Order['payments']
export function payments (payments: Order['payments'], order: Order) : Order
export function payments (payments: Order['payments'], order?: Order) : Order | Order['payments'] {
    if(!validate.payments(payments)._isValid) throw new Error("Payments is not valid");
    if (order) {
        return {
            ...order,
            payments
        };
    }
    return payments;
}
