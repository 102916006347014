import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";

export const deleteOrderItems = (toDeleteIds: string[]) => {

	const editOrder = getEditOrder();
	const currentItemValues = editOrder.currentValues.order.items;
	const newItemValues = editOrder.newValues.order.items;

	const updatedItems: libTypes.EditedOrderItem[] = [];

	newItemValues?.forEach((item) => {

        const isNewItemOnly = !currentItemValues.find((c) => c.id === item.id);
        const isToBeDeleted = toDeleteIds.includes(item.id);
        const hasDeletedParent = fns.shared.hasProperty(item, "parentId") && toDeleteIds.includes(item.parentId);

        if (isNewItemOnly && (isToBeDeleted || hasDeletedParent)) return;

        else if (isToBeDeleted || hasDeletedParent) {
            updatedItems.push({
                ...item,
                _deleted: true,
            });
            return;
        }
        else updatedItems.push(item);

	});

    currentItemValues?.forEach((item) => {

        const hasNewValues = newItemValues?.find((c) => c.id === item.id);
        const isToBeDeleted = toDeleteIds.includes(item.id);
        const hasDeletedParent = fns.shared.hasProperty(item, "parentId") && toDeleteIds.includes(item.parentId);

        if (!hasNewValues && (isToBeDeleted || hasDeletedParent)) {
            updatedItems.push({
                id: item.id,
                _deleted: true,
            });
        }

    });

	useStore.setState({
		editOrder: create(editOrder, (draft) => {
			draft.newValues.order.items = updatedItems;
		}),
	});
};
