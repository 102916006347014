import { fns, libTypes, staticData } from "@holta/lib";
import { ModalSidebar } from "../../modal";
import React from "react";
import styled from "styled-components";
import { TreeNav } from "../../treeNav";
import { useProductBrowserContext } from "../context";



export const Sidebar = () => {

    const { categories, expandedCategories, selectedCategoryBranch, toggleCategory} = useProductBrowserContext();
    
    return (
        <S.StyledModalSidebar>
            <S.ModalSidebarTop>
                <TreeNav
                    items={categories}
                    expandedItems={expandedCategories}
                    selectedBranch={selectedCategoryBranch}
                    toggleItem={toggleCategory}
                />
            </S.ModalSidebarTop>
        </S.StyledModalSidebar>
    );
};

/* --------------------------------- Styles --------------------------------- */

const S = {
    StyledModalSidebar: styled(ModalSidebar)`
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
    `,

    ModalSidebarTop: styled.div`
        flex-grow: 1;
        text-align: left;
    `,

    ModalSidebarBottom: styled.div`
        padding: ${({ theme }) => theme.spacing(2)};
        button {
            width: 100%;
            text-align: center;
        }
    `,
};
