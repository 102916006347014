export const orderItemTypes = {
    CARCASE : {
        key: 'CARCASE',
    },
    SUPPLIER : {
        key: 'SUPPLIER',
    },
    MISSING_LINKED_DOOR : {
        key: 'MISSING_LINKED_DOOR',
    }
} as const;
    