import styled from 'styled-components';


export const FormInputInnerRow = styled.div`

    display: flex;
    margin-bottom:  ${({theme}) => theme.spacing(2)};

    input, textarea {
        flex: auto;
    }

`

export const FormTextarea = styled.textarea`


`;
