import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

interface Props {
    items: any[];
    Row: React.ComponentType<ListChildComponentProps<any>> & React.ReactNode;
    rowHeight?: number;
}

export const VirtualisedTable: React.FC<Props> = ({ Row, items, rowHeight = 46 }) => {
    return (
        <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
                <List
                    height={height - 2}
                    itemCount={items.length}
                    itemSize={rowHeight}
                    width={width - 1}
                    overscanCount={10}
                    itemData={items}
                    itemKey={(item) => items[item].id}
                >
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
};
