
export function filterById <Items extends Array<any>>(items: Items, key: keyof Items[number], id: string | string[]) : Items[number][] {
    if(!Array.isArray(items)) throw new Error("Items must be an array");
    if(items.length === 0) return items;
   

    const ids = Array.isArray(id) ? id : [id];

    return items.filter(item => {
        if(Array.isArray(item[key])) {
            return ids.every(id => item[key].includes(id));
        }
        return ids.includes(item[key]);
    });
}