import * as set from './set';
import * as validate from './validate';
import {create} from './create';
import { getCategories } from './getCategories';
import {findBaseProduct} from './findBaseProduct';

export default {
    create,
    set,
    validate,
    getCategories,
    findBaseProduct
}