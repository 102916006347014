import { isLinkedDoorItem } from "../order_item/isLinkedDoorItem";
import { isMissingLinkedDoorItem } from "../order_item/isMissingLinkedDoorItem";
import { CarcaseOrderItem, SupplierOrderItem, OrderItem } from "../../types";
import { isCarcaseItem } from "../order_item/isCarcaseItem";
import { isManuallyAddedSupplierItem } from "../order_item/isManuallyAddedSupplierItem";

export const removeLinkedDoorItems = (
    items: OrderItem[]
): (CarcaseOrderItem | SupplierOrderItem)[] => {
    return items.filter((item) => isCarcaseItem(item) || isManuallyAddedSupplierItem(item)) as (CarcaseOrderItem | SupplierOrderItem)[];
};
