import React from 'react';
import styled from 'styled-components';


export const Tabs = styled.div<{height?: number, inline? : boolean}>`
    min-height: ${({theme, height}) => theme.spacing(height || 8)};
    width: 100%;
    display: flex;
    
    align-items : center;
    position: relative;
    background-color: ${ props => props.theme.color.base_contrast };

    ${p => !p.inline && `justify-content: space-between;`}
`;


const TabContainer = styled.div<{isSelected? : boolean, height : number, inline? : boolean}>`
    display: flex;
    align-items: center;
    min-height: ${({theme, height}) => theme.spacing(height)};
    position: relative;
    justify-content:center;
    
    color: ${ props => props.isSelected ? props.theme.color.text : props.theme.color.accent3 };
    ${({theme}) => theme.type.m};
    ${({theme}) => theme.type.bold};

    .is-selected & {
        color: ${ props => props.theme.color.text };
    }
    ${p => !p.inline && `
        width:100%;
    `}
    ${p => p.inline && `
        margin-right: ${p.theme.spacing(2)};
    `}
`

const Underline = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 4px;
    background-color: ${({theme}) => theme.color.highlight};
    opacity: 0;
    
    .is-selected & {
        opacity: 1;
    }
`

export const Tab = ({children, isSelected, onClick, height = 8, inline = false} : {children : React.ReactNode, isSelected? : boolean, onClick? : () => void, height? : number, inline? : boolean }) : React.ReactElement => (
    <TabContainer onClick={onClick} isSelected={isSelected} height={height} className={isSelected ? 'is-selected' : ''} inline={inline}>
        {children}
        <Underline />
    </TabContainer>
)

