import {Order, ProductionStatus} from '../../types';
import { getOrderTotalOrEstimate } from "../order/getOrderTotalOrEstimate";


export const getProductionStatusPercentage = (orders : Order[], productionStatus : ProductionStatus) : number =>  {
    const weekTotal = orders.reduce((acc, order ) =>  acc + getOrderTotalOrEstimate(order), 0);
    if(weekTotal === 0) return 0;

    const statusTotal = orders.filter(order => order.productionStatus == productionStatus ).reduce((acc, order ) =>   acc + getOrderTotalOrEstimate(order), 0);
    let percent = 100 / weekTotal;
    percent = percent * statusTotal;
    return percent
}
