import React from "react";
import styled from "styled-components";
import {Arrow} from '../arrow';

const Container = styled.div`
    display: flex;
    align-items: center;   
    justify-content: space-between; 
    padding: ${props => props.theme.spacing(0.5)};
    background-color: ${props => props.theme.color.accent1};
    border-radius: 1000px;
    border: 1px solid ${props => props.theme.color.accent2};
`;

const StyledArrow = styled(Arrow)`
    background-color: ${props => props.theme.color.base_contrast};
    border: 1px solid ${props => props.theme.color.accent2};
    cursor: pointer;
    &:active {
        background-color: ${props => props.theme.color.accent2};
    }
`

const Text = styled.div`
    padding-left:${props => props.theme.spacing(1)};
    padding-right:${props => props.theme.spacing(1)};
    ${props => props.theme.type.l};
    user-select: none;
    white-space: nowrap;
    
`;

interface WeekSwitcherProps {
    onClickNext: () => void;
    onClickPrev: () => void;
    onClickNow: () => void;
    week: number | string;
}

export const WeekSwitcher = ({
    onClickNext,
    onClickPrev,
    onClickNow,
    week,
}: WeekSwitcherProps): React.ReactElement => {
    return (
        <Container>
            <StyledArrow left onClick={onClickPrev} />
            <Text onClick={onClickNow}>
                Week {week}
            </Text>
            <StyledArrow right onClick={onClickNext}/>
        </Container>
    );
};
