import React, { Ref } from "react";
import { StyledDialogOverlay, StyledDialogContent, ClickBlocker, Overlay} from "./styled";
import { useDialog } from "../dialog";
import { useModal } from "./context";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";


interface ModalProps {
    id: string;
    children: React.ReactElement;
    width?: string;
    sideBar? :boolean;
    onDismiss?: (closeModal: () => void) => void;
    initialFocusRef?: React.RefObject<any>;
    style? : React.CSSProperties,
    className? : string;
}

export function Modal({ id, children, onDismiss, width, sideBar, initialFocusRef, style, className }: ModalProps): React.ReactElement | null {
    const { isDialogOpen, isDialogClosing } = useDialog();
    const { isModalOpen, isModalClosing, closeModal, isTopModal } = useModal();
    const isOpen = isModalOpen(id);
    const isLeaving = isModalClosing(id);
    const bodyScrollContainerRef = React.useRef<any>()

    React.useEffect(() => {

        let containerRef = bodyScrollContainerRef.current;
        if(bodyScrollContainerRef.current){
            containerRef = bodyScrollContainerRef.current;
            if (isOpen) {
                //disableBodyScroll(bodyScrollContainerRef.current, {allowTouchMove: el => true,});
            } else {
                //enableBodyScroll(bodyScrollContainerRef.current);
            }
        }

        return () => {
            //containerRef && enableBodyScroll(containerRef);
        }

    }, [isOpen, id, bodyScrollContainerRef]);

    const handleOnDismiss = () => {
        if (onDismiss) {
            onDismiss(() => closeModal(id));
        } else {
            closeModal(id);
        }
    };

    let overlayClassName = "";
    overlayClassName = isDialogOpen() || !isTopModal(id) ? " isOpen " : overlayClassName;
    overlayClassName = isDialogClosing()  ?  " isLeaving " : overlayClassName;

    let dialogOverlayClassName = sideBar ? 'isSidebar ' : '';
    dialogOverlayClassName += isLeaving ? 'isLeaving ' : '';

    return (
        <>
            {(isOpen || isLeaving) && (
                <div ref={bodyScrollContainerRef} className={sideBar ? 'isSidebar' : ''}>
                <StyledDialogOverlay
                    initialFocusRef={initialFocusRef}
                    id={"dialog-" + id}
                    className={dialogOverlayClassName}
                    onDismiss={handleOnDismiss}
                    dangerouslyBypassFocusLock={isLeaving ? true : false}
                >
                    <StyledDialogContent 
                    aria-label="modal"
                    className={'dialogContent ' + className } 
                    style={style}
                    width={width}>
                        {isLeaving && (
                            <ClickBlocker
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            ></ClickBlocker>
                        )}
                        <Overlay className={overlayClassName}></Overlay>
                        {children}
                    </StyledDialogContent>
                </StyledDialogOverlay>
                </div>
            )}
        </>
    );
}


