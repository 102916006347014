import { OrderItem } from "../../types/orderItems";
import { isAnySupplierItem } from "../order_item/isAnySupplierItem";

export function getSupplierRanges(orderItems: OrderItem[]) : string[] {
    const supplierRanges = new Set<string>();
    orderItems.forEach((orderItem) => {
        if (isAnySupplierItem(orderItem) && orderItem.supplierRangeId) {
            supplierRanges.add(orderItem.supplierRangeId);
        }
    });

    return Array.from(supplierRanges);
}