export const constants = {
    SHEET_MATERIAL_MAX_WIDTH : 2400,
    SHEET_MATERIAL_MAX_HEIGHT : 2400,
    SHEET_MATERIAL_EDGING_MARGIN: 8,
    SHEET_MATERIAL_USEABLE_M2: 4.4,
    SHEET_MATERIAL_MARGIN: 2.25,

    MFC_MAX_WIDTH: 2800,
    MFC_MAX_HEIGHT: 2800,
    MFC_36_SURCHARGE_MULTIPLE: 2.5,
    MFC_BOARD_USEABLE_M2: 4.4,
    MFC_BOARD_MARGIN: 2.25,
    MFC_BOARD_MINIMUM_CHARGABLE_AREA: 100000,
    MFC_BOARD_EDGING_MARGIN: 8,


    CUT_EDGE_ITEM_SURCHARGE: 1,
    MINIMUM_CUT_ITEM_QTY: 1,

    CUT_EDGE_ORDER_DELIVERY_MAX_DISTANCE: 80000,
    CUT_EDGE_ORDER_DELIVERY_DISTANCE_UNIT_MULTIPLE: 0.001,
    CUT_EDGE_ORDER_DELIVERY_MIN_VALUE: 40,
    CUT_EDGE_ORDER_FREE_DELIVERY_DISTANCE_THRESHOLD: 40000,
    CUT_EDGE_ORDER_FREE_DELIVERY_VALUE_THRESHOLD: 1000,

    WEEK_PRODUCTION_VALUE_CAPACITY: 20000,

    EVENT_RELOAD_PRODUCTS: "reload-products",
    EVENT_RELOAD_PRODUCT_COMPONENTS: "reload-product-components",


} as const;