import { AddressFields } from "../../types/address";

export function createAddressString(address : AddressFields) {
    let merged = address.addressLine1 ? address.addressLine1 + '\n' : '';
    merged += address.addressLine2 ? address.addressLine2 + '\n' : "";
    merged += address.addressLine3 ? address.addressLine3 + '\n' : "";
    merged += address.city ? address.city + '\n' : "";
    merged += address.county ? address.county + '\n' : "";
    merged += address.country ? address.country + '\n' : "";
    merged += address.postcode ? address.postcode : "";
    return merged;
}