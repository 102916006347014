import { Order } from "../../types";
import { isSameISOWeek, getISOWeek } from "date-fns";

export function filterByProductionWeek(orders: Order[], date: number): Order[] {
    return orders.filter((order) => {

        return (
            order.productionDate &&
            isSameISOWeek(order.productionDate, date) &&
            (order.productionDateType === "WEEK" || order.productionDateType === "DAY")
        );
    });
}
