import React from 'react';

export const useWarnOnLeave = () => {
    React.useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])
    
    const alertUser = (e : BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = ''
    }
}