import {Category} from '../../types';
import {filterBranch} from './filterBranch';

/**
 * 
 * Filters an array of categories to exclude any provided ids (string or array). Optional parameter to also exclude children
 * 
 * @param  {Category[]} categories
 * @param  {string|string[]} id
 * @param  {boolean} excludeChildren=false
 * @returns Category
 */

export function filterById(categories: Category[], id:string | string[], excludeChildren: boolean = false) : Category[]{

    const idsToFilter = Array.isArray(id) ? id : [id];
    let filteredCategories : Category[] = [] 

    filteredCategories = categories.filter(
        category => !idsToFilter.includes(category.id)
    );

    if(excludeChildren) {
        filteredCategories = filterBranch(filteredCategories, idsToFilter)
    }

    return filteredCategories;

}
