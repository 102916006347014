



const _spacing = (constant : number, val : number, unitless: boolean = false): string | number => {
    val = val * constant;
    if(unitless) return val;
    return val + 'px';
}

const spacingSmall = (val : number, unitless: boolean = false): string | number => {
    return _spacing(8, val, unitless);
}
const spacingMedium = (val : number, unitless: boolean = false): string | number => {
    return _spacing(10, val, unitless);
}
const spacingLarge = (val : number, unitless: boolean = false): string | number => {
    return _spacing(12, val, unitless);
}


export interface Color {
    [index : string] : string;
}

export const colorLight = {
    accent1 : '#F3F4F7',
    accent2 : '#E1E3E9',
    accent3 : '#C2C6D0',
    accent4 : '#647487',
    accent5 : '#313543',
    base : '#F8F9FA',
    base_contrast: 'white',
    danger: '#EB5757',
    confirm: 'rgb(74, 185, 141)', 
    text: '#2D2F37',
    highlight: '#4D4DDC',
    highlight2: '#DAE2FD',
    production_PROVISIONAL : "rgb(255, 168, 168)",
    production_IN_PRODUCTION : "rgb(77, 173, 247)",
    production_CUT : "rgb(241, 196, 15)",
    production_EDGED : "rgb(102, 217, 232)",
    production_MACHINED : "rgb(255, 169, 77)",
    production_BUILT : "rgb(177, 151, 252)",
    production_COMPLETE : "rgb(105, 219, 124)",
    production_DELIVERED : "rgb(155, 155, 155)",
    production_bg_PROVISIONAL : "#faf1f1",
    production_bg_IN_PRODUCTION : "#e8f5ff",
    production_bg_CUT : "#fffdf6",
    production_bg_EDGED : "#edfafc",
    production_bg_MACHINED : "#faf2e9",
    production_bg_BUILT : "#f6f3ff",
    production_bg_COMPLETE : "#eefff1",
    production_bg_DELIVERED : "#f8f8f8",
    production_text : "white"
}

export const colorDark  = {
    accent1 : '#272931',
    accent2 : '#414552',
    accent3 : '#414552',
    accent4 : '#96999F',
    accent5 : '#686B71',
    base : 'black',
    base_contrast: '#16171B',
    danger: '#EB5757',
    confirm: 'rgb(74, 185, 141)',
    text: 'white',
    highlight: '#48FACF',
    highlight2: '#133531',
    production_PROVISIONAL : "rgb(255, 168, 168)",
    production_IN_PRODUCTION : "rgb(77, 173, 247)",
    production_CUT : "rgb(241, 196, 15)",
    production_EDGED : "rgb(102, 217, 232)",
    production_MACHINED : "rgb(255, 169, 77)",
    production_BUILT : "rgb(177, 151, 252)",
    production_COMPLETE : "rgb(105, 219, 124)",
    production_DELIVERED : "rgb(155, 155, 155)",
    production_bg_PROVISIONAL : "#faf1f1",
    production_bg_IN_PRODUCTION : "#e8f5ff",
    production_bg_CUT : "#fffdf6",
    production_bg_EDGED : "#edfafc",
    production_bg_MACHINED : "#faf2e9",
    production_bg_BUILT : "#f6f3ff",
    production_bg_COMPLETE : "#eefff1",
    production_bg_DELIVERED : "#f8f8f8",
    production_text : "white" 
}

const typeSmall  = {
    s : 'font-size: 11px;',
    m : 'font-size: 14px;',
    l : 'font-size: 18px;',
    xl : 'font-size: 24px;',
    bold : 'font-weight: 600;'
}
const typeMedium  = {
    s : 'font-size: 11px;',
    m : 'font-size: 14px;',
    l : 'font-size: 18px;',
    xl : 'font-size: 24px;',
    bold : 'font-weight: 600;'
}
const typeLarge  = {
    s : 'font-size: 13px;',
    m : 'font-size: 17px;',
    l : 'font-size: 22px;',
    xl : 'font-size: 29px;',
    bold : 'font-weight: 600;'
}

export const dropShadow = {
    s : 'box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);',
    m : 'box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.3);',

}

export const themeSmall = {
    type : typeSmall,
    spacing : spacingSmall,
    dropShadow
}

export const themeMedium = {
    type : typeMedium,
    spacing : spacingMedium,
    dropShadow : dropShadow
}

export const themeLarge = {
    type : typeLarge,
    spacing : spacingLarge,
    dropShadow : dropShadow
}


export type Colors = {
    accent1 : string
    accent2 : string
    accent3 : string
    accent4 : string
    accent5 : string
    base : string
    base_contrast: string
    danger: string
    confirm: string
    text: string
    highlight: string
    highlight2: string
    production_PROVISIONAL : string
    production_IN_PRODUCTION : string
    production_CUT : string
    production_EDGED : string
    production_MACHINED : string
    production_BUILT : string
    production_COMPLETE : string
    production_DELIVERED : string
    production_bg_PROVISIONAL : string
    production_bg_IN_PRODUCTION : string
    production_bg_CUT : string
    production_bg_EDGED : string
    production_bg_MACHINED : string
    production_bg_BUILT : string
    production_bg_COMPLETE : string
    production_bg_DELIVERED : string
    production_text : string
}


export type TypeSizes = {
    s : string
    m : string
    l : string
    xl : string
    bold : string
}


export type Theme = {
    spacing: (val : number, unitless?: boolean) => string | number ,
    color : Colors,
    type : TypeSizes,
    maxContainerWidth: string,
    dropShadow : typeof dropShadow
}

export const theme = {
    colorLight,
    colorDark,
    themeSmall,
    themeMedium,
    themeLarge,
    dropShadow
}
