export const orderStatuses = {
    QUOTE: {
        label: "Quote",
        key: "QUOTE"
    },
    ORDER: {
        label: "Order",
        key: "ORDER"
    },

    INVOICE: {
        label: "Invoice",
        key: "INVOICE"
    },
    DELETED: {
        label: "Deleted",
        key: "DELETED"
    },
} as const;
