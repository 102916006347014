import { Contact } from "../../types";

export const firstName = (contact: Contact, firstName: Contact["firstName"]) => {
    if (typeof firstName !== "string") throw new Error("firstName must be a string");

    return {
        ...contact,
        firstName,
    };
};

export const lastName = (contact: Contact, lastName: Contact["lastName"]) => {
    if (typeof lastName !== "string") throw new Error("lastName must be a string");

    return {
        ...contact,
        lastName,
    };
};

export const phone1 = (contact: Contact, phone1: Contact["phone1"]) => {
    if (typeof phone1 !== "string") throw new Error("phone1 must be a string");

    return {
        ...contact,
        phone1,
    };
};

export const phone2 = (contact: Contact, phone2: Contact["phone2"]) => {
    if (typeof phone2 !== "string") throw new Error("phone2 must be a string");

    return {
        ...contact,
        phone2,
    };
};

export const email = (contact: Contact, email: Contact["email"]) => {
    if (typeof email !== "string") throw new Error("email must be a string");

    return {
        ...contact,
        email,
    };
};

export const fax = (contact: Contact, fax: Contact["fax"]) => {
    if (typeof fax !== "string") throw new Error("fax must be a string");

    return {
        ...contact,
        fax,
    };
};
