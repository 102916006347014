import React, { useEffect, useState } from "react";
import { useStore } from "../../../config/store";
import { useDialog } from "@holta/ui";
import { feathersApp } from "../../../config/feathers";
import editOrderActions from "../../../actions/editOrder";
import { fns } from "@holta/lib";
import { Text } from "@holta/ui";
import toast from "react-hot-toast";
import { errors } from "@holta/lib";
import { ModalXeroContacts, useOpenModalXeroContacts } from "../../../components/ModalXeroContacts";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";

// Types
interface XeroInvoice {
    subTotal: number;
    total: number;
    id: string;
}

// Custom hook for Xero API calls
export const useXeroApi = () => {
    
    const openModalXeroContacts = useOpenModalXeroContacts();

    const createInvoice = async (orderId: string, customerId: string): Promise<void> => {
        try {
            toast.loading("Creating Xero invoice...");
            await feathersApp.service("xero").create({
                type: "CREATE_DRAFT_XERO_INVOICE",
                id: orderId,
                customerId,
            });
            editOrderActions.loadOrder(orderId);
            toast.dismiss();
            toast.success("Xero invoice created");
        } catch (error) {
            handleErrors(error, {
                handleNoContactId: () =>
                    openModalXeroContacts(() => createInvoice(orderId, customerId)),
            });
        }
    };

    const updateInvoice = async (orderId: string, successCallback?: () => void): Promise<void> => {
        try {
            toast.loading("Updating Xero invoice...");
            await feathersApp.service("xero").create({
                type: "UPDATE_XERO_INVOICE",
                id: orderId,
            });
            toast.dismiss();
            toast.success("Xero invoice updated");
            successCallback?.();
        } catch (error) {
            handleErrors(error);
        }
    };

    const assignXeroContactToCustomer = async (
        customerId: string,
        contactId: string
    ): Promise<void> => {
        if (fns.customer.validate.xeroContactId(contactId)._isValid) {
            try {
                toast.loading("Assigning Xero contact to customer...");
                await feathersApp.service("customers").patch(customerId, {
                    xeroContactId: contactId,
                });
                toast.dismiss();
                toast.success("Xero contact assigned to customer");
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    const getXeroInvoice = async (invoiceId: string): Promise<XeroInvoice | null> => {
        try {
            return await feathersApp.service("xero").create({
                type: "GET_XERO_INVOICE",
                id: invoiceId,
            });
        } catch (error) {
            handleErrors(error);
            return null;
        }
    };

    return { createInvoice, updateInvoice, assignXeroContactToCustomer, getXeroInvoice };
};

// Custom hook for error handling
function handleErrors(
    error: any,
    options?: {
        handleNoContactId?: () => void;
    }
): void {
    toast.dismiss();

    if (error.name === errors.xeroAuthenticationError) {
        window.open(error.data, "_blank");
    } else if (error.name === errors.xeroNoContactIdError) {
        toast("No Xero contact ID found for this customer");
        options?.handleNoContactId?.();
    } else {
        toast.error(error.message || "An unexpected error occurred");
    }
}

// Custom hook for Xero authentication
const useXeroAuth = () => {
    const [isXeroAuthenticated, setIsXeroAuthenticated] = useState(false);
    const [authenticationUrl, setAuthenticationUrl] = useState<string | null>(null);

    useEffect(() => {
        let isMounted = true;

        const checkToken = async () => {
            try {
                await feathersApp.service("xero").create({ type: "TEST_XERO_CONNECTION" });
                if (isMounted) {
                    setIsXeroAuthenticated(true);
                }
            } catch (error: unknown) {
                if (error instanceof Error && error.name === errors.xeroAuthenticationError) {
                    setAuthenticationUrl((error as any).data);
                } else {
                    handleErrors(error);
                }
            }
        };

        checkToken();
        return () => {
            isMounted = false;
        };
    }, []);

    return { isXeroAuthenticated, authenticationUrl };
};

// Dialog hooks
export const useDialogRequestCreateXeroInvoice = () => {
    const { createInvoice } = useXeroApi();
    const openModalXeroContacts = useOpenModalXeroContacts();
    const editOrder = useStore((state) => state.editOrder);
    const { openGenericDialog } = useDialog();

    useEffect(() => {
        const { currentValues, requestedActions } = editOrder || {};
        const orderId = currentValues?.order?.id;
        const customerId = currentValues?.order?.customerId;

        if (
            customerId &&
            orderId &&
            requestedActions?.[0]?.type === "REQUEST_CREATE_XERO_INVOICE"
        ) {
            openGenericDialog(
                "Would you like to create a new Xero invoice?",
                "Create Xero Invoice",
                true,
                true,
                async () => {
                    try {
                        await createInvoice(orderId, customerId);
                    } catch (error) {
                        handleErrors(error, {
                            handleNoContactId: () =>
                                openModalXeroContacts(() => createInvoice(orderId, customerId)),
                        });
                    }
                }
            );
        }
    }, [editOrder]);
};

export const XeroButtons = () => {
    const editOrder = useMergedEditOrder();
    const editOrderNewValues = useStore((state) => state.editOrder?.newValues.order);
    const customer = useStore((state) => state.customers).find(
        (c) => c.id === editOrder?.customerId
    );
    const editOrderType = useStore((state) => state.editOrder?.type);
    const openModalXeroContacts = useOpenModalXeroContacts();
    const { isXeroAuthenticated, authenticationUrl } = useXeroAuth();
    const { createInvoice, updateInvoice, assignXeroContactToCustomer, getXeroInvoice } =
        useXeroApi();

    const [xeroInvoice, setXeroInvoice] = useState<XeroInvoice | null>(null);
    const [xeroInvoiceTotalMatch, setXeroInvoiceTotalMatch] = useState<boolean | null>(null);

    const orderSubTotal = editOrder
        ? parseFloat(fns.order.getTotal(editOrder, { excludeTax: true }))
        : null;
    const orderTotal = editOrder
        ? parseFloat(fns.order.getTotal(editOrder, { excludeTax: false }))
        : null;

    useEffect(() => {
        if (!isXeroAuthenticated) return;
        const fetchXeroInvoice = async () => {
            if (editOrder?.xeroInvoiceId && !xeroInvoice) {
                const invoice = await getXeroInvoice(editOrder.xeroInvoiceId);
                setXeroInvoice(invoice);
            }
        };

        fetchXeroInvoice();
    }, [editOrder?.xeroInvoiceId, xeroInvoice, isXeroAuthenticated]);

    useEffect(() => {
        if (!isXeroAuthenticated) return;
        if (!xeroInvoice) setXeroInvoiceTotalMatch(null);
        if (xeroInvoice && (orderSubTotal || orderSubTotal === 0)) {
            setXeroInvoiceTotalMatch(
                xeroInvoice.subTotal === orderSubTotal && xeroInvoice.total === orderTotal
            );
        }
    }, [xeroInvoice, orderSubTotal, orderTotal, isXeroAuthenticated]);

    if (!editOrder || !editOrder.customerId) return null;

    const canSendToXero =
        (!editOrderNewValues || Object.keys(editOrderNewValues).length === 0) &&
        editOrderType === "EDIT";

    console.log(isXeroAuthenticated, authenticationUrl);
    if (authenticationUrl) {
        return (
            <button
                className="whitespace-nowrap btn tertiary"
                onClick={() => window.open(authenticationUrl, "_blank")}
            >
                Login to Xero
            </button>
        );
    }

    if (!isXeroAuthenticated) return null;

    const renderTotalMatchStatus = () => {
        if (xeroInvoiceTotalMatch === null) {
            return (
                <Text size="s" bold color="accent3">
                    [ Checking... ]
                </Text>
            );
        }
        if (xeroInvoiceTotalMatch === false) {
            return (
                <Text size="s" bold color="danger">
                    [ MISMATCHED TOTAL ]
                </Text>
            );
        }
        if (xeroInvoiceTotalMatch === true) {
            return (
                <Text size="s" bold color="confirm">
                    [ MATCHED TOTAL ]
                </Text>
            );
        }
        return null;
    };

    return (
        <>
            {(editOrder.orderStatus === "ORDER" || editOrder.orderStatus === "INVOICE") && (
                <>
                    {!customer?.xeroContactId && (
                        <button
                            className="whitespace-nowrap btn tertiary"
                            disabled={!canSendToXero}
                            onClick={() => openModalXeroContacts()}
                        >
                            Assign Xero Contact
                        </button>
                    )}

                    {customer?.xeroContactId && !editOrder.xeroInvoiceId && (
                        <button
                            className="whitespace-nowrap btn tertiary"
                            disabled={!canSendToXero}
                            onClick={() => createInvoice(editOrder.id, editOrder.customerId!)}
                        >
                            Create on Xero
                        </button>
                    )}

                    {customer?.xeroContactId && editOrder.xeroInvoiceId && (
                        <>
                            <button
                                className="flex gap-2 items-center whitespace-nowrap btn tertiary"
                                disabled={!canSendToXero}
                                onClick={() => {
                                    updateInvoice(editOrder.id, () => setXeroInvoice(null));
                                }}
                            >
                                Update on Xero
                                {renderTotalMatchStatus()}
                            </button>

                            <a
                                className="block pl-2"
                                href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${
                                    import.meta.env.VITE_XERO_ORG_SHORTCODE
                                }&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${
                                    editOrder.xeroInvoiceId
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="whitespace-nowrap btn tertiary"
                                    disabled={!canSendToXero}
                                >
                                    View on Xero
                                </button>
                            </a>
                        </>
                    )}
                </>
            )}

            <ModalXeroContacts
                handleSave={(contactId: string) =>
                    assignXeroContactToCustomer(editOrder.customerId!, contactId)
                }
            />
        </>
    );
};
