import React from "react";
import {
    ModalHeader,
    Modal,
    ModalBody,
    ModalFooter,
    useModal,
    Spacer,
    Island,
    Text,
} from "@holta/ui";

const changelogVersion = 202;

export const ChangelogNotification = () => {
    const { openModal, closeModal } = useModal();
    React.useEffect(() => {
        if (
            !window?.localStorage?.getItem("changelog") ||
            Number(window.localStorage.getItem("changelog"))! < changelogVersion
        ) {
            window.localStorage.setItem("changelog", changelogVersion.toString());
            openModal("MODAL_CHANGELOG");
        }
    }, []);
    return null;

    return (
        <Modal id="MODAL_CHANGELOG" style={{ width: "600px" }}>
            <>
                <ModalHeader>
                    <h3>Updates</h3>
                </ModalHeader>
                <ModalBody>
                <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            
                            <Text size="l" bold>
                                Xero Inter
                            </Text>
                            <br />
                            <Text size="m">• Fixed deleted order items reappearing when items are sorted.</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal("MODAL_CHANGELOG")}>Close</button>
                </ModalFooter>
            </>
        </Modal>
    );
};
