import { CarcaseOrderItem, OrderItem, Product } from "../../types";
import {getPrice as getProductPrice} from "../product/getPrice";
import {v4 as uuid} from 'uuid';
import { ProductComponent } from "../../types/productComponent";

export function createCarcaseItemFromProduct(product : Product, components: ProductComponent[], discount: number, existingItem?: CarcaseOrderItem) : CarcaseOrderItem{
    
    return {
        code: product.code,
        d: product.d,
        dateCreated: existingItem?.dateCreated || new Date().getTime(),
        dEdited: existingItem?.dEdited ||  null,
        description: product.description,
        discount: discount,
        h: product.h,
        handing: existingItem?.handing || null,
        hEdited: existingItem?.hEdited || null,
        id:  existingItem?.id || uuid(),
        linkProducts: product.linkProducts.filter(lp => product.activeLinkProductGroups.includes(lp.constructionType)),
        notes: existingItem?.notes || '',
        position: existingItem?.position || "",
        price: getProductPrice(product, components),
        productId: product.id,
        quantity: 1,
        soldByM2: product.soldByM2,
        sortOrder: existingItem?.sortOrder || null,
        specification: product.specification,
        w: product.w,
        wEdited: existingItem?.wEdited || null,
        type: 'CARCASE',
    }
}
