import {Order} from '../../types';
import {getProductionWeek} from './getProductionWeek';
import {formatDate} from '../shared/formatDate';
import {productionDateTypes} from '../../static_data/productionDateTypes';


/**
 * 
 * Returns a readable version of an orders production date / date type
 * 
 * @param  {Order} order
 * @returns string
 */

export function formatProductionDate(order: Order) : string {

    if (order.productionDateType == productionDateTypes.NONE.key || !order.productionDateType || !order.productionDate) {
        return "";
    } 
    if (order.productionDateType == productionDateTypes.WEEK.key) {
        return "Week " + getProductionWeek(order);
    }
    if (order.productionDateType == productionDateTypes.DAY.key) {
        return formatDate(order.productionDate)
    }
    return "";
}

