import { isManuallyAddedSupplierItem } from '../order_item/isManuallyAddedSupplierItem';
import {Order, CarcaseOrderItem, SupplierOrderItem} from '../../types';
import { isLinkedDoorItem } from '../order_item/isLinkedDoorItem';

/**
 * 
 * Returns an array of IDs for all suppliers used in the order
 * 
 * @param  {Order} order
 * @returns string
 */

export function getSupplierIds(order: Order): string[] {
    const suppliers = new Set<string>();

    order.items.forEach(item => {
        if(isManuallyAddedSupplierItem(item)) {
            suppliers.add(item.supplierId)
        }
        if(isLinkedDoorItem(item) && order.hasDoors) {
            suppliers.add(item.supplierId)
        }
    })
    return Array.from(suppliers);
}