import {formatDate} from './formatDate';
import {getDiscountPrice} from './getDiscountPrice';
import {getMarginPrice} from './getMarginPrice';
import {getQuantityPrice} from './getQuantityPrice';
import {isValidPostcode} from './isValidPostcode';
import {sortByNumerical} from './sortByNumerical'; 
import {updateArrayItems} from './updateArrayItems';
import {hasProperty} from './hasProperty';
import {sortByString} from './sortByString';
import {filterByString} from './filterByString';
import {filterById} from './filterById';
import {formatPrice} from './formatPrice';
import {filterOutArchived} from './filterOutArchived';
import { naturalSort } from "./naturalSort";
import * as formSelect from './formSelect';

export default {
    updateArrayItems,
    formatDate,
    hasProperty,
    getDiscountPrice,
    getMarginPrice,
    getQuantityPrice,
    isValidPostcode,
    sortByNumerical,
    sortByString,
    filterByString,
    filterById,
    formSelect,
    formatPrice,
    naturalSort,
    filterOutArchived
}