import { OrderItem } from "../../types";
import { removeMissingLinkedDoorItems } from "./removeMissingLinkedDoorItems";
import Decimal from "decimal.js";
import {getOrderItemTotal} from "../order_item/getOrderItemTotal";

export function getAllOrderItemsTotal(orderItems : OrderItem[] ) : string {

    const filtererd = removeMissingLinkedDoorItems(orderItems);
    let total = new Decimal(0);

    filtererd.forEach(item => {
        total = total.add(getOrderItemTotal(item));
    });

    return total.toFixed(2);

}