import {Category} from '../../types';
/**
 * 
 * Returns an array of categories descendent from a specific category
 * 
 * @param  {Category[]} categories
 * @param  {string} parentId
 * @param  {returnChildren} returnChildren=false
 * @returns Category
 */

export function getChildren(categories : Category[], parentId : string) : Category[] {

    let childIdList : string[] = [];

    function getChildren(categories : Category[], parentId : string) {

        let children = categories.filter(child => {
            return  parentId === child.parentId;
        });

        if (children.length > 0) {
            children.forEach(child => {
                
                childIdList.push(child.id);
                getChildren(categories, child.id);
            });
        }
    }

    getChildren(categories, parentId);

    return categories.filter(category => childIdList.includes(category.id));
    
}
