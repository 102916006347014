export const carcaseColours = [
    {
        structure: "TBC",
        code: "TBC",
        label: "TBC",
        price_variation: 0
    },
    {
        structure: "Various",
        code: "Various",
        label: "Various",
        price_variation: 0
    },
    {
        structure: "H1137",
        code: "ST12",
        label: "Black-Brown Sorano Oak",
        price_variation: 0
    },
    {
        structure: "H1277",
        code: "ST9",
        label: "Light Lakeland Acacia",
        price_variation: 0
    },
    {
        structure: "H1319",
        code: "ST12",
        label: "Light Calais Oak",
        price_variation: 0
    },
    {
        structure: "H1334",
        code: "ST9",
        label: "Light Sorano Oak",
        price_variation: 0
    },
    {
        structure: "H1342",
        code: "ST12",
        label: "Natural Helena Oak",
        price_variation: 0
    },
    {
        structure: "H1381",
        code: "ST12",
        label: "Winchester Oak",
        price_variation: 0
    },
    {
        structure: "H1511",
        code: "ST15",
        label: "Bavarian Beech",
        price_variation: 0
    },
    {
        structure: "H1521",
        code: "ST15",
        label: "Maple",
        price_variation: 0
    },
    {
        structure: "H1582",
        code: "ST15",
        label: "Ellmau Beech",
        price_variation: 0
    },
    {
        structure: "H1615",
        code: "ST9",
        label: "Verona Cherry",
        price_variation: 0
    },
    {
        structure: "H1713",
        code: "ST9",
        label: "Grey Brown Toronto Walnu",
        price_variation: 0
    },
    {
        structure: "H1733",
        code: "ST9",
        label: "Mainau Birch",
        price_variation: 0
    },
    {
        structure: "H1869",
        code: "ST9",
        label: "Natural Canadian Maple",
        price_variation: 0
    },
    {
        structure: "H3031",
        code: "ST9",
        label: "Dark Cordoba Olive",
        price_variation: 0
    },
    {
        structure: "H3368",
        code: "ST9",
        label: "Natural Lancaster Oak",
        price_variation: 0
    },
    {
        structure: "H3382",
        code: "ST9",
        label: "Light Winchester Oak",
        price_variation: 0
    },
    {
        structure: "H3703",
        code: "ST15",
        label: "Natural Aida Walnut",
        price_variation: 0
    },
    {
        structure: "H3704",
        code: "ST15",
        label: "Tobacco Aida Walnut",
        price_variation: 0
    },
    {
        structure: "H3734",
        code: "ST9",
        label: "Natural Dijon Walnut",
        price_variation: 0
    },
    {
        structure: "H3840",
        code: "ST9",
        label: "Natural Mandal Maple",
        price_variation: 0
    },
    {
        structure: "H3911",
        code: "ST9",
        label: "Natural Tauern Beech",
        price_variation: 0
    },
    {
        structure: "U100",
        code: "ST9",
        label: "Mussel",
        price_variation: 0
    },
    {
        structure: "U104",
        code: "ST9",
        label: "Alabaster White",
        price_variation: 0
    },
    {
        structure: "U108",
        code: "ST9",
        label: "Vanilla Yellow",
        price_variation: 0
    },
    {
        structure: "U156",
        code: "ST9",
        label: "Sand Beige",
        price_variation: 0
    },
    {
        structure: "U201",
        code: "ST9",
        label: "Pebble Grey",
        price_variation: 0
    },
    {
        structure: "U215",
        code: "ST9",
        label: "Magnolia",
        price_variation: 0
    },
    {
        structure: "U222",
        code: "ST9",
        label: "Crema Beige",
        price_variation: 0
    },
    {
        structure: "U507",
        code: "ST9",
        label: "Smoke Blue",
        price_variation: 0
    },
    {
        structure: "U540",
        code: "ST9",
        label: "Denim Blue",
        price_variation: 0
    },
    {
        structure: "U599",
        code: "ST9",
        label: "Indigo Blue",
        price_variation: 0
    },
    {
        structure: "U636",
        code: "ST9",
        label: "Fjord Green",
        price_variation: 0
    },
    {
        structure: "U702",
        code: "ST9",
        label: "Cashmere Grey",
        price_variation: 0
    },
    {
        structure: "U708",
        code: "ST9",
        label: "Light Grey",
        price_variation: 0
    },
    {
        structure: "U717",
        code: "ST9",
        label: "Dakar Grey",
        price_variation: 0
    },
    {
        structure: "U727",
        code: "ST9",
        label: "Stone Grey",
        price_variation: 0
    },
    {
        structure: "U732",
        code: "ST9",
        label: "Dust Grey",
        price_variation: 0
    },
    {
        structure: "U741",
        code: "ST9",
        label: "Lava Grey",
        price_variation: 0
    },
    {
        structure: "U763",
        code: "ST9",
        label: "Pearl Grey",
        price_variation: 0
    },
    {
        structure: "U765",
        code: "ST9",
        label: "Silver Grey",
        price_variation: 0
    },
    {
        structure: "U775",
        code: "ST9",
        label: "White Grey",
        price_variation: 0
    },
    {
        structure: "U795",
        code: "ST9",
        label: "Brown Grey",
        price_variation: 0
    },
    {
        structure: "U899",
        code: "ST9",
        label: "Cosmos Grey",
        price_variation: 0
    },
    {
        structure: "U960",
        code: "ST9",
        label: "Onyx Grey",
        price_variation: 0
    },
    {
        structure: "U961",
        code: "ST2",
        label: "Graphite Grey",
        price_variation: 0
    },
    {
        structure: "U999",
        code: "ST2",
        label: "Black",
        price_variation: 0
    },
    {
        structure: "W1000",
        code: "ST9",
        label: "Premium White",
        price_variation: 0
    },
    {
        structure: "W1100",
        code: "ST9",
        label: "Alpine White",
        price_variation: 0
    },
    {
        structure: "W1200",
        code: "ST9",
        label: "Porcelain White",
        price_variation: 0
    },
    {
        structure: "W1300",
        code: "ST9",
        label: "Glacier White",
        price_variation: 0
    },
    {
        structure: "W980",
        code: "SM",
        label: "Platinum White",
        price_variation: 0
    },
    {
        structure: "W980",
        code: "ST2",
        label: "Platinum White",
        price_variation: 0
    },
    {
        structure: "F040",
        code: "",
        label: "Finsa White Gloss",
        price_variation: 7
    },
    {
        structure: "H1145",
        code: "ST10",
        label: "Natural Bardolino Oak",
        price_variation: 7
    },
    {
        structure: "H1146",
        code: "ST10",
        label: "Grey Bardolino Oak",
        price_variation: 7
    },
    {
        structure: "H1158",
        code: "ST10",
        label: "Truffle Bardolino Oak",
        price_variation: 7
    },
    {
        structure: "H1298",
        code: "ST22",
        label: "Sand Lyon Ash",
        price_variation: 7
    },
    {
        structure: "H1372",
        code: "ST22",
        label: "Natural Aragon Oak",
        price_variation: 7
    },
    {
        structure: "H1474",
        code: "ST22",
        label: "White Avola",
        price_variation: 7
    },
    {
        structure: "H1476",
        code: "ST22",
        label: "Champagne Avola",
        price_variation: 7
    },
    {
        structure: "H1477",
        code: "ST22",
        label: "Green Grey Avola",
        price_variation: 7
    },
    {
        structure: "H1478",
        code: "ST22",
        label: "Truffle Brown Avola",
        price_variation: 7
    },
    {
        structure: "H1484",
        code: "ST22",
        label: "Brown Grey Avola",
        price_variation: 7
    },
    {
        structure: "H1725",
        code: "ST10",
        label: "Sand-Beige Provincial Elm",
        price_variation: 7
    },
    {
        structure: "H3012",
        code: "ST22",
        label: "Coco Bolo",
        price_variation: 7
    },
    {
        structure: "H3058",
        code: "ST22",
        label: "Mali Wenge",
        price_variation: 7
    },
    {
        structure: "H3078",
        code: "ST22",
        label: "White Havana Pine",
        price_variation: 7
    },
    {
        structure: "H3081",
        code: "ST22",
        label: "Black Havana Pine",
        price_variation: 7
    },
    {
        structure: "H3082",
        code: "ST22",
        label: "Amazonas",
        price_variation: 7
    },
    {
        structure: "H3090",
        code: "ST22",
        label: "Shorewood",
        price_variation: 7
    },
    {
        structure: "H3131",
        code: "ST12",
        label: "Natural Davos Oak",
        price_variation: 7
    },
    {
        structure: "H3133",
        code: "ST12",
        label: "Truffle Brown Davos Oa",
        price_variation: 7
    },
    {
        structure: "H3170",
        code: "ST12",
        label: "Natural Kendal Oak",
        price_variation: 7
    },
    {
        structure: "H3171",
        code: "ST12",
        label: "Oiled Kendal Oak",
        price_variation: 7
    },
    {
        structure: "H3303",
        code: "ST10",
        label: "Natural Hamilton Oak",
        price_variation: 7
    },
    {
        structure: "H3331",
        code: "ST10",
        label: "Natural Nebraska Oak",
        price_variation: 7
    },
    {
        structure: "H3332",
        code: "ST10",
        label: "Grey Nebraska Oak",
        price_variation: 7
    },
    {
        structure: "H3700",
        code: "ST10",
        label: "Natural Pacific Walnut",
        price_variation: 7
    },
    {
        structure: "H3702",
        code: "ST10",
        label: "Tobacco Pacific Walnut",
        price_variation: 7
    },
    {
        structure: "H3710",
        code: "ST9",
        label: "Natural Carini Walnut",
        price_variation: 7
    },
    {
        structure: "F283",
        code: "ST22",
        label: "Boston Concrete",
        price_variation: 10
    },
    {
        structure: "F311",
        code: "ST87",
        label: "Ceramic Anthracite",
        price_variation: 10
    },
    {
        structure: "F425",
        code: "ST10",
        label: "Beige Line",
        price_variation: 10
    },
    {
        structure: "F426",
        code: "ST10",
        label: "Grey Line",
        price_variation: 10
    },
    {
        structure: "F447",
        code: "ST2",
        label: "Lava Grey Metallic",
        price_variation: 10
    },
    {
        structure: "F477",
        code: "ST9",
        label: "Blue-Grey Metallic",
        price_variation: 10
    },
    {
        structure: "F488",
        code: "ST2",
        label: "Quartz Cubanit",
        price_variation: 10
    },
    {
        structure: "F501",
        code: "ST2",
        label: "Brushed Aluminium",
        price_variation: 10
    },
    {
        structure: "F509",
        code: "ST2",
        label: "Aluminium",
        price_variation: 10
    },
    {
        structure: "H1122",
        code: "ST22",
        label: "Whitewood",
        price_variation: 10
    },
    {
        structure: "H1123",
        code: "ST22",
        label: "Graphitewood",
        price_variation: 10
    },
    {
        structure: "H1250",
        code: "ST36",
        label: "Navarra Ash",
        price_variation: 10
    },
    {
        structure: "H1377",
        code: "ST36",
        label: "Sand Orleans Oak",
        price_variation: 10
    },
    {
        structure: "H1379",
        code: "ST36",
        label: "Brown Orleans Oak",
        price_variation: 10
    },
    {
        structure: "H1387",
        code: "ST10",
        label: "Graphite Denver Oak",
        price_variation: 10
    },
    {
        structure: "H1399",
        code: "ST10",
        label: "Truffle Brown Denver Oa",
        price_variation: 10
    },
    {
        structure: "H1401",
        code: "ST22",
        label: "Cascina Pine",
        price_variation: 10
    },
    {
        structure: "H1486",
        code: "ST36",
        label: "Pasadena Pine",
        price_variation: 10
    },
    {
        structure: "H3048",
        code: "ST10",
        label: "Antique Brown Borneo",
        price_variation: 10
    },
    {
        structure: "H3155",
        code: "ST10",
        label: "Tobacco Charleston Oak",
        price_variation: 10
    },
    {
        structure: "H3403",
        code: "ST38",
        label: "White Mountain Larch",
        price_variation: 10
    },
    {
        structure: "H3406",
        code: "ST38",
        label: "Anthracite Mountain Larch",
        price_variation: 10
    },
    {
        structure: "H3420",
        code: "ST36",
        label: "Thermo Pine",
        price_variation: 10
    },
    {
        structure: "H3450",
        code: "ST36",
        label: "White Fleetwood",
        price_variation: 10
    },
    {
        structure: "H3451",
        code: "ST36",
        label: "Champagne Fleetwood",
        price_variation: 10
    },
    {
        structure: "H3452",
        code: "ST36",
        label: "Graphite Grey Fleetwood",
        price_variation: 10
    },
    {
        structure: "H3730",
        code: "ST10",
        label: "Natural Hickory",
        price_variation: 10
    },
    {
        structure: "H3991",
        code: "ST10",
        label: "Natural Country Beech",
        price_variation: 10
    },
    {
        structure: "U100",
        code: "ST27",
        label: "Mussel",
        price_variation: 10
    },
    {
        structure: "U104",
        code: "ST27",
        label: "Alabaster White",
        price_variation: 10
    },
    {
        structure: "U201",
        code: "ST27",
        label: "Pebble Grey",
        price_variation: 10
    },
    {
        structure: "U636",
        code: "ST27",
        label: "Fjord Green",
        price_variation: 10
    },
    {
        structure: "U702",
        code: "ST27",
        label: "Cashmere Grey",
        price_variation: 10
    },
    {
        structure: "U708",
        code: "ST27",
        label: "Light Grey",
        price_variation: 10
    },
    {
        structure: "U717",
        code: "ST27",
        label: "Dakar Grey",
        price_variation: 10
    },
    {
        structure: "U727",
        code: "ST27",
        label: "Stone Grey",
        price_variation: 10
    },
    {
        structure: "U961",
        code: "ST27",
        label: "Graphite Grey",
        price_variation: 10
    },
    {
        structure: "U999",
        code: "ST38",
        label: "Black",
        price_variation: 10
    },
    {
        structure: "W1000",
        code: "ST38",
        label: "Premium White",
        price_variation: 10
    },
    {
        structure: "W1200",
        code: "ST27",
        label: "Porcelain White",
        price_variation: 10
    },
    {
        structure: "W980",
        code: "ST27",
        label: "Platinum White",
        price_variation: 10
    },
    {
        structure: "H3025",
        code: "HG",
        label: "Macassar",
        price_variation: 20
    },
    {
        structure: "U104",
        code: "HG",
        label: "Alabaster White",
        price_variation: 20
    },
    {
        structure: "U222",
        code: "HG",
        label: "Crema Beige",
        price_variation: 20
    },
    {
        structure: "U323",
        code: "HG",
        label: "Chilli Red",
        price_variation: 20
    },
    {
        structure: "U702",
        code: "HG",
        label: "Cashmere  Grey",
        price_variation: 20
    },
    {
        structure: "U708",
        code: "HG",
        label: "Light Grey",
        price_variation: 20
    },
    {
        structure: "U727",
        code: "HG",
        label: "Stone Grey",
        price_variation: 20
    },
    {
        structure: "U732",
        code: "HG",
        label: "Dust Grey",
        price_variation: 20
    },
    {
        structure: "U763",
        code: "HG",
        label: "Pearl Grey",
        price_variation: 20
    },
    {
        structure: "U961",
        code: "HG",
        label: "Graphite Grey",
        price_variation: 20
    },
    {
        structure: "U999",
        code: "HG",
        label: "Black",
        price_variation: 20
    },
    {
        structure: "W1000",
        code: "HG",
        label: "Premium White",
        price_variation: 20
    },
    {
        structure: "W1100",
        code: "HG",
        label: "Alpine White",
        price_variation: 20
    },
    {
        structure: "H1176",
        code: "ST37",
        label: "White Halifax Oak",
        price_variation: 40
    },
    {
        structure: "H1180",
        code: "ST37",
        label: "Natural Halifax Oak",
        price_variation: 40
    },
    {
        structure: "H1181",
        code: "ST37",
        label: "Tobacco Halifax Oak",
        price_variation: 40
    },
    {
        structure: "H1793",
        code: "ST27",
        label: "Lava Savona",
        price_variation: 40
    },
    {
        structure: "H1796",
        code: "ST27",
        label: "Natural Savona",
        price_variation: 40
    },
    {
        structure: "H3309",
        code: "ST28",
        label: "Sand Gladstone Oak",
        price_variation: 40
    },
    {
        structure: "H3325",
        code: "ST28",
        label: "Tobacco Gladstone Oak",
        price_variation: 40
    },
    {
        structure: "H3326",
        code: "ST28",
        label: "Grey-Beige Gladstone Oak",
        price_variation: 40
    },
    {
        structure: "H3335",
        code: "ST28",
        label: "White Gladstone Oak",
        price_variation: 40
    },
    {
        structure: "H3342",
        code: "ST28",
        label: "Sepia Gladstone Oak",
        price_variation: 40
    },
    {
        structure: "H3760",
        code: "ST29",
        label: "White Cape Elm",
        price_variation: 40
    },
    {
        structure: "H3766",
        code: "ST29",
        label: "Dark Brown Cape El",
        price_variation: 40
    },
    {
        structure: "H3025",
        code: "PG",
        label: "Macassar",
        price_variation: 90
    },
    {
        structure: "U104",
        code: "PG",
        label: "Alabaster White",
        price_variation: 90
    },
    {
        structure: "U104",
        code: "PM",
        label: "Alabaster White",
        price_variation: 90
    },
    {
        structure: "U222",
        code: "PG",
        label: "Crema Beige",
        price_variation: 90
    },
    {
        structure: "U222",
        code: "PM",
        label: "Crema Beige",
        price_variation: 90
    },
    {
        structure: "U323",
        code: "PG",
        label: "Chilli Red",
        price_variation: 90
    },
    {
        structure: "U702",
        code: "PG",
        label: "Cashmere Grey",
        price_variation: 90
    },
    {
        structure: "U702",
        code: "PM",
        label: "Cashmere Grey",
        price_variation: 90
    },
    {
        structure: "U708",
        code: "PG",
        label: "Light Grey",
        price_variation: 90
    },
    {
        structure: "U708",
        code: "PM",
        label: "Light Grey",
        price_variation: 90
    },
    {
        structure: "U727",
        code: "PG",
        label: "Stone Grey",
        price_variation: 90
    },
    {
        structure: "U727",
        code: "PM",
        label: "Stone Grey",
        price_variation: 90
    },
    {
        structure: "U732",
        code: "PG",
        label: "Dust Grey",
        price_variation: 90
    },
    {
        structure: "U732",
        code: "PM",
        label: "Dust Grey",
        price_variation: 90
    },
    {
        structure: "U763",
        code: "PG",
        label: "Pearl Grey",
        price_variation: 90
    },
    {
        structure: "U961",
        code: "PG",
        label: "Graphite Grey",
        price_variation: 90
    },
    {
        structure: "U961",
        code: "PM",
        label: "Graphite Grey",
        price_variation: 90
    },
    {
        structure: "U999",
        code: "PG",
        label: "Black",
        price_variation: 90
    },
    {
        structure: "U999",
        code: "PM",
        label: "Black",
        price_variation: 90
    },
    {
        structure: "W1100",
        code: "PG",
        label: "Alpine White",
        price_variation: 90
    },
    {
        structure: "W1100",
        code: "PM",
        label: "Alpine White",
        price_variation: 90
    },
    {
        structure: "W1000",
        code: "PG",
        label: "Premium White",
        price_variation: 90
    },
    {
        structure: "W1000",
        code: "PM",
        label: "Premium White",
        price_variation: 90
    }
    
] as const;
