
import {v4 as uuid} from 'uuid';
import { Contact } from "../../types"
import { number } from 'yup';

export const create = (createdBy: string, parentId: string) : Contact => {

    return {
        firstName: "",
        lastName: "",
        phone1: "",
        phone2: "",
        email: "",
        fax: "",
        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        id: uuid(),
        parentId: parentId,
        deleted_at: null,
    }
    
}

