import {Category, CategoryTree, CategoryTreeItem} from '../../types';
/**
 * 
 * Denoramlises a list of categories into a tree
 * 
 * @param  {Category[]} categories
 * @returns CategoryTree
 */
export function computeTree(categories: Category[]): CategoryTree {


    const unflatten = function(categories: Category[], parentId: string|null = null): CategoryTree {

        let tree: CategoryTree = [];

        //Find all the children of the current category, then clone them to prevent mutation
        const children = categories.filter(child => {
            return child.parentId == parentId;
        }).map(child => ({...child}));


        if (children.length > 0) {
            tree = children.map(child => {
                const _child = child as CategoryTreeItem;
                _child.children = unflatten(categories, child.id);
                return _child
            });
        }

        return tree;
    };

    return unflatten(categories);
}