import {results} from './results';
import {cloneDeep} from 'lodash';

export function passIf(cb: () => boolean): void {
    try {
        const result = cb();
        if (result) {
            results.draft = cloneDeep(results.current)
        }
    }
    catch {}
    
}