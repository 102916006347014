import { getCutAndEdgeOrderItemSubtotal } from './getCutAndEdgeOrderItemSubtotal';
import { getCutAndEdgeOrderTotal } from './getCutAndEdgeOrderTotal';
import { getMergedDeliveryAddress } from './getMergedDeliveryAddress';
import {getCutAndEdgeDeliveryPrice} from './getCutAndEdgeDeliveryPrice';
import {mapCutAndEdgeToOrder} from './mapCutAndEdgeToOrder';
import {hasPoaItems} from './hasPoaItems';
import * as validate from './validate';


export default {
    getCutAndEdgeDeliveryPrice,
    getCutAndEdgeOrderItemSubtotal,
    getCutAndEdgeOrderTotal,
    getMergedDeliveryAddress,
    hasPoaItems,
    validate,
    mapCutAndEdgeToOrder
}