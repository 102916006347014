import {Decimal} from 'decimal.js';


/**
 * 
 * Returns a price with a discount applied
 * 
 * @param  {string|number} price
 * @param  {string|number} discount
 * @returns string
 */
export function getDiscountPrice(price : string | number, discount: string | number) : string {

    let _price = Number(price); // Convert price to a number or NaN
    _price = _price || 0 // If NaN, convert to 0

    const _discount =  Number(discount);

    if(!_discount) return _price.toFixed(2);
    
    const invertedDiscount = new Decimal(100).sub(_discount); 
    const decimalPrice = new Decimal(_price).div(100).mul(invertedDiscount)
    return decimalPrice.toFixed(2);
   
}