

export const compareDate = (a : number | Date | null | undefined, b : number | Date | null | undefined) : number => {

    const aDate = a instanceof Date ? a.getTime() : a;
    const bDate = b instanceof Date ? b.getTime() : b;

    if (!aDate && !bDate) return 0;
    if (aDate && !bDate) return -1;
    if (!aDate && bDate) return 1;
    if (aDate && bDate) return aDate - bDate;
    return 1;
}