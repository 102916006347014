import {create} from './create';
import * as set from './set';
import * as validate from './validate';
import {getSupplierProductPrice} from './getSupplierProductPrice';
import {createSupplierComputedProduct} from './createSupplierComputedProduct';


export default {
    create,
    set,
    validate,
    getSupplierProductPrice,
    createSupplierComputedProduct
};