
import {  ProductComponent } from "../../types";
import {v4 as uuid} from 'uuid';

export const create = (createdBy : string, fields? : Partial<ProductComponent>) : ProductComponent => {     
    return  {
        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        id: uuid(),
        code: "",
        categories: [],
        description: "",
        h: null,
        d: null,
        w: null,
        price: 0,
        margin: 0,
        discount: 0,
        priceType: "SIMPLE",
        priceExpression: null,
        specification: "",
        supplierId: null,
        image: "",
        ...fields
}};