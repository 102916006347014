import { LinkedDoorOrderItem, OrderItem } from '../../types';

/**
 * 
 * Checks if an order item is a door item
 * 
 */
export function isLinkedDoorItem(item : OrderItem[][number]) : item is LinkedDoorOrderItem {
    if(item.type === 'SUPPLIER' && item.supplierOrderItemType === 'LINKED_DOOR'){
        return true;
    }
    return false;
}


    
