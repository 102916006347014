import { Order } from '../../types/order';
import { constants } from '../../constants';
import { getOrderTotalOrEstimate } from '../order/getOrderTotalOrEstimate';
import Decimal from 'decimal.js';

export function getWeekProductionCapacity(orders: Order[]): number {
    const capacity = constants.WEEK_PRODUCTION_VALUE_CAPACITY;
    const ordersValue = orders.reduce((acc, order) => {
        return acc + Number(getOrderTotalOrEstimate(order, {excludeTax: true}));
    }, 0);
    return new Decimal(ordersValue).div(capacity).mul(100).toNumber();
}