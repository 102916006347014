import { value, verify } from "../validate";
import { SupplierProduct } from "../../types";



export const code = (product: SupplierProduct, code: SupplierProduct["code"])  : SupplierProduct => {
    if (typeof code !== "string") throw new Error("SupplierProduct code must be a string");
    return {
        ...product,
        code
    };
};


export const description = (product: SupplierProduct, description: SupplierProduct["description"])  : SupplierProduct => {
    if (typeof description !== "string") throw new Error("Description must be a string");
    return {
        ...product,
        description
    };
};


export const h = (product: SupplierProduct, h: SupplierProduct["h"])  : SupplierProduct => {
    if (typeof h !== "number" && h !== null) throw new Error("Height must be a number or null");
    return {
        ...product,
        h
    };
};

export const d = (product: SupplierProduct, d: SupplierProduct["d"])  : SupplierProduct => {
    if (typeof d !== "number" && d !== null) throw new Error("Depth must be a number or null");
    return {
        ...product,
        d
    };
};


export const w = (product: SupplierProduct, w: SupplierProduct["w"])  : SupplierProduct => {
    if (typeof w !== "number" && w !== null) throw new Error("Width must be a number or null");
    return {
        ...product,
        w
    };
};

export const price = (product: SupplierProduct, price: SupplierProduct["price"])  : SupplierProduct => {
    if (typeof price !== "number") throw new Error("Price must be a number");
    return {
        ...product,
        price
    };
}


export const dateUpdated = (product: SupplierProduct, dateUpdated: SupplierProduct["dateUpdated"])  : SupplierProduct => {
    if (typeof price !== "number") throw new Error("Price must be a number");
    return {
        ...product,
        price
    };
}


export const supplierRangeId = (product: SupplierProduct, supplierRangeId: SupplierProduct["supplierRangeId"])  : SupplierProduct => {
    verify(() => value(supplierRangeId).isUuid());
    return {
        ...product,
        supplierRangeId
    };
};

export const baseProductId = (product: SupplierProduct, baseProductId: SupplierProduct["baseProductId"])  : SupplierProduct => {
    verify(() => value(baseProductId).isUuid());
    return {
        ...product,
        baseProductId
    };
};


