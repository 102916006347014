import {Order} from '../../types';
import {isSameISOWeek} from 'date-fns';
import {isWeekDelivery} from '../order/isWeekDelivery';

export function filterWCDeliveries(orders : Order[], date? : number ) : Order[]{
    
    return orders.filter(order=> {
        if(!order.deliveryDate) return false;
        if(date) return isWeekDelivery(order) && isSameISOWeek(order.deliveryDate, date);
        return isWeekDelivery(order);
    })

}       