import {ValidationResults} from '../types';

type Results = {
    current: any[];
    draft: any[];
}

export const results: Results = {
    current: [],
    draft: [],
};
