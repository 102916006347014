import { taxGroups } from "../../static_data/taxGroups";
import { Supplier } from "../../types";
import { value } from "../validate";

export function name(name: string): string;
export function name(name: string, supplier?: Supplier): Supplier;
export function name(name: string, supplier?: Supplier): Supplier | string {
    // This will trow if the name is not valid
    value(name).isString().isNotWhitespace();

    if (supplier) {
        return {
            ...supplier,
            name,
        };
    }
    return name;
}



// set Notes
export function notes(notes: string): string;
export function notes(notes: string, supplier?: Supplier): Supplier;
export function notes(notes: string, supplier?: Supplier): Supplier | string {
    // This will trow if the notes is not valid
    value(notes).isString();

    if (supplier) {
        return {
            ...supplier,
            notes,
        };
    }
    return notes;
}

export function defaultContact(defaultContact: string | null): string | null;
export function defaultContact(defaultContact: string | null, supplier?: Supplier): Supplier;
export function defaultContact(
    defaultContact: string | null,
    supplier?: Supplier
): Supplier | string | null {
    if (defaultContact !== null) {
        // This will trow if the defaultContact is not valid
        value(defaultContact).isUuid();
    }

    if (supplier) {
        return {
            ...supplier,
            defaultContact,
        };
    }
    return defaultContact;
}


export function discount(discount: number): number;
export function discount(discount: number, supplier?: Supplier): Supplier;
export function discount(discount: number, supplier?: Supplier): Supplier | number {
    // This will trow if the discount is not valid
    value(discount).isNumber();

    if (supplier) {
        return {
            ...supplier,
            discount,
        };
    }
    return discount;
}


export function margin(margin: number): number;
export function margin(margin: number, supplier?: Supplier): Supplier;
export function margin(margin: number, supplier?: Supplier): Supplier | number {
    // This will trow if the margin is not valid
    value(margin).isNumber();

    if (supplier) {
        return {
            ...supplier,
            margin,
        };
    }
    return margin;
}


export function lastImport(lastImport: number): number;
export function lastImport(lastImport: number, supplier?: Supplier): Supplier;
export function lastImport(lastImport: number, supplier?: Supplier): Supplier | number {
    // This will trow if the lastImport is not valid
    value(lastImport).isNumber();

    if (supplier) {
        return {
            ...supplier,
            lastImport,
        };
    }
    return lastImport;
}



export function matchedImportHeadings(matchedImportHeadings: Supplier['matchedImportHeadings']): Supplier['matchedImportHeadings'];
export function matchedImportHeadings(matchedImportHeadings: Supplier['matchedImportHeadings'], supplier?: Supplier): Supplier;
export function matchedImportHeadings(matchedImportHeadings: Supplier['matchedImportHeadings'], supplier?: Supplier): Supplier | Supplier['matchedImportHeadings'] {
    // This will trow if the matchedImportHeadings is not valid

    if (supplier) {
        return {
            ...supplier,
            matchedImportHeadings,
        };
    }
    return matchedImportHeadings;
}

