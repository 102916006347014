import React from "react";
import styled from "styled-components";
import { useDialog } from "../dialog";
import { useLoading } from "../loading";
import { useModal } from "../modal";

const Background = styled.div`
    
    z-index: 1000;
    position: fixed;
    animation-name: overlayFadeOut;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;

    @keyframes overlayFadeOut {
        0% {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.8);
        }
        99% {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0);
        }
        100% {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    @keyframes overlayFadeIn {
            from {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0);
            }
            to {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

    &.isOpen {
        animation-name: overlayFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
    }
`;

export const Overlay = (): React.ReactElement | null => {
    const { isLoadingOpen } = useLoading();
    const { isDialogOpen } = useDialog();
    const { isModalOpen } = useModal();

    const getClassName = (): string => {
        if (isLoadingOpen() || isDialogOpen() || isModalOpen()) return "isOpen";
        return "";
    };

    return <Background className={getClassName()} />;
};
