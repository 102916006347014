import React from 'react';
import { Theme } from '../../theme';
import styled from 'styled-components';

interface Props {
    color: keyof Theme['color'],
    onClick? : () => void,
}

export const Notice : React.FC<Props> = ({color, children, onClick}) =>{

    return (
        <Wrapper color={color} onClick={onClick} className={onClick ? 'isClickable' : ''}>
            {children}
        </Wrapper>
    )

};

const Wrapper = styled.div<{color: keyof Theme['color']}>`
    border-radius: 4px;
    background-color: ${(p) => p.theme.color[p.color]};
    ${p => p.theme.type.s};
    padding: ${p => p.theme.spacing(1)};
    color: white;
    transition: all 0.2s;

    &.isClickable:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`

