import { Product } from "../../types/product";

export function merge(base:Product, changes:Partial<Product>, options?: { replace?: boolean }) : Product {

    const mergedProduct = { ...base, ...changes };

    // if replace is true, overrite the base product with the changes
    if(options?.replace) return mergedProduct;

    // If merging, merge the categories 
    if(changes?.categories) {
        const categoriesSet = new Set(...base.categories);
        changes.categories.forEach(c => {
            categoriesSet.add(c);
        });
        mergedProduct.categories = Array.from(categoriesSet);
    }

    // merge the components
    if(changes?.components) {
        const componentsMap = new Map(base.components.map(c => [c.id, c]));
        changes.components.forEach(c => {
            componentsMap.set(c.id, c);
        });
        mergedProduct.components = Array.from(componentsMap.values());
    }

    // merge the customerWhitelistIds
    if(changes?.customerWhitelistIds) {
        const customerWhitelistIdsSet = new Set(base.customerWhitelistIds);
        changes.customerWhitelistIds.forEach(c => {
            customerWhitelistIdsSet.add(c);
        });
        mergedProduct.customerWhitelistIds = Array.from(customerWhitelistIdsSet);
    }

    // merge the linkProducts
    if(changes?.linkProducts) {
        const linkProductsMap = new Map(base.linkProducts.map(c => [c.id, c]));
        changes.linkProducts.forEach(c => {
            linkProductsMap.set(c.id, c);
        });
        mergedProduct.linkProducts = Array.from(linkProductsMap.values());
    }

    // merge the activeLinkProductGroups
    if(changes?.activeLinkProductGroups) {
        const activeLinkProductGroupsSet = new Set<Product['activeLinkProductGroups'][number]>(base.activeLinkProductGroups);
        changes.activeLinkProductGroups.forEach(c => {
            activeLinkProductGroupsSet.add(c);
        });
        mergedProduct.activeLinkProductGroups = Array.from(activeLinkProductGroupsSet);
    }


    return mergedProduct;
}