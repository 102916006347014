
import Decimal from 'decimal.js'
import {CutAndEdgeOrderMFCGroup} from '../../types'
import { findMFCColour } from './findMFCColour'
import { findMFCPricingGroup } from './findMFCPricingGroup'
import { getMFCM2Price } from './getMFCM2Price'
import { getCutItemPrice } from './getCutItemPrice'
import { getMFCEdgingPrice } from './getMFCEdgingPrice';
import {applyMFCThicknessSurcharge} from './applyMFCThicknessSurcharge';
import { mfcColours } from '../../static_data/mfcColours';

export const getMFCOrderGroupPrice = (boardGroup : CutAndEdgeOrderMFCGroup) : number => {


    // Get the board m2 price
    const m2Price = getMFCM2Price(boardGroup.mfcGroup.price);


    // Get the edging cost or return 0
    let edgingPrice = 0;
    if((boardGroup.edgingType === '_08' || boardGroup.edgingType === '_20') && boardGroup.edgingColour && boardGroup.edgingGroup) {
        edgingPrice = getMFCEdgingPrice(boardGroup.edgingGroup, boardGroup.edgingType);
    }

    let total = new Decimal(0);

    boardGroup.cuts.forEach(cut => {
        const cutTotal = getCutItemPrice(m2Price, edgingPrice, cut);
        total = total.add(cutTotal);
    });

    return applyMFCThicknessSurcharge(total.toNumber(), boardGroup.type);
    
}


