import {constants} from '../../constants';

import Decimal from "decimal.js";


export const getCutItemBoardPrice = (height : number, width : number, boardM2Price : number) : number => {

    let mm2 = new Decimal(height).mul(width).toNumber();

    if(mm2 < constants.MFC_BOARD_MINIMUM_CHARGABLE_AREA) mm2 = constants.MFC_BOARD_MINIMUM_CHARGABLE_AREA;

    const price = new Decimal(mm2).div(1000000).mul(boardM2Price);

    if(price.isNaN()) throw new Error("Board price is not a number");

    return price.toNumber()
}


