import React from 'react';
import styled from 'styled-components';
import useResizeObserver from "use-resize-observer";

interface Props {
    position : "top" | "bottom" | "left" | "right",
    showSpacer? : boolean,
    children: React.ReactNode,
    style? : React.CSSProperties,
    className? : string
}

const Container = styled.section<Pick<Props, "position">>`
    position: fixed;
    top: ${props => getTopValue(props.position)};
    bottom: ${props => getBottomValue(props.position)};
    left: ${props => getLeftValue(props.position)};
    right: ${props => getRightValue(props.position)};
`;

const Spacer = styled.div<{w: number, h: number}>`
    width : ${props => props.w + 'px'};
    height : ${props => props.h + 'px'};
`;

function getTopValue(position: Props['position']) : 0 | 'initial' {
    if (['top', 'left', 'right'].includes(position)) return 0;
    return 'initial';
}
function getBottomValue(position: Props['position']) : 0 | 'initial' {
    if (['bottom', 'left', 'right'].includes(position)) return 0;
    return 'initial';
}
function getLeftValue(position: Props['position']) : 0 | 'initial' {
    if (['top', 'left', 'bottom'].includes(position)) return 0;
    return 'initial';
}
function getRightValue(position: Props['position']) : 0 | 'initial' {
    if (['top', 'bottom', 'right'].includes(position)) return 0;
    return 'initial';
}

/**
 * Adds a fixed container to any edge of a page with a spacer added below, sized correctly
 */
export function FixedContainer({position, showSpacer = false, children, style, className} : Props) : React.ReactElement {

    const { ref, width = 1, height = 1 } = useResizeObserver();
    
    return (
        <>
            <Container ref={ref} position={position} style={style} className={className}>
                {children}
            </Container>
            {showSpacer && <Spacer w={width} h={height}/> }
        </>
    );
}; 