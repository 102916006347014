import { AddressFields, Address } from "../../types/address";

export function hasFieldValues(address: Address | AddressFields) {
    return (
        address.addressLine1 !== "" ||
        address.addressLine2 !== "" ||
        address.addressLine3 !== "" ||
        address.city !== "" ||
        address.country !== "" ||
        address.county !== "" ||
        address.postcode !== ""
    );
}
