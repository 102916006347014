import {v4 as uuid} from 'uuid';
import {SupplierProduct} from '../../types';


export const create = (supplierId:string, fields? : Partial<SupplierProduct>) : SupplierProduct  => {
    return {
        id: uuid(),
        code: '',
        supplierId,
        description: '',
        h: null,
        w: null,
        d: null,
        price: 0,
        dateCreated: new Date().getTime(),
        dateUpdated: null,
        baseProductId: null,
        supplierRangeId: null,
        ...fields
    }
}





