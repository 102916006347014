import { test, testInPlace, verify, passIf, value, arrayOf } from "../validate";
import { SupplierRange } from "../../types";
import { result } from "lodash";

export const name = test((name: SupplierRange["name"], existingNames: string[]) => {
    verify(() => value(name).isString().isNotWhitespace().isNotEmpty().isNotOneOf(existingNames));
});

export const supplierId = test((supplierId: SupplierRange["supplierId"]) => {
    verify(() => value(supplierId).isUuid());
});

export const prefix = test((prefix: SupplierRange["prefix"], exisitingPrefixes: string[]) => {
    verify(() => value(prefix).isString().isNotWhitespace().isNotEmpty().isNotOneOf(exisitingPrefixes));
});

export const discount = test((discount: SupplierRange["discount"]) => {
    if (discount !== null) verify(() => value(discount).isNumber());
});

export const margin = test((margin: SupplierRange["margin"]) => {
    if (margin !== null) verify(() => value(margin).isNumber());
});

export const notes = test((notes: SupplierRange["notes"]) => {
    verify(() => value(notes).isString());
});

export const subDiscountBaseProduct = test(
    (
        subDiscountBaseProduct: SupplierRange["subDiscounts"][number]["baseProducts"][number],
        usedBaseProducts: string[]
    ) => {
        verify(() => value(subDiscountBaseProduct).isNotOneOf(usedBaseProducts));
    }
);

export const subDiscount = test(
    (subDiscount: SupplierRange["subDiscounts"][number], usedBaseProducts: string[]) => {
        return {
            discount: discount(subDiscount.discount),
            margin: margin(subDiscount.margin),
            baseProducts: testInPlace(() => ({
                items: arrayOf(subDiscount.baseProducts, (bp) =>
                    subDiscountBaseProduct(bp, usedBaseProducts)
                ),
            })),
        };
    }
);

export const supplierRange = test((supplierRange: SupplierRange, otherRanges: SupplierRange[]) => {
    return {
        name: name(
            supplierRange.name,
            otherRanges.map((r) => r.name)
        ),
        supplierId: supplierId(supplierRange.supplierId),
        prefix: prefix(
            supplierRange.prefix,
            otherRanges.map((r) => r.prefix)
        ),
        discount: discount(supplierRange.discount),
        margin: margin(supplierRange.margin),
        notes: notes(supplierRange.notes),
        subDiscounts: testInPlace(() => {
            const usedBaseProducts: string[] = [];

            return {
                items: arrayOf(supplierRange.subDiscounts, (sd) => {
                    const result = subDiscount(sd, usedBaseProducts);
                    usedBaseProducts.push(...sd.baseProducts);
                    return result;
                }),
            };
        }),
    };
});
