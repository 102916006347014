import {Order} from '../../types';

export const getLatestDate = (order: Order) : number => {
    if (order.orderStatus === 'ORDER') {
        return order.dateOrdered!;
    }
    if (order.orderStatus === 'INVOICE') {
        return order.dateInvoiced!;
    }
    if (order.orderStatus === 'QUOTE') {
        return order.dateCreated;
    }
    return 0;
}