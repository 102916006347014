
import {create} from './create';
import {getPrice} from './getPrice';
import * as set from './set';
import * as validate from './validate';
import { getComponentsTotal } from './getComponentsTotal';
import { getComponentTotal } from "./getComponentTotal";
import { merge } from './merge';
export default {
    create,
    getPrice,
    set,
    validate,
    getComponentTotal,
    getComponentsTotal,
    merge
}

