import { constants } from "../../constants";
import Decimal from "decimal.js";
import { EdgingTypes, SheetMaterial } from "../../types";

export const getSheetMaterialEdgingPrice = (price : number | null, type : Exclude<keyof EdgingTypes, "_08" | "_20" >) : number => {

    if(type === 'NONE') return 0;
    if(!price) return 0;

    const edgingMargin = constants.SHEET_MATERIAL_EDGING_MARGIN;

    const total = new Decimal(price).mul(edgingMargin);

    if(total.isNaN()) throw new Error("Edging cost is not a number");
    
    return total.toNumber();
}
