import { Address, AddressFields } from "../../types";

export const name = (address: Address, name: Address["name"]): Address => {
    if (typeof name !== "string") throw new Error("Name must be a string");

    return {
        ...address,
        name,
    };
};

export const addressLine1 = <Ad extends Address | AddressFields>(address: Ad, addressLine1: Address["addressLine1"]): Ad => {
    if (typeof addressLine1 !== "string") throw new Error("Address line 1 must be a string");

    return {
        ...address,
        addressLine1,
    };
};

export const addressLine2 = <Ad extends Address | AddressFields>(address: Ad, addressLine2: Address["addressLine2"]): Ad => {
    if (typeof addressLine2 !== "string") throw new Error("Address line 2 must be a string");

    return {
        ...address,
        addressLine2,
    };
};

export const addressLine3 = <Ad extends Address | AddressFields>(address: Ad, addressLine3: Address["addressLine3"]): Ad => {
    if (typeof addressLine3 !== "string") throw new Error("Address line 3 must be a string");

    return {
        ...address,
        addressLine3,
    };
};

export const city = <Ad extends Address | AddressFields>(address: Ad, city: Address["city"]): Ad => {
    if (typeof city !== "string") throw new Error("city must be a string");

    return {
        ...address,
        city,
    };
};

export const county = <Ad extends Address | AddressFields>(address: Ad, county: Address["county"]): Ad => {
    if (typeof county !== "string") throw new Error("county must be a string");

    return {
        ...address,
        county,
    };
};

export const country = <Ad extends Address | AddressFields>(address: Ad, country: Address["country"]): Ad => {
    if (typeof country !== "string") throw new Error("country must be a string");

    return {
        ...address,
        country,
    };
};

export const postcode = <Ad extends Address | AddressFields>(address: Ad, postcode: Address["postcode"]): Ad => {
    if (typeof postcode !== "string") throw new Error("postcode must be a string");

    return {
        ...address,
        postcode,
    };
};

export const note = (address: Address, note: Address["note"]): Address => {
    if (typeof note !== "string") throw new Error("note must be a string");

    return {
        ...address,
        note,
    };
};
