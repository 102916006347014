export function updateArrayItems<ArrayItem extends Object>(
	array: Array<ArrayItem>,
    matchCriteria: Partial<ArrayItem>,
	updates: Partial<ArrayItem>
): Array<ArrayItem> {
	return array.map((item) => {
		if (Object.keys(matchCriteria).every((key) => item[key as keyof ArrayItem] === matchCriteria[key as keyof ArrayItem])) {
            return {
                ...item,
                ...updates
            };
        }
		return item;
	});
}
