import styled, { DefaultTheme } from 'styled-components';


const getFixedSize = (size : string) => `
    width: ${size};
    height: ${size};
    font-size: ${parseInt(size)/2}${size.replace(/[^a-zA-Z]+/g, '')};
`;

const getDynamicSize = (theme : DefaultTheme, size? : number ) => `
    width: ${size ? theme.spacing(size) : theme.spacing(5)};
    height: ${ size ? theme.spacing(size) : theme.spacing(5)};
`;


export const CircleButton = styled.button<{size? : number | string}>`
    padding: 0px !important;
    border: none  !important;
    box-shadow:  0 1px 4px 0 rgba(12, 12, 13, 0.3);
    background-color: ${({theme}) => theme.color.base_contrast}  !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px  !important;
    ${({size, theme}) => typeof size == 'string' ? getFixedSize(size) : getDynamicSize(theme, size)};
    
    
`;