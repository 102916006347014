import { LinkedDoorOrderItem, SupplierOrderItem } from "../../types";
import { supplierProductTypes } from "../../static_data/supplierProductTypes";
import { isEqual } from "lodash";

/**
 *
 * Condenses an array of supplier items down to an array of each distinct product, with appropriate quantities then applied
 *
 * @param  {SupplierOrderItem[]} items
 * @returns SupplierOrderItem
 */
export function condenseSupplierItems(
    items: (SupplierOrderItem | LinkedDoorOrderItem)[]
): (SupplierOrderItem | LinkedDoorOrderItem)[] {
    const condensedItems: (SupplierOrderItem | LinkedDoorOrderItem)[] = [];

    for (const item of items) {
        const matchedItem = condensedItems.find((condensedItem) => {
            if (
                condensedItem.supplierProductId === item.supplierProductId &&
                condensedItem.discount === item.discount
            )
                return true;
            return false;
        });

        if (matchedItem) {
            const _item = { ...item, supplierOrderItemType: null };
            _item.quantity = Number(matchedItem.quantity) + Number(item.quantity);
            condensedItems[condensedItems.indexOf(matchedItem)] = _item;
        } else {
            condensedItems.push({ ...item, supplierOrderItemType: null });
        }
    }

    return condensedItems;
}

