import { isMissingLinkedDoorItem } from "../order_item/isMissingLinkedDoorItem";
import {NotMissingOrderItem, OrderItem} from "../../types";

export const removeMissingLinkedDoorItems = (items: OrderItem[]): NotMissingOrderItem[] => {
    const filtered = items.filter(item => {
        if(isMissingLinkedDoorItem(item)) return false;
        return true;
    });    

    return filtered as NotMissingOrderItem[];
}