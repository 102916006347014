import {Category} from '../../types';
import {getHeirachicalName} from './getHeirachicalName';

export function formatAdvancedSelectValue(category: Category|false, categories : Category[]) : {label : string, value : string} | null {
    return category
        ?   {
                label: getHeirachicalName(category, categories),
                value: category.id
            }
        : null;
}