export const productionDateTypes = {
    NONE: {
        label: "None",
        key: "NONE"
    },
    WEEK: {
        label: "Week",
        key: "WEEK"
    },
    DAY: {
        label: "Day",
        key: "DAY"
    },
};
