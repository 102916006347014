export const deliveryDateTypes = {
    NONE : {
        label : 'None',
        key: 'NONE'
    },
    ASAP :{
        label : 'ASAP',
        key: 'ASAP'
    },
    WEEK : {
        label : 'Week',
        key: 'WEEK'
    },
    DAY : {
        label : 'Day',
        key: 'DAY'
    }
} as const;
