import {EditedNotMissingOrderItem, OrderItem, NotMissingOrderItem, CarcaseOrderItem, EditedCarcaseOrderItem} from '../../types';
import { isMissingLinkedDoorItem } from './isMissingLinkedDoorItem';
import * as validate from './validate';



export function discount (discount: NotMissingOrderItem['discount']): NotMissingOrderItem['discount']
export function discount (discount: NotMissingOrderItem['discount'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function discount (discount: NotMissingOrderItem['discount'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['discount']{

    if(!validate.discount(discount)._isValid) throw new Error("Invalid discount");
    if(item){
        return {
            ...item,
            discount,
        };
    } else {
        return discount;
    }
}

export function price (price: NotMissingOrderItem['price']): NotMissingOrderItem['price']
export function price (price: NotMissingOrderItem['price'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function price (price: NotMissingOrderItem['price'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['price']{

    if(!validate.price(price)._isValid) throw new Error("Invalid price");
    
    if(item){
        return {
            ...item,
            price,
        };
    } else {
        return price;
    }
}

export function quantity (quantity: NotMissingOrderItem['quantity']): NotMissingOrderItem['quantity']
export function quantity (quantity: NotMissingOrderItem['quantity'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function quantity (quantity: NotMissingOrderItem['quantity'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['quantity']{

    if(!validate.quantity(quantity)._isValid) throw new Error("Invalid quantity");
    
    if(item){
        return {
            ...item,
            quantity,
        };
    } else {
        return quantity;
    }
}

export function sortOrder (sortOrder: NotMissingOrderItem['sortOrder']): NotMissingOrderItem['sortOrder']
export function sortOrder (sortOrder: NotMissingOrderItem['sortOrder'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function sortOrder (sortOrder: NotMissingOrderItem['sortOrder'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['sortOrder']{

    if(!validate.sortOrder(sortOrder)._isValid) throw new Error("Invalid sortOrder");
    
    if(item){
        return {
            ...item,
            sortOrder,
        };
    } else {
        return sortOrder;
    }
}

export function notes (notes: NotMissingOrderItem['notes']): NotMissingOrderItem['notes']
export function notes (notes: NotMissingOrderItem['notes'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function notes (notes: NotMissingOrderItem['notes'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['notes']{

    if(!validate.notes(notes)._isValid) throw new Error("Invalid notes");
    
    if(item){
        return {
            ...item,
            notes,
        };
    } else {
        return notes;
    }
}

export function description (description: NotMissingOrderItem['description']): NotMissingOrderItem['description']
export function description (description: NotMissingOrderItem['description'], item : EditedNotMissingOrderItem ): EditedNotMissingOrderItem
export function description (description: NotMissingOrderItem['description'], item? : EditedNotMissingOrderItem ): EditedNotMissingOrderItem | NotMissingOrderItem['description']{

    if(!validate.description(description)._isValid) throw new Error("Invalid description");
    
    if(item){
        return {
            ...item,
            description,
        };
    } else {
        return description;
    }
}


export function dEdited (dEdited: CarcaseOrderItem['dEdited']): CarcaseOrderItem['dEdited']
export function dEdited (dEdited: CarcaseOrderItem['dEdited'], item : EditedCarcaseOrderItem ): EditedCarcaseOrderItem
export function dEdited (dEdited: CarcaseOrderItem['dEdited'], item? : EditedCarcaseOrderItem ): EditedCarcaseOrderItem | CarcaseOrderItem['dEdited']{

    if(!validate.dEdited(dEdited)._isValid) throw new Error("Invalid dEdited");
    
    if(item){
        return {
            ...item,
            dEdited,
        };
    } else {
        return dEdited;
    }
}

export function hEdited (hEdited: CarcaseOrderItem['hEdited'], soldByM2 : CarcaseOrderItem['soldByM2']): CarcaseOrderItem['hEdited']
export function hEdited (hEdited: CarcaseOrderItem['hEdited'], soldByM2 : CarcaseOrderItem['soldByM2'], item : EditedCarcaseOrderItem ): EditedCarcaseOrderItem
export function hEdited (hEdited: CarcaseOrderItem['hEdited'], soldByM2 : CarcaseOrderItem['soldByM2'], item? : EditedCarcaseOrderItem ): EditedCarcaseOrderItem | CarcaseOrderItem['hEdited']{

    if(!validate.hEdited(hEdited, soldByM2)._isValid) throw new Error("Invalid hEdited");
    
    if(item){
        return {
            ...item,
            hEdited,
        };
    } else {
        return hEdited;
    }
}

export function handing (handing: CarcaseOrderItem['handing']): CarcaseOrderItem['handing']
export function handing (handing: CarcaseOrderItem['handing'], item : EditedCarcaseOrderItem ): EditedCarcaseOrderItem
export function handing (handing: CarcaseOrderItem['handing'], item? : EditedCarcaseOrderItem ): EditedCarcaseOrderItem | CarcaseOrderItem['handing']{

    if(!validate.handing(handing)._isValid) throw new Error("Invalid handing");
    
    if(item){
        return {
            ...item,
            handing,
        };
    } else {
        return handing;
    }
}

export function position (position: CarcaseOrderItem['position']): CarcaseOrderItem['position']
export function position (position: CarcaseOrderItem['position'], item : EditedCarcaseOrderItem ): EditedCarcaseOrderItem
export function position (position: CarcaseOrderItem['position'], item? : EditedCarcaseOrderItem ): EditedCarcaseOrderItem | CarcaseOrderItem['position']{

    if(!validate.position(position)._isValid) throw new Error("Invalid position");

    if(item){
        return {
            ...item,
            position,
        };
    }
    return position;
}

export function wEdited (wEdited: CarcaseOrderItem['wEdited'], soldByM2 : CarcaseOrderItem['soldByM2']): CarcaseOrderItem['wEdited']
export function wEdited (wEdited: CarcaseOrderItem['wEdited'], soldByM2 : CarcaseOrderItem['soldByM2'], item : EditedCarcaseOrderItem ): EditedCarcaseOrderItem
export function wEdited (wEdited: CarcaseOrderItem['wEdited'], soldByM2 : CarcaseOrderItem['soldByM2'], item? : EditedCarcaseOrderItem ): EditedCarcaseOrderItem | CarcaseOrderItem['wEdited']{

    if(!validate.wEdited(wEdited, soldByM2)._isValid) throw new Error("Invalid wEdited");
    
    if(item){
        return {
            ...item,
            wEdited,
        };
    } else {
        return wEdited;
    }
}