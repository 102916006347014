import {create, StateCreator} from "zustand";
import { devtools } from 'zustand/middleware'
import { subscribeWithSelector } from "zustand/middleware";
import React from "react";
import { libTypes } from "@holta/lib";
import { sub, startOfISOWeek, endOfISOWeek } from "date-fns";

type EditedProps = { _deleted?: boolean; _dbUnsaved?: boolean };

export type EditedAddress = libTypes.Address & EditedProps;
export type EditedContact = libTypes.Contact & EditedProps;
export type EditedSupplierRange = libTypes.SupplierRange & EditedProps;
export type EditedBaseProduct = libTypes.SupplierBaseProduct & EditedProps;
export type EditedSupplierProduct = libTypes.SupplierProduct & EditedProps;

export type StoreEditOrder = {
    type: "NEW" | "EDIT";
    newValues: {
        order: libTypes.EditedOrderValues;
        orderItem: libTypes.EditedOrderItem | null;
    };
    currentValues: {
        order: libTypes.Order;
        supplierDoorRange: libTypes.SupplierComputedProduct[];
    };
    requestedActions: ({
        type: "REQUEST_CREATE_XERO_INVOICE",
    } | {
        type: "REQUEST_UPDATE_XERO_INVOICE",
    })[];
};

export type StoreEditCustomer = {
    type: "NEW" | "EDIT";
    newValues: {
        customer: Partial<libTypes.Customer>;
        addresses: EditedAddress[];
        contacts: EditedContact[];
    };
    currentValues: {
        customer: libTypes.Customer;
        addresses: libTypes.Address[];
        contacts: libTypes.Contact[];
    };
};

export type StoreEditSupplier = {
    type: "NEW" | "EDIT";
    newValues: {
        supplier: Partial<libTypes.Supplier>;
        addresses: EditedAddress[];
        contacts: EditedContact[];
        ranges: EditedSupplierRange[];
        baseProducts: EditedBaseProduct[];
        products: EditedSupplierProduct[];
    };
    currentValues: {
        supplier: libTypes.Supplier;
        addresses: libTypes.Address[];
        contacts: libTypes.Contact[];
        ranges: libTypes.SupplierRange[];
        baseProducts: libTypes.SupplierBaseProduct[];
        products: libTypes.SupplierComputedProduct[];
    };
};

export type AdminStore = {
    orders: libTypes.Order[];
    ordersSearch: libTypes.Order[];
    productionOrders: libTypes.Order[];
    deliveryOrders: libTypes.Order[];
    contacts: libTypes.Contact[];
    customers: libTypes.Customer[];
    suppliers: libTypes.Supplier[];
    editCustomer: StoreEditCustomer | null;
    editOrder: StoreEditOrder | null;
    editSupplier: StoreEditSupplier | null;
    categories: libTypes.Category[];
    products: libTypes.Product[];
    productComponents: libTypes.ProductComponent[];
    linkProducts: libTypes.LinkProduct[];
    ui: {
        customers: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "name" | "dateCreated";
        };
        categories: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "fullname";
        };
        productComponents: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "code";
        };
        linkProducts: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "name";
        };
        orders: {
            searchFilter: string;
            dateFrom: number;
            dateTo: number | null;
            dateType: libTypes.OrderDateFilter;
            type: "ALL" | "QUOTE" | "ORDER" | "INVOICE";
            sortDirection: "ASC" | "DESC";
            sortField:  "none" | "orderRef" | "quoteRef" | "customerRef" | "date";
            accountingStatus: "ALL" | libTypes.AccountingStatus;
            customerFilter: {label: string; value: string} | null;
        };
        products: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "code" | "description" | "dateCreated";
            selectedProducts: string[];
            componentsFilter: { label: string; value: string }[];
            categoriesFilter: { label: string; value: string }[];
        };
        production: {
            weekStart: number,
        };
        delivery: {
            weekStart: number,
        };
        suppliers: {
            searchFilter: string;
            sortDirection: "ASC" | "DESC";
            sortField: "name" | "dateCreated";
        };
    };
};



export const useStore = create<AdminStore>()(devtools((set) => ({
    customers: [],
    contacts: [],
    orders: [],
    ordersSearch: [],
    productionOrders: [],
    deliveryOrders: [],
    editCustomer: null,
    editOrder: null,
    editSupplier: null,
    categories: [],
    products: [],
    productComponents: [],
    suppliers: [],
    linkProducts: [],
    ui: {
        customers: {
            searchFilter: "",
            sortDirection: "ASC",
            sortField: "name",
        },
        categories: {
            sortDirection: "ASC",
            sortField: "fullname",
            searchFilter: "",
        },
        productComponents: {
            sortDirection: "ASC",
            sortField: "code",
            searchFilter: "",
        },
        linkProducts: {
            sortDirection: "ASC",
            sortField: "name",
            searchFilter: "",
        },
        orders: {
            searchFilter: "",
            dateFrom: sub(new Date(), { days: 30 }).getTime(),
            dateTo: null,
            dateType: "CURRENT_STATUS",
            type: "ALL",
            sortDirection: "ASC",
            sortField: "none" ,
            accountingStatus: "ALL",
            customerFilter: null,
        },
        products: {
            sortDirection: "ASC",
            sortField: "code",
            searchFilter: "",
            selectedProducts: [],
            componentsFilter: [],
            categoriesFilter: [],
        },
        production: {
            weekStart: startOfISOWeek(new Date()).getTime()
        },
        delivery: {
            weekStart: startOfISOWeek(new Date()).getTime()
        },
        suppliers: {
            searchFilter: "",
            sortDirection: "ASC",
            sortField: "name",
        },
    },
}))) ;
