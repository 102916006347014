import {CarcaseOrderItem} from '../../types';
import Decimal from 'decimal.js';


/**
 * 
 * Gets the m2 value of w / h values (supplied in mm)
 * 
 * @param  {CarcaseOrderItem} item
 * @returns number
 */
export function getM2Value(item : CarcaseOrderItem) : number{

    let width = Number(item.wEdited);
    width = width || 0;

    let height = Number(item.hEdited);
    height = height || 0;

    const m2 = new Decimal(width).div(1000).mul(new Decimal(height).div(1000)).toNumber();

    return m2;

}
