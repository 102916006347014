import React, { ReactElement } from 'react';


interface DialogButton {
    label : string,
    fn : () => void,
    primary? : boolean
}


export interface Dialog {
    id : string
    label : string,
    description? : string,
    success? : boolean,
    buttons : DialogButton[]
}

interface OpenGenericDialog {
    (description : string, label?: string, success? : boolean,  withCancel? : boolean, handleOk? : () => void, okPrimary? : boolean, id?: string) : void
}
interface DialogContextType {
    openDialog : (id : string | Dialog) => void;
    openGenericDialog : OpenGenericDialog;
    closeDialog : (id : string) => void;
    openDialogs : OpenDialogs;
    isDialogOpen : (id? : string) => boolean;
    isDialogClosing : (id? : string) => boolean
}

interface OpenDialogs {
    [id : string] : Dialog | string
}

const defaultFunctions = {
    openDialog : () => {},
    openGenericDialog : () => {},
    closeDialog : () => {},
    openDialogs : {},
    isDialogOpen : () => false,
    isDialogClosing : () => false
}


export const DialogContext = React.createContext<DialogContextType>(defaultFunctions as DialogContextType);

export const useDialog = () : DialogContextType => React.useContext(DialogContext);


const DialogProviderRenderFunction:React.ForwardRefRenderFunction<DialogContextType, {children : React.ReactNode, genericDialogId? : string}> = ({children, genericDialogId = 'GENERIC_DIALOG'}, ref) : ReactElement => {

    const [openDialogs, setOpenDialog] = React.useState<OpenDialogs>({});
    const [closingDialogs, setClosingDialog] = React.useState<string[]>([]);


    const openDialog : DialogContextType['openDialog'] = arg => {

        const id = typeof arg == "object" ? arg.id : arg; 
        if(id in openDialogs) return false;
        if(typeof arg == "object"){
            setOpenDialog(openDialogs => ({...openDialogs, [id] : arg}));
            return false;
        } 
        setOpenDialog(openDialogs => ({...openDialogs, [id] : id }))
    }

    const openGenericDialog : OpenGenericDialog = (description, label = 'Alert', success, withCancel, handleOk,  okPrimary = true, id?: string) => {
        openDialog({
            id : id || genericDialogId ,
            label : label,
            success : success ? success : false,
            description : description,
            buttons: [
                ...withCancel ? [{label : 'Cancel', fn: () =>{}, primary : !okPrimary}] : [],
                {label : 'Ok', fn : () => {handleOk && handleOk()}, primary : okPrimary}
            ]
        })
    }

    const closeDialog : DialogContextType['closeDialog'] = id => {

        if(!(id in openDialogs)) return false;
        
        if(!closingDialogs.includes(id)) {
            setClosingDialog(closingDialogs => [...closingDialogs, id])
            cleanUpClosingDialogs(id)
        }
        
    }

    function cleanUpClosingDialogs(id : string){
        setTimeout(() => {
            setOpenDialog(openDialogs => {
                const nextOpenDialogs = {...openDialogs}
                delete nextOpenDialogs[id];
                return nextOpenDialogs;
            });
            setClosingDialog(closingDialogs => {
                return closingDialogs.filter(item => item !== id);
            });
        }, 200)
    }

    const isDialogOpen : DialogContextType['isDialogOpen'] = id => {
        if(!id && Object.keys(openDialogs).length > 0) return true;
        if(!id) return false;
        if(openDialogs[id]) return true;
        return false;
    }

    const isDialogClosing : DialogContextType['isDialogClosing'] = id => {
        if(closingDialogs.length < 1) return false;
        if(!id && closingDialogs.length === Object.keys(openDialogs).length) return true;
        if(!id) return false;        if(closingDialogs.includes(id)) return true;
        return false;
    }


    React.useImperativeHandle(ref, () => ({
        openDialog : openDialog,
        openGenericDialog : openGenericDialog,
        closeDialog : closeDialog,
        openDialogs : openDialogs,
        isDialogOpen : isDialogOpen,
        isDialogClosing : isDialogClosing
    }));

    
    return (
        <DialogContext.Provider value={{openDialog, openGenericDialog, closeDialog, openDialogs, isDialogOpen, isDialogClosing}} >
            {children}
        </DialogContext.Provider>
    )

};

// NOTE: This can be writter more succinctly by using an anonymous function in the forward ref function which automatically infers the ForwardRefRenderFunction type
export const DialogProvider = React.forwardRef<DialogContextType, {children : React.ReactNode,  genericDialogId? : string}>(DialogProviderRenderFunction);