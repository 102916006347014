import { value } from "../validate";
import { verify } from "../validate";
import { SupplierRange } from "../../types";


export const name = (supplierRange: SupplierRange, name: SupplierRange["name"]): SupplierRange => {
    verify(() => value(name).isString());
    return {
        ...supplierRange,
        name,
    };
};


export const prefix = (
    supplierRange: SupplierRange,
    prefix: SupplierRange["prefix"]
): SupplierRange => {
    verify(() => value(prefix).isString());
    return {
        ...supplierRange,
        prefix,
    };
};

export const discount = (
    supplierRange: SupplierRange,
    discount: SupplierRange["discount"]
): SupplierRange => {
    if(discount !== null) verify(() => value(discount).isNumber());
    return {
        ...supplierRange,
        discount,
    };
};


export const margin = (
    supplierRange: SupplierRange,
    margin: SupplierRange["margin"]
): SupplierRange => {
    if(margin !== null) verify(() => value(margin).isNumber());
    return {
        ...supplierRange,
        margin,
    };
};


export const notes = (
    supplierRange: SupplierRange,
    notes: SupplierRange["notes"]
): SupplierRange => {
    verify(() => value(notes).isString());
    return {
        ...supplierRange,
        notes,
    };
};

export const subDiscounts = ( supplierRange: SupplierRange, subDiscounts: SupplierRange["subDiscounts"] ): SupplierRange => {
    verify(() => value(subDiscounts).isArray());
    return {
        ...supplierRange,
        subDiscounts,
    };
}

export const createSubDiscount = ( supplierRange: SupplierRange): SupplierRange => {
    
    return {
        ...supplierRange,
        subDiscounts: [...supplierRange.subDiscounts, {
            discount: 0,
            margin: 0,
            baseProducts: [],
        }],
    };
}

export const subDiscountMargin = ( supplierRange: SupplierRange, subDiscountIndex: number, margin: number ): SupplierRange => {
    verify(() => value(margin).isNumber());
    return {
        ...supplierRange,
        subDiscounts: supplierRange.subDiscounts.map((subDiscount, index) => {
            if(index === subDiscountIndex) {
                return {
                    ...subDiscount,
                    margin,
                };
            }
            return subDiscount;
        }),
    };
}

export const subDiscountDiscount = ( supplierRange: SupplierRange, subDiscountIndex: number, discount: number ): SupplierRange => {
    verify(() => value(discount).isNumber());
    return {
        ...supplierRange,
        subDiscounts: supplierRange.subDiscounts.map((subDiscount, index) => {
            if(index === subDiscountIndex) {
                return {
                    ...subDiscount,
                    discount,
                };
            }
            return subDiscount;
        }),
    };
}

export const addSubDiscoundBaseProduct = ( supplierRange: SupplierRange, subDiscountIndex: number, baseProduct: string ): SupplierRange => {
    verify(() => value(baseProduct).isString());
    return {
        ...supplierRange,
        subDiscounts: supplierRange.subDiscounts.map((subDiscount, index) => {
            if(index === subDiscountIndex) {
                return {
                    ...subDiscount,
                    baseProducts: [...subDiscount.baseProducts, baseProduct],
                };
            }
            return subDiscount;
        }),
    };
}

export const removeSubDiscoundBaseProduct = ( supplierRange: SupplierRange, subDiscountIndex: number, baseProduct: string ): SupplierRange => {
    verify(() => value(baseProduct).isString());
    return {
        ...supplierRange,
        subDiscounts: supplierRange.subDiscounts.map((subDiscount, index) => {
            if(index === subDiscountIndex) {
                return {
                    ...subDiscount,
                    baseProducts: subDiscount.baseProducts.filter(b => b !== baseProduct),
                };
            }
            return subDiscount;
        }),
    };
}


export const addSubDiscount = ( supplierRange: SupplierRange, subDiscount: SupplierRange["subDiscounts"][number] ): SupplierRange => {
    verify(() => value(subDiscount).isObject());
    return {
        ...supplierRange,
        subDiscounts: [...supplierRange.subDiscounts, subDiscount],
    };
}

export const removeSubDiscount = ( supplierRange: SupplierRange, index: number ): SupplierRange => {

    return {
        ...supplierRange,
        subDiscounts: supplierRange.subDiscounts.filter((sd, i) => i !== index),
    };
}