import { CutAndEdgeOrderGroup } from '../../types';
import { isSheetMaterialGroup } from '../board/isSheetMaterialGroup';
import { isSheetMaterialPoa } from '../board/isSheetMaterialPoa';


// Check if any board groups have POA pricing

export const hasPoaItems = (cutAndEdgeGroups : CutAndEdgeOrderGroup[]) : boolean => {

    let isPoa = false;
    cutAndEdgeGroups.forEach(group => {
        if(isSheetMaterialGroup(group)) {
            isPoa = isPoa || isSheetMaterialPoa(group);
        }
    })

    return isPoa;
}