
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({theme}) => theme.spacing(1)};
`;

const Title = styled.h2`
    ${({theme}) => theme.type.l};
    ${({theme}) => theme.type.bold};
    margin-right:${({theme}) => theme.spacing(1)};
`;

const Line = styled.div`
    border-bottom: 2px solid ${({theme}) => theme.color.accent2};
    flex: auto;
`;

export const DividerTitle = ({title = "", className = ""}) : React.ReactElement => {
    return (
        <Container className={className}>
            <Title>
                {title}
            </Title>
            <Line />
        </Container>
    )
}