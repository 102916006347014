import { produce } from "immer";
import type { StoreApi, UseBoundStore } from "zustand";

const actionNames: string[] = [];

export interface Action<Q, P extends any[]> {
    (newState: Q, ...rest: P): void;
}

type ReturnedAction<P extends any[]> = (...args: P) => void;

export const createActionCreator = <AppState>(useStore: StoreApi<AppState>, sideEffects? : Array<(state:AppState, name: string )=>void>) => {

    const state = useStore.getState();
    const devTools = connectDevTools<AppState>(state);

    return <Params extends any[]>(name: string, fn: Action<AppState, Params>): ReturnedAction<Params> => {
        if (actionNames.includes(name)) throw new Error("Action already defined");
        actionNames.push(name);
        return (...args) => {
            const state = useStore.getState();

            const nextState = produce<AppState, AppState>(state, (nextState) => {
                fn(nextState, ...args);
            });

            useStore.setState(nextState);

            devTools && devTools.send(name, nextState);

            if(Array.isArray(sideEffects)) {
                sideEffects.forEach(fn =>{
                    fn(nextState, name)
                })
            }

            
        };
    };
};

function connectDevTools<AppState>(state : AppState) {
    let devTools;
    try {
        devTools =
            (window as any).__REDUX_DEVTOOLS_EXTENSION__ ||
            (window as any).top.__REDUX_DEVTOOLS_EXTENSION__;
    } catch {}

    
    if (devTools) {
        const connectedDevTools = devTools.connect();
        connectedDevTools.init(state);
        return connectedDevTools
    }

    return false;
    
}
