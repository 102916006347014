import { Supplier, SupplierBaseProduct, SupplierProduct, SupplierRange } from '../../types';
import {getDiscountPrice} from '../shared/getDiscountPrice';
import {getMarginPrice} from '../shared/getMarginPrice';


/**
 * 
 * Gets the price of an supplier item, after listPriceDiscount + margin is applied
 * 
 * @param  {SupplierOrderItem} item
 * @returns string
 
*/

export function getSupplierProductPrice(product : SupplierProduct, baseProduct: SupplierBaseProduct,  supplier: Supplier, range?: SupplierRange) : string {

    // if its a range product
    if(baseProduct.type === 'RANGE'){
        if(!range) throw new Error('Range product has no range');
        // first get the required discount & margin
        let discount = 0;
        let margin = 0;

        // Check for range subdiscounts first
        const subDiscount = range?.subDiscounts?.find(subDiscount => subDiscount.baseProducts.includes(baseProduct.id));
        discount = subDiscount?.discount || discount;
        margin = subDiscount?.margin || margin;

        // Check for range discounts
        discount = range?.discount || discount;
        margin = range?.margin || margin;

        // Check for supplier discounts
        discount = supplier?.discount || discount;
        margin = supplier?.margin || margin;

        const listPrice = product.price;
        const discountPrice = getDiscountPrice(listPrice, discount);
        const marginPrice = getMarginPrice(discountPrice, margin);

        return marginPrice;
    }
    
    // if its a unique product
    if(baseProduct.type === 'UNIQUE'){
        const listPrice = product.price;
        const discountPrice = getDiscountPrice(listPrice, supplier.discount || 0);
        const marginPrice = getMarginPrice(discountPrice, supplier.margin || 0);
        return marginPrice;
    }
    return '0';

}

