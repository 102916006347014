import { getItemQtyPrice } from "./getItemQtyPrice";
import { getCutItemBoardPrice } from "./getCutItemBoardPrice";
import { getCutItemEdgingPrice } from "./getCutItemEdgingPrice";
import Decimal from "decimal.js";
import { BoardCut } from "../../types";
import { constants } from "../../constants";

// The total price of a cut piece of board with edging

export const getCutItemPrice = (
    m2Price: number,
    edgingPrice: number,
    cut: BoardCut
): number => {

    if(!cut.width && !cut.height && !cut.qty) return 0;

    if(!cut.width || !cut.height || !cut.qty) throw new Error("Cut incomplete");
    
    const itemBoardPrice = getCutItemBoardPrice(Number(cut.width), Number(cut.height), m2Price);
    const itemEdgingPrice = getCutItemEdgingPrice(edgingPrice, Number(cut.width), Number(cut.height), ...cut.edging);
    const subTotal = new Decimal(itemBoardPrice).add(itemEdgingPrice).add(constants.CUT_EDGE_ITEM_SURCHARGE);

    return getItemQtyPrice(subTotal.toNumber(), Number(cut.qty));
};
