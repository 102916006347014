import {Order} from '../../types';
import { deliveryDateTypes } from "../../static_data/deliveryDateTypes";
import { isAsapDelivery } from '../order/isAsapDelivery';
import { hasDeliveryDate } from '../order/hasDeliveryDate';

export function filterNoDelivery(orders : Order[], returnRest = false) : Order[]{



    return orders.filter(order => {
        if(order.deliveryDateType === deliveryDateTypes.NONE.key) return true;
        if (hasDeliveryDate(order)) return false;
        if (isAsapDelivery(order)) return false;
    })

}       