import { LinkedDoorOrderItem, OrderItem, SupplierOrderItem } from '../../types';
import { isLinkedDoorItem } from '../order_item/isLinkedDoorItem';

export function filterLinkedDoorItems(items : OrderItem[]) : LinkedDoorOrderItem[] {
    
    return items.filter(isLinkedDoorItem)

}


    
