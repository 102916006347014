import {CutAndEdgeOrderGroup} from '../../types';
import Decimal from 'decimal.js';
import { getMFCOrderGroupPrice } from '../board/getMFCOrderGroupPrice';
import { isMFCGroup } from '../board/isMFCGroup';
import { isSheetMaterialGroup } from '../board/isSheetMaterialGroup';
import { getSheetMaterialOrderGroupPrice} from '../board/getSheetMaterialOrderGroupPrice';

// The total cost of a cut and edge order

export const getCutAndEdgeOrderItemSubtotal = (cutAndEdgeGroups : CutAndEdgeOrderGroup[]) : number => {
    let total = new Decimal(0);

    cutAndEdgeGroups.forEach(group => {
        if(isMFCGroup(group)) {
            total = total.add(getMFCOrderGroupPrice(group))
        }
        if(isSheetMaterialGroup(group)) {
            total = total.add(getSheetMaterialOrderGroupPrice(group))
        }
    })

    return Number( total.toFixed(2) );
}