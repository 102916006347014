import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { clearEditOrderItem } from "./clearEditOrderItem";
import { quantity } from "@holta/lib/functions/order_item/set";
import { CarcaseOrderItem } from "@holta/lib/types";



export const _saveEditOrderItem = () => {

    const editOrder = getEditOrder();
    const mergedOrder = getMergedOrder();
    const mergedOrderItem = getMergedOrderItem();
    const orderItem = editOrder.newValues.orderItem;
    let childItems : (libTypes.EditedLinkedDoorOrderItem | libTypes.MissingLinkedOrderItem)[] = [];

    if(!orderItem) return;

    const mergedExistingOrderItem = mergedOrder.items?.find((item) => item.id === orderItem.id);

    if (mergedOrderItem?.quantity && mergedExistingOrderItem?.quantity && orderItem.quantity !== mergedExistingOrderItem.quantity) {
        const quantityFactor = mergedOrderItem.quantity / mergedExistingOrderItem.quantity;
        childItems = mergedOrder.items.filter(item => fns.order_item.isAnySupplierItem(item) && item.parentId === orderItem.id).map((item) => {
            return {
                id: item.id,
                quantity: item.quantity * quantityFactor
            }
        });
    }

    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.order.items = fns.order_items.combineEdits(editOrder.newValues.order.items || [], [orderItem, ...childItems]);
        })
    });

    clearEditOrderItem();

};




export const saveEditOrderItem = (handleDuplicates: (
    linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][],
    cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void
) => void) => {
    const editOrder = getEditOrder();
    const orderItem = editOrder.newValues.orderItem;
    const mergedOrder = getMergedOrder();
    const linkProducts = useStore.getState().linkProducts;
    
    if(!orderItem) return;

    const isNewItem = !mergedOrder.items?.find((item) => item.id === orderItem.id);
    const isCodeUpdate = mergedOrder.items?.find((item) => 
        item.id === orderItem.id && 
        'code' in item && 
        'code' in orderItem && 
        item.code !== orderItem.code
    );

    // if its a code update, delete any linked doors
    if (isCodeUpdate) {
        mergedOrder.items?.filter((item) => fns.shared.hasProperty(item, "parentId") && item.parentId === orderItem.id).forEach((item) => {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    draft.newValues.order.items = fns.order_items.combineEdits(
                        editOrder.newValues.order.items || [],
                        [{ id: item.id, _deleted: true }]
                    );
                })
            });
        });
    }

    // First save the item
    
    //_saveEditOrderItem();


    if(mergedOrder.hasDoors && (isNewItem || isCodeUpdate)) {

        if (fns.order_item.isCarcaseItem(orderItem as libTypes.OrderItem)) {
            const doorMatches = fns.order_item.getLinkedSupplierProducts(
                editOrder.newValues.orderItem as libTypes.CarcaseOrderItem,
                editOrder.currentValues.supplierDoorRange,
                linkProducts,
                mergedOrder.constructionType || "LAYON",
                mergedOrder.supplierDiscount
            );
            if (doorMatches.duplicates.length > 0) {
                handleDuplicates(doorMatches.duplicates, (linkedDoorOrderItems) => {
                    linkedDoorOrderItems.forEach((linkedDoorOrderItem) => {
                        doorMatches.matched.forEach((item) => saveLinkedDoorOrderItem(item));
                        doorMatches.unmatched.forEach((item) => saveMissingLinkedDoorOrderItem(item));
                        saveLinkedDoorOrderItem(linkedDoorOrderItem);
                        _saveEditOrderItem();
                        
                    });
                });
            } else {
                doorMatches.matched.forEach((item) => saveLinkedDoorOrderItem(item));
                doorMatches.unmatched.forEach((item) => saveMissingLinkedDoorOrderItem(item));
                _saveEditOrderItem();
            }
        } else {
            _saveEditOrderItem();
        }

    } else {
        _saveEditOrderItem();
    }

};


