export const mfcColours = [
    {
        "code": "W980",
        "texture": "SM",
        "decor_name": "Platinum White",
        "group": 2
    },
    {
        "code": "U104",
        "texture": "ST9",
        "decor_name": "Alabaster White",
        "group": 3
    },
    {
        "code": "U108",
        "texture": "ST9",
        "decor_name": "Vanilla Yellow",
        "group": 3
    },
    {
        "code": "U156",
        "texture": "ST9",
        "decor_name": "Sand Beige",
        "group": 3
    },
    {
        "code": "U222",
        "texture": "ST9",
        "decor_name": "Crema Beige",
        "group": 3
    },
    {
        "code": "U708",
        "texture": "ST9",
        "decor_name": "Light Grey",
        "group": 3
    },
    {
        "code": "U732",
        "texture": "ST9",
        "decor_name": "Dust Grey",
        "group": 3
    },
    {
        "code": "U750",
        "texture": "ST9",
        "decor_name": "Taupe Grey",
        "group": 3
    },
    {
        "code": "U795",
        "texture": "ST9",
        "decor_name": "Brown Grey",
        "group": 3
    },
    {
        "code": "U100",
        "texture": "ST9",
        "decor_name": "Mussel",
        "group": 4
    },
    {
        "code": "U201",
        "texture": "ST9",
        "decor_name": "Pebble Grey",
        "group": 4
    },
    {
        "code": "U507",
        "texture": "ST9",
        "decor_name": "Smoke Blue",
        "group": 4
    },
    {
        "code": "U540",
        "texture": "ST9",
        "decor_name": "Denim Blue",
        "group": 4
    },
    {
        "code": "U599",
        "texture": "ST9",
        "decor_name": "Indigo Blue",
        "group": 4
    },
    {
        "code": "U636",
        "texture": "ST9",
        "decor_name": "Fjord Green",
        "group": 4
    },
    {
        "code": "U702",
        "texture": "ST9",
        "decor_name": "Cashmere Grey",
        "group": 4
    },
    {
        "code": "U717",
        "texture": "ST9",
        "decor_name": "Dakar Grey",
        "group": 4
    },
    {
        "code": "U727",
        "texture": "ST9",
        "decor_name": "Stone Grey",
        "group": 4
    },
    {
        "code": "U741",
        "texture": "ST9",
        "decor_name": "Lava Grey",
        "group": 4
    },
    {
        "code": "U763",
        "texture": "ST9",
        "decor_name": "Pearl Grey",
        "group": 4
    },
    {
        "code": "U765",
        "texture": "ST9",
        "decor_name": "Silver Grey",
        "group": 4
    },
    {
        "code": "U775",
        "texture": "ST9",
        "decor_name": "White Grey",
        "group": 4
    },
    {
        "code": "U780",
        "texture": "ST9",
        "decor_name": "Monument Grey",
        "group": 4
    },
    {
        "code": "U899",
        "texture": "ST9",
        "decor_name": "Soft Black",
        "group": 4
    },
    {
        "code": "U960",
        "texture": "ST9",
        "decor_name": "Onyx Grey",
        "group": 4
    },
    {
        "code": "U961",
        "texture": "ST2",
        "decor_name": "Graphite",
        "group": 4
    },
    {
        "code": "U963",
        "texture": "ST9",
        "decor_name": "Diamond Grey",
        "group": 4
    },
    {
        "code": "U968",
        "texture": "ST9",
        "decor_name": "Carbon Grey",
        "group": 4
    },
    {
        "code": "U999",
        "texture": "ST2",
        "decor_name": "Black",
        "group": 4
    },
    {
        "code": "W1000",
        "texture": "ST9",
        "decor_name": "Premium White",
        "group": 4
    },
    {
        "code": "W1100",
        "texture": "ST9",
        "decor_name": "Alpine White",
        "group": 4
    },
    {
        "code": "W1300",
        "texture": "ST9",
        "decor_name": "Glacier White",
        "group": 4
    },
    {
        "code": "H1154",
        "texture": "ST15",
        "decor_name": "Lissa Oak",
        "group": 5
    },
    {
        "code": "H1334",
        "texture": "ST9",
        "decor_name": "Light Sorano Oak",
        "group": 5
    },
    {
        "code": "H1342",
        "texture": "ST12",
        "decor_name": "Natural Helena Oak",
        "group": 5
    },
    {
        "code": "H1381",
        "texture": "ST12",
        "decor_name": "Winchester Oak",
        "group": 5
    },
    {
        "code": "H1511",
        "texture": "ST15",
        "decor_name": "Bavarian Beech",
        "group": 5
    },
    {
        "code": "H1521",
        "texture": "ST15",
        "decor_name": "Maple",
        "group": 5
    },
    {
        "code": "H1582",
        "texture": "ST15",
        "decor_name": "Ellmau Beech",
        "group": 5
    },
    {
        "code": "H1733",
        "texture": "ST9",
        "decor_name": "Mainau Birch",
        "group": 5
    },
    {
        "code": "H3382",
        "texture": "ST9",
        "decor_name": "Light Winchester Oak",
        "group": 5
    },
    {
        "code": "H3703",
        "texture": "ST15",
        "decor_name": "Natural Aida Walnut",
        "group": 5
    },
    {
        "code": "H3704",
        "texture": "ST15",
        "decor_name": "Tobacco Aida Walnut",
        "group": 5
    },
    {
        "code": "H1137",
        "texture": "ST12",
        "decor_name": "Black-Brown Sorano Oak",
        "group": 6
    },
    {
        "code": "H1277",
        "texture": "ST9",
        "decor_name": "Light Lakeland Acacia",
        "group": 6
    },
    {
        "code": "H1319",
        "texture": "ST12",
        "decor_name": "Light Calais Oak",
        "group": 6
    },
    {
        "code": "H1615",
        "texture": "ST9",
        "decor_name": "Verona Cherry",
        "group": 6
    },
    {
        "code": "H3368",
        "texture": "ST9",
        "decor_name": "Natural Lancaster Oak",
        "group": 6
    },
    {
        "code": "H3734",
        "texture": "ST9",
        "decor_name": "Natural Dijon Walnut",
        "group": 6
    },
    {
        "code": "H3840",
        "texture": "ST9",
        "decor_name": "Natural Mandal Maple",
        "group": 6
    },
    {
        "code": "F501",
        "texture": "ST2",
        "decor_name": "Brushed Aluminium",
        "group": 7
    },
    {
        "code": "F509",
        "texture": "ST2",
        "decor_name": "Aluminium",
        "group": 7
    },
    {
        "code": "H1145",
        "texture": "ST10",
        "decor_name": "Natural Bardolino Oak",
        "group": 7
    },
    {
        "code": "H1146",
        "texture": "ST10",
        "decor_name": "Grey Bardolino Oak",
        "group": 7
    },
    {
        "code": "H1158",
        "texture": "ST10",
        "decor_name": "Truffle Bardolino Oak",
        "group": 7
    },
    {
        "code": "H1313",
        "texture": "ST10",
        "decor_name": "Grey Brown Whiteriver Oak",
        "group": 7
    },
    {
        "code": "H1372",
        "texture": "ST22",
        "decor_name": "Natural Aragon Oak",
        "group": 7
    },
    {
        "code": "H1474",
        "texture": "ST22",
        "decor_name": "White Avola",
        "group": 7
    },
    {
        "code": "H1477",
        "texture": "ST22",
        "decor_name": "Green Grey Avola",
        "group": 7
    },
    {
        "code": "H1478",
        "texture": "ST22",
        "decor_name": "Truffle Brown Avola",
        "group": 7
    },
    {
        "code": "H1484",
        "texture": "ST22",
        "decor_name": "Brown Grey Avola",
        "group": 7
    },
    {
        "code": "H3012",
        "texture": "ST22",
        "decor_name": "Coco Bolo",
        "group": 7
    },
    {
        "code": "H3058",
        "texture": "ST22",
        "decor_name": "Mali Wenge",
        "group": 7
    },
    {
        "code": "H3078",
        "texture": "ST22",
        "decor_name": "White Havana Pine",
        "group": 7
    },
    {
        "code": "H3081",
        "texture": "ST22",
        "decor_name": "Black Havana Pine",
        "group": 7
    },
    {
        "code": "H3090",
        "texture": "ST22",
        "decor_name": "Shorewood",
        "group": 7
    },
    {
        "code": "H3131",
        "texture": "ST12",
        "decor_name": "Natural Davos Oak",
        "group": 7
    },
    {
        "code": "H3133",
        "texture": "ST12",
        "decor_name": "Truffle Brown Davos Oak",
        "group": 7
    },
    {
        "code": "H3170",
        "texture": "ST12",
        "decor_name": "Natural Kendal Oak",
        "group": 7
    },
    {
        "code": "H3171",
        "texture": "ST12",
        "decor_name": "Oiled Kendal Oak",
        "group": 7
    },
    {
        "code": "H3303",
        "texture": "ST10",
        "decor_name": "Natural Hamilton Oak",
        "group": 7
    },
    {
        "code": "H3331",
        "texture": "ST10",
        "decor_name": "Natural Nebraska Oak",
        "group": 7
    },
    {
        "code": "H3332",
        "texture": "ST10",
        "decor_name": "Grey Nebraska Oak",
        "group": 7
    },
    {
        "code": "H3700",
        "texture": "ST10",
        "decor_name": "Natural Pacific Walnut",
        "group": 7
    },
    {
        "code": "H3702",
        "texture": "ST10",
        "decor_name": "Tobacco Pacific Walnut",
        "group": 7
    },
    {
        "code": "H3710",
        "texture": "ST12",
        "decor_name": "Natural Carini Walnut",
        "group": 7
    },
    {
        "code": "H3730",
        "texture": "ST10",
        "decor_name": "Natural Hickory",
        "group": 7
    },
    {
        "code": "U201",
        "texture": "ST19",
        "decor_name": "Pebble Grey",
        "group": 7
    },
    {
        "code": "U599",
        "texture": "ST19",
        "decor_name": "Indigo Blue",
        "group": 7
    },
    {
        "code": "U636",
        "texture": "ST19",
        "decor_name": "Fjord Green",
        "group": 7
    },
    {
        "code": "U702",
        "texture": "ST19",
        "decor_name": "Cashmere Grey",
        "group": 7
    },
    {
        "code": "U708",
        "texture": "ST19",
        "decor_name": "Light Grey",
        "group": 7
    },
    {
        "code": "U727",
        "texture": "ST19",
        "decor_name": "Stone Grey",
        "group": 7
    },
    {
        "code": "U732",
        "texture": "ST19",
        "decor_name": "Dust Grey",
        "group": 7
    },
    {
        "code": "U750",
        "texture": "ST19",
        "decor_name": "Taupe Grey",
        "group": 7
    },
    {
        "code": "U775",
        "texture": "ST19",
        "decor_name": "White Grey",
        "group": 7
    },
    {
        "code": "U960",
        "texture": "ST19",
        "decor_name": "Onyx Grey",
        "group": 7
    },
    {
        "code": "U961",
        "texture": "ST19",
        "decor_name": "Graphite",
        "group": 7
    },
    {
        "code": "U999",
        "texture": "ST19",
        "decor_name": "Black",
        "group": 7
    },
    {
        "code": "W1000",
        "texture": "ST19",
        "decor_name": "Premium White",
        "group": 7
    },
    {
        "code": "F186",
        "texture": "ST9",
        "decor_name": "Light Grey Chicago Concrete",
        "group": 8
    },
    {
        "code": "F283",
        "texture": "ST22",
        "decor_name": "Boston Concrete",
        "group": 8
    },
    {
        "code": "F416",
        "texture": "ST10",
        "decor_name": "Beige Textile",
        "group": 8
    },
    {
        "code": "F417",
        "texture": "ST10",
        "decor_name": "Grey Textile",
        "group": 8
    },
    {
        "code": "F433",
        "texture": "ST10",
        "decor_name": "Anthracite Linen",
        "group": 8
    },
    {
        "code": "F628",
        "texture": "ST16",
        "decor_name": "Silver Grey Metal Slate",
        "group": 8
    },
    {
        "code": "F629",
        "texture": "ST16",
        "decor_name": "Black Gold Metal Slate",
        "group": 8
    },
    {
        "code": "F637",
        "texture": "ST16",
        "decor_name": "White Chromix",
        "group": 8
    },
    {
        "code": "F812",
        "texture": "ST9",
        "decor_name": "White Levanto Marble",
        "group": 8
    },
    {
        "code": "F823",
        "texture": "ST10",
        "decor_name": "Light Cefalu Concrete",
        "group": 8
    },
    {
        "code": "H1122",
        "texture": "ST22",
        "decor_name": "Whitewood",
        "group": 8
    },
    {
        "code": "H1123",
        "texture": "ST22",
        "decor_name": "Graphitewood",
        "group": 8
    },
    {
        "code": "H1253",
        "texture": "ST19",
        "decor_name": "Truffle Brown Branson Robinia",
        "group": 8
    },
    {
        "code": "H1330",
        "texture": "ST10",
        "decor_name": "Vintage Santa Fe Oak",
        "group": 8
    },
    {
        "code": "H1331",
        "texture": "ST10",
        "decor_name": "Grey Santa Fe Oak",
        "group": 8
    },
    {
        "code": "H1387",
        "texture": "ST10",
        "decor_name": "Graphite Grey Denver Oak",
        "group": 8
    },
    {
        "code": "H1399",
        "texture": "ST10",
        "decor_name": "Truffle Brown Denver Oak",
        "group": 8
    },
    {
        "code": "H1401",
        "texture": "ST22",
        "decor_name": "Cascina Pine",
        "group": 8
    },
    {
        "code": "H1714",
        "texture": "ST19",
        "decor_name": "Lincoln Walnut",
        "group": 8
    },
    {
        "code": "H3146",
        "texture": "ST19",
        "decor_name": "Beige Grey Lorenzo Oak",
        "group": 8
    },
    {
        "code": "H3158",
        "texture": "ST19",
        "decor_name": "Grey Vicenza Oak",
        "group": 8
    },
    {
        "code": "H3190",
        "texture": "ST19",
        "decor_name": "Anthracite Fineline Metallic",
        "group": 8
    },
    {
        "code": "H3349",
        "texture": "ST19",
        "decor_name": "Kaisersberg Oak",
        "group": 8
    },
    {
        "code": "F121",
        "texture": "ST87",
        "decor_name": "Anthracite Metal Rock",
        "group": 9
    },
    {
        "code": "F461",
        "texture": "ST10",
        "decor_name": "Anthracite Metal Fabric",
        "group": 9
    },
    {
        "code": "F500",
        "texture": "ST20",
        "decor_name": "Metallic Inox",
        "group": 9
    },
    {
        "code": "U100",
        "texture": "ST27",
        "decor_name": "Mussel",
        "group": 9
    },
    {
        "code": "U104",
        "texture": "ST27",
        "decor_name": "Alabaster White",
        "group": 9
    },
    {
        "code": "U104",
        "texture": "ST30",
        "decor_name": "Alabaster White",
        "group": 9
    },
    {
        "code": "U201",
        "texture": "ST27",
        "decor_name": "Pebble Grey",
        "group": 9
    },
    {
        "code": "U222",
        "texture": "ST30",
        "decor_name": "Crema Beige",
        "group": 9
    },
    {
        "code": "U599",
        "texture": "ST27",
        "decor_name": "Indigo Blue",
        "group": 9
    },
    {
        "code": "U636",
        "texture": "ST27",
        "decor_name": "Fjord Green",
        "group": 9
    },
    {
        "code": "U702",
        "texture": "ST27",
        "decor_name": "Cashmere Grey",
        "group": 9
    },
    {
        "code": "U702",
        "texture": "ST30",
        "decor_name": "Cashmere Grey",
        "group": 9
    },
    {
        "code": "U708",
        "texture": "ST27",
        "decor_name": "Light Grey",
        "group": 9
    },
    {
        "code": "U708",
        "texture": "ST30",
        "decor_name": "Light Grey",
        "group": 9
    },
    {
        "code": "U717",
        "texture": "ST27",
        "decor_name": "Dakar Grey",
        "group": 9
    },
    {
        "code": "U727",
        "texture": "ST27",
        "decor_name": "Stone Grey",
        "group": 9
    },
    {
        "code": "U727",
        "texture": "ST30",
        "decor_name": "Stone Grey",
        "group": 9
    },
    {
        "code": "U732",
        "texture": "ST27",
        "decor_name": "Dust Grey",
        "group": 9
    },
    {
        "code": "U732",
        "texture": "ST30",
        "decor_name": "Dust Grey",
        "group": 9
    },
    {
        "code": "U750",
        "texture": "ST27",
        "decor_name": "Taupe Grey",
        "group": 9
    },
    {
        "code": "U763",
        "texture": "ST30",
        "decor_name": "Pearl Grey",
        "group": 9
    },
    {
        "code": "U775",
        "texture": "ST27",
        "decor_name": "White Grey",
        "group": 9
    },
    {
        "code": "U960",
        "texture": "ST27",
        "decor_name": "Onyx Grey",
        "group": 9
    },
    {
        "code": "U961",
        "texture": "ST27",
        "decor_name": "Graphite",
        "group": 9
    },
    {
        "code": "U961",
        "texture": "ST30",
        "decor_name": "Graphite",
        "group": 9
    },
    {
        "code": "U999",
        "texture": "ST27",
        "decor_name": "Black",
        "group": 9
    },
    {
        "code": "U999",
        "texture": "ST30",
        "decor_name": "Black",
        "group": 9
    },
    {
        "code": "W1000",
        "texture": "ST27",
        "decor_name": "Premium White",
        "group": 9
    },
    {
        "code": "W1000",
        "texture": "ST30",
        "decor_name": "Premium White",
        "group": 9
    },
    {
        "code": "W1100",
        "texture": "ST30",
        "decor_name": "Alpine White",
        "group": 9
    },
    {
        "code": "W1200",
        "texture": "ST27",
        "decor_name": "Porcelain White",
        "group": 9
    },
    {
        "code": "W1200",
        "texture": "ST9",
        "decor_name": "Porcelain White",
        "group": 4
    },
    {
        "code": "W980",
        "texture": "ST2",
        "decor_name": "Platinum White",
        "group": 2
    },
    {
        "code": "H1250",
        "texture": "ST36",
        "decor_name": "Navarra Ash",
        "group": 10
    },
    {
        "code": "H1377",
        "texture": "ST36",
        "decor_name": "Sand Orleans Oak",
        "group": 10
    },
    {
        "code": "H1379",
        "texture": "ST36",
        "decor_name": "Brown Orleans Oak",
        "group": 10
    },
    {
        "code": "H1486",
        "texture": "ST36",
        "decor_name": "Pasadena Pine",
        "group": 10
    },
    {
        "code": "H3330",
        "texture": "ST36",
        "decor_name": "Natural Anthor Oak",
        "group": 10
    },
    {
        "code": "H3452",
        "texture": "ST36",
        "decor_name": "Graphite Grey Fleetwood",
        "group": 10
    },
    {
        "code": "U998",
        "texture": "ST38",
        "decor_name": "Shadow Black",
        "group": 10
    },
    {
        "code": "W1000",
        "texture": "ST38",
        "decor_name": "Premium White",
        "group": 10
    },
    {
        "code": "H1176",
        "texture": "ST37",
        "decor_name": "White Halifax Oak",
        "group": 11
    },
    {
        "code": "H1180",
        "texture": "ST37",
        "decor_name": "Natural Halifax Oak",
        "group": 11
    },
    {
        "code": "H1181",
        "texture": "ST37",
        "decor_name": "Tobacco Halifax Oak",
        "group": 11
    },
    {
        "code": "H1336",
        "texture": "ST37",
        "decor_name": "Sand Grey Glazed Halifax Oak",
        "group": 11
    },
    {
        "code": "H3176",
        "texture": "ST37",
        "decor_name": "Pewter Halifax Oak",
        "group": 11
    },
    {
        "code": "H3178",
        "texture": "ST37",
        "decor_name": "Black Glazed Halifax Oak",
        "group": 11
    },
    {
        "code": "H3309",
        "texture": "ST28",
        "decor_name": "Sand Gladstone Oak",
        "group": 11
    },
    {
        "code": "H3325",
        "texture": "ST28",
        "decor_name": "Tobacco Gladstone Oak",
        "group": 11
    },
    {
        "code": "H3326",
        "texture": "ST28",
        "decor_name": "Grey-Beige Gladstone Oak",
        "group": 11
    },
    {
        "code": "H3335",
        "texture": "ST28",
        "decor_name": "White Gladstone Oak",
        "group": 11
    },
    {
        "code": "H3342",
        "texture": "ST28",
        "decor_name": "Sepia Gladstone Oak",
        "group": 11
    },
    {
        "code": "H3403",
        "texture": "ST38",
        "decor_name": "White Mountain Larch",
        "group": 11
    },
    {
        "code": "H3406",
        "texture": "ST38",
        "decor_name": "Anthracite Mountain Larch",
        "group": 11
    },
    {
        "code": "F120",
        "texture": "PM",
        "decor_name": "Light Grey Metal Rock",
        "group": 12
    },
    {
        "code": "F206",
        "texture": "PG",
        "decor_name": "Black Pietra Grigia",
        "group": 13
    },
    {
        "code": "F206",
        "texture": "PM",
        "decor_name": "Black Pietra Grigia",
        "group": 12
    },
    {
        "code": "F627",
        "texture": "PM",
        "decor_name": "Dark Steel",
        "group": 12
    },
    {
        "code": "F812",
        "texture": "PG",
        "decor_name": "White Levanto Marble",
        "group": 13
    },
    {
        "code": "F812",
        "texture": "PM",
        "decor_name": "White Levanto Marble",
        "group": 12
    },
    {
        "code": "U323",
        "texture": "PG",
        "decor_name": "Chilli Red",
        "group": 13
    },
    {
        "code": "U599",
        "texture": "PM",
        "decor_name": "Indigo Blue",
        "group": 12
    },
    {
        "code": "U702",
        "texture": "PG",
        "decor_name": "Cashmere Grey",
        "group": 13
    },
    {
        "code": "U702",
        "texture": "PM",
        "decor_name": "Cashmere Grey",
        "group": 12
    },
    {
        "code": "U708",
        "texture": "PG",
        "decor_name": "Light Grey",
        "group": 13
    },
    {
        "code": "U708",
        "texture": "PM",
        "decor_name": "Light Grey",
        "group": 12
    },
    {
        "code": "U727",
        "texture": "PG",
        "decor_name": "Stone Grey",
        "group": 13
    },
    {
        "code": "U727",
        "texture": "PM",
        "decor_name": "Stone Grey",
        "group": 12
    },
    {
        "code": "U732",
        "texture": "PG",
        "decor_name": "Dust Grey",
        "group": 13
    },
    {
        "code": "U732",
        "texture": "PM",
        "decor_name": "Dust Grey",
        "group": 12
    },
    {
        "code": "U961",
        "texture": "PG",
        "decor_name": "Graphite",
        "group": 13
    },
    {
        "code": "U961",
        "texture": "PM",
        "decor_name": "Graphite",
        "group": 12
    },
    {
        "code": "U999",
        "texture": "PG",
        "decor_name": "Black",
        "group": 13
    },
    {
        "code": "U999",
        "texture": "PM",
        "decor_name": "Black",
        "group": 12
    },
    {
        "code": "W1000",
        "texture": "PG",
        "decor_name": "Premium White",
        "group": 13
    },
    {
        "code": "W1000",
        "texture": "PM",
        "decor_name": "Premium White",
        "group": 12
    },
    {
        "code": "W1100",
        "texture": "PG",
        "decor_name": "Alpine White",
        "group": 13
    },
    {
        "code": "W1100",
        "texture": "PM",
        "decor_name": "Alpine White",
        "group": 12
    }
] as const;
