import { CutAndEdgeOrderGroup } from '../../types';
import { isMFCGroup } from './isMFCGroup';


export function getEdgingDisplayDescription (group : CutAndEdgeOrderGroup) : string {

    
    if(isMFCGroup(group) && group.edgingColour) {

        return group.edgingColour?.decor_name || '';
    }

    return '';

};