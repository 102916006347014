import React, { ReactElement } from 'react';

export interface LoadingContextType {
    openLoading : (label? : string) => void;
    closeLoading : (delay? : number) => void;
    isLoadingOpen : () => boolean | string;
    isLoadingClosing : () => boolean;
}

const defaultFunctions = {
    openLoading : () => {},
    closeLoading : () => {},
    isLoadingOpen : () => false,
    isLoadingClosing : () => false
}


export const LoadingContext = React.createContext<LoadingContextType>(defaultFunctions as LoadingContextType);

export const useLoading = () : LoadingContextType => React.useContext(LoadingContext);


const LoadingProviderRenderFunction:React.ForwardRefRenderFunction<LoadingContextType, {children : React.ReactNode, loading? : boolean | string}>= ({children, loading = false}, ref) : ReactElement => {

    const [isLoadingOpen, setIsLoadingOpen] = React.useState<string | boolean>(loading);
    const [isLoadingClosing, setIsLoadingClosing] = React.useState(false);

    const openLoading : LoadingContextType['openLoading'] = label  => {
        label && setIsLoadingOpen(label); 
        !label && setIsLoadingOpen(true);
    }

    const closeLoading : LoadingContextType['closeLoading'] = (delay = 0) => {
        setTimeout(() => {
            setIsLoadingClosing(true);
            cleanUpClosing()
        }, delay);
    }
    const _isLoadingOpen : LoadingContextType['isLoadingOpen'] = () => {
        if(isLoadingOpen) return isLoadingOpen;
        return false;
    }

    const _isLoadingClosing : LoadingContextType['isLoadingClosing'] = () => {
        if(isLoadingClosing) return true;
        return false;
    }


    function cleanUpClosing(){
        setTimeout(() => {
            setIsLoadingClosing(false);
            setIsLoadingOpen(false);
        }, 200)
    }

    React.useImperativeHandle(ref, () => ({
        openLoading : openLoading,
        closeLoading : closeLoading,
        isLoadingOpen : _isLoadingOpen,
        isLoadingClosing : _isLoadingClosing
    }));

    return (
        <LoadingContext.Provider value={{openLoading, closeLoading, isLoadingOpen : _isLoadingOpen, isLoadingClosing : _isLoadingClosing}} >
            {children}
        </LoadingContext.Provider>
    )

};

// NOTE: This can be written more succinctly by using an anonymous function in the forward ref function which automatically infers the ForwardRefRenderFunction type
export const LoadingProvider = React.forwardRef<LoadingContextType, {children : React.ReactNode, loading? : boolean | string}>(LoadingProviderRenderFunction);